import React, { useState, useEffect } from 'react';
import styles from './Packages.module.css';
import { DataProviderInstance } from '../../api/DataProvider';
import CustomButton from '../../components/buttons/CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  InputAdornment
} from '@mui/material';

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPackage, setEditingPackage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    validity_days: '',
    active: true,
    procedures: [] // Array de {procedure_id, quantity}
  });

  // Estado para armazenar o valor total dos procedimentos
  const [totalProceduresValue, setTotalProceduresValue] = useState(0);
  
  // Estado para armazenar informações de desconto
  const [discount, setDiscount] = useState({
    difference: 0,
    percentage: 0
  });

  const dataProvider = DataProviderInstance();
  const { CompanyConfig } = dataProvider;
  const procedures = CompanyConfig.Procedures || [];

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = () => {
    dataProvider.GetPackages((response) => {
      if (response.success) {
        setPackages(response.data || []);
      }
    });
  };

  const handleOpenDialog = (pkg = null) => {
    if (pkg) {
      setEditingPackage(pkg);
      setFormData({
        name: pkg.name,
        description: pkg.description,
        price: pkg.price?.toString() || '',
        validity_days: pkg.validity_days?.toString() || '',
        active: pkg.active,
        procedures: pkg.procedures || []
      });
    } else {
      setEditingPackage(null);
      setFormData({
        name: '',
        description: '',
        price: '',
        validity_days: '',
        active: true,
        procedures: []
      });
    }
    setOpenDialog(true);
  };

  // Função para calcular o valor total dos procedimentos
  const calculateTotalProceduresValue = (procedures) => {
    return procedures.reduce((total, proc) => {
      const procedure = CompanyConfig.Procedures.find(p => p.Id === proc.procedure_id);
      return total + (procedure?.Price || 0) * proc.quantity;
    }, 0);
  };

  // Função para calcular o desconto
  const calculateDiscount = (totalValue, packagePrice) => {
    if (!packagePrice || packagePrice <= 0) return { difference: 0, percentage: 0 };
    
    const difference = totalValue - packagePrice;
    const percentage = ((difference / totalValue) * 100).toFixed(1);
    
    return {
      difference: Math.max(0, difference),
      percentage: Math.max(0, percentage)
    };
  };

  const handleProcedureChange = (procedure, quantity) => {
    setFormData(prev => {
      const procedures = [...prev.procedures];
      const index = procedures.findIndex(p => p.procedure_id === procedure.Id);
      
      if (quantity <= 0) {
        // Remove o procedimento se a quantidade for 0 ou negativa
        if (index !== -1) {
          procedures.splice(index, 1);
        }
      } else {
        // Atualiza ou adiciona o procedimento
        if (index !== -1) {
          procedures[index] = {
            procedure_id: procedure.Id,
            quantity: quantity
          };
        } else {
          procedures.push({
            procedure_id: procedure.Id,
            quantity: quantity
          });
        }
      }

      const newFormData = {
        ...prev,
        procedures
      };

      // Calcula o novo valor total dos procedimentos
      const newTotal = calculateTotalProceduresValue(procedures);
      setTotalProceduresValue(newTotal);

      // Atualiza o desconto se houver um preço definido
      if (newFormData.price) {
        setDiscount(calculateDiscount(newTotal, parseFloat(newFormData.price)));
      }

      return newFormData;
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingPackage(null);
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => {
      const newValue = name === 'active' ? checked : value;
      const newFormData = {
        ...prev,
        [name]: newValue
      };

      // Se o campo alterado for o preço, atualiza o desconto
      if (name === 'price') {
        setDiscount(calculateDiscount(totalProceduresValue, parseFloat(value)));
      }

      return newFormData;
    });
  };

  const handleSave = () => {
    const packageData = {
      ...formData,
      price: parseFloat(formData.price),
      validity_days: parseInt(formData.validity_days)
    };

    if (editingPackage) {
      dataProvider.UpdatePackage(
        editingPackage.package_id,
        packageData,
        (response) => {
          if (response.success) {
            loadPackages();
            handleCloseDialog();
          }
        }
      );
    } else {
      dataProvider.CreatePackage(
        packageData,
        (response) => {
          if (response.success) {
            loadPackages();
            handleCloseDialog();
          }
        }
      );
    }
  };

  return (
    <div className='page-content'>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Pacotes de Serviços</h2>
          <CustomButton
            style="primary"
            variant="contained"
            label="Novo Pacote"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => handleOpenDialog()}
          />
        </div>

        <div className={styles.packagesList}>
          {[...packages].sort((a, b) => {
            // Primeiro ordena por status (ativos primeiro)
            if (a.active !== b.active) {
              return b.active ? 1 : -1; // b.active primeiro se true
            }
            // Depois ordena por nome dentro de cada grupo
            return a.name.localeCompare(b.name, 'pt-BR');
          }).map((pkg) => (
            <div key={pkg.package_id} className={styles.packageCard}>
              <div className={styles.packageHeader}>
                <div>
                  <h3>{pkg.name}</h3>
                  <span className={`${styles.status} ${pkg.active ? styles.active : styles.inactive}`}>
                    {pkg.active ? 'Ativo' : 'Inativo'}
                  </span>
                </div>
                <div className={styles.actions}>
                  <Button
                    startIcon={<FontAwesomeIcon icon={faEdit} />}
                    onClick={() => handleOpenDialog(pkg)}
                  >
                    Editar
                  </Button>
                </div>
              </div>

              <p className={styles.description}>{pkg.description}</p>

              <div className={styles.details}>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Preço</span>
                  <span className={styles.value}>R$ {pkg.price.toFixed(2)}</span>
                </div>
                <div className={styles.detailItem}>
                  <span className={styles.label}>Validade</span>
                  <span className={styles.value}>{pkg.validity_days} dias</span>
                </div>
              </div>

              {pkg.procedures && pkg.procedures.length > 0 && (
                <div className={styles.proceduresList}>
                  <h4>Procedimentos Incluídos:</h4>
                  {pkg.procedures.map((proc) => {
                    return (
                      <div key={proc.procedure_id} className={styles.procedureItem}>
                        <span>{procedures.find(p => p.Id === proc.procedure_id)?.Label || 'Procedimento não encontrado'}</span>
                        <span className={styles.quantity}>x{proc.quantity}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>

        <Dialog 
          open={openDialog} 
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {editingPackage ? 'Editar Pacote' : 'Novo Pacote'}
          </DialogTitle>
          <DialogContent>
            <div className={styles.form}>
              <div className={styles.formHeader}>
                <div className={styles.formRow}>
                  <TextField
                    label="Nome do Pacote"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    sx={{ flex: 1 }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.active}
                        onChange={handleInputChange}
                        name="active"
                        color="primary"
                      />
                    }
                    label="Pacote Ativo"
                  />
                </div>

                <TextField
                  label="Descrição"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={2}
                />
              </div>

              <div className={styles.proceduresSection}>
                <h4>Procedimentos do Pacote</h4>
                <p className={styles.proceduresDescription}>
                  Um pacote é um conjunto de procedimentos vendidos por um valor específico.
                </p>
                {procedures.map((procedure) => {
                  const selectedProc = formData.procedures.find(p => p.procedure_id === procedure.Id);
                  return (
                    <div key={procedure.procedure_id} className={styles.procedureInput}>
                      <span>{procedure.Label}</span>
                      <TextField
                        className={styles.procedureInputField}
                        label="Quantidade"
                        type="number"
                        value={selectedProc?.quantity || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Permite apenas números positivos
                          if (value === '' || /^\d+$/.test(value)) {
                            const numValue = value === '' ? 0 : parseInt(value);
                            handleProcedureChange(procedure, numValue);
                          }
                        }}
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                        size="small"
                        variant="outlined"
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Seção de Preço */}
              <div className={styles.priceSection}>
                <div className={styles.priceSectionHeader}>Definição de Preço</div>
                
                <div className={styles.priceInputContainer}>
                  <div className={styles.priceWrapper}>
                    {/* Input de preço do pacote */}
                    <TextField
                      label="Preço do Pacote"
                      name="price"
                      value={formData.price}
                      onChange={handleInputChange}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                      }}
                      className={styles.priceInput}
                      onWheel={(e) => e.target.blur()}
                    />
                    {/* Porcentagem inline */}
                    {formData.price && (
                      <div className={`${styles.percentageTag} ${
                        discount.difference > 0
                          ? styles.discount
                          : (formData.price > totalProceduresValue ? styles.increase : '')
                      }`}>
                        {discount.difference > 0
                          ? `-${discount.percentage}%`
                          : (formData.price > totalProceduresValue
                              ? `+${((formData.price - totalProceduresValue) / totalProceduresValue * 100).toFixed(1)}%`
                              : '0%'
                            )
                        }
                      </div>
                    )}
                  </div>

                  {/* Valor normal */}
                  <div className={styles.normalPrice}>
                    <div className={styles.normalPriceLabel}>Preço normal</div>
                    <div className={styles.normalPriceValue}>R$ {totalProceduresValue.toFixed(2)}</div>
                  </div>
                </div>
              </div>

              {/* Campo de validade fora da seção de preço */}
              <div style={{ marginTop: '16px' }}>
                <TextField
                  label="Validade do pacote (dias)"
                  name="validity_days"
                  value={formData.validity_days}
                  onChange={handleInputChange}
                  type="number"
                  size="small"
                  fullWidth
                  onWheel={(e) => e.target.blur()}
                  sx={{
                    '& input': {
                      fontSize: '0.875rem',
                      padding: '8px 10px'
                    },
                    '& label': {
                      fontSize: '0.875rem'
                    }
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={!formData.procedures.some(proc => proc.quantity > 0)}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Packages;