import React, { useEffect } from 'react';
import { useState } from "react";
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import { TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch, Button } from "@mui/material";
import IconMoney from '@mui/icons-material/MonetizationOnOutlined';
import IconPackage from '@mui/icons-material/LocalMallOutlined';
import { DataProviderInstance } from "../../api/DataProvider";
import { GetFriendlyCPF } from '../../utils/Utils';
import PackagePurchaseFlow from "../../components/package-purchase/PackagePurchaseFlow";

function CreateInvoicePopup(props) {

    const getModifier = (id) => {
        // Se estiver usando créditos, não há modificador de preço
        if (id === 'credits') return 0;
        return CompanyConfig.PaymentOptions.find(item => item.Id == id)?.PriceModifier || 0;
    }

    const getPrice = () => {
        // Se estiver usando créditos, retorna o preço original
        if (paymentMethod === 'credits') return Number(Data.price);
        let modifier = getModifier(paymentMethod);
        return Number(Data.price) + Number(modifier);
    }

    const { Data, OnHide, OnCreated } = props;

    const { CompanyConfig, SetAppointmentInvoice, UpdateInvoice, GetPatientById, GetProcedureById, SetPatientDocument, GetInvoice, GetPatientWallet, DeductWalletCredits } = DataProviderInstance();

    const [paymentMethod, setPaymentMethod] = useState(CompanyConfig.PaymentOptions[0]?.Id);
    const [currentPrice, setPrice] = useState(getPrice());
    const [issueDocument, setIssueDocument] = useState(0);
    const [editing, setEditing] = useState(Data.invoiceId != "0");

    const [invoiceData, setInvoiceData] = useState(null);
    const [walletData, setWalletData] = useState(null);
    const [hasAvailableCredits, setHasAvailableCredits] = useState(false);
    const [isPackagePurchaseOpen, setIsPackagePurchaseOpen] = useState(false);

    // Busca os créditos do paciente quando o popup abre
    useEffect(() => {
        if (Data.patient) {
            GetPatientWallet(Data.patient, (response) => {
                if (response.success) {
                    setWalletData(response.data);
                    // Verifica se há créditos disponíveis para o procedimento atual
                    const totalAvailableCredits = response.data?.service_credits?.reduce((total, credit) => {
                        const today = new Date().setHours(0,0,0,0);
                        if (credit.procedure_id == Data.procedure &&
                            credit.credits_remaining > 0 &&
                            (credit.expiration_date === null || new Date(credit.expiration_date).setHours(0,0,0,0) >= today)) {
                            return total + credit.credits_remaining;
                        }
                        return total;
                    }, 0);
                    setHasAvailableCredits(totalAvailableCredits > 0);
                }
            });
        }
    }, [Data.patient]);

    useEffect(() => {

        setPrice(getPrice());
    }, [paymentMethod]);

    useEffect(() => {
        if(editing)
        {
            GetInvoice(Data.invoiceId, (data) => {
                setInvoiceData(data); 
            });
        }

    }, [editing]);

    useEffect(() => {
        if(invoiceData != null)
        {
            setPrice(invoiceData.invoice.price);
            setPaymentMethod(invoiceData.invoice.paymentMethod);
            setIssueDocument(invoiceData.invoice.issueDocument == "1" ? 1 : 0);
        }
    }, [invoiceData]);

    function OnCloseClickHandler() {
        Hide(false);
    }

    function Hide(needsPageReload) {
        OnHide(needsPageReload);
    }

    function createInvoiceHandler() {
        let invoiceData = {
            appointmentId: Data.id,
            patient: Data.patient,
            price: currentPrice,
            pay: paymentMethod === "0" ? "credits" : paymentMethod, // Convertendo "0" para "credits"
            issue: issueDocument,
            procedure: Data.procedure
        };

        // Verifica novamente se há créditos disponíveis (para evitar uso de créditos expirados)
        if (invoiceData.pay === 'credits') {
            const totalAvailableCredits = walletData?.service_credits?.reduce((total, credit) => {
                if (credit.procedure_id == invoiceData.procedure &&
                    credit.credits_remaining > 0 &&
                    (credit.expiration_date === null || new Date(credit.expiration_date).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0))) {
                    return total + credit.credits_remaining;
                }
                return total;
            }, 0);

            if (!totalAvailableCredits) {
                alert('Não há mais créditos disponíveis para este procedimento.');
                return;
            }

            
            // Deduz os créditos antes de criar a fatura
            DeductWalletCredits(
                walletData.wallet_id,
                invoiceData.procedure,
                `Uso de crédito para ${GetProcedureById(invoiceData.procedure).Label} em ${new Date().toLocaleDateString()}`,
                (deductResponse) => {
                    if (deductResponse.success) {
                        createInvoiceAndDocument(invoiceData);
                    } else {
                        alert('Erro ao utilizar créditos. Por favor, tente novamente.');
                    }
                }
            );
        } else {
            createInvoiceAndDocument(invoiceData);
        }
    }

    function createInvoiceAndDocument(data) {
        SetAppointmentInvoice(data.appointmentId, data.patient, data.price, data.pay, data.procedure, data.issue, (response) => {
            if(OnCreated) OnCreated(response);

            createInvoiceDocument((response) => {
                Hide(true);
            });
        });
    }
    
    function updateInvoiceHandler() {

        const invoiceId = invoiceData.invoice.id;

        const price = currentPrice;
        const pay = paymentMethod;
        const issue = issueDocument;

        UpdateInvoice(invoiceId, price, pay, issue, (response) => {
            Hide(true);
        });
    }


    function createInvoiceDocument(callback) {
        const appointmentData = Data;
        const patientData = GetPatientById(appointmentData.patient);
        const procedureData = GetProcedureById(appointmentData.procedure);
        const appointmentDate = new Date(appointmentData.date);

        const documentTemplate = CompanyConfig.DocumentTemplates.find(item => item.tag == "Recibo");

        const currentTitle = documentTemplate.title;

        const friendyDate = appointmentDate.toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' });

        const cpf = GetFriendlyCPF(patientData.cpf);
        const price = currentPrice;

        const paymentMethodLabel = paymentMethod === 'credits' ? 'Créditos do Pacote' : CompanyConfig.PaymentOptions.find(item => item.Id == paymentMethod).Label;

        const currentText = documentTemplate.text
            .replace("{patient-name}", "" + patientData.name + "")
            .replace("{patient-cpf}", "" + cpf + "")
            .replace("{appointment-date}", "" + friendyDate + "")
            .replace("{appointment-price}", "" + price + " (" + paymentMethodLabel + ")");

        const formattedAppointmentDate = appointmentDate.toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-');

        SetPatientDocument(appointmentData.patient, formattedAppointmentDate, appointmentData.id, currentTitle, currentText, (response) => {
            callback(response);
        });
    }

    function handlePaymentMethodChange(event) {
        setPaymentMethod(event.target.value);
    }

    function GetPositiveButton() {
        // Se estiver editando e for pagamento com créditos, não mostra botão
        if(editing && (paymentMethod === 'credits' || paymentMethod === "0")) {
            return null;
        }
        
        if(editing) {
            return <CustomButton variant="contained" style="primary" label={"Salvar"} onClick={updateInvoiceHandler} />
        }

        return <CustomButton variant="contained" style="primary" label={"Criar fatura"} icon={<IconMoney />} onClick={createInvoiceHandler} />
    }

    function getModifierLabel(id) {
        // Se estiver usando créditos, não mostra modificador
        if (id === 'credits') return "";
        
        const option = CompanyConfig.PaymentOptions.find(item => item.Id == id);
        if (!option) return "";
        
        const modifier = option.PriceModifier;
        if (modifier == 0) return "";
        
        return modifier > 0
            ? `(+ R$${modifier})`
            : `(- R$${modifier * -1})`;
    }

    function GetContent() {

        let friendlyDate = new Date().toLocaleDateString("pt-PT", { year: 'numeric', month: 'numeric', day: 'numeric' });
  
        return (
            <div style={{ marginTop: "10px" }}>
                <div className="form">
                    <div className="form-group">
                        <div className="form-line">
                            <TextField label="Procedimento" value={GetProcedureById(Data.procedure).Label} disabled />
                            <TextField label="Data" value={friendlyDate} disabled />
                        </div>
                    </div>
                    <TextField label="Paciente" value={GetPatientById(Data.patient).name} disabled />
                    
                    {/* Indicação de pagamento via créditos quando estiver editando */}
                    {editing && (paymentMethod === 'credits' || paymentMethod === "0") && (
                        <div className="form-group" style={{ marginTop: '20px', padding: '15px', backgroundColor: '#e3f2fd', borderRadius: '4px' }}>
                            <div style={{ color: '#1976d2'}}>
                                Este atendimento foi pago utilizando créditos do pacote
                            </div>
                        </div>
                    )}
                    
                    {/* Seção de Créditos - só mostra se não estiver editando */}
                    {!editing && (
                        <div className="form-group" style={{ marginTop: '20px', padding: '15px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
                            {hasAvailableCredits ? (
                                <>
                                    <div style={{ marginBottom: '10px', color: '#2196F3', fontWeight: 'bold' }}>
                                        Créditos disponíveis para este procedimento!
                                    </div>
                                    {(() => {
                                        // Agrupa os créditos por data de expiração
                                        const groupedCredits = walletData?.service_credits?.reduce((acc, credit) => {
                                            if (credit.procedure_id == Data.procedure &&
                                                credit.credits_remaining > 0 &&
                                                (credit.expiration_date === null || new Date(credit.expiration_date).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0))) {
                                                
                                                const key = credit.expiration_date || 'no_expiration';
                                                if (!acc[key]) {
                                                    acc[key] = {
                                                        procedure_name: credit.procedure_name,
                                                        credits_remaining: 0,
                                                        expiration_date: credit.expiration_date
                                                    };
                                                }
                                                acc[key].credits_remaining += credit.credits_remaining;
                                            }
                                            return acc;
                                        }, {});

                                        // Converte o objeto agrupado em array e ordena por data de expiração
                                        return Object.values(groupedCredits)
                                            .sort((a, b) => {
                                                if (!a.expiration_date) return 1;
                                                if (!b.expiration_date) return -1;
                                                return new Date(a.expiration_date) - new Date(b.expiration_date);
                                            })
                                            .map((credit, index) => (
                                                <div key={index} style={{ marginBottom: '10px', padding: '8px', backgroundColor: '#fff', borderRadius: '4px' }}>
                                                    <div style={{ fontWeight: 'bold', color: '#1976d2' }}>
                                                        {credit.procedure_name}
                                                    </div>
                                                    <div>Créditos disponíveis: {credit.credits_remaining}</div>
                                                    <div style={{ fontSize: '0.9em', color: '#666' }}>
                                                        {credit.expiration_date
                                                            ? `Expira em: ${new Date(credit.expiration_date).toLocaleDateString()}`
                                                            : 'Sem data de expiração'}
                                                    </div>
                                                </div>
                                            ));
                                    })()}
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={paymentMethod === 'credits'}
                                                onChange={(e) => handlePaymentMethodChange({ target: { value: e.target.checked ? 'credits' : CompanyConfig.PaymentOptions[0]?.Id } })}
                                            />
                                        }
                                        label="Usar créditos disponíveis"
                                    />
                                </>
                            ) : (
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ marginBottom: '10px', color: '#666' }}>
                                        Não há créditos disponíveis para este procedimento
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<IconPackage />}
                                        onClick={() => setIsPackagePurchaseOpen(true)}
                                    >
                                        Adicionar pacote
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Seção de Pagamento - só aparece se não estiver usando créditos */}
                    {paymentMethod !== 'credits' && paymentMethod !== "0" && (
                        <div className="form-group">
                            <div className="form-line">
                                <TextField
                                    label="Valor"
                                    type="number"
                                    value={currentPrice}
                                    onChange={(e) => { setPrice(e.target.value) }}
                                    disabled={paymentMethod === 'credits'}
                                />
                                {(paymentMethod === 'credits' || paymentMethod === "0") ? (
                                    <TextField
                                        label="Método de pagamento"
                                        value="Utilização de créditos"
                                        disabled
                                        style={{ flex: "1" }}
                                    />
                                ) : (
                                    <FormControl style={{ flex: "1" }}>
                                        <InputLabel>Método de pagamento</InputLabel>
                                        <Select
                                            value={paymentMethod}
                                            label="Método de pagamento"
                                            onChange={handlePaymentMethodChange}>
                                            {CompanyConfig.PaymentOptions.map((option) => (
                                                <MenuItem key={option.Id} value={option.Id}>
                                                    {option.Label} {getModifierLabel(option.Id)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                            <div className="form-line">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={issueDocument}
                                            onChange={(e) => { setIssueDocument(e.target.checked ? 1 : 0) }}
                                        />
                                    }
                                    label="Emitir nota fiscal ?"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const GetTitle = () => {
        if(editing)
        {
            return "Editar faturamento" ;
        }
        
        return "Faturar consulta";
    }

    const handlePurchaseComplete = (response) => {
        // Atualiza os créditos do paciente após a compra
        if (Data.patient) {
            GetPatientWallet(Data.patient, (response) => {
                if (response.success) {
                    setWalletData(response.data);
                    // Verifica novamente se há créditos disponíveis
                    const totalAvailableCredits = response.data?.service_credits?.reduce((total, credit) => {
                        const today = new Date().setHours(0,0,0,0);
                        if (credit.procedure_id == Data.procedure &&
                            credit.credits_remaining > 0 &&
                            (credit.expiration_date === null || new Date(credit.expiration_date).setHours(0,0,0,0) >= today)) {
                            return total + credit.credits_remaining;
                        }
                        return total;
                    }, 0);
                    setHasAvailableCredits(totalAvailableCredits > 0);
                    // Se houver créditos disponíveis, seleciona automaticamente o pagamento por créditos
                    if (totalAvailableCredits > 0) {
                        handlePaymentMethodChange({ target: { value: 'credits' } });
                    }
                }
            });
        }
    };

    return (
        <>
            <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetPositiveButton()} OnClose={OnCloseClickHandler} />
            
            <PackagePurchaseFlow
                open={isPackagePurchaseOpen}
                onClose={() => setIsPackagePurchaseOpen(false)}
                onPurchaseComplete={handlePurchaseComplete}
                companyConfig={CompanyConfig}
                walletId={walletData?.wallet_id}
            />
        </>
    );
}

export default CreateInvoicePopup;