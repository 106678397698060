import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import { format } from 'date-fns';
import styles from './TransactionsPage.module.css';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import CustomButton from '../../components/buttons/CustomButton';
import Loading from '../../components/loading/Loading';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import DateRangeHeader from '../../components/dateRangeHeader/DateRangeHeader';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
    Switch
} from "@mui/material";
import IconFinance from "@mui/icons-material/MonetizationOnOutlined";
import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";
import IconAdd from "@mui/icons-material/Add";
import IconIncome from "@mui/icons-material/TrendingUp";
import IconExpense from "@mui/icons-material/TrendingDown";
import IconBalance from "@mui/icons-material/AccountBalance";

// Componente do formulário memoizado
const TransactionForm = React.memo(({ 
    formData, 
    handleFormChange, 
    categories,
    handleDateChange,
    handleRecurrenceStartDateChange,
    handleRecurrenceEndDateChange,
    handleHasEndDateChange
}) => {
    return (
        <div className={styles.formContainer}>
            <FormControl fullWidth margin="normal">
                <InputLabel>Tipo</InputLabel>
                <Select
                    name="type"
                    value={formData.type}
                    onChange={handleFormChange}
                    label="Tipo"
                >
                    <MenuItem value="expense">Despesa</MenuItem>
                    <MenuItem value="income">Receita</MenuItem>
                </Select>
            </FormControl>

            <TextField
                fullWidth
                margin="normal"
                label="Descrição"
                name="description"
                value={formData.description}
                onChange={handleFormChange}
                required
            />

            <TextField
                fullWidth
                margin="normal"
                label="Valor"
                name="amount"
                type="number"
                value={formData.amount}
                onChange={handleFormChange}
                InputProps={{
                    startAdornment: 'R$',
                    step: '0.01'
                }}
                required
            />

            <DatePicker
                label="Data"
                value={dayjs(formData.transactionDate)}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                slotProps={{
                    textField: {
                        fullWidth: true,
                        margin: "normal",
                        required: true
                    }
                }}
            />

            <FormControl fullWidth margin="normal">
                <InputLabel>Categoria</InputLabel>
                <Select
                    name="categoryId"
                    value={formData.categoryId}
                    onChange={handleFormChange}
                    label="Categoria"
                    required
                >
                    <MenuItem value="">Selecione uma categoria</MenuItem>
                    {categories
                        .filter(cat => cat.type === formData.type)
                        .map(category => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>

            <FormControlLabel
                control={
                    <Checkbox
                        name="isRecurring"
                        checked={formData.isRecurring}
                        onChange={handleFormChange}
                    />
                }
                label="Transação Recorrente"
            />

            {formData.isRecurring && (
                <div className={styles.recurrenceFields}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Frequência</InputLabel>
                        <Select
                            name="recurrenceFrequency"
                            value={formData.recurrenceFrequency}
                            onChange={handleFormChange}
                            label="Frequência"
                        >
                            <MenuItem value="daily">Diária</MenuItem>
                            <MenuItem value="weekly">Semanal</MenuItem>
                            <MenuItem value="monthly">Mensal</MenuItem>
                            <MenuItem value="yearly">Anual</MenuItem>
                        </Select>
                    </FormControl>

                    <DatePicker
                        label="Data Início"
                        value={dayjs(formData.recurrenceStartDate)}
                        onChange={handleRecurrenceStartDateChange}
                        format="DD/MM/YYYY"
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                margin: "normal",
                                required: true
                            }
                        }}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={!!formData.hasEndDate}
                                onChange={handleHasEndDateChange}
                            />
                        }
                        label="Definir data de término"
                    />

                    {formData.hasEndDate && (
                        <DatePicker
                            label="Data Fim"
                            value={formData.recurrenceEndDate ? dayjs(formData.recurrenceEndDate) : null}
                            onChange={handleRecurrenceEndDateChange}
                            format="DD/MM/YYYY"
                            minDate={dayjs(formData.recurrenceStartDate)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    margin: "normal",
                                    required: true,
                                    error: formData.hasEndDate && !formData.recurrenceEndDate,
                                    helperText: formData.hasEndDate && !formData.recurrenceEndDate ?
                                        "Data de término é obrigatória quando habilitada" : ""
                                }
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
});

const TransactionsPage = () => {
    const {
        GetFinancialTransactions, GetActiveRecurringTransactions,
        GetFinancialCategories,
        SetFinancialTransaction,
        UpdateFinancialTransaction,
        DeleteFinancialTransaction
    } = DataProviderInstance();

    const [isLoading, setIsLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [recurringTransactions, setRecurringTransactions] = useState([]);
    const [categories, setCategories] = useState([]);

    // Filtros
    const [filters, setFilters] = useState({
        startDate: format(new Date(), 'yyyy-MM-01'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        type: 'all',
        categoryId: 'all'
    });

    // Estado do formulário
    const [formData, setFormData] = useState({
        type: 'expense',
        description: '',
        amount: '',
        transactionDate: format(new Date(), 'yyyy-MM-dd'),
        categoryId: '',
        isRecurring: false,
        recurrenceFrequency: 'monthly',
        recurrenceStartDate: '',
        recurrenceEndDate: null,
        hasEndDate: false
    });

    const [showForm, setShowForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteTransactionId, setDeleteTransactionId] = useState(null);

    const loadRecurringTransactions = useCallback(() => {
        GetActiveRecurringTransactions((response) => {
            if (response.status === 'success') {
                setRecurringTransactions(response.data);
            }
        });
    }, [GetActiveRecurringTransactions]);

    const loadTransactions = useCallback(() => {
        setIsLoading(true);
        GetFinancialTransactions(
            filters.startDate,
            filters.endDate,
            filters.type === 'all' ? '' : filters.type,
            filters.categoryId === 'all' ? '' : filters.categoryId,
            (response) => {
                setIsLoading(false);
                if (response.status === 'success') {
                    setTransactions(response.data.transactions);
                }
            }
        );
    }, [GetFinancialTransactions, filters]);

    const loadCategories = useCallback(() => {
        GetFinancialCategories((response) => {
            if (response.status === 'success') {
                setCategories(response.data);
            }
        });
    }, [GetFinancialCategories]);

    // Handlers memoizados
    const handleFilterChange = useCallback((e) => {
        const { name, value } = e.target;
        setFilters(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleFormChange = useCallback((e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => {
            const newData = {
                ...prev,
                [name]: type === 'checkbox' ? checked : value
            };

            if (name === 'isRecurring') {
                if (checked) {
                    newData.recurrenceStartDate = format(new Date(), 'yyyy-MM-dd');
                } else {
                    newData.recurrenceStartDate = '';
                    newData.recurrenceEndDate = '';
                }
            }

            return newData;
        });
    }, []);

    const handleDateChange = useCallback((date) => {
        setFormData(prev => ({
            ...prev,
            transactionDate: date.format('YYYY-MM-DD')
        }));
    }, []);

    const handleRecurrenceStartDateChange = useCallback((date) => {
        setFormData(prev => ({
            ...prev,
            recurrenceStartDate: date.format('YYYY-MM-DD')
        }));
    }, []);

    const handleRecurrenceEndDateChange = useCallback((date) => {
        setFormData(prev => ({
            ...prev,
            recurrenceEndDate: date ? date.format('YYYY-MM-DD') : null
        }));
    }, []);

    const handleHasEndDateChange = useCallback((e) => {
        setFormData(prev => ({
            ...prev,
            hasEndDate: e.target.checked,
            recurrenceEndDate: e.target.checked ? prev.recurrenceEndDate : null
        }));
    }, []);

    const resetForm = useCallback(() => {
        setFormData({
            type: 'expense',
            description: '',
            amount: '',
            transactionDate: format(new Date(), 'yyyy-MM-dd'),
            categoryId: '',
            isRecurring: false,
            recurrenceFrequency: 'monthly',
            recurrenceStartDate: '',
            recurrenceEndDate: null,
            hasEndDate: false
        });
        setIsEditing(false);
        setEditingId(null);
        setShowForm(false);
    }, []);

    const handleSubmit = useCallback(() => {
        const data = {
            ...formData,
            amount: parseFloat(formData.amount),
            categoryId: parseInt(formData.categoryId),
            recurrenceEndDate: formData.isRecurring && !formData.hasEndDate ? null : formData.recurrenceEndDate
        };

        const callback = (response) => {
            if (response.status === 'success') {
                loadTransactions();
                loadRecurringTransactions();
                resetForm();
            }
        };

        if (isEditing) {
            UpdateFinancialTransaction({ ...data, id: editingId }, callback);
        } else {
            SetFinancialTransaction(data, callback);
        }
    }, [formData, isEditing, editingId, UpdateFinancialTransaction, SetFinancialTransaction, loadTransactions, loadRecurringTransactions, resetForm]);

    const handleEdit = useCallback((transaction) => {
        const hasValidEndDate = transaction.recurrenceEndDate &&
            transaction.recurrenceEndDate !== '0000-00-00' &&
            transaction.recurrenceEndDate !== '0000-00-00 00:00:00';

        setFormData({
            type: transaction.type,
            description: transaction.description,
            amount: transaction.amount.toString(),
            transactionDate: transaction.transactionDate,
            categoryId: transaction.categoryId.toString(),
            isRecurring: transaction.isRecurring,
            recurrenceFrequency: transaction.recurrenceFrequency || 'monthly',
            recurrenceStartDate: transaction.recurrenceStartDate || transaction.transactionDate,
            recurrenceEndDate: hasValidEndDate ? transaction.recurrenceEndDate : null,
            hasEndDate: hasValidEndDate
        });
        setIsEditing(true);
        setEditingId(transaction.id);
        setShowForm(true);
    }, []);

    const handleDelete = useCallback((id) => {
        setDeleteTransactionId(id);
        setShowDeleteConfirm(true);
    }, []);

    const confirmDelete = useCallback(() => {
        if (deleteTransactionId) {
            DeleteFinancialTransaction(deleteTransactionId, (response) => {
                if (response.status === 'success') {
                    loadTransactions();
                    loadRecurringTransactions();
                }
            });
        }
        setShowDeleteConfirm(false);
        setDeleteTransactionId(null);
    }, [deleteTransactionId, DeleteFinancialTransaction, loadTransactions, loadRecurringTransactions]);

    // Effects
    useEffect(() => {
        loadTransactions();
        loadCategories();
        loadRecurringTransactions();
    }, [loadTransactions, loadCategories, loadRecurringTransactions]);

    useEffect(() => {
        loadTransactions();
    }, [filters, loadTransactions]);

    // Funções de formatação memoizadas
    const formatFrequency = useMemo(() => {
        const frequencies = {
            daily: 'Diária',
            weekly: 'Semanal',
            monthly: 'Mensal',
            yearly: 'Anual'
        };
        return (frequency) => frequencies[frequency] || frequency;
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <AreaTitle
                    Title="Transações Financeiras"
                    Description="Gerencie suas receitas e despesas"
                    Icon={<IconFinance />}
                />
                <div className={styles.actions}>
                    <CustomButton
                        variant="contained"
                        style="primary"
                        label="Nova Transação"
                        onClick={() => setShowForm(true)}
                        startIcon={<IconAdd />}
                    />
                </div>
            </div>

            <div className={styles.contentWrapper}>
                <div className={styles.sidebarContent}>
                    <h3 className={styles.sidebarTitle}>
                        <IconBalance style={{ fontSize: 20 }} />
                        Transações Recorrentes Ativas
                    </h3>
                    
                    {recurringTransactions.length > 0 ? (
                        recurringTransactions.map(transaction => (
                            <div key={transaction.id} className={styles.recurringItem}>
                                <div className={styles.recurringItemHeader}>
                                    <span className={styles.recurringItemTitle}>
                                        {transaction.description}
                                    </span>
                                    <span className={`${styles.recurringItemAmount} ${
                                        transaction.type === 'income' ? styles.income : styles.expense
                                    }`}>
                                        R$ {transaction.amount.toFixed(2)}
                                    </span>
                                </div>
                                <div className={styles.recurringItemInfo}>
                                    Frequência: {formatFrequency(transaction.recurrenceFrequency)}
                                </div>
                                <div className={styles.recurringItemInfo}>
                                    Início: {format(new Date(transaction.recurrenceStartDate), 'dd/MM/yyyy')}
                                    {transaction.recurrenceEndDate &&
                                     transaction.recurrenceEndDate !== '0000-00-00' &&
                                     transaction.recurrenceEndDate !== '0000-00-00 00:00:00' && (
                                        <> • Fim: {format(new Date(transaction.recurrenceEndDate), 'dd/MM/yyyy')}</>
                                    )}
                                </div>
                                <div className={styles.recurringItemActions}>
                                    <IconButton size="small" onClick={() => handleEdit(transaction)}>
                                        <IconEdit fontSize="small" />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => handleDelete(transaction.id)}>
                                        <IconDelete fontSize="small" />
                                    </IconButton>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={styles.noRecurringTransactions}>
                            Nenhuma transação recorrente ativa
                        </div>
                    )}
                </div>

                <div className={styles.mainContent}>
                    <div className={styles.filters}>
                        <h3 className={styles.filterTitle}>Filtros</h3>
                        <DateRangeHeader
                            initialStartDate={filters.startDate}
                            initialEndDate={filters.endDate}
                            onChange={(startDate, endDate) => {
                                setFilters(prev => ({
                                    ...prev,
                                    startDate,
                                    endDate
                                }));
                            }}
                        />
                        <div className={styles.additionalFilters}>
                            <FormControl style={{ minWidth: 200 }}>
                                <InputLabel>Tipo</InputLabel>
                                <Select
                                    name="type"
                                    value={filters.type}
                                    onChange={handleFilterChange}
                                    label="Tipo"
                                >
                                    <MenuItem value="all">Todos</MenuItem>
                                    <MenuItem value="income">Receitas</MenuItem>
                                    <MenuItem value="expense">Despesas</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{ minWidth: 200 }}>
                                <InputLabel>Categoria</InputLabel>
                                <Select
                                    name="categoryId"
                                    value={filters.categoryId}
                                    onChange={handleFilterChange}
                                    label="Categoria"
                                >
                                    <MenuItem value="all">Todas</MenuItem>
                                    {categories.map(category => (
                                        <MenuItem key={category.id} value={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <TableContainer component={Paper} className={styles.tableContainer}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Data</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell>Categoria</TableCell>
                                    <TableCell align="right">Valor</TableCell>
                                    <TableCell>Recorrente</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map(transaction => (
                                    <TableRow key={transaction.id}>
                                        <TableCell>
                                            {format(new Date(transaction.transactionDate), 'dd/MM/yyyy')}
                                        </TableCell>
                                        <TableCell>
                                            <span className={transaction.type === 'income' ? styles.typeBadgeIncome : styles.typeBadgeExpense}>
                                                {transaction.type === 'income' ? 'Receita' : 'Despesa'}
                                            </span>
                                        </TableCell>
                                        <TableCell>{transaction.description}</TableCell>
                                        <TableCell>{transaction.categoryName}</TableCell>
                                        <TableCell 
                                            align="right"
                                            className={transaction.type === 'income' ? styles.income : styles.expense}
                                        >
                                            R$ {transaction.amount.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.isRecurring ? (
                                                transaction.isRecurrenceGenerated ? (
                                                    transaction.recurrenceEndDate &&
                                                    transaction.recurrenceEndDate !== '0000-00-00' &&
                                                    transaction.recurrenceEndDate !== '0000-00-00 00:00:00' ? (
                                                        // Com data final: mostra N/X
                                                        `${Math.floor((new Date(transaction.transactionDate) - new Date(transaction.recurrenceStartDate)) / (1000 * 60 * 60 * 24 * 30)) + 1}/${Math.floor((new Date(transaction.recurrenceEndDate) - new Date(transaction.recurrenceStartDate)) / (1000 * 60 * 60 * 24 * 30)) + 1}`
                                                    ) : (
                                                        // Sem data final: mostra apenas o número
                                                        `Recorrência ${Math.floor((new Date(transaction.transactionDate) - new Date(transaction.recurrenceStartDate)) / (1000 * 60 * 60 * 24 * 30)) + 1}`
                                                    )
                                                ) : 'Recorrente (Original)'
                                            ) : 'Não'}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleEdit(transaction)}>
                                                <IconEdit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(transaction.id)}>
                                                <IconDelete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog
                        open={showForm}
                        onClose={resetForm}
                        maxWidth="sm"
                        fullWidth
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DialogTitle>
                                {isEditing ? 'Editar Transação' : 'Nova Transação'}
                            </DialogTitle>
                            <DialogContent>
                                <TransactionForm
                                    formData={formData}
                                    handleFormChange={handleFormChange}
                                    categories={categories}
                                    handleDateChange={handleDateChange}
                                    handleRecurrenceStartDateChange={handleRecurrenceStartDateChange}
                                    handleRecurrenceEndDateChange={handleRecurrenceEndDateChange}
                                    handleHasEndDateChange={handleHasEndDateChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <CustomButton
                                    variant="outlined"
                                    style="secondary"
                                    label="Cancelar"
                                    onClick={resetForm}
                                />
                                <CustomButton
                                    variant="contained"
                                    style="primary"
                                    label={isEditing ? 'Atualizar' : 'Criar'}
                                    onClick={handleSubmit}
                                />
                            </DialogActions>
                        </LocalizationProvider>
                    </Dialog>

                    <ConfirmPopup
                        Visibility={showDeleteConfirm}
                        Title="Excluir Transação"
                        Description="Tem certeza que deseja excluir esta transação? Esta ação não pode ser desfeita."
                        ConfirmLabel="Excluir"
                        OnConfirm={confirmDelete}
                        OnHide={() => {
                            setShowDeleteConfirm(false);
                            setDeleteTransactionId(null);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(TransactionsPage);
