import React, { useState } from 'react';
import styles from './Financial.module.css';
import TabBar from '../../components/tab-bar/TabBar';
import Categories from './areas/categories/Categories';
import TransactionsPage from './TransactionsPage';

function Financial() {
  let areas = [
    { id: 0, label: "Transações" },
    { id: 1, label: "Categorias" }
  ];

  const [currentArea, setCurrentArea] = useState(areas[0].id);

  function handleAreaChange(newValue) {
    setCurrentArea(newValue);
  };

  function GetArea() {
    switch (currentArea) {
      case 0:
        return (<TransactionsPage />);
      case 1:
        return (<Categories />);
      default:
        return (<div></div>);
    }
  }

  return (
    <div className='page-content'>
      <div className={styles.mainContainer}>
        <TabBar 
          labels={areas.map(item => item.label)} 
          value={currentArea} 
          onChange={handleAreaChange} 
        />
        <div className={styles.containerArea}>
          {GetArea()}
        </div>
      </div>
    </div>
  );
}

export default Financial;