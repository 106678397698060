import React, { useState, useEffect } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import {
    Box,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Payment as PaymentIcon } from '@mui/icons-material';
import ColorPalette from '../../colorpalette/ColorPalette';
import PopupBase from '../../popup/base/PopupBase';
import CustomButton from '../../components/buttons/CustomButton';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import AreaTitle from '../../components/areaTitle/AreaTitle';

export const PaymentMethodList = () => {
    const { GetCompanyPaymentMethods, SetCompanyPaymentMethod, UpdateCompanyPaymentMethod, DeleteCompanyPaymentMethod } = DataProviderInstance();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [methodToDelete, setMethodToDelete] = useState(null);
    const [formData, setFormData] = useState({
        label: '',
        priceModifier: 0
    });

    useEffect(() => {
        loadPaymentMethods();
    }, []);

    const loadPaymentMethods = () => {
        GetCompanyPaymentMethods((result) => {
            setPaymentMethods(result);
        });
    };

    const handleOpenModal = (mode, method = null) => {
        setModalMode(mode);
        setSelectedMethod(method);
        setFormData(method ? {
            label: method.Label,
            priceModifier: method.PriceModifier
        } : {
            label: '',
            priceModifier: 0
        });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedMethod(null);
        setFormData({
            label: '',
            priceModifier: 0
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'priceModifier' ? parseFloat(value) || 0 : value
        }));
    };

    const handleSubmit = () => {
        if (modalMode === 'create') {
            SetCompanyPaymentMethod(formData, () => {
                loadPaymentMethods();
                handleCloseModal();
            });
        } else {
            UpdateCompanyPaymentMethod({
                ...formData,
                id: selectedMethod.Id
            }, () => {
                loadPaymentMethods();
                handleCloseModal();
            });
        }
    };

    const handleOpenDeleteModal = (method) => {
        setMethodToDelete(method);
        setShowConfirmDelete(true);
    };

    const handleCloseDeleteModal = () => {
        setShowConfirmDelete(false);
        setMethodToDelete(null);
    };

    const handleDelete = () => {
        if (methodToDelete) {
            DeleteCompanyPaymentMethod(methodToDelete.Id, () => {
                loadPaymentMethods();
                handleCloseDeleteModal();
            });
        }
    };

    const modalContent = (
        <Stack spacing={2}>
            <TextField
                label="Nome do Método"
                name="label"
                value={formData.label}
                onChange={handleInputChange}
                fullWidth
                required
            />
            <TextField
                label="Modificador de Preço (%)"
                name="priceModifier"
                type="number"
                value={formData.priceModifier}
                onChange={handleInputChange}
                fullWidth
                required
                inputProps={{
                    min: -100,
                    max: 100,
                    step: 0.1
                }}
                helperText="Use valores negativos para desconto e positivos para acréscimo"
            />
        </Stack>
    );

    return (
        <div className="page-content">
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 3,
                px: 2
            }}>
                <AreaTitle
                    Title="Métodos de Pagamento"
                    Description="Gerencie os métodos de pagamento disponíveis em sua clínica"
                    Icon={<PaymentIcon />}
                />
                <CustomButton
                    style="primary"
                    variant="contained"
                    icon={<AddIcon />}
                    label="Adicionar Método"
                    onClick={() => handleOpenModal('create')}
                />
            </Box>

            <Box>
                <div className="dashboard-item-container" style={{ width: '100%' }}>
                    <Stack spacing={2}>
                        {paymentMethods.map(method => (
                            <Box
                                key={method.Id}
                                sx={{
                                    p: 2,
                                    borderRadius: 1,
                                    bgcolor: '#F8F9FA',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '1px solid #E9ECEF'
                                }}
                            >
                                <Box>
                                    <Typography 
                                        variant="body1" 
                                        sx={{ 
                                            mb: 0.5,
                                            fontWeight: 500,
                                            color: '#212529'
                                        }}
                                    >
                                        {method.Label}
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        sx={{ 
                                            color: '#6C757D',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        Modificador: {method.PriceModifier > 0 ? '+' : ''}{method.PriceModifier}%
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenModal('edit', method)}
                                        sx={{ 
                                            color: '#495057',
                                            '&:hover': {
                                                bgcolor: '#E9ECEF'
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenDeleteModal(method)}
                                        sx={{ 
                                            color: '#DC3545',
                                            '&:hover': {
                                                bgcolor: '#FFE9E9'
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                </div>
            </Box>

            {isModalOpen && (
                <PopupBase
                    Title={modalMode === 'create' ? 'Novo Método de Pagamento' : 'Editar Método de Pagamento'}
                    Content={modalContent}
                    PositiveButton={
                        <CustomButton
                            style="primary"
                            variant="contained"
                            label={modalMode === 'create' ? 'Adicionar' : 'Salvar'}
                            onClick={handleSubmit}
                        />
                    }
                    NegativeButton={
                        <CustomButton
                            style="secondary"
                            variant="text"
                            label="Cancelar"
                            onClick={handleCloseModal}
                        />
                    }
                    OnClose={handleCloseModal}
                />
            )}

            <ConfirmPopup
                Visibility={showConfirmDelete}
                Title="Desativar Método de Pagamento"
                Description={`Tem certeza que deseja desativar o método de pagamento "${methodToDelete?.Label}"?`}
                ConfirmLabel="Desativar"
                OnConfirm={handleDelete}
                OnHide={handleCloseDeleteModal}
            />
        </div>
    );
};