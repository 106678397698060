import React, { useState } from 'react';
import { breakLineTag } from '../../consts/MessageBreakLines';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './MessageTemplateSelector.module.css';

const MessageTemplateSelector = ({ open, title, templates, onSelect, onClose }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(0);

    const handleSelect = () => {
        onSelect(selectedTemplate);
        onClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <RadioGroup
                    value={selectedTemplate}
                    onChange={(e) => setSelectedTemplate(Number(e.target.value))}
                    className={styles.templateList}
                >
                    {templates.map((template, index) => (
                        <FormControlLabel
                            key={index}
                            value={index}
                            control={<Radio />}
                            label={
                                <div className={styles.templateItemContent}>
                                    <div className={styles.templateNumber}>{index + 1}</div>
                                    <div className={styles.templatePreview}>
                                        {template.split(breakLineTag)[0]}...
                                    </div>
                                </div>
                            }
                            className={styles.templateItem}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    Cancelar
                </Button>
                <Button onClick={handleSelect} color="primary" variant="contained">
                    Selecionar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageTemplateSelector;