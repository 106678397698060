import React, { useEffect, useState } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import styles from './Templates.module.css';
import Loading from '../../components/loading/Loading.js';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material/';
import CustomButton from '../../components/buttons/CustomButton';
import CreateEditTemplateDocument from '../../popup/CreateEditTemplateDocument/CreateEditTemplateDocument';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import ColorPalette from '../../colorpalette/ColorPalette.js';

const DocumentsTemplates = () => {
    const { GetTemplatesDocuments, DeleteTemplatesDocuments } = DataProviderInstance();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmPopupData, setConfirmPopupData] = useState({title: "", description: ""});

    const [currentItem, setCurrentItem] = useState(null);
    const [isVisibleDocumentInfo, setIsVisibleDocumentInfo] = useState(false);
    const [isVisibleConfirmPopup, setIsVisibleConfirmPopup] = useState(false);

    useEffect(() => {
        loadDocumentsTemplate(() => setLoading(false));
    }, []);

    const loadDocumentsTemplate = (callback) => {
        GetTemplatesDocuments((response) => {
            setData(response)
            if(callback) callback();
        });
    }

    if (loading) {
        return <Loading />;
    }

    const handleAddDocument = () => {
        setCurrentItem(null);
        setIsVisibleDocumentInfo(true);
    };

    const handleEditDocument = (id) => {
        const itemToEdit = data.find((template) => template.id === id);
        setCurrentItem(itemToEdit);
        setIsVisibleDocumentInfo(true);
    };

    const handleDeleteDocument = (id) => 
    {
        const itemToDelete = data.find((template) => template.id === id);
        setCurrentItem(itemToDelete);

        setConfirmPopupData({
            title: "Deletar Documento",
            description: `Deseja realmente deletar o documento <strong>${itemToDelete.title}</strong>?`
        });

        setIsVisibleConfirmPopup(true);
    };

    const handleClose = () => 
    {
        setIsVisibleDocumentInfo(false);
        setCurrentItem(null);
    };

    const handleReloadDocumentsTemplate = () =>
    {
        setIsVisibleDocumentInfo(false);
        loadDocumentsTemplate();
    }

    const handleConfirmAction = (action) =>
    {
        if(action === "delete_documents")
        {
            DeleteTemplatesDocuments(currentItem.id, (response) => {
                handleReloadDocumentsTemplate();
            });
        }
    }

    const handleConfirmPopupHide = () => 
    {
        setIsVisibleConfirmPopup(false);
        setCurrentItem(null);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {isVisibleConfirmPopup && (
                <ConfirmPopup 
                    Visibility={isVisibleConfirmPopup} 
                    Title={confirmPopupData.title} 
                    Description={confirmPopupData.description} 
                    ConfirmLabel={"Apagar"} 
                    OnConfirm={() => handleConfirmAction("delete_documents")} 
                    OnHide={handleConfirmPopupHide}
                />
            )}
            {isVisibleDocumentInfo && (
                <CreateEditTemplateDocument 
                    Data={currentItem} 
                    OnUpdate={handleReloadDocumentsTemplate} 
                    OnHide={handleClose} 
                />
            )}
            <div>          
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <AreaTitle Title={`Documentos (${data.length})`} />
                    <CustomButton
                        variant="contained"
                        style="primary"
                        label={"Adicionar"}
                        onClick={handleAddDocument}
                    />
                </div>
                <List>
                    {data.map((template) => (
                        <ListItem 
                            key={template.id} 
                            style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '8px', 
                                marginBottom: '10px' 
                            }}
                        >
                            <ListItemText
                                primary={template.tag}
                                secondary={
                                    <>
                                        {template.text.substring(0, 60)}...
                                    </>
                                }
                            />
                            <ListItemSecondaryAction style={{ display: 'flex', gap: '10px' }}>
                                <IconButton 
                                    edge="end" 
                                    aria-label="edit" 
                                    onClick={() => handleEditDocument(template.id)}
                                >
                                    <Edit htmlColor={ColorPalette.black}/>
                                </IconButton>
                                <IconButton  
                                    aria-label="delete" 
                                    onClick={() => handleDeleteDocument(template.id)}
                                >
                                    <Delete htmlColor={ColorPalette.redLight1} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    );
};

export default DocumentsTemplates;