import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import PopupBase from '../base/PopupBase';
import CustomButton from '../../components/buttons/CustomButton';
import {
    TextField,
    Grid,
    Paper,
    Typography,
    Box,
    IconButton,
    Button,
    Tooltip
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuestionEditor from './QuestionEditor';
import DynamicForm from '../../components/dynamicForm/DynamicForm';
import styles from './CreateEditAnamnesisTemplate.module.css';

const CreateEditAnamnesisTemplate = ({ Data, OnUpdate, OnHide }) => {
    const { SetAnamnesisTemplate, UpdateAnamnesisTemplate } = DataProviderInstance();
    const [label, setLabel] = useState(Data ? Data.label : '');
    const [category, setCategory] = useState(Data?.category || '');
    const [description, setDescription] = useState(Data?.description || '');
    const [questions, setQuestions] = useState(Data ? JSON.parse(Data.questions) : []);
    const [showPreview, setShowPreview] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 960);

    const handleResize = useCallback(() => {
        setIsDesktop(window.innerWidth >= 960);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    const getPreviewButtonLabel = () => {
        return showPreview ? 'Ocultar Visualização' : 'Visualizar Template';
    };
    const [editorOpen, setEditorOpen] = useState(false);
    const [editingQuestion, setEditingQuestion] = useState(null);

    useEffect(() => {
        // Ensure editor is closed when template editor opens
        setEditorOpen(false);
        setEditingQuestion(null);
    }, [Data]); // Reset when template data changes
    const dynamicFormRef = useRef(null);

    const handleClose = () => {
        OnHide();
    };

    const handleCancel = () => {
        OnHide();
    };

    const handleMoveQuestion = (index, direction) => {
        const newQuestions = [...questions];
        if (direction === 'up' && index > 0) {
            [newQuestions[index], newQuestions[index - 1]] = [newQuestions[index - 1], newQuestions[index]];
            setQuestions(newQuestions);
        } else if (direction === 'down' && index < questions.length - 1) {
            [newQuestions[index], newQuestions[index + 1]] = [newQuestions[index + 1], newQuestions[index]];
            setQuestions(newQuestions);
        }
    };

    const handleAddQuestion = () => {
        setEditingQuestion(null);
        setEditorOpen(true);
    };

    const handleEditQuestion = (question, index) => {
        setEditingQuestion({ ...question, index });
        setEditorOpen(true);
    };

    const handleDeleteQuestion = (index) => {
        if (window.confirm('Tem certeza que deseja excluir esta pergunta?')) {
            const newQuestions = questions.filter((_, i) => i !== index);
            setQuestions(newQuestions);
        }
    };

    const handleSaveQuestion = (questionData) => {
        if (editingQuestion !== null) {
            const newQuestions = [...questions];
            newQuestions[editingQuestion.index] = questionData;
            setQuestions(newQuestions);
        } else {
            setQuestions([...questions, questionData]);
        }
    };

    const handleConfirm = () => {
        if (!label.trim()) {
            alert('Por favor, preencha o nome do template');
            return;
        }

        if (questions.length === 0) {
            alert('Por favor, adicione pelo menos uma pergunta ao template');
            return;
        }

        const templateData = {
            id: Data?.id,
            label: label,
            category: category,
            description: description,
            questions: questions
        };

        if (Data) {
            UpdateAnamnesisTemplate(templateData, (response) => {
                OnUpdate();
            });
        } else {
            SetAnamnesisTemplate(label, questions, (response) => {
                OnUpdate();
            });
        }
    };

    const getTitle = () => {
        return Data ? "Editar template" : "Criar novo template";
    };

    const getContent = () => {
        return (
            <div className={styles.container}>
                <Grid container spacing={3}>
                    {/* Template Name Section */}
                    <Grid item xs={12}>
                        <TextField
                            label="Nome do template"
                            variant="outlined"
                            fullWidth
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            sx={{ mt: 1, mb: 2 }}
                        />
                    </Grid>

                    {/* Questions Section */}
                    <Grid item xs={12}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">
                                    Perguntas
                                </Typography>
                                <CustomButton
                                    variant={showPreview ? "contained" : "outlined"}
                                    style="primary"
                                    icon={<VisibilityIcon />}
                                    label={getPreviewButtonLabel()}
                                    onClick={() => setShowPreview(!showPreview)}
                                />
                            </Box>

                            <Box className={showPreview && isDesktop ? styles.splitView : ''}>
                                <Box className={showPreview && isDesktop ? styles.editorPanel : ''}>
                                    <Box>
                                        {questions.map((question, index) => (
                                            <Paper
                                                key={index}
                                                elevation={0}
                                                className={styles.questionItem}
                                            >
                                                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '100px' }}>
                                                        <Typography variant="body2" color="textSecondary" sx={{ width: '30px' }}>
                                                            {(index + 1).toString().padStart(2, '0')}
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleMoveQuestion(index, 'up')}
                                                                disabled={index === 0}
                                                                sx={{
                                                                    ...(index === 0 ? {
                                                                        color: 'rgba(0, 0, 0, 0.05)',
                                                                        transform: 'scale(0.75)',
                                                                        '&:hover': {
                                                                            backgroundColor: 'transparent'
                                                                        }
                                                                    } : {
                                                                        color: 'primary.main',
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgba(25, 118, 210, 0.04)'
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <ArrowUpwardIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleMoveQuestion(index, 'down')}
                                                                disabled={index === questions.length - 1}
                                                                sx={{
                                                                    ...(index === questions.length - 1 ? {
                                                                        color: 'rgba(0, 0, 0, 0.05)',
                                                                        transform: 'scale(0.75)',
                                                                        '&:hover': {
                                                                            backgroundColor: 'transparent'
                                                                        }
                                                                    } : {
                                                                        color: 'primary.main',
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgba(25, 118, 210, 0.04)'
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <ArrowDownwardIcon fontSize="small" />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ flex: 1, ml: 2 }}>
                                                        <Typography variant="subtitle1">
                                                            {question.label}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            Tipo: {question.type}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', gap: 1, ml: 2 }}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleEditQuestion(question, index)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleDeleteQuestion(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        ))}
                                    </Box>

                                    <CustomButton
                                        variant="outlined"
                                        style="primary"
                                        icon={<AddIcon />}
                                        label="Adicionar Pergunta"
                                        onClick={handleAddQuestion}
                                        className={styles.addQuestionButton}
                                    />
                                </Box>

                                {showPreview && (
                                    <Paper
                                        elevation={1}
                                        className={isDesktop ? styles.previewPanel : ''}
                                        sx={{
                                            ...(!isDesktop ? { mt: 3 } : {}),
                                            p: 3,
                                            bgcolor: '#fafafa'
                                        }}
                                    >
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="h6" color="primary" gutterBottom>
                                                Visualização do Template
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Esta é uma prévia de como o template será exibido para preenchimento
                                            </Typography>
                                        </Box>
                                        <DynamicForm
                                            ref={dynamicFormRef}
                                            questions={questions}
                                            isEditable={false}
                                        />
                                    </Paper>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <QuestionEditor
                    open={editorOpen}
                    onClose={() => {
                        setEditorOpen(false);
                        setEditingQuestion(null);
                    }}
                    onSave={handleSaveQuestion}
                    initialData={editingQuestion}
                />
            </div>
        );
    };

    const getFooter = () => {
        return (
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <CustomButton
                    variant="outlined"
                    style="secondary"
                    label="Cancelar"
                    onClick={handleCancel}
                />
                <CustomButton
                    variant="contained"
                    style="primary"
                    label={Data ? "Salvar alterações" : "Criar template"}
                    onClick={handleConfirm}
                />
            </div>
        );
    };

    return (
        <PopupBase
            Title={getTitle()}
            Content={getContent()}
            Footer={getFooter()}
            OnClose={handleClose}
        />
    );
};

export default CreateEditAnamnesisTemplate;