import React from 'react';
import styles from '../styles.module.css';
import AreaTitle from '../../../components/areaTitle/AreaTitle';
import { Grid, Paper, Typography } from "@mui/material";
import { PieChart, BarChart, BarPlot, ChartsXAxis, ChartsYAxis, ChartsTooltip, ChartsAxisHighlight } from '@mui/x-charts';
import ColorPalette from '../../../colorpalette/ColorPalette';
import IconTotal from "@mui/icons-material/PieChart";
import IconLeads from "@mui/icons-material/Group";
import IconConversion from "@mui/icons-material/TrendingUp";
import IconPatients from "@mui/icons-material/PersonAddAlt1Outlined";

function LeadsStatistics({ dashboardData }) {

  function GetLeadsOverview() {
    if (!dashboardData || !dashboardData.LeadsStatistics) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Resumo de Leads"}
            Description={"Visão geral da performance de leads"}
            Icon={<IconPatients />}
          />
          <div>Dados de leads indisponíveis.</div>
        </div>
      );
    }

    const stats = dashboardData.LeadsStatistics;

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Resumo de Leads"}
          Description={"Visão geral da performance de leads"}
          Icon={<IconPatients />}
        />
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.main,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <IconTotal sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="h4" fontWeight="bold">
                {stats.total_leads}
              </Typography>
              <Typography variant="subtitle1">
                Total de Leads
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.mainDark,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <IconLeads sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="h4" fontWeight="bold">
                {stats.converted_leads}
              </Typography>
              <Typography variant="subtitle1">
                Leads Convertidos
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: ColorPalette.secondary,
                color: 'white',
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <IconConversion sx={{ fontSize: 40, mb: 1 }} />
              <Typography variant="h4" fontWeight="bold">
                {stats.conversion_rate.toFixed(2)}%
              </Typography>
              <Typography variant="subtitle1">
                Taxa de Conversão
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  function GetLeadsCampaignDistribution() {
    if (!dashboardData || !dashboardData.LeadsStatistics || !dashboardData.LeadsStatistics.by_campaign) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Distribuição de Leads por Campanha"}
            Description={"Porcentagem de leads por campanha"}
            Icon={<IconPatients />}
          />
          <div>Dados de campanhas indisponíveis.</div>
        </div>
      );
    }

    const campaigns = dashboardData.LeadsStatistics.by_campaign || [];

    if (campaigns.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Distribuição de Leads por Campanha"}
            Description={"Porcentagem de leads por campanha"}
            Icon={<IconPatients />}
          />
          <div>Não há dados de campanhas disponíveis.</div>
        </div>
      );
    }

    const validCampaigns = campaigns.filter(campaign =>
      campaign &&
      typeof campaign.campaignId !== 'undefined' &&
      typeof campaign.campaign_name === 'string' &&
      (typeof campaign.total_leads === 'number' || typeof campaign.total_leads === 'string')
    );

    if (validCampaigns.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Distribuição de Leads por Campanha"}
            Description={"Porcentagem de leads por campanha"}
            Icon={<IconPatients />}
          />
          <div>Não há dados válidos de campanhas disponíveis.</div>
        </div>
      );
    }

    const totalLeads = validCampaigns.reduce((sum, campaign) => sum + Number(campaign.total_leads || 0), 0);

    const data = validCampaigns.map(campaign => ({
      id: campaign.campaignId,
      value: Number(campaign.total_leads || 0),
      label: `${campaign.campaign_name} (${((Number(campaign.total_leads || 0) / totalLeads) * 100).toFixed(1)}%)`,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Distribuição de Leads por Campanha"}
          Description={"Porcentagem de leads por campanha"}
          Icon={<IconPatients />}
        />
        <PieChart
          colors={[ColorPalette.main, ColorPalette.secondary, ColorPalette.greenLight, ColorPalette.mainLight1, ColorPalette.secondaryLight, ColorPalette.mainDark, ColorPalette.mainLight1]}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetLeadsByCampaign() {
    if (!dashboardData || !dashboardData.LeadsStatistics || !dashboardData.LeadsStatistics.by_campaign) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Leads por Campanha"}
            Description={"Distribuição e conversão de leads por campanha"}
            Icon={<IconPatients />}
          />
          <div>Dados de campanhas indisponíveis.</div>
        </div>
      );
    }

    const campaignData = dashboardData.LeadsStatistics.by_campaign || [];

    if (campaignData.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Leads por Campanha"}
            Description={"Distribuição e conversão de leads por campanha"}
            Icon={<IconPatients />}
          />
          <div>Não há dados de campanhas disponíveis.</div>
        </div>
      );
    }

    const validData = campaignData.filter(item =>
      item &&
      typeof item.campaign_name === 'string' &&
      (typeof item.total_leads === 'number' || typeof item.total_leads === 'string') &&
      (typeof item.converted_leads === 'number' || typeof item.converted_leads === 'string')
    ).map(item => ({
      campaign_name: item.campaign_name,
      total_leads: Number(item.total_leads || 0),
      converted_leads: Number(item.converted_leads || 0)
    }));

    if (validData.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Leads por Campanha"}
            Description={"Distribuição e conversão de leads por campanha"}
            Icon={<IconPatients />}
          />
          <div>Não há dados válidos de campanhas disponíveis.</div>
        </div>
      );
    }

    const dataWithConversion = validData.map(item => ({
      ...item,
      conversion_rate: item.total_leads > 0
        ? ((item.converted_leads / item.total_leads) * 100).toFixed(1)
        : '0.0'
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Leads por Campanha"}
          Description={"Distribuição e conversão de leads por campanha"}
          Icon={<IconPatients />}
        />
        <BarChart
          xAxis={[{
            scaleType: 'band',
            data: dataWithConversion.map(item =>
              `${item.campaign_name}\n(${item.conversion_rate}% conv.)`
            )
          }]}
          series={[
            {
              data: dataWithConversion.map(item => item.total_leads),
              label: 'Total de Leads',
              type: 'bar',
              color: ColorPalette.mainLight1,
            },
            {
              data: dataWithConversion.map(item => item.converted_leads),
              label: 'Leads Convertidos',
              type: 'bar',
              color: ColorPalette.main,
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
          height={400}
        >
          <BarPlot />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip
            itemStyle={{ fontSize: 12 }}
            formatter={(value, name, item) => {
              const campaign = dataWithConversion[item.index];
              return [
                `${value} leads`,
                `${name}`,
                `Taxa de conversão: ${campaign.conversion_rate}%`
              ];
            }}
          />
          <ChartsAxisHighlight x="line" y="line" />
        </BarChart>
      </div>
    );
  }

  function GetLeadsByChannel() {
    if (!dashboardData || !dashboardData.LeadsStatistics || !dashboardData.LeadsStatistics.by_channel) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Leads por Canal"}
            Description={"Distribuição e conversão de leads por canal de origem"}
            Icon={<IconPatients />}
          />
          <div>Dados de canais indisponíveis.</div>
        </div>
      );
    }

    const channelData = dashboardData.LeadsStatistics.by_channel || [];

    if (channelData.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Leads por Canal"}
            Description={"Distribuição e conversão de leads por canal de origem"}
            Icon={<IconPatients />}
          />
          <div>Não há dados de canais disponíveis.</div>
        </div>
      );
    }

    const validData = channelData.filter(item =>
      item &&
      typeof item.channel === 'string' &&
      typeof item.total_leads === 'number' &&
      typeof item.conversion_rate === 'number'
    ).map(item => ({
      id: item.channel,
      value: item.total_leads,
      label: `${item.channel} (${item.conversion_rate.toFixed(1)}% conv.)`,
    }));

    if (validData.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Leads por Canal"}
            Description={"Distribuição e conversão de leads por canal de origem"}
            Icon={<IconPatients />}
          />
          <div>Não há dados válidos de canais disponíveis.</div>
        </div>
      );
    }

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Leads por Canal"}
          Description={"Distribuição e conversão de leads por canal de origem"}
          Icon={<IconPatients />}
        />
        <PieChart
          colors={[ColorPalette.main, ColorPalette.secondary, ColorPalette.greenLight, ColorPalette.mainLight1, ColorPalette.secondaryLight, ColorPalette.mainDark, ColorPalette.mainLight1]}
          series={[
            {
              data: validData,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles.gridContainer}>
      {GetLeadsOverview()}
      {GetLeadsByCampaign()}
      {GetLeadsCampaignDistribution()}
      {GetLeadsByChannel()}
    </div>
  );
}

export default LeadsStatistics;