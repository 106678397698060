import React, { useEffect, useState } from 'react';
import styles from './WaitingList.module.css';
import Loading from '../../components/loading/Loading';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import { DataProviderInstance } from "../../api/DataProvider";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FilterListIcon from '@mui/icons-material/FilterList';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomButton from '../../components/buttons/CustomButton';
import AddWaitingListPopup from '../../popup/AddWaitingListPopup/AddWaitingListPopup';
import MessagesTemplate from '../../consts/MessagesTemplate';
import { SendWhatsAppMessage } from '../../api/WhatsappAPI';
import CalendarConfig from '../../consts/CalendarConfig';
import ColorPalette from '../../colorpalette/ColorPalette';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import PopupBase from '../../popup/base/PopupBase';
import AppointmentPopupContent from '../../popup/AppointmentPopupContent/AppointmentPopupContent';
import AppointmensState from '../../consts/AppointmentsState';
import {
  Alert,
  Chip,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Grid,
  Divider,
  Typography
} from '@mui/material';

function WaitingList() {
  // Helper function to safely create date objects and format them
  const safeFormatDate = (dateInput) => {
    try {
      if (!dateInput) return { success: false };
      
      const date = new Date(dateInput);
      
      // Check if date is valid
      if (isNaN(date.getTime())) return { success: false };
      
      return {
        success: true,
        date: date.toISOString().split('T')[0],
        time: date.toTimeString().substr(0, 5)
      };
    } catch (error) {
      console.error("Error formatting date:", error, dateInput);
      return { success: false };
    }
  };

  const {
    GetPatientById,
    GetProcedureById,
    GetResourceById,
    CompanyConfig,
    UserData,
    GetWaitingList,
    SetWaitingList,
    UpdateWaitingList,
    DeleteWaitingList
  } = DataProviderInstance();
  
  const templates = MessagesTemplate.getTemplates(CompanyConfig);
  
  // State variables
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [visibilityAddWaitingListPopup, setVisibilityAddWaitingListPopup] = useState(false);
  const [visibilityConfirmPopup, setVisibilityConfirmPopup] = useState(false);
  const [visibilityParamsPopup, setVisibilityParamsPopup] = useState(false);
  const [visibilityDatesPopup, setVisibilityDatesPopup] = useState(false);
  const [visibilityAppointmentPopup, setVisibilityAppointmentPopup] = useState(false);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedAppointmentData, setSelectedAppointmentData] = useState(null);
  const [currentTab, setCurrentTab] = useState('ALL');
  const [selectedDate, setSelectedDate] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [expandedItems, setExpandedItems] = useState({});
  
  // Local cache for offered dates and rejected dates - keyed by waitingListId_patientId
  const [offeredDates, setOfferedDates] = useState(() => {
    const saved = localStorage.getItem('waitingListOfferedDates');
    return saved ? JSON.parse(saved) : {};
  });
  
  const [rejectedDates, setRejectedDates] = useState(() => {
    const saved = localStorage.getItem('waitingListRejectedDates');
    return saved ? JSON.parse(saved) : {};
  });
  
  // Helper function to generate a unique cache key for a waiting list item
  const getCacheKey = (item) => `${item.id}_${item.patientId}`;
  
  // Filter menu state
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    status: [],
    priority: '',
    resourceId: '',
    startDate: '',
    endDate: ''
  });

  // Availability parameters state with localStorage persistence
  const [availabilityParams, setAvailabilityParams] = useState(() => {
    // Try to load from localStorage using companyId as part of the key
    if (CompanyConfig && CompanyConfig.Id) {
      const saved = localStorage.getItem(`waitingListAvailabilityParams_${CompanyConfig.Id}`);
      if (saved) {
        try {
          return JSON.parse(saved);
        } catch (error) {
          console.error("Error parsing saved availability params:", error);
        }
      }
    }
    // Default values if nothing in localStorage or parsing failed
    return {
      daysLimit: 30,
      timesPerDayLimit: 3,
      workingHoursStart: '08:00',
      workingHoursEnd: '20:00',
      maxMorning: 4,
      maxAfternoon: 4,
      nextAppointmentFilter: 'all', // 'all', 'before', or 'after'
      blockedTimes: [] // Array of times that should not be suggested (e.g., ['12:00', '13:00'] for lunch hours)
    };
  });
  
  // Temporary parameters for editing
  const [tempParams, setTempParams] = useState({...availabilityParams});

  // Load data when component mounts or when refreshData changes
  useEffect(() => {
    // Only load data if CompanyConfig and Id are available
    if (CompanyConfig && CompanyConfig.Id) {
      LoadData();
    }
  }, [refreshData, page, itemsPerPage, filters, availabilityParams, CompanyConfig]);

  // Handle filtered data and tab changes
  useEffect(() => {
    if (data) {
      const filtered = filterDataByTab(data);
      setFilteredData(filtered);
    }
  }, [data, currentTab]);
  
  // Save offered and rejected dates to localStorage
  useEffect(() => {
    localStorage.setItem('waitingListOfferedDates', JSON.stringify(offeredDates));
  }, [offeredDates]);
  
  useEffect(() => {
    localStorage.setItem('waitingListRejectedDates', JSON.stringify(rejectedDates));
  }, [rejectedDates]);
  
  // Save availability parameters to localStorage when they change
  useEffect(() => {
    if (CompanyConfig && CompanyConfig.Id) {
      localStorage.setItem(`waitingListAvailabilityParams_${CompanyConfig.Id}`, JSON.stringify(availabilityParams));
    }
  }, [availabilityParams, CompanyConfig]);

  // Function to load waiting list data
  function LoadData() {
    setLoading(true);
    
    // Ensure CompanyConfig is available
    if (!CompanyConfig || !CompanyConfig.Id) {
      setError("Aguardando configuração da empresa...");
      setLoading(false);
      return;
    }
    
    console.log("Loading waiting list with companyId:", CompanyConfig.Id);
    
    // Prepare filter parameters
    const params = {
      companyId: CompanyConfig.Id,
      page: page,
      limit: itemsPerPage,
      includeAvailability: true,
      daysLimit: availabilityParams.daysLimit,
      timesPerDayLimit: availabilityParams.timesPerDayLimit,
      workingHoursStart: availabilityParams.workingHoursStart,
      workingHoursEnd: availabilityParams.workingHoursEnd,
      maxMorning: availabilityParams.maxMorning,
      maxAfternoon: availabilityParams.maxAfternoon,
      blockedTimes: availabilityParams.blockedTimes
    };

    // Add filters if they're set
    if (filters.status && filters.status.length > 0) {
      params.status = filters.status.join(',');
    }
    if (filters.priority) {
      params.priority = filters.priority;
    }
    if (filters.resourceId) {
      params.resourceId = filters.resourceId;
    }
    if (filters.startDate) {
      params.startDate = filters.startDate;
    }
    if (filters.endDate) {
      params.endDate = filters.endDate;
    }

    GetWaitingList(params, (response) => {
      if (response.error) {
        setError("Erro ao carregar a lista de espera: " + response.error);
        setData([]);
      } else {
        setData(response.waitingList);
        setTotalPages(response.pagination.pages);
        setError(null);
      }
      setLoading(false);
      setRefreshData(false);
    });
  }

  // Filter data to exclude scheduled and deleted items
  function filterDataByTab(data) {
    if (!data) return [];
    
    // Filter out SCHEDULED, DECLINED, and CANCELLED items
    return data.filter(item =>
      item.status !== 'SCHEDULED' &&
      item.status !== 'DECLINED' &&
      item.status !== 'CANCELLED'
    );
  }

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    // Always set to ALL tab for better visualization
    setCurrentTab('ALL');
  };

  // Send WhatsApp message with available date suggestion
  function handleSendMessage(item, dateObj) {
    let message = templates.SuggestDateFromWaitingList;
    let patient = GetPatientById(item.patientId + "");

    if (!patient) {
      setError("Paciente não encontrado");
      return;
    }

    // Format the date for the message
    const date = new Date(dateObj.date + " " + dateObj.time);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const weekday = date.toLocaleDateString('pt-BR', { weekday: 'long' });
    const time = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

    // Replace placeholders in the message template
    message = message.replace("{name}", patient.name.split(' ')[0]);
    message = message.replace("{day}", day);
    message = message.replace("{month}", CalendarConfig.Months[parseInt(month) - 1]);
    message = message.replace("{when}", weekday);
    message = message.replace("{time}", time);

    // Send WhatsApp message
    SendWhatsAppMessage(patient.phone, MessagesTemplate.ConvertToWhatsAppText(message));
    
    // Format the date for storing
    const formattedDate = `${dateObj.date} ${dateObj.time}`;
    const formattedDisplayDate = `${weekday}, ${day}/${month} ${time}`;
    
    // Store the offered date in local cache
    setOfferedDates(prev => {
      const cacheKey = getCacheKey(item);
      const itemOfferedDates = prev[cacheKey] || [];
      
      // Add the new offered date
      const updatedDates = [
        ...itemOfferedDates,
        {
          date: formattedDate,
          displayDate: formattedDisplayDate,
          status: 'pending',
          offeredAt: new Date().toISOString()
        }
      ];
      
      return {
        ...prev,
        [cacheKey]: updatedDates
      };
    });
    
    // Update item status to CONTACTED
    const updatedItem = {
      id: item.id,
      status: 'CONTACTED',
      updatedBy: UserData.id
    };
    
    UpdateWaitingList(updatedItem, (response) => {
      if (response.success) {
        setRefreshData(true);
      } else {
        setError("Erro ao atualizar status: " + response.error);
      }
    });
  }

  // Delete item from waiting list
  function handleDeleteClick(item) {
    setSelectedItem(item);
    setVisibilityConfirmPopup(true);
  }

  // Confirm deletion
  function handleDeleteConfirm() {
    if (!selectedItem) return;
    
    DeleteWaitingList(selectedItem.id, (response) => {
      if (response.success) {
        setSelectedItem(null);
        setRefreshData(true);
      } else {
        setError("Erro ao excluir: " + response.error);
      }
    });
    
    setVisibilityConfirmPopup(false);
  }

  // Cancel deletion
  function handleDeleteCancel() {
    setSelectedItem(null);
    setVisibilityConfirmPopup(false);
  }

  // Edit item
  function handleEditClick(item) {
    setSelectedItem(item);
    setVisibilityAddWaitingListPopup(true);
  }

  // Close popup
  function handlePopupClose() {
    setVisibilityAddWaitingListPopup(false);
    setSelectedItem(null);
  }

  // Handle item created or updated
  function handleItemSaved() {
    setRefreshData(true);
  }
  
  // Open appointment popup
  function openAppointmentPopup(item, dateObj) {
    // Garantir que temos o ID do paciente
    const patientId = item.patientId;
    
    if (!patientId) {
      setError("ID do paciente não encontrado");
      return;
    }
    
    const procedure = GetProcedureById(item.procedureId);
    const resource = item.resourceId ? GetResourceById(item.resourceId) : null;
    
    // Create appointment data
    const appointmentData = {
      id: null, // Sem ID para que seja tratado como novo agendamento
      status: AppointmensState.Available,
      date: `${dateObj.date} ${dateObj.time}:00`,
      patient: patientId,
      procedure: procedure ? procedure.Id : null,
      resource: resource ? resource.Id : null
    };
    
    // Pré-carregar o paciente para garantir que ele apareça na popup
    const patient = GetPatientById(patientId);
    if (!patient) {
      setError("Paciente não encontrado");
      return;
    }
    
    // Store the waiting list item for later reference
    setSelectedItem(item);
    setSelectedAppointmentData(appointmentData);
    
    // Abrir a popup de agendamento
    setVisibilityAppointmentPopup(true);
  }
  
  // Close appointment popup
  function handleAppointmentPopupClose() {
    setVisibilityAppointmentPopup(false);
    setSelectedAppointmentData(null);
  }
  
  // Handle appointment created or updated
  function handleAppointmentSaved() {
    // If we have a selected waiting list item, update its status
    if (selectedItem) {
      const updatedItem = {
        id: selectedItem.id,
        status: 'SCHEDULED',
        updatedBy: UserData.id
      };
      
      UpdateWaitingList(updatedItem, (response) => {
        if (response.success) {
          setRefreshData(true);
        } else {
          setError("Erro ao atualizar status: " + response.error);
        }
      });
    }
  }

  // Handle filter menu open
  const handleFilterMenuOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  // Handle filter menu close
  const handleFilterMenuClose = () => {
    setFilterAnchorEl(null);
  };

  // Apply filters
  const handleApplyFilters = () => {
    setPage(1); // Reset to first page when filters change
    setRefreshData(true);
    handleFilterMenuClose();
  };

  // Reset filters
  const handleResetFilters = () => {
    setFilters({
      status: [],
      priority: '',
      resourceId: '',
      startDate: '',
      endDate: ''
    });
    const defaultParams = {
      daysLimit: 30,
      timesPerDayLimit: 3,
      workingHoursStart: '08:00',
      workingHoursEnd: '20:00',
      maxMorning: 4,
      maxAfternoon: 4,
      blockedTimes: [],
      nextAppointmentFilter: 'all'
    };
    setAvailabilityParams(defaultParams);
    setTempParams(defaultParams);
    setPage(1);
    setRefreshData(true);
    handleFilterMenuClose();
  };

  // Update status
  const handleStatusChange = (item, newStatus) => {
    const updatedItem = {
      id: item.id,
      status: newStatus,
      updatedBy: UserData.id
    };
    
    UpdateWaitingList(updatedItem, (response) => {
      if (response.success) {
        setRefreshData(true);
      } else {
        setError("Erro ao atualizar status: " + response.error);
      }
    });
  };
  
  // Verificar se um horário específico já foi sugerido e não foi recusado
  const isDateOfferedAndNotRejected = (item, dateObj) => {
    // Verificar se o item tem status CONTACTED (foi contatado)
    if (item.status !== 'CONTACTED') {
      return false;
    }
    
    // Verificar se o horário foi oferecido
    const cacheKey = getCacheKey(item);
    const formattedDate = `${dateObj.date} ${dateObj.time}`;
    
    // Verificar se o horário foi oferecido e não foi recusado
    return offeredDates[cacheKey] &&
      offeredDates[cacheKey].some(offered =>
        offered.date === formattedDate && offered.status !== 'rejected');
  };

  // Handle rejecting a specific date
  const handleRejectDate = (item, dateObj) => {
    const formattedDate = `${dateObj.date} ${dateObj.time}`;
    const cacheKey = getCacheKey(item);
    
    // Store the rejected date in local cache
    setRejectedDates(prev => {
      const itemRejectedDates = prev[cacheKey] || [];
      
      if (!itemRejectedDates.includes(formattedDate)) {
        return {
          ...prev,
          [cacheKey]: [...itemRejectedDates, formattedDate]
        };
      }
      
      return prev;
    });
    
    // Also update the status in offeredDates if it exists
    setOfferedDates(prev => {
      const itemOfferedDates = prev[cacheKey] || [];
      const updatedDates = itemOfferedDates.map(offered => {
        if (offered.date === formattedDate) {
          return {
            ...offered,
            status: 'rejected',
            rejectedAt: new Date().toISOString()
          };
        }
        return offered;
      });
      
      return {
        ...prev,
        [cacheKey]: updatedDates
      };
    });
    
    // Check if all offered dates are rejected
    const allRejected = offeredDates[cacheKey]?.every(date =>
      date.status === 'rejected' ||
      rejectedDates[cacheKey]?.includes(date.date)
    );
    
    // If all dates are rejected and the patient is in CONTACTED status,
    // update the status back to PENDING
    if (allRejected && item.status === 'CONTACTED') {
      const updatedItem = {
        id: item.id,
        status: 'PENDING',
        updatedBy: UserData.id
      };
      
      UpdateWaitingList(updatedItem, (response) => {
        if (response.success) {
          setRefreshData(true);
        } else {
          setError("Erro ao atualizar status: " + response.error);
        }
      });
    }
  };

  // Format patient name
  const formatPatientName = (fullName) => {
    if (!fullName) return "Desconhecido";
    const names = fullName.split(' ');
    
    if (names.length === 1) return names[0];
    if (names.length === 2) return `${names[0]} ${names[1]}`;
    if (names.length >= 3) return `${names[0]} ${names[1]} ${names[2].charAt(0)}.`;
    
    return `${names[0]} ${names[1]}`;
  };

  // Format date for display
  const formatDate = (dateString, timeString) => {
    const date = new Date(`${dateString} ${timeString}`);
    if (isNaN(date.getTime())) {
      return "Data inválida";
    }
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const time = timeString;
    
    return `${day}/${month} ${time}`;
  };
  
  // Open dates popup
  const openDatesPopup = (item) => {
    setSelectedItem(item);
    setVisibilityDatesPopup(true);
  };
  
  // Close dates popup
  const closeDatesPopup = () => {
    setVisibilityDatesPopup(false);
  };
  
  // Open parameters popup
  const openParamsPopup = () => {
    setTempParams({
      ...availabilityParams,
      blockedTimes: availabilityParams.blockedTimes || []
    });
    setVisibilityParamsPopup(true);
  };
  
  // Apply parameters and close popup
  const applyParamsAndClose = () => {
    setAvailabilityParams({...tempParams});
    setVisibilityParamsPopup(false);
    setRefreshData(true);
  };

  // Get priority label and colors
  const getPriorityInfo = (priority) => {
    switch (priority) {
      case 'HIGH':
        return { label: 'Alta', color: ColorPalette.red };
      case 'MEDIUM':
        return { label: 'Média', color: ColorPalette.orange };
      case 'LOW':
        return { label: 'Baixa', color: ColorPalette.mainLight1 };
      default:
        return { label: 'Nenhuma', color: ColorPalette.gray };
    }
  };

  // Get status label and colors
  const getStatusInfo = (status) => {
    switch (status) {
      case 'PENDING':
        return { label: 'Pendente', color: ColorPalette.orange, icon: null };
      case 'CONTACTED':
        return { label: 'Contatado', color: ColorPalette.blue, icon: <WhatsAppIcon fontSize="small" /> };
      case 'CONFIRMED':
        return { label: 'Confirmado', color: ColorPalette.purple, icon: null };
      case 'SCHEDULED':
        return { label: 'Agendado', color: ColorPalette.green, icon: null };
      case 'DECLINED':
        return { label: 'Recusado', color: ColorPalette.red, icon: null };
      case 'CANCELLED':
        return { label: 'Cancelado', color: ColorPalette.gray, icon: null };
      default:
        return { label: 'Desconhecido', color: ColorPalette.gray, icon: null };
    }
  };

  // Pagination handlers
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  // Filter components
  const filterMenu = (
    <Menu
      anchorEl={filterAnchorEl}
      open={Boolean(filterAnchorEl)}
      onClose={handleFilterMenuClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        style: {
          padding: '16px',
          width: '400px',
        },
      }}
    >
      <div style={{ padding: '8px' }}>
        <h3>Filtros</h3>
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            multiple
            value={filters.status}
            onChange={(e) => setFilters({...filters, status: e.target.value})}
            renderValue={(selected) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                {selected.map((value) => (
                  <Chip 
                    key={value} 
                    label={getStatusInfo(value).label} 
                    style={{ backgroundColor: getStatusInfo(value).color, color: '#fff' }}
                    size="small"
                  />
                ))}
              </div>
            )}
          >
            <MenuItem value="PENDING">Pendente</MenuItem>
            <MenuItem value="CONTACTED">Contatado</MenuItem>
            <MenuItem value="CONFIRMED">Confirmado</MenuItem>
            <MenuItem value="SCHEDULED">Agendado</MenuItem>
            <MenuItem value="DECLINED">Recusado</MenuItem>
            <MenuItem value="CANCELLED">Cancelado</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Prioridade</InputLabel>
          <Select
            value={filters.priority}
            onChange={(e) => setFilters({...filters, priority: e.target.value})}
          >
            <MenuItem value="">Todas</MenuItem>
            <MenuItem value="HIGH">Alta</MenuItem>
            <MenuItem value="MEDIUM">Média</MenuItem>
            <MenuItem value="LOW">Baixa</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Profissional</InputLabel>
          <Select
            value={filters.resourceId}
            onChange={(e) => setFilters({...filters, resourceId: e.target.value})}
          >
            <MenuItem value="">Todos</MenuItem>
            {CompanyConfig.Resources.map((resource) => (
              <MenuItem key={resource.Id} value={resource.Id}>{resource.Label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
          <TextField
            label="Data inicial"
            type="date"
            value={filters.startDate}
            onChange={(e) => setFilters({...filters, startDate: e.target.value})}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          
          <TextField
            label="Data final"
            type="date"
            value={filters.endDate}
            onChange={(e) => setFilters({...filters, endDate: e.target.value})}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </div>
        
        {/* Removed availability parameters section as it's now in the main UI */}

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
          <CustomButton
            variant="outlined"
            style="secondary"
            label="Limpar"
            onClick={handleResetFilters}
          />
          
          <CustomButton
            variant="contained"
            style="primary"
            label="Aplicar"
            onClick={handleApplyFilters}
          />
        </div>
      </div>
    </Menu>
  );

  // Show loading state
  if (loading) {
    return (
      <div className="page-content">
        {!CompanyConfig && <Alert severity="info">Carregando configurações da empresa...</Alert>}
        <Loading />
      </div>
    );
  }

  return (
    <div className='page-content'>
      {error && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>}

      {/* Add/Edit Popup */}
      {visibilityAddWaitingListPopup && (
        <AddWaitingListPopup
          OnHide={handlePopupClose}
          OnSaved={handleItemSaved}
          initialData={selectedItem}
          availabilityParams={availabilityParams}
        />
      )}

      {/* Confirm Delete Popup */}
      {visibilityConfirmPopup && selectedItem && (
        <ConfirmPopup
          Visibility={visibilityConfirmPopup}
          Title={"Remover paciente"}
          Description={`Você irá remover o paciente ${GetPatientById(selectedItem.patientId)?.name || "Desconhecido"} da sua lista de espera.`}
          OnHide={handleDeleteCancel}
          OnConfirm={handleDeleteConfirm}
          OnCancel={handleDeleteCancel}
        />
      )}
      {/* Appointment Popup */}
      {visibilityAppointmentPopup && selectedAppointmentData && (
        <AppointmentPopupContent
          Data={selectedAppointmentData}
          OnChange={handleAppointmentSaved}
          OnHide={handleAppointmentPopupClose}
        />
      )}
      
      {/* Parameters Popup */}
      {visibilityParamsPopup && (
        <PopupBase
          Title="Parâmetros de Disponibilidade"
          Content={
            <div className={styles.paramsPopupContent}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Dias a verificar"
                    type="number"
                    value={tempParams.daysLimit}
                    onChange={(e) => setTempParams({
                      ...tempParams,
                      daysLimit: Math.max(1, parseInt(e.target.value) || 1)
                    })}
                    InputProps={{ inputProps: { min: 1, max: 90 } }}
                    fullWidth
                    helperText="Limite máximo de dias a verificar (1-90)"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Horários por dia"
                    type="number"
                    value={tempParams.timesPerDayLimit}
                    onChange={(e) => setTempParams({
                      ...tempParams,
                      timesPerDayLimit: Math.max(1, parseInt(e.target.value) || 1)
                    })}
                    InputProps={{ inputProps: { min: 1, max: 20 } }}
                    fullWidth
                    helperText="Quantidade de horários por dia"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Horário de início"
                    type="time"
                    value={tempParams.workingHoursStart}
                    onChange={(e) => setTempParams({
                      ...tempParams,
                      workingHoursStart: e.target.value
                    })}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    helperText="Horário de início do expediente"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Horário de término"
                    type="time"
                    value={tempParams.workingHoursEnd}
                    onChange={(e) => setTempParams({
                      ...tempParams,
                      workingHoursEnd: e.target.value
                    })}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    helperText="Horário de término do expediente"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Máx. atendimentos (manhã)"
                    type="number"
                    value={tempParams.maxMorning}
                    onChange={(e) => setTempParams({
                      ...tempParams,
                      maxMorning: Math.max(1, parseInt(e.target.value) || 1)
                    })}
                    InputProps={{ inputProps: { min: 1, max: 20 } }}
                    fullWidth
                    helperText="Máximo de atendimentos no período da manhã"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Máx. atendimentos (tarde)"
                    type="number"
                    value={tempParams.maxAfternoon}
                    onChange={(e) => setTempParams({
                      ...tempParams,
                      maxAfternoon: Math.max(1, parseInt(e.target.value) || 1)
                    })}
                    InputProps={{ inputProps: { min: 1, max: 20 } }}
                    fullWidth
                    helperText="Máximo de atendimentos no período da tarde"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Exibir sugestões:</InputLabel>
                    <Select
                      value={tempParams.nextAppointmentFilter}
                      onChange={(e) => setTempParams({
                        ...tempParams,
                        nextAppointmentFilter: e.target.value
                      })}
                    >
                      <MenuItem value="all">Mostrar todas as sugestões</MenuItem>
                      <MenuItem value="before">Mostrar apenas antes da próxima consulta</MenuItem>
                      <MenuItem value="after">Mostrar apenas após a próxima consulta</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    Horários bloqueados para sugestão:
                  </Typography>
                  <Typography variant="caption" color="textSecondary" gutterBottom display="block">
                    Selecione os horários que não devem ser sugeridos (ex: horário de almoço)
                  </Typography>
                  <div className={styles.datesGrid} style={{ marginTop: '8px' }}>
                    {(() => {
                      const times = [];
                      const [startHour] = tempParams.workingHoursStart.split(':').map(Number);
                      const [endHour] = tempParams.workingHoursEnd.split(':').map(Number);
                      
                      for (let hour = startHour; hour <= endHour; hour++) {
                        const time = `${hour.toString().padStart(2, '0')}:00`;
                        times.push(
                          <Chip
                            key={time}
                            label={time}
                            onClick={() => {
                              const newBlockedTimes = tempParams.blockedTimes.includes(time)
                                ? tempParams.blockedTimes.filter(t => t !== time)
                                : [...tempParams.blockedTimes, time];
                              setTempParams({
                                ...tempParams,
                                blockedTimes: newBlockedTimes
                              });
                            }}
                            className={styles.dateGridChip}
                            style={{
                              backgroundColor: tempParams.blockedTimes.includes(time) ? '#ffeeee' : '#fff',
                              color: tempParams.blockedTimes.includes(time) ? ColorPalette.red : '#666',
                              border: `1px solid ${tempParams.blockedTimes.includes(time) ? ColorPalette.red : '#ddd'}`
                            }}
                          />
                        );
                      }
                      return times;
                    })()}
                  </div>
                </Grid>
              </Grid>
            </div>
          }
          Footer={
            <div className={styles.paramsPopupFooter}>
              <CustomButton
                variant="outlined"
                style="secondary"
                label="Cancelar"
                onClick={() => setVisibilityParamsPopup(false)}
              />
              <CustomButton
                variant="contained"
                style="primary"
                label="Aplicar Parâmetros"
                onClick={applyParamsAndClose}
              />
            </div>
          }
          OnClose={() => setVisibilityParamsPopup(false)}
          maxWidth="md"
        />
      )}
      
      {/* Dates Popup */}
      {visibilityDatesPopup && selectedItem && (
        <PopupBase
          Title={`Horários disponíveis para ${GetPatientById(selectedItem.patientId)?.name || "Paciente"}`}
          Content={
            <div className={styles.datesPopupContent}>
              <div className={styles.patientInfoSection}>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" className={styles.infoLabel}>Paciente:</Typography>
                    <Typography variant="body1">{GetPatientById(selectedItem.patientId)?.name || "Desconhecido"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" className={styles.infoLabel}>Telefone:</Typography>
                    <Typography variant="body1">{GetPatientById(selectedItem.patientId)?.phone || "Não informado"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" className={styles.infoLabel}>Procedimento:</Typography>
                    <Typography variant="body1">{GetProcedureById(selectedItem.procedureId)?.Label || "Não especificado"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" className={styles.infoLabel}>Profissional:</Typography>
                    <Typography variant="body1">{selectedItem.resourceId ? GetResourceById(selectedItem.resourceId)?.Label : "Qualquer"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="caption" className={styles.infoLabel}>Próxima consulta:</Typography>
                    <Typography variant="body1">
                      {selectedItem.nextAppointmentDate ?
                        new Date(selectedItem.nextAppointmentDate).toLocaleString('pt-BR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        }) :
                        "Sem agendamentos futuros"}
                    </Typography>
                  </Grid>
                  {selectedItem.observations && (
                    <Grid item xs={12}>
                      <Typography variant="caption" className={styles.infoLabel}>Observação:</Typography>
                      <div className={styles.observationText} style={{ marginTop: 0, maxWidth: '100%' }}>
                        <InfoIcon className={styles.observationIcon} fontSize="small" />
                        <span className={styles.observationContent}>
                          {selectedItem.observations}
                        </span>
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ mb: 3 }} />
              </div>
              
              {/* Offered dates section (pending response) */}
              {offeredDates[getCacheKey(selectedItem)] && offeredDates[getCacheKey(selectedItem)].some(date => date.status !== 'rejected') && (
                <>
                  <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 2 }}>
                    Horários oferecidos (aguardando resposta):
                  </Typography>
                  <div className={styles.datesGrid}>
                    {offeredDates[getCacheKey(selectedItem)]
                      .filter(offeredDate => offeredDate.status !== 'rejected')
                      .map((offeredDate, index) => (
                        <div key={index} className={styles.offeredDateGridItem}>
                          <Tooltip title="Clique para enviar novamente">
                            <Chip
                              label={offeredDate.displayDate}
                              className={styles.dateGridChip}
                              style={{ backgroundColor: '#e6f7ff', color: ColorPalette.blue }}
                              icon={null}
                              onClick={() => {
                                const formattedDate = safeFormatDate(offeredDate.date);
                                if (formattedDate.success) {
                                  handleSendMessage(selectedItem, {
                                    date: formattedDate.date,
                                    time: formattedDate.time
                                  });
                                } else {
                                  console.error("Cannot send message: Invalid date format", offeredDate.date);
                                  // Optionally show an error message to the user
                                }
                                closeDatesPopup();
                              }}
                              clickable
                            />
                          </Tooltip>
                          <div style={{ display: 'flex', gap: '4px' }}>
                            <Tooltip title="Marcar como recusado">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  const formattedDate = safeFormatDate(offeredDate.date);
                                  if (formattedDate.success) {
                                    handleRejectDate(selectedItem, {
                                      date: formattedDate.date,
                                      time: formattedDate.time
                                    });
                                  } else {
                                    console.error("Cannot reject date: Invalid date format", offeredDate.date);
                                    // Optionally show an error message to the user
                                  }
                                }}
                                style={{ color: ColorPalette.red }}
                              >
                                <CancelIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            
                            <Tooltip title="Realizar agendamento">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  const formattedDate = safeFormatDate(offeredDate.date);
                                  if (formattedDate.success) {
                                    openAppointmentPopup(selectedItem, {
                                      date: formattedDate.date,
                                      time: formattedDate.time
                                    });
                                  } else {
                                    console.error("Cannot open appointment popup: Invalid date format", offeredDate.date);
                                    // Optionally show an error message to the user
                                  }
                                  closeDatesPopup();
                                }}
                                style={{ color: ColorPalette.main }}
                              >
                                <EventAvailableIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                  </div>
                  <Divider sx={{ my: 3 }} />
                </>
              )}
              
              {/* Available dates section (not offered yet) */}
              <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 2 }}>
                Horários disponíveis:
              </Typography>
              
              {selectedItem.availableDates && selectedItem.availableDates.some(date => date.isBeforeNextAppointment !== undefined) && (
                <div style={{
                  display: 'flex',
                  gap: '16px',
                  marginBottom: '16px',
                  alignItems: 'center',
                  fontSize: '0.875rem',
                  color: '#666'
                }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div style={{
                      width: '16px',
                      height: '16px',
                      backgroundColor: '#e3f2fd',
                      borderRadius: '4px'
                    }} />
                    <span>Antes do próximo agendamento</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div style={{
                      width: '16px',
                      height: '16px',
                      backgroundColor: '#f5f5f5',
                      borderRadius: '4px'
                    }} />
                    <span>Após o próximo agendamento</span>
                  </div>
                </div>
              )}
              
              <div className={styles.datesGrid}>
                {selectedItem.availableDates && selectedItem.availableDates.length > 0 ? (
                  selectedItem.availableDates
                    .map((availableDate, index) => {
                      const [date, time] = availableDate.datetime.split(' ');
                      const dateObj = {
                        date,
                        time: time.substr(0, 5),
                        isBeforeNextAppointment: availableDate.isBeforeNextAppointment
                      };
                      
                      // Format the date for checking
                      const formattedDate = `${dateObj.date} ${dateObj.time}`;
                      
                      // Check if this date has been rejected or offered
                      const cacheKey = getCacheKey(selectedItem);
                      const isRejected = rejectedDates[cacheKey] &&
                                        rejectedDates[cacheKey].includes(formattedDate);
                      
                      // Check if this date has been offered
                      const isOffered = offeredDates[cacheKey] &&
                                       offeredDates[cacheKey].some(offered =>
                                         offered.date === formattedDate);
                      
                      // Check if this time is blocked
                      const timeStr = dateObj.time;
                      const isBlocked = availabilityParams.blockedTimes &&
                                      availabilityParams.blockedTimes.includes(timeStr);

                      // Skip dates that have been offered, rejected, or blocked
                      if (isRejected || isOffered || isBlocked) {
                        return null;
                      } else {
                        return (
                          <div key={index} className={styles.offeredDateGridItem}>
                            <Tooltip
                              title={`Clique para enviar mensagem com esta data${
                                dateObj.isBeforeNextAppointment !== undefined ?
                                  (dateObj.isBeforeNextAppointment ?
                                    " (antes do próximo agendamento)" :
                                    " (após próximo agendamento)") :
                                  ""}`}
                            >
                              <Chip
                                label={formatDate(dateObj.date, dateObj.time)}
                                onClick={() => {
                                  handleSendMessage(selectedItem, dateObj);
                                  closeDatesPopup();
                                }}
                                className={styles.dateGridChip}
                                style={{
                                  ...(dateObj.isBeforeNextAppointment !== undefined ? {
                                    backgroundColor: dateObj.isBeforeNextAppointment ?
                                      '#e3f2fd' : // azul claro para datas antes do próximo agendamento
                                      '#f5f5f5', // cinza claro para datas após o próximo agendamento
                                    color: '#000000' // texto preto para ambos os casos
                                  } : {})
                                }}
                                icon={null}
                                clickable
                              />
                            </Tooltip>
                            {/* Mostrar botão de agendamento apenas para horários já sugeridos e não recusados */}
                            {isDateOfferedAndNotRejected(selectedItem, dateObj) && (
                              <Tooltip title="Realizar agendamento">
                                <CustomButton
                                  variant="outlined"
                                  style="primary"
                                  size="small"
                                  label="Agendar"
                                  onClick={() => {
                                    openAppointmentPopup(selectedItem, dateObj);
                                    closeDatesPopup();
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>
                        );
                      }
                    }).filter(chip => chip !== null)
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    Não há horários disponíveis para este paciente.
                  </Typography>
                )}
              </div>
              
              {/* Rejected dates section */}
              {((rejectedDates[getCacheKey(selectedItem)] && rejectedDates[getCacheKey(selectedItem)].length > 0) ||
                (offeredDates[getCacheKey(selectedItem)] && offeredDates[getCacheKey(selectedItem)].some(date => date.status === 'rejected'))) && (
                <>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="subtitle1" fontWeight="medium" sx={{ mb: 2 }}>
                    Horários recusados:
                  </Typography>
                  <div className={styles.datesGrid}>
                    {/* Create a combined list of all rejected dates */}
                    {(() => {
                      const cacheKey = getCacheKey(selectedItem);
                      const rejectedDatesList = [];
                      
                      // Add dates from rejectedDates array
                      if (rejectedDates[cacheKey]) {
                        selectedItem.availableDates.forEach(availableDate => {
                          let dateObj;
                          
                          // Check if availableDate is an object with datetime property
                          if (availableDate && typeof availableDate === 'object' && availableDate.datetime) {
                            const [date, time] = availableDate.datetime.split(' ');
                            dateObj = {
                              date,
                              time: time.substr(0, 5)
                            };
                          } else {
                            // Fallback for legacy format (if availableDate is a string)
                            const formattedDate = safeFormatDate(availableDate);
                            if (formattedDate.success) {
                              dateObj = {
                                date: formattedDate.date,
                                time: formattedDate.time
                              };
                            } else {
                              console.error("Invalid date format:", availableDate);
                              return; // Skip this iteration
                            }
                          }
                          
                          const formattedDate = `${dateObj.date} ${dateObj.time}`;
                          
                          if (rejectedDates[cacheKey].includes(formattedDate)) {
                            // Check if this date is not already in the list
                            if (!rejectedDatesList.some(item => item.formattedDate === formattedDate)) {
                              rejectedDatesList.push({
                                formattedDate,
                                displayDate: formatDate(dateObj.date, dateObj.time),
                                source: 'rejectedDates'
                              });
                            }
                          }
                        });
                      }
                      
                      // Add dates from offeredDates array with status 'rejected'
                      if (offeredDates[cacheKey]) {
                        offeredDates[cacheKey].forEach(offeredDate => {
                          if (offeredDate.status === 'rejected') {
                            // Check if this date is not already in the list
                            if (!rejectedDatesList.some(item => item.formattedDate === offeredDate.date)) {
                              rejectedDatesList.push({
                                formattedDate: offeredDate.date,
                                displayDate: formatDate(
                                  offeredDate.date.split(' ')[0],
                                  offeredDate.date.split(' ')[1]
                                ),
                                source: 'offeredDates'
                              });
                            }
                          }
                        });
                      }
                      
                      // Return the combined list of rejected dates
                      return rejectedDatesList.map((item, index) => (
                        <Tooltip
                          key={`rejected-${index}`}
                          title="Esta data foi recusada pelo paciente"
                        >
                          <Chip
                            label={item.displayDate}
                            className={styles.dateGridChip}
                            style={{ backgroundColor: '#ffeeee', color: ColorPalette.red }}
                            icon={<CancelIcon style={{ color: ColorPalette.red }} fontSize="small" />}
                          />
                        </Tooltip>
                      ));
                    })()}
                  </div>
                </>
              )}
            </div>
          }
          Footer={
            <div className={styles.datesPopupFooter}>
              <CustomButton
                variant="outlined"
                style="secondary"
                label="Fechar"
                onClick={closeDatesPopup}
              />
            </div>
          }
          OnClose={closeDatesPopup}
          maxWidth="lg"
        />
      )}

      <div className={styles.container}>
        {/* Header */}
        <div className={styles.header}>
          <AreaTitle
            Title={"Lista de espera"}
            Description={"Pacientes que estão no aguardo de uma disponibilidade."}
          />
          
          <div className={styles.headerActions}>
            <Tooltip title="Filtros">
              <IconButton
                onClick={handleFilterMenuOpen}
                className={
                  Object.values(filters).some(value =>
                    Array.isArray(value) ? value.length > 0 : value !== ''
                  ) ? styles.activeFilter : ''
                }
              >
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={!Object.values(filters).some(value =>
                    Array.isArray(value) ? value.length > 0 : value !== ''
                  )}
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            
            <CustomButton
              variant="contained"
              style="primary"
              label={"Adicionar"}
              onClick={() => setVisibilityAddWaitingListPopup(true)}
            />
          </div>
          
          {filterMenu}
        </div>

        {/* Availability Parameters Summary */}
        <div className={styles.availabilityParamsSummary}>
          <div className={styles.availabilityParamsHeader}>
            <Typography variant="subtitle1" fontWeight="medium">
              Parâmetros de Disponibilidade
            </Typography>
            <CustomButton
              variant="outlined"
              style="secondary"
              label="Editar"
              onClick={openParamsPopup}
              size="small"
            />
          </div>
          
          <div className={styles.paramsSummaryContent}>
            <div className={styles.paramGroup}>
              <Typography variant="caption" className={styles.paramLabel}>Período de busca:</Typography>
              <Chip
                label={`${availabilityParams.daysLimit} dias`}
                size="small"
                className={styles.paramChip}
                title="Quantidade de dias a verificar disponibilidade"
              />
            </div>
            
            <div className={styles.paramGroup}>
              <Typography variant="caption" className={styles.paramLabel}>Sugestões por dia:</Typography>
              <Chip
                label={`${availabilityParams.timesPerDayLimit} horários`}
                size="small"
                className={styles.paramChip}
                title="Quantidade de horários sugeridos por dia"
              />
            </div>
            
            <div className={styles.paramGroup}>
              <Typography variant="caption" className={styles.paramLabel}>Horário de atendimento:</Typography>
              <Chip
                label={`${availabilityParams.workingHoursStart} - ${availabilityParams.workingHoursEnd}`}
                size="small"
                className={styles.paramChip}
                title="Horário de início e término do expediente"
              />
            </div>
            
            <div className={styles.paramGroup}>
              <Typography variant="caption" className={styles.paramLabel}>Máx. atendimentos:</Typography>
              <Chip
                label={`Manhã: ${availabilityParams.maxMorning} | Tarde: ${availabilityParams.maxAfternoon}`}
                size="small"
                className={styles.paramChip}
                title="Máximo de atendimentos por período"
              />
            </div>
            <div className={styles.paramGroup}>
              <Typography variant="caption" className={styles.paramLabel}>Exibir sugestões:</Typography>
              <Chip
                label={availabilityParams.nextAppointmentFilter === 'all' ? 'Todas as sugestões' :
                       availabilityParams.nextAppointmentFilter === 'before' ? 'Antes da próxima consulta' :
                       'Após a próxima consulta'}
                size="small"
                className={styles.paramChip}
                title="Filtro de sugestões em relação à próxima consulta"
              />
            </div>

            <div className={styles.paramGroup}>
              <Typography variant="caption" className={styles.paramLabel}>Horários bloqueados:</Typography>
              <Chip
                label={availabilityParams.blockedTimes && availabilityParams.blockedTimes.length > 0
                  ? availabilityParams.blockedTimes.sort().join(', ')
                  : 'Nenhum horário bloqueado'}
                size="small"
                className={styles.paramChip}
                title="Horários que não serão sugeridos (ex: horário de almoço)"
              />
            </div>
          </div>
        </div>

        {/* No status counts section as requested */}

        {/* Empty state */}
        {filteredData.length === 0 ? (
          <div className={styles.emptyState}>
            <p>Nenhum paciente na lista de espera com os filtros selecionados.</p>
          </div>
        ) : (
          /* List */
          <div className={styles.waitingListTable}>
            <table>
              <thead>
                <tr>
                  <th>Paciente</th>
                  <th>Procedimento</th>
                  <th>Profissional</th>
                  <th>Dias na fila</th>
                  <th>Status</th>
                  <th style={{ width: '40px' }}>Próx.</th>
                  <th>Disponibilidade</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item) => {
                  const patient = GetPatientById(item.patientId);
                  const procedure = GetProcedureById(item.procedureId);
                  const resource = item.resourceId ? GetResourceById(item.resourceId) : null;
                  const priorityInfo = getPriorityInfo(item.priority);
                  const statusInfo = getStatusInfo(item.status);
                  
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className={styles.patientCell}>
                          <div 
                            className={styles.priorityIndicator} 
                            style={{ backgroundColor: priorityInfo.color }}
                            title={`Prioridade: ${priorityInfo.label}`}
                          />
                          <div>
                            <div className={styles.patientName}>{formatPatientName(patient?.name || "Desconhecido")}</div>
                            <div className={styles.patientInfo}>
                              {patient?.phone && <span>{patient.phone}</span>}
                              {item.observations && (
                                <div className={styles.observationText} title={item.observations}>
                                  <InfoIcon className={styles.observationIcon} fontSize="small" />
                                  <span className={styles.observationContent}>
                                    {item.observations.length > 30
                                      ? `${item.observations.substring(0, 30)}...`
                                      : item.observations}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{procedure?.Label || "Não especificado"}</td>
                      <td>{resource?.Label || "Qualquer"}</td>
                      <td>
                        <div className={styles.daysInQueue}>
                          {item.daysInQueue === 0
                            ? "Hoje"
                            : item.daysInQueue === 1
                              ? "1 dia"
                              : `${item.daysInQueue} dias`}
                        </div>
                      </td>
                      <td>
                        <div className={styles.statusCell}>
                          <Chip
                            label={statusInfo.label}
                            style={{
                              backgroundColor: statusInfo.color,
                              color: "#fff"
                            }}
                            size="small"
                            icon={statusInfo.icon}
                          />
                          {item.lastNotified && (
                            <Tooltip title={`Último contato: ${new Date(item.lastNotified).toLocaleString()}`}>
                              <span className={styles.notificationCount}>
                                {item.notificationCount > 0 && `${item.notificationCount}x`}
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {item.nextAppointmentDate ? (
                          <Tooltip title={`Próxima consulta: ${new Date(item.nextAppointmentDate).toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}`}>
                            <EventAvailableIcon
                              fontSize="small"
                              style={{
                                color: ColorPalette.blue,
                                verticalAlign: 'middle'
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <span style={{ color: '#999', fontSize: '0.75rem' }}>-</span>
                        )}
                      </td>
                      <td>
                        <div className={styles.availabilityCell}>
                          {/* Show offered dates if this item has any and status is CONTACTED */}
                          {item.status === 'CONTACTED' && offeredDates[getCacheKey(item)] &&
                           offeredDates[getCacheKey(item)].filter(date => date.status !== 'rejected').length > 0 ? (
                            <div className={styles.datesList}>
                              {/* Filter out rejected dates in the CONTACTED section */}
                              {offeredDates[getCacheKey(item)]
                                .filter(offeredDate => offeredDate.status !== 'rejected')
                                .slice(0, expandedItems[`offered_${item.id}`] ? offeredDates[getCacheKey(item)].length : 3)
                                .map((offeredDate, index) => (
                                  <div key={index} className={styles.offeredDateRow}>
                                    <Tooltip title="Clique para enviar novamente">
                                      <Chip
                                        label={offeredDate.displayDate}
                                        className={styles.dateChip}
                                        style={{ backgroundColor: '#e6f7ff', color: ColorPalette.blue }}
                                        icon={null}
                                        onClick={() => {
                                          const formattedDate = safeFormatDate(offeredDate.date);
                                          if (formattedDate.success) {
                                            handleSendMessage(item, {
                                              date: formattedDate.date,
                                              time: formattedDate.time
                                            });
                                          } else {
                                            console.error("Cannot send message: Invalid date format", offeredDate.date);
                                            // Optionally show an error message to the user
                                          }
                                        }}
                                        clickable
                                      />
                                    </Tooltip>
                                    <div style={{ display: 'flex', gap: '4px' }}>
                                      <Tooltip title="Marcar como recusado">
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            const formattedDate = safeFormatDate(offeredDate.date);
                                            if (formattedDate.success) {
                                              handleRejectDate(item, {
                                                date: formattedDate.date,
                                                time: formattedDate.time
                                              });
                                            } else {
                                              console.error("Cannot reject date: Invalid date format", offeredDate.date);
                                              // Optionally show an error message to the user
                                            }
                                          }}
                                          style={{ color: ColorPalette.red }}
                                        >
                                          <CancelIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      
                                      <Tooltip title="Realizar agendamento">
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            const formattedDate = safeFormatDate(offeredDate.date);
                                            if (formattedDate.success) {
                                              openAppointmentPopup(item, {
                                                date: formattedDate.date,
                                                time: formattedDate.time
                                              });
                                            } else {
                                              console.error("Cannot open appointment popup: Invalid date format", offeredDate.date);
                                              // Optionally show an error message to the user
                                            }
                                          }}
                                          style={{ color: ColorPalette.main }}
                                        >
                                          <EventAvailableIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                ))}
                              
                              {/* Always show "Ver todos" button to access all dates */}
                              <Tooltip title="Ver todos os horários">
                                <IconButton
                                  size="small"
                                  onClick={() => openDatesPopup(item)}
                                  style={{ marginLeft: '8px' }}
                                >
                                  <MoreHorizIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : (
                            // Show available dates if no offered dates or not in CONTACTED status
                            item.availableDates && item.availableDates.length > 0 ? (
                              <div className={styles.datesList}>
                                {/* Filter out rejected dates and get up to 3 valid dates */}
                                {(() => {
                                  const cacheKey = getCacheKey(item);
                                  const validDates = [];
                                  let count = 0;
                                  
                                  // First add offered dates that are not rejected
                                  if (offeredDates[cacheKey]) {
                                    offeredDates[cacheKey]
                                      .filter(offered => offered.status !== 'rejected')
                                      .forEach(offered => {
                                        if (count < 3) {
                                          const formattedDate = safeFormatDate(offered.date);
                                          if (formattedDate.success) {
                                            const dateObj = {
                                              date: formattedDate.date,
                                              time: formattedDate.time,
                                              formattedDate: offered.date,
                                              isOffered: true
                                            };
                                            validDates.push(dateObj);
                                          } else {
                                            console.warn("Skipping invalid date:", offered.date);
                                          }
                                          count++;
                                        }
                                      });
                                  }
                                  
                                  // Helper function to check if a date matches the filter
                                  const matchesFilter = (dateObj) => {
                                    if (availabilityParams.nextAppointmentFilter === 'all') return true;
                                    if (availabilityParams.nextAppointmentFilter === 'before') {
                                      return dateObj.isBeforeNextAppointment === true;
                                    }
                                    if (availabilityParams.nextAppointmentFilter === 'after') {
                                      return dateObj.isBeforeNextAppointment === false;
                                    }
                                    return true;
                                  };

                                  // Then add available dates that are not offered or rejected
                                  for (let i = 0; i < item.availableDates.length && count < 3; i++) {
                                    const availableDate = item.availableDates[i];
                                    const [date, time] = availableDate.datetime.split(' ');
                                    const dateObj = {
                                      date,
                                      time: time.substr(0, 5),
                                      isOffered: false,
                                      isBeforeNextAppointment: availableDate.isBeforeNextAppointment
                                    };
                                    
                                    // Add formattedDate after dateObj is initialized
                                    dateObj.formattedDate = availableDate.datetime;
                                    
                                    // Check if this date has been rejected
                                    const isRejected = rejectedDates[cacheKey] &&
                                                      rejectedDates[cacheKey].includes(dateObj.formattedDate);
                                    
                                    // Check if this date has been offered
                                    const isOffered = offeredDates[cacheKey] &&
                                                     offeredDates[cacheKey].some(offered =>
                                                       offered.date === dateObj.formattedDate);
                                    
                                    // Check if this time is blocked
                                    const timeStr = dateObj.time;
                                    const isBlocked = availabilityParams.blockedTimes &&
                                                    availabilityParams.blockedTimes.includes(timeStr);

                                    // Only add if not rejected, not already offered, not blocked, and matches filter
                                    if (!isRejected && !isOffered && !isBlocked && matchesFilter(dateObj)) {
                                      validDates.push(dateObj);
                                      count++;
                                    }
                                  }
                                  
                                  // Render the valid dates
                                  return validDates.map((dateObj, index) => {
                                    if (dateObj.isOffered) {
                                      return (
                                        <Tooltip
                                          key={index}
                                          title="Esta data já foi oferecida ao paciente (clique para enviar novamente)"
                                        >
                                          <Chip
                                            label={formatDate(dateObj.date, dateObj.time)}
                                            onClick={() => handleSendMessage(item, dateObj)}
                                            className={styles.dateChip}
                                            style={{ backgroundColor: '#e6f7ff', color: ColorPalette.blue }}
                                            icon={null}
                                            clickable
                                          />
                                        </Tooltip>
                                      );
                                    } else {
                                      return (
                                        <div key={index} className={styles.offeredDateRow}>
                                          <Tooltip
                                            title={`Clique para enviar mensagem com esta data${
                                              dateObj.isBeforeNextAppointment !== undefined ?
                                                (dateObj.isBeforeNextAppointment ?
                                                  " (antes do próximo agendamento)" :
                                                  " (após próximo agendamento)") :
                                              ""}`}
                                          >
                                            <Chip
                                              label={formatDate(dateObj.date, dateObj.time)}
                                              onClick={() => handleSendMessage(item, dateObj)}
                                              className={styles.dateChip}
                                              style={{
                                                ...(dateObj.isBeforeNextAppointment !== undefined ? {
                                                  backgroundColor: dateObj.isBeforeNextAppointment ?
                                                    '#e3f2fd' : // azul claro para datas antes do próximo agendamento
                                                    '#f5f5f5', // cinza claro para datas após o próximo agendamento
                                                  color: '#000000' // texto preto para ambos os casos
                                                } : {})
                                              }}
                                              icon={null}
                                              clickable
                                            />
                                          </Tooltip>
                                          {/* Mostrar botão de agendamento apenas para horários já sugeridos e não recusados */}
                                          {isDateOfferedAndNotRejected(item, dateObj) && (
                                            <Tooltip title="Realizar agendamento">
                                              <CustomButton
                                                variant="outlined"
                                                style="primary"
                                                size="small"
                                                label="Agendar"
                                                onClick={() => {
                                                  openAppointmentPopup(item, dateObj);
                                                }}
                                              />
                                            </Tooltip>
                                          )}
                                        </div>
                                      );
                                    }
                                  });
                                })()}
                                
                                {/* Always show "Ver todos" button to access all dates */}
                                <Tooltip title="Ver todos os horários">
                                  <IconButton
                                    size="small"
                                    onClick={() => openDatesPopup(item)}
                                    style={{ marginLeft: '8px' }}
                                  >
                                    <MoreHorizIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) : (
                              <span className={styles.noAvailability}>Sem disponibilidade</span>
                            )
                          )}
                        </div>
                      </td>
                      <td>
                        <div className={styles.actionButtons}>
                          <Tooltip title="Editar">
                            <IconButton 
                              size="small" 
                              onClick={() => handleEditClick(item)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          
                          <Tooltip title="Excluir">
                            <IconButton 
                              size="small" 
                              onClick={() => handleDeleteClick(item)}
                            >
                              <DeleteIcon 
                                fontSize="small" 
                                style={{ color: ColorPalette.red }} 
                              />
                            </IconButton>
                          </Tooltip>
                          
                          {/* Removed "Marcar como agendado" button as requested for better usability */}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <div className={styles.pagination}>
            <CustomButton
              variant="outlined"
              style="secondary"
              label="Anterior"
              onClick={handlePrevPage}
              disabled={page === 1}
            />
            
            <span>
              Página {page} de {totalPages}
            </span>
            
            <CustomButton
              variant="outlined"
              style="secondary"
              label="Próxima"
              onClick={handleNextPage}
              disabled={page === totalPages}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default WaitingList;