// Always use the production API URL, but handle www/non-www case
const normalizeApiUrl = () => {
    // If accessing through non-www domain in production, use www
    if (window.location.hostname === 'maisqueagenda.app.br') {
        window.visualLogger?.log('Redirecionando para www...');
        window.location.href = window.location.href.replace('maisqueagenda.app.br', 'www.maisqueagenda.app.br');
    }
    
    // Always use the www domain for API calls
    return 'https://www.maisqueagenda.app.br/api/';
};

const BASE_URL = normalizeApiUrl();

const LOGIN = BASE_URL + "login.php";

// Company Resources APIs
const RESOURCES_GET = BASE_URL + "get_company_resources.php";
const RESOURCES_SET = BASE_URL + "set_company_resource.php";
const RESOURCES_UPDATE = BASE_URL + "update_company_resource.php";
const RESOURCES_DELETE = BASE_URL + "delete_company_resource.php";

// Company Payment Methods APIs
const PAYMENT_METHODS_GET = BASE_URL + "get_company_payment_methods.php";
const PAYMENT_METHODS_SET = BASE_URL + "set_company_payment_method.php";
const PAYMENT_METHODS_UPDATE = BASE_URL + "update_company_payment_method.php";
const PAYMENT_METHODS_DELETE = BASE_URL + "delete_company_payment_method.php";

// Company Procedures APIs
const PROCEDURES_GET = BASE_URL + "get_company_procedures.php";
const PROCEDURES_SET = BASE_URL + "set_company_procedure.php";
const PROCEDURES_UPDATE = BASE_URL + "update_company_procedure.php";
const PROCEDURES_DELETE = BASE_URL + "delete_company_procedure.php";

// Company Message Templates APIs
const MESSAGE_TEMPLATES_GET = BASE_URL + "get_company_message_templates.php";
const MESSAGE_TEMPLATES_UPDATE = BASE_URL + "update_company_message_template.php";

// Anamnesis Templates APIs
const ANAMNESIS_TEMPLATES_GET = BASE_URL + "anamneses/get_templates.php";
const ANAMNESIS_TEMPLATES_SET = BASE_URL + "anamneses/set_template.php";
const ANAMNESIS_TEMPLATES_UPDATE = BASE_URL + "anamneses/update_template.php";
const ANAMNESIS_TEMPLATES_DELETE = BASE_URL + "anamneses/delete_template.php";

// Patient Anamnesis APIs
const PATIENT_SET_ANAMNESE = BASE_URL + "anamneses/set_patient_anamnese.php";
const PATIENT_UPDATE_ANAMNESE = BASE_URL + "anamneses/update_patient_anamnese.php";
const PATIENT_DELETE_ANAMNESE = BASE_URL + "anamneses/delete_patient_anamnese.php";

// Wallet APIs
const WALLET_GET_PATIENT = BASE_URL + "wallet/get_patient_wallet.php";
const WALLET_GET_TRANSACTIONS = BASE_URL + "wallet/get_wallet_transactions.php";
const WALLET_PURCHASE_PACKAGE = BASE_URL + "packages/purchase_service_package.php";
const WALLET_DEDUCT_CREDITS = BASE_URL + "wallet/deduct_wallet_credits.php";

const PACKAGES_GET = BASE_URL + "packages/get_service_packages.php";
const PACKAGES_CREATE = BASE_URL + "packages/set_service_package.php";
const PACKAGES_UPDATE = BASE_URL + "packages/update_service_package.php";
const PACKAGES_DELETE = BASE_URL + "packages/delete_service_package.php";

// Financial APIs
const FINANCIAL_CATEGORIES_GET = BASE_URL + "get_financial_categories.php";
const FINANCIAL_CATEGORIES_SET = BASE_URL + "set_financial_category.php";
const FINANCIAL_CATEGORIES_UPDATE = BASE_URL + "update_financial_category.php";

const FINANCIAL_TRANSACTIONS_GET = BASE_URL + "get_financial_transactions.php";
const FINANCIAL_TRANSACTIONS_SET = BASE_URL + "set_financial_transaction.php";
const FINANCIAL_TRANSACTIONS_UPDATE = BASE_URL + "update_financial_transaction.php";
const FINANCIAL_TRANSACTIONS_DELETE = BASE_URL + "delete_financial_transaction.php";
const FINANCIAL_TRANSACTIONS_GET_ACTIVE_RECURRING = BASE_URL + "get_active_recurring_transactions.php";

const FINANCIAL_DASHBOARD_GET = BASE_URL + "get_financial_dashboard.php";

const FINANCIAL_STATISTICS_GET = BASE_URL + "get_financial_statistics.php";

const COMPANY_CONFIG_GET = BASE_URL + "get_company.php";
const COMPANY_UPDATE = BASE_URL + "update_company.php";
const COMPANY_CONFIG_UPDATE = BASE_URL + "update_company_config.php";

const DASHBOARD_GET = BASE_URL + "get_dashboard.php";

const LEADS_GET = BASE_URL + "get_leads.php";
const LEADS_SET = BASE_URL + "set_lead.php";
const LEADS_UPDATE = BASE_URL + "update_lead.php";

const STATISTICS_GET = BASE_URL + "get_statistics.php";

const REPORTS_APPOINTMENTS_GET = BASE_URL + "get_reports_appointments.php";
const REPORTS_FINANCIAL_GET = BASE_URL + "get_reports_financial.php";

const APPOINTMENTS_TO_CONFIRM_GET = BASE_URL + "get_appointments_to_confirm.php"
const APPOINTMENTS_GET = BASE_URL + "get_appointments.php";
const APPOINTMENTS_SET = BASE_URL + "set_appointment.php";
const APPOINTMENTS_UPDATE = BASE_URL + "update_appointment.php";
const APPOINTMENTS_UPDATE_STATUS = BASE_URL + "update_appointment_status.php";
const APPOINTMENTS_DELETE = BASE_URL + "delete_appointment.php";

const APPOINTMENTS_WAITING_LIST_GET = BASE_URL + "waiting-list/get_waiting_list.php";
const APPOINTMENTS_WAITING_LIST_SET = BASE_URL + "waiting-list/set_waiting_list.php";
const APPOINTMENTS_WAITING_LIST_UPDATE = BASE_URL + "waiting-list/update_waiting_list.php";
const APPOINTMENTS_WAITING_LIST_DELETE = BASE_URL + "waiting-list/delete_waiting_list.php";
const APPOINTMENTS_WAITING_LIST_CHECK_AVAILABILITY = BASE_URL + "waiting-list/check_waiting_list_availability.php";

const APPOINTMENT_GET_FULLINFO = BASE_URL + "get_appointment_info.php";
const APPOINTMENT_CREATE_INVOICE = BASE_URL + "set_appointment_invoice.php";
const APPOINTMENT_GET_RECEIPT = BASE_URL + "get_receipt_from_appointment.php";
const APPOINTMENT_GET_INVOICE = BASE_URL + "get_invoice.php";

const INVOICE_UPDATE = BASE_URL + "update_invoice.php";
const INVOICE_UPDATE_NF_ISSUED = BASE_URL + "update_invoice_nf_issued.php";

const PATIENTS_GET = BASE_URL + "get_patients.php";
const PATIENT_GET = BASE_URL + "get_patient.php"
const PATIENT_SET = BASE_URL + "set_patient.php";
const PATIENT_UPDATE = BASE_URL + "update_patient.php";

const PATIENT_SET_HISTORY = BASE_URL + "set_patient_history.php";
const PATIENT_SET_COMPLAINTS = BASE_URL + "set_patient_complaint.php";
const PATIENT_SET_CRANIAL_ASYMMETRY = BASE_URL + "set_patient_cranial_asymmetry.php";
const PATIENT_UPDATE_CRANIAL_ASYMMETRY = BASE_URL + "update_patient_cranial_asymmetry.php";
const PATIENT_DELETE_CRANIAL_ASYMMETRY = BASE_URL + "delete_patient_cranial_asymmetry.php";

const PATIENT_SET_DOCUMENT = BASE_URL + "set_patient_document.php";
const PATIENT_GET_DOCUMENTS = BASE_URL + "get_patient_documents.php";

const WHATSAPP_SEND_MESSAGE = BASE_URL + "messages/wa_send_message.php";

const WHATSAPP_GET_CHAT = BASE_URL + "get_message_chat_whatsapp.php";
const WHATSAPP_GET_CHAT_MESSAGES = BASE_URL + "get_messages_whatsapp.php";

// Export APIs
const EXPORT_CREATE = BASE_URL + "export/create.php";
const EXPORT_STATUS = BASE_URL + "export/status.php";
const EXPORT_HISTORY = BASE_URL + "export/get_history.php";
const EXPORT_CANCEL = BASE_URL + "export/cancel.php";


const TEMPLATES_GET_DOCUMENTS = BASE_URL + "get_company_documents_templates.php";
const TEMPLATES_SET_DOCUMENTS = BASE_URL + "set_company_documents_templates.php";
const TEMPLATES_UPDATE_DOCUMENTS = BASE_URL + "update_company_documents_templates.php";
const TEMPLATES_DELETE_DOCUMENTS = BASE_URL + "delete_company_documents_templates.php";

const AI_GET_ANSWER = BASE_URL + "ia/get_ia_answer.php";

// Automation Settings APIs
const AUTOMATION_SETTINGS_GET = BASE_URL + "company/automation_settings/get.php";
const AUTOMATION_SETTINGS_UPDATE = BASE_URL + "company/automation_settings/update.php";

const NPS_GET_STATISTICS = BASE_URL + "get_nps_statistics.php";
const NPS_GET_RESPONSES = BASE_URL + "get_nps_responses.php";
const NPS_SET_RESPONSE = BASE_URL + "set_nps_response.php";
const NPS_UPDATE_GOOGLE_REVIEW = BASE_URL + "update_nps_google_review.php";

// Anamnesis Templates APIs
const GetAnamnesisTemplatesAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(ANAMNESIS_TEMPLATES_GET, json, callback);
}

const SetAnamnesisTemplateAPI = (companyId, data, callback) => {
    let json = "companyId=" + companyId +
        "&label=" + encodeURIComponent(data.label) +
        "&questions=" + encodeURIComponent(JSON.stringify(data.questions));
    CallPOSTAPI(ANAMNESIS_TEMPLATES_SET, json, callback);
}

const UpdateAnamnesisTemplateAPI = (data, callback) => {
    let json = "id=" + data.id +
        "&label=" + encodeURIComponent(data.label) +
        "&questions=" + encodeURIComponent(JSON.stringify(data.questions));
    CallPOSTAPI(ANAMNESIS_TEMPLATES_UPDATE, json, callback);
}

const DeleteAnamnesisTemplateAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(ANAMNESIS_TEMPLATES_DELETE, json, callback);
}

// Wallet APIs
const GetPatientWalletAPI = (patientId, callback) => {
    const json = "patient_id=" + patientId;
    CallPOSTAPI(WALLET_GET_PATIENT, json, callback);
};

const GetWalletTransactionsAPI = (walletId, startDate, endDate, callback) => {
    const json = "wallet_id=" + walletId +
        "&start_date=" + startDate +
        "&end_date=" + endDate;
    CallPOSTAPI(WALLET_GET_TRANSACTIONS, json, callback);
};

const DeductWalletCreditsAPI = (walletId, procedureId, description, createdBy, callback) => {
    const json = "wallet_id=" + walletId +
        "&type=SERVICE" +
        "&procedure_id=" + procedureId +
        "&service_credits=1" +
        "&description=" + encodeURIComponent(description) +
        "&created_by=" + createdBy;
    CallPOSTAPI(WALLET_DEDUCT_CREDITS, json, callback);
};

const GetServicePackagesAPI = (companyId, callback) => {
    const json = "company_id=" + companyId;
    CallPOSTAPI(PACKAGES_GET, json, callback);
};



const PurchaseServicePackageAPI = (walletId, packageId, paymentMethod, paymentStatus,createdBy, callback) => {
    const json = "wallet_id=" + walletId +
        "&package_id=" + packageId +
        "&payment_method=" + paymentMethod +
        "&payment_status=" + paymentStatus +
        "&created_by=" + createdBy;
        
    CallPOSTAPI(WALLET_PURCHASE_PACKAGE, json, callback);
};

const GetAIAnswerAPI = (companyId, messages, callback) => {
    const json = "companyId="+companyId+"&messages=" + JSON.stringify(messages);
    CallPOSTAPI(AI_GET_ANSWER, json, callback);
}

const GetTemplatesDocumentsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(TEMPLATES_GET_DOCUMENTS, json, callback);
}

const SetTemplatesDocumentsAPI = (companyId, createdBy, data, callback) => {

    let json = "companyId=" + companyId +
        "&createdBy=" + createdBy +
        "&type=" + data.type +
        "&tag=" + data.tag +
        "&title=" + data.title +
        "&text=" + data.text;

    CallPOSTAPI(TEMPLATES_SET_DOCUMENTS, json, callback);
}

const UpdateTemplatesDocumentsAPI = (companyId, updatedBy, data, callback) => {

    let json = "companyId=" + companyId +
        "&id=" + data.id +
        "&updatedBy=" + updatedBy +
        "&type=" + data.type +
        "&tag=" + data.tag +
        "&title=" + data.title +
        "&text=" + data.text;

    CallPOSTAPI(TEMPLATES_UPDATE_DOCUMENTS, json, callback);
}

const DeleteTemplatesDocumentsAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(TEMPLATES_DELETE_DOCUMENTS, json, callback);
}

const GetWhatsappChatsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(WHATSAPP_GET_CHAT, json, callback);
}

const GetWhatsappChatMessagesAPI = (companyId, chatId, callback) => {
    let json = "companyId=" + companyId + "&chatId=" + chatId;
    CallPOSTAPI(WHATSAPP_GET_CHAT_MESSAGES, json, callback);
}

const SendWhatsappMessageAPI = (to, text, delay, callback) => {
    let json = "phone=" + to 
    +"&message=" + text
    +"&delay=" + delay;
    CallPOSTAPI(WHATSAPP_SEND_MESSAGE, json, callback);
}


const GetInvoiceAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENT_GET_INVOICE, json, callback);
}

const UpdateInvoiceAPI = (invoiceId, price, paymentMethod, issueDocument, updatedBy, callback) => {
    let json = "id=" + invoiceId + "&price=" + price + "&paymentMethod=" + paymentMethod + "&issueDocument=" + issueDocument + "&updatedBy=" + updatedBy;
    CallPOSTAPI(INVOICE_UPDATE, json, callback);
}

const UpdateInvoiceNfIssuedAPI = (invoiceId, issuedDocument, updatedBy, callback) => {
    let json = "id=" + invoiceId +  "&issuedDocument=" + issuedDocument + "&updatedBy=" + updatedBy;
    CallPOSTAPI(INVOICE_UPDATE_NF_ISSUED, json, callback);
}

const GetAppointmentFullInfo = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENT_GET_FULLINFO, json, callback);
}

const DeletePatientAnamneseAPI = (id, deletedBy, callback) => {
    let json = "id=" + id +
        "&deletedBy=" + deletedBy;

    CallPOSTAPI(PATIENT_DELETE_ANAMNESE, json, callback);
}

const UpdatePatientAnamneseAPI = (id, patientId, anamnese, updatedBy, callback) => {
    let json = "id=" + id +
        "&patientId=" + patientId +
        "&anamnese=" + JSON.stringify(anamnese) +
        "&updatedBy=" + updatedBy;

    CallPOSTAPI(PATIENT_UPDATE_ANAMNESE, json, callback);
}

const SetPatientAnamneseAPI = (date, createdBy, patientId, appointmentId, anamnese, template, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&createdBy=" + createdBy +
        "&anamnese=" + JSON.stringify(anamnese) +
        "&template=" + template;

    CallPOSTAPI(PATIENT_SET_ANAMNESE, json, callback);
}

const SetPatientComplaints = (date, patientId, appointmentId, complaints, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&complaints=" + JSON.stringify(complaints);

    CallPOSTAPI(PATIENT_SET_COMPLAINTS, json, callback);
}

const SetPatientDocumentAPI = (patientId, date, appointmentId, title, description, createdBy, callback) => {

    let json = "appointmentId=" + appointmentId +
        "&date=" + date +
        "&patientId=" + patientId +
        "&title=" + title +
        "&description=" + description +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET_DOCUMENT, json, callback);
}

const GetPatientDocumentsAPI = (patientId, callback) => {
    let json = "patientId=" + patientId;
    CallPOSTAPI(PATIENT_GET_DOCUMENTS, json, callback);
}

const SetPatientHistoryAPI = (date, createdBy, patientId, appointmentId, assessment, evolution, callback) => {

    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&assessment=" + assessment +
        "&evolution=" + evolution +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET_HISTORY, json, callback);
}

const SetPatientCranialAsymmetryAPI = (date, createdBy, patientId, appointmentId, ap, bp, dd, de, callback) => {
    let json = "appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&createdBy=" + createdBy +
        "&ap=" + ap +
        "&bp=" + bp +
        "&dd=" + dd +
        "&de=" + de;

    CallPOSTAPI(PATIENT_SET_CRANIAL_ASYMMETRY, json, callback);
}

const UpdatePatientCranialAsymmetryAPI = (id, date, patientId, appointmentId, ap, bp, dd, de, callback) => {
    let json = "id=" + id +
        "&appointmentId=" + appointmentId +
        "&createdAt=" + date +
        "&patientId=" + patientId +
        "&ap=" + ap +
        "&bp=" + bp +
        "&dd=" + dd +
        "&de=" + de;

    CallPOSTAPI(PATIENT_UPDATE_CRANIAL_ASYMMETRY, json, callback);
}

const DeletePatientCranialAsymmetryAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(PATIENT_DELETE_CRANIAL_ASYMMETRY, json, callback);
}

const SetAppointmentInvoiceAPI = (appointmentId, companyId, createdBy, patient, price, paymentMethod, procedure, issueDocument, callback) => {
    
    let json = "appointmentId=" + appointmentId +
        "&companyId=" + companyId +
        "&patient=" + patient +
        "&price=" + price +
        "&paymentMethod=" + String(paymentMethod) +
        "&procedure=" + procedure +
        "&issueDocument=" + issueDocument +
        "&createdBy=" + createdBy;

   CallPOSTAPI(APPOINTMENT_CREATE_INVOICE, json, callback);
}

const UpdateCompanyAPI = (companyId, data, callback) => {
    const formData = new FormData();
    formData.append("id", companyId);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("email", data.email);
    formData.append("cnpj", data.cnpj);
    formData.append("cep", data.cep);
    formData.append("address", data.address);
    formData.append("number", data.number);
    formData.append("complement", data.complement);
    formData.append("district", data.district);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("logo", data.logo); // Assuming data.logo is a File object

    CallPOSTAPIWithFormData(COMPANY_UPDATE, formData, callback);
}

const UpdateCompanyConfigAPI = (companyId, data, callback) => {

    const formData = new FormData();
    formData.append("id", companyId);
    formData.append("availableDaysOfWeek", JSON.stringify(data.availableDaysOfWeek));
    formData.append("availableTime", JSON.stringify(data.availableTime));
    formData.append("blockedTimes", JSON.stringify(data.blockedTimes));
    formData.append("targetOccupation", data.targetOccupation);
    formData.append("timeInterval", data.timeInterval);
    formData.append("goalAppointments", data.goalAppointments);
    formData.append("goalBilling", data.goalBilling);

   CallPOSTAPIWithFormData(COMPANY_CONFIG_UPDATE, formData, callback);
}

// Waiting List APIs
const GetWaitingListAPI = (params, callback) => {
    // Build query string from params
    let queryParams = Object.entries(params)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return `${key}=${value.join(',')}`;
            } else if (value !== undefined && value !== null) {
                return `${key}=${encodeURIComponent(value)}`;
            }
            return '';
        })
        .filter(param => param !== '')
        .join('&');
    
    CallPOSTAPI(APPOINTMENTS_WAITING_LIST_GET, queryParams, callback);
}

const SetWaitingListAPI = (companyId, createdBy, data, callback) => {
    // Convert arrays and objects to JSON strings
    const processedData = { ...data };
    if (Array.isArray(processedData.preferredDays)) {
        processedData.preferredDays = JSON.stringify(processedData.preferredDays);
    }
    if (Array.isArray(processedData.preferredTimes)) {
        processedData.preferredTimes = JSON.stringify(processedData.preferredTimes);
    }
    
    // Build query string
    let json = "companyId=" + companyId +
           "&createdBy=" + createdBy;
    
    // Add all other parameters
    Object.entries(processedData).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            json += `&${key}=${encodeURIComponent(value)}`;
        }
    });

    CallPOSTAPI(APPOINTMENTS_WAITING_LIST_SET, json, callback);
}

const UpdateWaitingListAPI = (updatedBy, data, callback) => {
    // Convert arrays and objects to JSON strings
    const processedData = { ...data };
    if (Array.isArray(processedData.preferredDays)) {
        processedData.preferredDays = JSON.stringify(processedData.preferredDays);
    }
    if (Array.isArray(processedData.preferredTimes)) {
        processedData.preferredTimes = JSON.stringify(processedData.preferredTimes);
    }
    
    // Build query string
    let json = "id=" + data.id + "&updatedBy=" + updatedBy;
    
    // Add all other parameters
    Object.entries(processedData).forEach(([key, value]) => {
        if (key !== 'id' && value !== undefined && value !== null) {
            json += `&${key}=${encodeURIComponent(value)}`;
        }
    });

    CallPOSTAPI(APPOINTMENTS_WAITING_LIST_UPDATE, json, callback);
}

const DeleteWaitingListAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENTS_WAITING_LIST_DELETE, json, callback);
}

const CheckWaitingListAvailabilityAPI = (params, callback) => {
    // Build query string from params
    let queryParams = Object.entries(params)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return `${key}=${value.join(',')}`;
            } else if (value !== undefined && value !== null) {
                return `${key}=${encodeURIComponent(value)}`;
            }
            return '';
        })
        .filter(param => param !== '')
        .join('&');
    
    // Add cache-busting parameter to force reload
    const timestamp = new Date().getTime();
    if (queryParams) {
        queryParams += `&_nocache=${timestamp}`;
    } else {
        queryParams = `_nocache=${timestamp}`;
    }
    
    console.log("Calling waiting list availability API with cache-busting:", timestamp);
    CallPOSTAPI(APPOINTMENTS_WAITING_LIST_CHECK_AVAILABILITY, queryParams, callback);
}

const CallPOSTAPIWithFormData = (path, formData, callback) => {
    const token = localStorage.getItem('token');

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: formData
    };

    fetch(path, requestOptions)
        .then(response => {
            if (response.status === 401) {
                console.warn('Token expirado, redirecionando para login.');
                Disconnect();
                return;
            }
            return response.text();
        })
        .then(data => {
            try {
                if (data) {
                    const jsonData = JSON.parse(data);
                    callback(jsonData);
                } else {
                    console.error('Dados vazios recebidos da API');
                }
            } catch (e) {
                console.error('Erro ao analisar o JSON:', path, e);
            }
        })
        .catch(error => {
            console.error('Erro na requisição:', error);
        });
}

const GetCompanyConfigAPI = (companyId, callback) => {
    let json = "id=" + companyId;
    CallPOSTAPI(COMPANY_CONFIG_GET, json, callback);
}

const LoginAPI = (email, password, callback) => {
    let json = "email=" + email + "&password=" + password;
    CallPOSTAPI(LOGIN, json, callback, true); // Show connection logs for login
}

const GetDashboardAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(DASHBOARD_GET, json, callback);
}

const GetLeadsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(LEADS_GET, json, callback);
}

const SetLeadAPI = (createdBy, data, callback) => {

    const payload = {
        ...data, // Inclui todos os campos de `data`
        createdBy: createdBy, // Adiciona o campo createdBy
      };
    
      // Converter o payload em uma string formatada para envio
      const json = Object.keys(payload)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(payload[key]))
        .join("&");
    
      // Chamar a API usando a função CallPOSTAPI
      CallPOSTAPI(LEADS_SET, json, callback);
}

const UpdateLeadAPI = (updatedBy, data, callback) => {

    const payload = {
      ...data, // Inclui todos os campos de `data`
      updatedBy: updatedBy, // Adiciona o campo updatedBy
    };
  
    // Converter o payload em uma string formatada para envio
    const json = Object.keys(payload)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(payload[key]))
      .join("&");
  
    // Chamar a API usando a função CallPOSTAPI
    CallPOSTAPI(LEADS_UPDATE, json, callback);
  };
  

const GetStatisticsAPI = (companyId,  startDate, endDate,callback) => {

    let json = "companyId=" + companyId + "&startDate=" + startDate + "&endDate=" + endDate;

    CallPOSTAPI(STATISTICS_GET, json, (response) => {
       callback(response);
    });
}

const GetReportsAppointmentsAPI = (companyId, startDate, endDate, callback) => {
    let json = "companyId=" + companyId + "&startDate=" + startDate + "&endDate=" + endDate;
    CallPOSTAPI(REPORTS_APPOINTMENTS_GET, json, callback);
}

const GetReportsFinancialAPI = (companyId, startDate, endDate, callback) => {
    let json = "companyId=" + companyId + "&startDate=" + startDate + "&endDate=" + endDate;
    CallPOSTAPI(REPORTS_FINANCIAL_GET, json, callback);
}

const GetAppointmentsAPI = (companyId, startDate, endDate, callback) => {
    let json = "companyId=" + companyId;
    if (startDate) {
        json += "&startDate=" + startDate;
    }
    if (endDate) {
        json += "&endDate=" + endDate;
    }
    CallPOSTAPI(APPOINTMENTS_GET, json, callback);
}

const SetAppointmentAPI = (companyId, createdBy, data, callback) => {
    let json = "status=" + data.status + "&date=" + data.date + "&patient=" + data.patient + "&resource=" + data.resource + "&procedure=" + data.procedure + "&duration=" + data.duration + "&price=" + data.price + "&companyId=" + companyId + "&createdBy=" + createdBy;
    CallPOSTAPI(APPOINTMENTS_SET, json, callback);
}

const UpdateAppointmentStatusAPI = (id, status, callback) => {
    let json = "id=" + id + "&status=" + status;
    CallPOSTAPI(APPOINTMENTS_UPDATE_STATUS, json, callback);
}
const UpdateAppointmentAPI = (data, callback) => {
    let json = "id=" + data.id + "&status=" + data.status + "&date=" + data.date + "&patient=" + data.patient + "&resource=" + data.resource + "&procedure=" + data.procedure + "&duration=" + data.duration + "&price=" + data.price;
    CallPOSTAPI(APPOINTMENTS_UPDATE, json, callback);
}

const DeleteAppointmentAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(APPOINTMENTS_DELETE, json, callback);
}

const GetAppointmentsToConfirmAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(APPOINTMENTS_TO_CONFIRM_GET, json, callback);
}

const GetPatientAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(PATIENT_GET, json, callback);
}

const GetPatientsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(PATIENTS_GET, json, callback);
}

const SetPatientAPI = (companyId, createdBy, data, callback) => {
    let json = "name=" + data.name +
        "&companyId=" + companyId +
        "&countryCode=" + data.countryCode +
        "&phone=" + data.phone +
        "&cpf=" + data.cpf +
        "&email=" + data.email +
        "&birthday=" + data.birthday +
        "&gender=" + data.gender +
        "&cep=" + data.cep +
        "&address=" + data.address +
        "&number=" + data.number +
        "&complement=" + data.complement +
        "&district=" + data.district +
        "&city=" + data.city +
        "&state=" + data.state +
        "&origin=" + data.origin +
        "&createdBy=" + createdBy;

    CallPOSTAPI(PATIENT_SET, json, callback);
}

const UpdatePatientAPI = (data, callback) => {
    let json = "id=" + data.id +
        "&status=" + data.status +
        "&name=" + data.name +
        "&countryCode=" + data.countryCode +
        "&phone=" + data.phone +
        "&cpf=" + data.cpf +
        "&email=" + data.email +
        "&birthday=" + data.birthday +
        "&gender=" + data.gender +
        "&cep=" + data.cep +
        "&address=" + data.address +
        "&number=" + data.number +
        "&complement=" + data.complement +
        "&district=" + data.district +
        "&city=" + data.city +
        "&state=" + data.state +
        "&origin=" + data.origin;

    CallPOSTAPI(PATIENT_UPDATE, json, callback);
}

const GetCEPInfoAPI = (value, callback) => {
    CallGETAPI("https://viacep.com.br/ws/" + value + "/json/", callback);
}

const CallGETAPI = (path, callback) => {
    fetch(path)
        .then(response => response.json())
        .then(data => callback(data));
}

const Disconnect = () => {

    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('password');

    window.location.href = '/';
}

const GetNPSStatisticsAPI = (companyId, startDate, endDate, callback) => {
    let json = "companyId=" + companyId;
    if (startDate) {
        json += "&startDate=" + startDate;
    }
    if (endDate) {
        json += "&endDate=" + endDate;
    }
    CallPOSTAPI(NPS_GET_STATISTICS, json, callback);
}

const GetNPSResponsesAPI = (companyId, startDate, endDate, category, callback) => {
    let json = "companyId=" + companyId;
    if (startDate) {
        json += "&startDate=" + startDate;
    }
    if (endDate) {
        json += "&endDate=" + endDate;
    }
    if (category && category !== 'all') {
        json += "&category=" + category;
    }
    CallPOSTAPI(NPS_GET_RESPONSES, json, callback);
}

const SetNPSResponseAPI = (companyId, patientId, appointmentId, score, comments, callback) => {
    let json = "companyId=" + companyId +
        "&patient_id=" + patientId +
        "&appointment_id=" + appointmentId +
        "&score=" + score;
    
    if (comments) {
        json += "&comments=" + comments;
    }

    CallPOSTAPI(NPS_SET_RESPONSE, json, callback);
}

const UpdateNPSGoogleReviewAPI = (companyId, reviewId, status, callback) => {
    let json = "companyId=" + companyId +
        "&review_id=" + reviewId +
        "&status=" + status;

    CallPOSTAPI(NPS_UPDATE_GOOGLE_REVIEW, json, callback);
}

// Financial Categories APIs
const GetFinancialCategoriesAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(FINANCIAL_CATEGORIES_GET, json, callback);
}

const SetFinancialCategoryAPI = (companyId, data, callback) => {
    let json = "companyId=" + companyId +
        "&name=" + encodeURIComponent(data.name) +
        "&type=" + data.type;
    CallPOSTAPI(FINANCIAL_CATEGORIES_SET, json, callback);
}

const UpdateFinancialCategoryAPI = (data, callback) => {
    let json = "companyId=" + data.companyId +
        "&id=" + data.id +
        "&name=" + encodeURIComponent(data.name) +
        "&type=" + data.type +
        "&isActive=" + (data.isActive ? 1 : 0);
    CallPOSTAPI(FINANCIAL_CATEGORIES_UPDATE, json, callback);
}

// Financial Transactions APIs
const GetFinancialTransactionsAPI = (companyId, startDate, endDate, type, categoryId, callback) => {
    let json = "companyId=" + companyId;
    if (startDate) json += "&startDate=" + startDate;
    if (endDate) json += "&endDate=" + endDate;
    if (type) json += "&type=" + type;
    if (categoryId) json += "&categoryId=" + categoryId;
    CallPOSTAPI(FINANCIAL_TRANSACTIONS_GET, json, callback);
}

const SetFinancialTransactionAPI = (companyId, data, callback) => {
    let json = "companyId=" + companyId +
        "&type=" + data.type +
        "&description=" + data.description +
        "&amount=" + data.amount +
        "&transactionDate=" + data.transactionDate +
        "&categoryId=" + data.categoryId +
        "&createdBy=" + data.createdBy;

    if (data.isRecurring) {
        json += "&isRecurring=1" +
            "&recurrenceFrequency=" + data.recurrenceFrequency +
            "&recurrenceStartDate=" + data.recurrenceStartDate +
            "&recurrenceEndDate=" + (data.recurrenceEndDate || 'null');
    }

    CallPOSTAPI(FINANCIAL_TRANSACTIONS_SET, json, callback);
}

const UpdateFinancialTransactionAPI = (data, callback) => {
    let json = "companyId=" + data.companyId +
        "&id=" + data.id +
        "&type=" + data.type +
        "&description=" + data.description +
        "&amount=" + data.amount +
        "&transactionDate=" + data.transactionDate +
        "&categoryId=" + data.categoryId;

    if (data.isRecurring) {
        json += "&isRecurring=1" +
            "&recurrenceFrequency=" + data.recurrenceFrequency +
            "&recurrenceStartDate=" + data.recurrenceStartDate +
            "&recurrenceEndDate=" + (data.recurrenceEndDate || 'null');
    }

    CallPOSTAPI(FINANCIAL_TRANSACTIONS_UPDATE, json, callback);
}

const DeleteFinancialTransactionAPI = (companyId, id, callback) => {
    let json = "companyId=" + companyId + "&id=" + id;
    CallPOSTAPI(FINANCIAL_TRANSACTIONS_DELETE, json, callback);
}

const GetFinancialDashboardAPI = (companyId, month, year, callback) => {
    let json = "companyId=" + companyId;
    if (month) json += "&month=" + month;
    if (year) json += "&year=" + year;
    CallPOSTAPI(FINANCIAL_DASHBOARD_GET, json, callback);
}

const CreateServicePackageAPI = (companyId, data, callback) => {
  let json = "companyId=" + companyId +
      "&name=" + encodeURIComponent(data.name) +
      "&description=" + encodeURIComponent(data.description) +
      "&price=" + data.price +
      "&validity_days=" + data.validity_days +
      "&active=" + (data.active ? 1 : 0) +
      "&procedures=" + JSON.stringify(data.procedures);

  CallPOSTAPI(PACKAGES_CREATE, json, callback);
};

const UpdateServicePackageAPI = (packageId, data, callback) => {
  let json = "package_id=" + packageId +
      "&name=" + encodeURIComponent(data.name) +
      "&description=" + encodeURIComponent(data.description) +
      "&price=" + data.price +
      "&validity_days=" + data.validity_days +
      "&active=" + (data.active ? 1 : 0) +
      "&procedures=" + JSON.stringify(data.procedures);

  CallPOSTAPI(PACKAGES_UPDATE, json, callback);
};

const DeleteServicePackageAPI = (packageId, callback) => {
  let json = "package_id=" + packageId;
  CallPOSTAPI(PACKAGES_DELETE, json, callback);
};

// Resources APIs
const GetCompanyResourcesAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(RESOURCES_GET, json, callback);
}

const SetCompanyResourceAPI = (companyId, data, callback) => {
    let json = "companyId=" + companyId +
        "&label=" + encodeURIComponent(data.label) +
        "&regulator=" + encodeURIComponent(data.regulator || '') +
        "&signature=" + encodeURIComponent(data.signature || '');
    CallPOSTAPI(RESOURCES_SET, json, callback);
}

const UpdateCompanyResourceAPI = (data, callback) => {
    let json = "id=" + data.id +
        "&label=" + encodeURIComponent(data.label) +
        "&regulator=" + encodeURIComponent(data.regulator || '') +
        "&signature=" + encodeURIComponent(data.signature || '');
    CallPOSTAPI(RESOURCES_UPDATE, json, callback);
}

const DeleteCompanyResourceAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(RESOURCES_DELETE, json, callback);
}

// Procedures APIs
const GetCompanyProceduresAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(PROCEDURES_GET, json, callback);
}

const SetCompanyProcedureAPI = (companyId, data, callback) => {
    let json = "companyId=" + companyId +
        "&label=" + encodeURIComponent(data.label) +
        "&price=" + data.price +
        "&duration=" + data.duration;
    CallPOSTAPI(PROCEDURES_SET, json, callback);
}

const UpdateCompanyProcedureAPI = (data, callback) => {
    let json = "id=" + data.id +
        "&label=" + encodeURIComponent(data.label) +
        "&price=" + data.price +
        "&duration=" + data.duration;
    CallPOSTAPI(PROCEDURES_UPDATE, json, callback);
}

const DeleteCompanyProcedureAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(PROCEDURES_DELETE, json, callback);
}

const GetFinancialStatisticsAPI = (companyId, startDate, endDate, callback) => {
  let json = "companyId=" + companyId + "&startDate=" + startDate + "&endDate=" + endDate;
  CallPOSTAPI(FINANCIAL_STATISTICS_GET, json, callback);
}

const CallPOSTAPI = (path, body, callback, showConnectionLogs = false) => {
    const token = localStorage.getItem('token');
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 10000); // 10 second timeout

    const log = (msg, type = 'log') => {
        if (showConnectionLogs || type === 'error') {
            window.visualLogger?.[type](msg);
        }
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: body,
        signal: controller.signal
    };

    fetch(path, requestOptions)
        .then(response => {
            clearTimeout(timeout);
            
            if (response.status === 401) {
                log('Sessão expirada, redirecionando...', 'error');
                Disconnect();
                return;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response.text();
        })
        .then(data => {
            try {
                if (data) {
                    const jsonData = JSON.parse(data);
                    if (jsonData.error) {
                        log(jsonData.error, 'error');
                    }
                    callback(jsonData);
                } else {
                    log('Resposta vazia do servidor', 'error');
                }
            } catch (e) {
                log('Erro ao processar resposta do servidor', 'error');
                throw e;
            }
        })
        .catch(error => {
            if (error.name === 'AbortError') {
                log('Tempo limite de conexão excedido', 'error');
            } else if (!navigator.onLine) {
                log('Sem conexão com a internet', 'error');
            } else {
                log('Erro de conexão com o servidor', 'error');
            }
            callback({ error: 'Erro de conexão' });
        });
}

const GetActiveRecurringTransactionsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(FINANCIAL_TRANSACTIONS_GET_ACTIVE_RECURRING, json, callback);
}

// Payment Methods APIs
const GetCompanyPaymentMethodsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(PAYMENT_METHODS_GET, json, callback);
}

const SetCompanyPaymentMethodAPI = (companyId, data, callback) => {
    let json = "companyId=" + companyId +
        "&label=" + encodeURIComponent(data.label) +
        "&priceModifier=" + data.priceModifier;
    CallPOSTAPI(PAYMENT_METHODS_SET, json, callback);
}

const UpdateCompanyPaymentMethodAPI = (data, callback) => {
    let json = "id=" + data.id +
        "&label=" + encodeURIComponent(data.label) +
        "&priceModifier=" + data.priceModifier;
    CallPOSTAPI(PAYMENT_METHODS_UPDATE, json, callback);
}

const DeleteCompanyPaymentMethodAPI = (id, callback) => {
    let json = "id=" + id;
    CallPOSTAPI(PAYMENT_METHODS_DELETE, json, callback);
}

// Message Templates APIs
const GetCompanyMessageTemplatesAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(MESSAGE_TEMPLATES_GET, json, callback);
}

const UpdateCompanyMessageTemplateAPI = (id, template_content, callback) => {
    let json = "id=" + id + "&template_content=" + encodeURIComponent(template_content);
    CallPOSTAPI(MESSAGE_TEMPLATES_UPDATE, json, callback);
}

// Export APIs
const CreateExportJobAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(EXPORT_CREATE, json, callback);
}

const GetExportStatusAPI = (exportId, companyId, callback) => {
    let json = "exportId=" + exportId + "&companyId=" + companyId;
    CallPOSTAPI(EXPORT_STATUS, json, callback);
}

const GetExportHistoryAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(EXPORT_HISTORY, json, callback);
}

const CancelExportAPI = (exportId, companyId, callback) => {
    let json = "exportId=" + exportId + "&companyId=" + companyId;
    CallPOSTAPI(EXPORT_CANCEL, json, callback);
}

// Automation Settings APIs
const GetCompanyAutomationSettingsAPI = (companyId, callback) => {
    let json = "companyId=" + companyId;
    CallPOSTAPI(AUTOMATION_SETTINGS_GET, json, callback);
}

const UpdateCompanyAutomationSettingAPI = (companyId, automationType, isEnabled, callback) => {
    let json = "companyId=" + companyId +
        "&automation_type=" + automationType +
        "&is_enabled=" + (isEnabled ? 1 : 0);
    CallPOSTAPI(AUTOMATION_SETTINGS_UPDATE, json, callback);
}

export {
    GetActiveRecurringTransactionsAPI,
    GetAIAnswerAPI,
    APPOINTMENT_GET_RECEIPT,
    LoginAPI,
    GetCompanyConfigAPI,UpdateCompanyAPI,UpdateCompanyConfigAPI,
    GetLeadsAPI, SetLeadAPI, UpdateLeadAPI,
    GetCEPInfoAPI,
    GetDashboardAPI,
    GetStatisticsAPI ,
    GetReportsAppointmentsAPI,GetReportsFinancialAPI,
    GetAppointmentsToConfirmAPI,
    UpdateAppointmentStatusAPI,
    GetPatientsAPI, GetPatientAPI, SetPatientAPI, UpdatePatientAPI,
    SetPatientHistoryAPI, SetPatientComplaints, SetPatientAnamneseAPI, UpdatePatientAnamneseAPI, DeletePatientAnamneseAPI, SetPatientCranialAsymmetryAPI, UpdatePatientCranialAsymmetryAPI, DeletePatientCranialAsymmetryAPI, SetPatientDocumentAPI, GetPatientDocumentsAPI,
    GetAppointmentsAPI, SetAppointmentAPI, UpdateAppointmentAPI, DeleteAppointmentAPI,
    GetWaitingListAPI, SetWaitingListAPI, UpdateWaitingListAPI, DeleteWaitingListAPI, CheckWaitingListAvailabilityAPI,
    SetAppointmentInvoiceAPI,
    GetAppointmentFullInfo,
    GetInvoiceAPI,UpdateInvoiceAPI,UpdateInvoiceNfIssuedAPI,
    SendWhatsappMessageAPI,GetWhatsappChatsAPI,GetWhatsappChatMessagesAPI,
    GetTemplatesDocumentsAPI, SetTemplatesDocumentsAPI, UpdateTemplatesDocumentsAPI, DeleteTemplatesDocumentsAPI,
    // Message Templates APIs
    GetCompanyMessageTemplatesAPI,
    UpdateCompanyMessageTemplateAPI,
    // Anamnesis Templates APIs
    GetAnamnesisTemplatesAPI,
    SetAnamnesisTemplateAPI,
    UpdateAnamnesisTemplateAPI,
    DeleteAnamnesisTemplateAPI,
    // NPS APIs
    GetNPSStatisticsAPI,
    GetNPSResponsesAPI,
    SetNPSResponseAPI,
    UpdateNPSGoogleReviewAPI,
    // Financial APIs
    GetFinancialCategoriesAPI,
    SetFinancialCategoryAPI,
    UpdateFinancialCategoryAPI,
    GetFinancialTransactionsAPI,
    SetFinancialTransactionAPI,
    UpdateFinancialTransactionAPI,
    DeleteFinancialTransactionAPI,
    GetFinancialDashboardAPI,
    GetFinancialStatisticsAPI,
    // Wallet APIs
    GetPatientWalletAPI,
    GetWalletTransactionsAPI,
    GetServicePackagesAPI,
    PurchaseServicePackageAPI,
    DeductWalletCreditsAPI,
    CreateServicePackageAPI,
    UpdateServicePackageAPI,
    DeleteServicePackageAPI,
    // Resources APIs
    GetCompanyResourcesAPI,
    SetCompanyResourceAPI,
    UpdateCompanyResourceAPI,
    DeleteCompanyResourceAPI,
    // Procedures APIs
    GetCompanyProceduresAPI,
    SetCompanyProcedureAPI,
    UpdateCompanyProcedureAPI,
    DeleteCompanyProcedureAPI,
    // Payment Methods APIs
    GetCompanyPaymentMethodsAPI,
    SetCompanyPaymentMethodAPI,
    UpdateCompanyPaymentMethodAPI,
    DeleteCompanyPaymentMethodAPI,
    // Export APIs
    CreateExportJobAPI,
    GetExportStatusAPI,
    GetExportHistoryAPI,
    CancelExportAPI,
    // Automation Settings APIs
    GetCompanyAutomationSettingsAPI,
    UpdateCompanyAutomationSettingAPI
};
