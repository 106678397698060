import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.css';
import { CreatePDF } from '../../utils/Utils';
import { DataProviderInstance } from '../../api/DataProvider';
import Loading from '../../components/loading/Loading';
import DateRangeHeader from '../../components/dateRangeHeader/DateRangeHeader';
import TabBar from '../../components/tab-bar/TabBar';
import CustomButton from '../../components/buttons/CustomButton';

// Componentes de estatísticas
import LeadsStatistics from './components/LeadsStatistics';
import AppointmentsStatistics from './components/AppointmentsStatistics';
import PatientsStatistics from './components/PatientsStatistics';
import FinancialStatistics from './components/FinancialStatistics';
import NPSStatistics from './components/NPSStatistics';

function Statistics() {
  const areas = [
    { id: 0, label: "Leads" },
    { id: 1, label: "Agendamentos" },
    { id: 2, label: "Pacientes" },
    { id: 3, label: "Financeiro" },
    { id: 4, label: "NPS" },
  ];

  const {
    CompanyConfig,
    GetStatistics,
    GetFinancialStatistics,
    GetNPSStatistics,
    GetNPSResponses,
  } = DataProviderInstance()

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [financialStats, setFinancialStats] = useState(null);
  const [npsData, setNpsData] = useState({ statistics: null, responses: [] });
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [npsError, setNpsError] = useState(null);
  const pdfContentRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showUnknownOrigin, setShowUnknownOrigin] = useState(false);
  const [hasStatisticsData, setHasStatisticsData] = useState(false);
  const [hasFinancialData, setHasFinancialData] = useState(false);
  const [hasNPSData, setHasNPSData] = useState(false);
  const [currentArea, setCurrentArea] = useState(areas[0].id);

  useEffect(() => {
    if (startDate && endDate) {
      loadData(startDate, endDate);
    }
  }, []);

  useEffect(() => {
    if (hasStatisticsData && hasFinancialData && hasNPSData) {
      setIsLoaded(true);
    }
  }, [hasStatisticsData, hasFinancialData, hasNPSData]);

  const loadNPSData = async (start, end, category = 'all') => {
    setNpsError(null);
    
    try {
      const statisticsResult = await GetNPSStatistics(start, end);
      const responsesResult = await GetNPSResponses(start, end, category);
      
      if (!statisticsResult || !responsesResult) {
        throw new Error('Falha ao carregar dados do NPS');
      }
      
      const newData = {
        statistics: statisticsResult?.status === "Success" ? statisticsResult.data : null,
        responses: responsesResult?.status === "Success" ? responsesResult.data : []
      };
      
      setNpsData(newData);
      setHasNPSData(true);
      
    } catch (error) {
      console.error('Erro ao carregar dados do NPS:', error);
      setNpsError(error.message);
      setNpsData({
        statistics: null,
        responses: []
      });
      setHasNPSData(true); // Mesmo com erro, consideramos que tentamos carregar
    }
  };

  const loadData = (initialDate, finalDate) => {
    setIsLoaded(false);
    setHasStatisticsData(false);
    setHasFinancialData(false);
    setHasNPSData(false);

    // Busca dados gerais
    GetStatistics(initialDate, finalDate, (result) => {
      if (!result) {
        console.error('GetStatistics retornou resultado nulo/undefined');
        return;
      }
      setDashboardData(result);
      setHasStatisticsData(true);
    });

    // Busca dados financeiros separadamente
    GetFinancialStatistics(initialDate, finalDate, (response) => {
      if (response.status === 'success') {
        setFinancialStats(response.data);
        setHasFinancialData(true);
      }
    });

    // Busca dados do NPS
    loadNPSData(initialDate, finalDate, selectedCategory);
  };

  const handleDateChange = (initialDate, finalDate) => {
    setStartDate(initialDate);
    setEndDate(finalDate);
    loadData(initialDate, finalDate);
  }

  const handleExport = () => {
    GenerateReport();
  }

  function GenerateReport() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const fileName = `Relatorio_${formattedDate}.pdf`;

    CreatePDF(pdfContentRef.current, fileName);
  }

  function handleAreaChange(newValue) {
    setCurrentArea(newValue);
  };

  const handleNPSCategoryChange = (event) => {
    const newCategory = event.target.value;
    setSelectedCategory(newCategory);
    loadNPSData(startDate, endDate, newCategory);
  };

  function renderCurrentArea() {
    switch (currentArea) {
      case 0:
        return (
          <>
            <h2>Leads</h2>
            <LeadsStatistics dashboardData={dashboardData} />
          </>
        );
      case 1:
        return (
          <>
            <h2>Agendamentos</h2>
            <AppointmentsStatistics dashboardData={dashboardData} CompanyConfig={CompanyConfig} />
          </>
        );
      case 2:
        return (
          <>
            <h2>Pacientes</h2>
            <PatientsStatistics 
              dashboardData={dashboardData} 
              showUnknownOrigin={showUnknownOrigin}
              setShowUnknownOrigin={setShowUnknownOrigin}
            />
          </>
        );
      case 3:
        return (
          <>
            <h2>Financeiro</h2>
            <FinancialStatistics financialStats={financialStats} dashboardData={dashboardData} />
          </>
        );
      case 4:
        return (
          <>
            <h2>NPS</h2>
            <NPSStatistics 
              npsData={npsData}
              error={npsError}
              selectedCategory={selectedCategory}
              onCategoryChange={handleNPSCategoryChange}
            />
          </>
        );
      default:
        return null;
    }
  }

  return (
    <div className='page-content' style={{ gap: "20px", display: "flex", flexDirection: "column" }}>
      <DateRangeHeader
        onChange={handleDateChange}
      />
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px", justifyContent: "space-between" }}>
        <div style={{ width: "100%" }}>
          <TabBar labels={areas.map(item => item.label)} value={currentArea} onChange={handleAreaChange} />
        </div>
        <CustomButton variant="contained" style={"primary"} label={"Exportar"} onClick={handleExport} />
      </div>

      {!isLoaded && <Loading />}
      {isLoaded &&
        <div ref={pdfContentRef} style={{ display: "flex", flexDirection: "column", gap: "20px", paddingTop: 'initial' }}>
          {renderCurrentArea()}
        </div>
      }
    </div>
  );
}

export default Statistics;
