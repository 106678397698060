import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './SidebarItem.css';
import { Tooltip } from '@mui/material';

function SidebarItem(props) {
  const { path, icon, label, isOpen } = props;
  const active = useLocation().pathname === path;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (!active) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const itemContent = (
    <>
      <div className={isOpen && active ? "sidebar-item-icon-actived" : "sidebar-item-icon"}>
        {icon}
      </div>
      {isOpen && (
        <span className={
          active 
            ? "sidebar-label-active"
            : isHovered 
              ? "sidebar-label-hover" 
              : "sidebar-label"
        }>
          {label}
        </span>
      )}
    </>
  );

  return (
    <NavLink 
      to={path} 
      style={{ textDecoration: 'none' }}
      className={isOpen && active ? "sidebar-item-actived" : "sidebar-item"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isOpen ? (
        itemContent
      ) : (
        <Tooltip title={label} placement="right">
          <div className="sidebar-item-content">
            {icon && <div className="sidebar-item-icon">
              {icon}
            </div>}
          </div>
        </Tooltip>
      )}
    </NavLink>
  );
}

export default SidebarItem;