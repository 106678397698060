import React from 'react';
import styles from './CpfWarning.module.css';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Tooltip } from '@mui/material';

const CpfWarning = () => {
    return (
        <Tooltip title="CPF não cadastrado" placement="top">
            <div className={styles.warning}>
                <WarningAmberOutlinedIcon fontSize="small" />
            </div>
        </Tooltip>
    );
};

export default CpfWarning;