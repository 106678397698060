import React from 'react';
import styles from './PatientItem.module.css';
import { NavLink } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { GetFriendlyCPF } from '../../../utils/Utils';
import { SendWhatsAppMessage } from "../../../api/WhatsappAPI";
import MessagesTemplate from '../../../consts/MessagesTemplate';
import { DataProviderInstance } from '../../../api/DataProvider';
import CpfWarning from '../../../components/cpf-warning/CpfWarning';
import PatientConfig, { Patient_State_Active, Patient_State_Inactive } from '../../../consts/PatientConfig';

function PatientItem(props) {
    const { Id, Status, Name, CPF, Phone, OnSelect, Highlight } = props;
    const { CompanyConfig: companyConfig } = DataProviderInstance();

    function onWhatsappClickHandler(e) {
        e.preventDefault(); // Prevent NavLink navigation
        if (!companyConfig) return;

        const templates = MessagesTemplate.getTemplates(companyConfig);
        const currentHour = new Date().getHours();
        let greeting = "Olá";
        
        if (currentHour >= 5 && currentHour < 12) {
            greeting = "Bom dia";
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = "Boa tarde";
        } else {
            greeting = "Boa noite";
        }

        let template = templates.PatientListGreetingMessage || "{greetings} {name}";
        let message = template;
        message = message.replaceAll("{name}", Name.split(' ')[0]);
        message = message.replaceAll("{greetings}", greeting);
        message = message.replaceAll("{doctor_name}", companyConfig.doctor_name || "");
        message = message.replaceAll("{company_name}", companyConfig.company_name || "");

        SendWhatsAppMessage(Phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    const statusData = PatientConfig.GetStateById(Status);
    const isActive = statusData.Label === Patient_State_Active;

    const renderHighlightedName = () => {
        if (!Highlight || Highlight.trim() === '') {
            return <span className={styles.patientName}>{Name}</span>;
        }

        const parts = Name.split(new RegExp(`(${Highlight.trim()})`, 'gi'));
        return (
            <span className={styles.patientName}>
                {parts.map((part, i) => 
                    part.toLowerCase() === Highlight.trim().toLowerCase() ? 
                        <span key={i} className={styles.highlight}>{part}</span> : 
                        part
                )}
            </span>
        );
    };

    return (
        <tr key={Id}>
            <td>
                <div className={styles.statusCell}>
                    <Tooltip title={isActive ? "Ativo" : "Inativo"}>
                        {isActive ? (
                            <TaskAltIcon className={styles.statusIconActive} fontSize="small" />
                        ) : (
                            <CancelIcon className={styles.statusIconInactive} fontSize="small" />
                        )}
                    </Tooltip>
                </div>
            </td>
            <td>
                {renderHighlightedName()}
            </td>
            <td>
                <div className={styles.cpfCell}>
                    {GetFriendlyCPF(CPF)}
                    {(!CPF || CPF.trim() === '') && <CpfWarning />}
                </div>
            </td>
            <td>
                <div className={styles.contactCell}>
                    {Phone && (
                        <>
                            {Phone}
                            <Tooltip title="Enviar mensagem">
                                <IconButton 
                                    className={`${styles.actionButton} ${styles.whatsappButton}`}
                                    onClick={onWhatsappClickHandler}
                                    size="small"
                                >
                                    <WhatsAppIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </td>
            <td>
                <NavLink to={Id} style={{ textDecoration: 'none' }}>
                    <Tooltip title="Ver ficha">
                        <IconButton 
                            className={styles.actionButton}
                            size="small"
                        >
                            <ContactPageOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </NavLink>
            </td>
        </tr>
    );
}

export default PatientItem;
