import { breakLineTag, whatsAppBreakLineTag, textAppBreakLineTag } from './MessageBreakLines';

// Default templates as fallback
const DefaultTemplates = {
    AppointmentCreatedFullMessage: "Olá {name}, sua consulta está agendada para:" + breakLineTag + breakLineTag +
        "*{day}* de *{month}*, às {time} horas." + breakLineTag + breakLineTag +
        "Por favor, avise com antecedência caso não possa comparecer." + breakLineTag + breakLineTag +
        "Obrigado!",

    AppointmentCreatedShortMessage: "Olá {name}, sua consulta está agendada para:" + breakLineTag + breakLineTag +
        "*{day}* de *{month}*, às {time} horas." + breakLineTag + breakLineTag +
        "Por favor, avise com antecedência caso não possa comparecer." + breakLineTag + breakLineTag +
        "Obrigado!",

    AppointmentAskForConfirmation1: "Olá {name}, podemos confirmar sua consulta para *{day}* de *{month}* às {time} horas?",

    AppointmentAskForConfirmation2: "{name}, precisamos confirmar sua consulta para *{day}* de *{month}* às {time} horas. Podemos contar com sua presença?",

    AppointmentAskForConfirmation3: "{name}, por favor confirme sua consulta para *{day}* de *{month}* às {time} horas nos próximos 30 minutos para não perder seu horário.",

    Appointment7DaysReminder: "Olá {name}, lembramos que sua consulta está marcada para *{day}* de *{month}* às {time} horas." + breakLineTag + breakLineTag +
        "Por favor, avise com antecedência caso não possa comparecer." + breakLineTag + breakLineTag +
        "Aguardamos você!",

    AppointmentCancellationDueLackOfReturn: "Olá {name}, sua consulta foi cancelada por falta de confirmação." + breakLineTag + breakLineTag +
        "Entre em contato conosco para agendar uma nova data.",

    SuggestDate: "Olá {name}, temos disponibilidade para consulta no dia *{day} de {month}* às *{time} horas*." + breakLineTag + breakLineTag +
        "Este horário é conveniente para você?",

    HappyBirthday: "Feliz Aniversário, {name}!" + breakLineTag + breakLineTag +
        "Desejamos um dia especial e um ano cheio de saúde e alegrias." + breakLineTag + breakLineTag +
        "Atenciosamente," + breakLineTag +
        "{company_name}",

    SuggestDateFromWaitingList: "Olá {name}, surgiu uma vaga para consulta no dia *{day} de {month}* às *{time} horas*." + breakLineTag + breakLineTag +
        "Você gostaria de agendar para este horário?",

    AppointmentRescheduledConfirmedMessage: "Olá {name}, sua consulta foi reagendada para:" + breakLineTag +
        "*{day}* de *{month}*, às {time} horas." + breakLineTag + breakLineTag +
        "Por favor, avise com antecedência caso não possa comparecer." + breakLineTag +
        "Obrigado!"
};

const MessagesTemplate = {
    ConvertToWhatsAppText: (message) => {
        let rtn = message.replaceAll(breakLineTag, whatsAppBreakLineTag);
        return rtn;
    },

    ConvertToClipboardText: (message) => {
        let rtn = message.replaceAll(breakLineTag, textAppBreakLineTag);
        return rtn;
    },

    getTemplate: (key, companyConfig) => {
        
        if (!companyConfig || !companyConfig.MessageTemplates) {
            return DefaultTemplates[key];
        }

        const template = companyConfig.MessageTemplates.find(t => t.TemplateKey === key);
        return template ? template.TemplateContent : DefaultTemplates[key];
    },

    getTemplates: (companyConfig) => {
        
        const templates = {
            AppointmentCreatedFullMessage: MessagesTemplate.getTemplate('AppointmentCreatedFullMessage', companyConfig),
            AppointmentCreatedShortMessage: MessagesTemplate.getTemplate('AppointmentCreatedShortMessage', companyConfig),
            AppointmentAskForConfirmation: [
                MessagesTemplate.getTemplate('AppointmentAskForConfirmation1', companyConfig),
                MessagesTemplate.getTemplate('AppointmentAskForConfirmation2', companyConfig),
                MessagesTemplate.getTemplate('AppointmentAskForConfirmation3', companyConfig)
            ],
            Appointment7DaysReminder: MessagesTemplate.getTemplate('Appointment7DaysReminder', companyConfig),
            AppointmentCancellationDueLackOfReturn: MessagesTemplate.getTemplate('AppointmentCancellationDueLackOfReturn', companyConfig),
            SuggestDate: MessagesTemplate.getTemplate('SuggestDate', companyConfig),
            HappyBirthday: MessagesTemplate.getTemplate('HappyBirthday', companyConfig),
            SuggestDateFromWaitingList: MessagesTemplate.getTemplate('SuggestDateFromWaitingList', companyConfig),
            AppointmentRescheduledConfirmedMessage: MessagesTemplate.getTemplate('AppointmentRescheduledConfirmedMessage', companyConfig)
        };
        
        return templates;
    }
};

export default MessagesTemplate;