import React, { useState } from 'react';
import styles from './SidebarSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SidebarItem from '../group/SidebarItem';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

const SidebarSection = ({ title, items, isOpen, icon }) => {
    const [isExpanded, setIsExpanded] = useState(title === "Principal");
    const location = useLocation();

    const toggleExpand = () => {
        if (isOpen) {
            setIsExpanded(!isExpanded);
        }
    };

    // Verifica se algum item da seção está ativo
    const isSectionActive = items.some(item => location.pathname === item.path);

    const sectionHeader = (
        <>
            <div className={`${styles.sectionIcon} ${!isOpen && isSectionActive ? styles.sectionIconActive : ''}`}>
                {icon}
            </div>
            {isOpen && (
                <>
                    <span className={styles.title}>{title}</span>
                    <FontAwesomeIcon 
                        icon={isExpanded ? faChevronDown : faChevronRight} 
                        className={styles.chevron}
                    />
                </>
            )}
        </>
    );

    return (
        <div className={styles.section}>
            {isOpen ? (
                <div className={styles.sectionHeader} onClick={toggleExpand}>
                    {sectionHeader}
                </div>
            ) : (
                <Tooltip title={title} placement="right">
                    <div className={`${styles.sectionHeaderCollapsed} ${isSectionActive ? styles.sectionHeaderActive : ''}`}>
                        {sectionHeader}
                    </div>
                </Tooltip>
            )}
            {isOpen && (
                <div className={`${styles.sectionContent} ${!isExpanded && styles.collapsed}`}>
                    {items.map((item) => (
                        <SidebarItem 
                            key={item.path} 
                            path={item.path} 
                            icon={item.icon} 
                            label={item.label}
                            isOpen={isOpen}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default SidebarSection;