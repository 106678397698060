import React from 'react';
import styles from './TimeColumn.module.css';

const TimeColumn = ({ startHour = 8, endHour = 20, interval = 15 }) => {
  const hourMarkers = [];
  const totalHours = endHour - startHour;
  
  // Create markers for full hours, excluding the last hour
  for (let hour = startHour; hour < endHour; hour++) {
    const position = ((hour - startHour) / totalHours) * 100;
    
    hourMarkers.push(
      <div
        key={`hour-${hour}`}
        className={styles.hourMarker}
        style={{
          top: `${position}%`
        }}
      >
        <span className={styles.hourLabel}>
          {hour.toString().padStart(2, '0')}:00
        </span>
      </div>
    );
  }

  return (
    <div className={styles.timeColumn}>
      <div className={styles.headerSpacer} />
      <div className={styles.markersContainer}>
        {hourMarkers}
      </div>
    </div>
  );
};

export default TimeColumn;