import React, { useEffect, useState } from 'react';
import styles from './AppointmentToReminderItem.module.css';
import { SendWhatsAppMessage } from "../../../../api/WhatsappAPI";
import AppointmensState from "../../../../consts/AppointmentsState";
import CustomButtom from '../../../../components/buttons/CustomButton';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import MessagesTemplate from '../../../../consts/MessagesTemplate';
import CalendarConfig from '../../../../consts/CalendarConfig';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { UpdateAppointmentStatusAPI } from '../../../../api/API';
import ColorPalette from '../../../../colorpalette/ColorPalette';
import { Check } from '@mui/icons-material';
import { DataProviderInstance } from '../../../../api/DataProvider';

function AppointmentToReminderItem(props) {
    const { id, patientId, name, date, time, phone, procedure, resource } = props;
    const todayDate = new Date().toISOString().split('T')[0];
    const messageStatusKey = `message7DaysReminderStatus-${date + time + id}`;
    const { CompanyConfig } = DataProviderInstance();
    const templates = MessagesTemplate.getTemplates(CompanyConfig);

    const [sentMessage, setSentMessage] = useState(false);
    const [state, setState] = useState(AppointmensState.Scheduled);

    useEffect(() => {
        setSentMessage(getSentMessage());
    }, []);

    useEffect(() => {
        if (sentMessage === true) {
            localStorage.setItem(messageStatusKey, true);
        }
    }, [sentMessage, messageStatusKey]);

    function getSentMessage() {
        const storedData = localStorage.getItem(messageStatusKey);
        return storedData != null ? true : false;
    }

    function OnPhoneClickHandler() {
        window.location.href = `tel:${phone}`;
    }

    function OnAskForAppointmentConfirmClickHandler() {
        let message = templates.Appointment7DaysReminder;
        let yyyymmdd = CalendarConfig.GetAAAAMMDDArray(date);
        let monthDay = yyyymmdd[2];
        let monthName = CalendarConfig.Months[yyyymmdd[1] - 1];

        const today = new Date();
        const appointmentDate = new Date(date);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        let greetings = "";
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            greetings = "Bom dia";
        } else if (currentHour < 18) {
            greetings = "Boa tarde";
        } else {
            greetings = "Boa noite";
        }

        let when = "";
        if (appointmentDate.toDateString() === today.toDateString()) {
            when = "hoje";
        } else if (appointmentDate.toDateString() === tomorrow.toDateString()) {
            when = "amanhã";
        }

        message = message.replaceAll("{greetings}", greetings);
        message = message.replaceAll("{name}", name.split(' ')[0]);
        message = message.replaceAll("{when}", when);
        message = message.replaceAll("{day}", monthDay);
        message = message.replaceAll("{month}", monthName);
        message = message.replaceAll("{time}", CalendarConfig.GetTime(date));
        message = message.replaceAll("{doctor_name}", resource.Label || "");

        SendWhatsAppMessage(phone, MessagesTemplate.ConvertToWhatsAppText(message));
        setSentMessage(true);
    }

    const OnItemSelectHandler = (event) => {
        setState(event.target.value);
        UpdateAppointmentStatusAPI(id, event.target.value, () => { });
    };

    const onNameClickHandler = () => {
        window.open(`/patients/${patientId}`);
    }

    const GetStyle = () => {
        let statusStyle = AppointmensState.GetDataFromId(state);
        return {
            backgroundColor: statusStyle.backgroundColor,
            color: statusStyle.textColor,
            fontSize: "14px",
            borderRadius: "4px",
            padding: "8px"
        }
    }

    function SendCancellationMessageClickHandler() {
        let message = templates.AppointmentCancellationDueLackOfReturn;
        message = message.replaceAll("{name}", name.split(' ')[0]);
        message = message.replaceAll("{doctor_name}", resource.Label || "");
        SendWhatsAppMessage(phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    const GetActionState = () => {
        if (state === AppointmensState.Canceled) {
            return (
                <CustomButtom
                    style="error"
                    variant="contained"
                    icon={<IconWhatsapp />}
                    onClick={SendCancellationMessageClickHandler}
                />
            );
        }
        else if (state === AppointmensState.Confirmed) {
            return null;
        }
        else {
            return (
                <div className={styles.containerActions}>
                    {sentMessage && <Check color='primary' className={styles.checkIcon} />}
                    <CustomButtom
                        style="primary"
                        variant="contained"
                        icon={<IconWhatsapp />}
                        onClick={OnAskForAppointmentConfirmClickHandler}
                    />
                </div>
            );
        }
    }

    const getStatusSelector = () => {
        return (
            <FormControl className={styles.statusSelect}>
                <Select
                    value={state}
                    onChange={OnItemSelectHandler}
                    style={GetStyle()}
                >
                    {AppointmensState.ChangeableBySystem.map((option, index) => {
                        let customStyle = AppointmensState.GetDataFromId(option);
                        return (
                            <MenuItem
                                key={index}
                                value={option}
                                style={{
                                    backgroundColor: customStyle.backgroundColor,
                                    color: customStyle.textColor,
                                    padding: "8px 16px",
                                    borderRadius: "4px",
                                    margin: "4px"
                                }}
                            >
                                {customStyle.icon}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        )
    }

    return (
        <div className={styles.container}>
            {window.innerWidth <= 1010 ? (
                <>
                    <div className={styles.firstLine}>
                        <div className={styles.timeContainer}>
                            <span className={styles.time}>{time}</span>
                        </div>
                        <span className={styles.name} onClick={onNameClickHandler}>
                            {name.split(' ')[0]}
                        </span>
                        <span className={styles.phone}>{phone}</span>
                    </div>
                    <div className={styles.secondLine}>
                        <span className={styles.procedure}>{procedure.Label}</span>
                        <span className={styles.resource}>
                            {resource.Label.split(' ').map(word => word[0]).join('')}
                        </span>
                    </div>
                    {getStatusSelector()}
                    {GetActionState()}
                </>
            ) : (
                <>
                    <div className={styles.timeContainer}>
                        <span className={styles.time}>{time}</span>
                    </div>
                    <div className={styles.info}>
                        <span className={styles.name} onClick={onNameClickHandler}>
                            {name.split(' ')[0]}
                        </span>
                        <span className={styles.procedure}>{procedure.Label}</span>
                        <span className={styles.resource}>
                            {resource.Label.split(' ').map(word => word[0]).join('')}
                        </span>
                    </div>
                    {getStatusSelector()}
                    {GetActionState()}
                </>
            )}
        </div>
    );
}

export default AppointmentToReminderItem;
