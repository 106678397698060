import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import 'dayjs/locale/pt-br';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Box } from '@mui/material';
import ColorPalette from '../../../../../colorpalette/ColorPalette';

dayjs.extend(isBetweenPlugin);
dayjs.locale('pt-br');

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered' && prop !== 'isWeekView',
})(({ theme, isSelected, isHovered, day, isWeekView }) => ({
  borderRadius: isWeekView ? 0 : '50%', // Default rounded style for day view
  margin: '0px', // No gap between days
  padding: '0px',
  fontSize: '0.75rem',
  ...(isSelected && {
    backgroundColor: ColorPalette.secondaryLight,
    color: ColorPalette.secondary,
    '&:hover, &:focus': {
      backgroundColor: ColorPalette.secondaryLight,
    },
  }),
  ...(isHovered && isWeekView && {
    backgroundColor: ColorPalette.secondaryLight,
    color: ColorPalette.secondary,
    '&:hover, &:focus': {
      backgroundColor: ColorPalette.secondaryLight,
    },
  }),
  ...(day.day() === 0 && isWeekView && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(day.day() === 6 && isWeekView && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

const isInSameWeek = (dayA, dayB) => {
  if (dayB == null) {
    return false;
  }
  return dayA.isSame(dayB, 'week');
};

function Day(props) {
  const { day, selectedDay, hoveredDay, isWeekView, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      selected={false}
      isSelected={isWeekView ? isInSameWeek(day, selectedDay) : day.isSame(selectedDay, 'day')}
      isHovered={isWeekView && isInSameWeek(day, hoveredDay)}
      isWeekView={isWeekView}
    />
  );
}

const CalendarDatePicker = ({ currentDate, viewDaysCount, onChange }) => {
  const [hoveredDay, setHoveredDay] = useState(null);
  const [value, setValue] = useState(dayjs(currentDate));
  const isWeekView = viewDaysCount > 1;

  const handleDateChange = (newValue) => {
    setValue(newValue);
    onChange(newValue.toDate());
  };

  useEffect(() => {
    setValue(dayjs(currentDate));
  }, [currentDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-br">
      <Box sx={{ 
        width: '100%', 
        '& .MuiDateCalendar-root': {
          width: 'fit-content',
          maxHeight: '240px',
          padding: '4px',
          margin: '0 auto',
          '& .MuiYearCalendar-root, & .MuiMonthCalendar-root': {
            width: 'fit-content'
          }
        },
        '& .MuiMonthCalendar-root, & .MuiYearCalendar-root': {
          maxHeight: '180px',
          width: 'fit-content',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, auto)',
          columnGap: '8px',
          rowGap: '2px',
          padding: '4px',
          '& .MuiPickersMonth-monthButton, & .MuiPickersYear-yearButton': {
            fontSize: '0.75rem',
            padding: '0px',
            margin: '0px',
            minHeight: '18px',
            width: '45px',
            maxWidth: '45px',
            lineHeight: '1',
            borderRadius: '0',
            justifyContent: 'center'
          }
        },
        '& .MuiPickersCalendarHeader-root': {
          '& .MuiPickersCalendarHeader-label': {
            fontSize: '0.875rem',
          }
        },
        '& .MuiDayCalendar-header, & .MuiDayCalendar-weekContainer': {
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 26px)',
          justifyContent: 'center',
          gap: '0px',
          margin: '1px 0',
        },
        '& .MuiDayCalendar-header': {
          paddingTop: '2px',
          '& .MuiTypography-root': {
            width: '26px',
            textAlign: 'center',
          }
        },
        '& .MuiPickersDay-root': {
          width: '26px',
          height: '26px',
          fontSize: '0.75rem',
          margin: '0',
        }
      }}>
        <DateCalendar
          value={value}
          onChange={handleDateChange}
          showDaysOutsideCurrentMonth
          slots={{ day: Day }}
          slotProps={{
            day: (ownerState) => ({
              selectedDay: value,
              hoveredDay,
              onPointerEnter: () => setHoveredDay(ownerState.day),
              onPointerLeave: () => setHoveredDay(null),
              isWeekView,
            }),
          }}
          dayOfWeekFormatter={(day) => {
            const dayOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
            return dayOfWeek[day];
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default CalendarDatePicker;
