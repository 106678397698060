import React, { useEffect, useState } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import styles from './Templates.module.css';
import Loading from '../../components/loading/Loading.js';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Chip, Typography, Box } from '@mui/material';
import { Edit, Delete, Category } from '@mui/icons-material/';
import CustomButton from '../../components/buttons/CustomButton';
import CreateEditAnamnesisTemplate from '../../popup/CreateEditAnamnesisTemplate/CreateEditAnamnesisTemplate';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import ColorPalette from '../../colorpalette/ColorPalette.js';

const AnamnesisTemplates = () => {
    const { GetAnamnesisTemplates, DeleteAnamnesisTemplate } = DataProviderInstance();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [confirmPopupData, setConfirmPopupData] = useState({title: "", description: ""});

    const [currentItem, setCurrentItem] = useState(null);
    const [isVisibleTemplateInfo, setIsVisibleTemplateInfo] = useState(false);
    const [isVisibleConfirmPopup, setIsVisibleConfirmPopup] = useState(false);

    useEffect(() => {
        loadAnamnesisTemplates(() => setLoading(false));
    }, []);

    const loadAnamnesisTemplates = (callback) => {
        GetAnamnesisTemplates((response) => {
            // Extract the data array from the response and ensure it's an array
            const templatesArray = response?.data && Array.isArray(response.data) ? response.data : [];
            setData(templatesArray);
            if(callback) callback();
        });
    }

    if (loading) {
        return <Loading />;
    }

    const handleAddTemplate = () => {
        setCurrentItem(null);
        setIsVisibleTemplateInfo(true);
    };

    const handleEditTemplate = (id) => {
        const itemToEdit = data.find((template) => template.id === id);
        setCurrentItem(itemToEdit);
        setIsVisibleTemplateInfo(true);
    };

    const handleDeleteTemplate = (id) => {
        const itemToDelete = data.find((template) => template.id === id);
        setCurrentItem(itemToDelete);

        setConfirmPopupData({
            title: "Deletar Template",
            description: `Deseja realmente deletar o template <strong>${itemToDelete.label}</strong>?`
        });

        setIsVisibleConfirmPopup(true);
    };

    const handleClose = () => {
        setIsVisibleTemplateInfo(false);
        setCurrentItem(null);
    };

    const handleReloadTemplates = () => {
        setIsVisibleTemplateInfo(false);
        loadAnamnesisTemplates();
    }

    const handleConfirmAction = (action) => {
        if(action === "delete_template") {
            DeleteAnamnesisTemplate(currentItem.id, (response) => {
                handleReloadTemplates();
            });
        }
    }

    const handleConfirmPopupHide = () => {
        setIsVisibleConfirmPopup(false);
        setCurrentItem(null);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {isVisibleConfirmPopup && (
                <ConfirmPopup 
                    Visibility={isVisibleConfirmPopup} 
                    Title={confirmPopupData.title} 
                    Description={confirmPopupData.description} 
                    ConfirmLabel={"Apagar"} 
                    OnConfirm={() => handleConfirmAction("delete_template")} 
                    OnHide={handleConfirmPopupHide}
                />
            )}
            {isVisibleTemplateInfo && (
                <CreateEditAnamnesisTemplate 
                    Data={currentItem} 
                    OnUpdate={handleReloadTemplates} 
                    OnHide={handleClose} 
                />
            )}
            <div>          
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <AreaTitle Title={`Templates de Anamnese (${data.length})`} />
                    <CustomButton
                        variant="contained"
                        style="primary"
                        label={"Adicionar"}
                        onClick={handleAddTemplate}
                    />
                </div>
                <List>
                    {data.map((template) => (
                        <ListItem 
                            key={template.id} 
                            style={{ 
                                backgroundColor: 'white', 
                                borderRadius: '8px', 
                                marginBottom: '10px' 
                            }}
                        >
                            <ListItemText
                                primary={template.label}
                                secondary={
                                    <>
                                        {JSON.parse(template.questions).length} perguntas
                                    </>
                                }
                            />
                            <ListItemSecondaryAction style={{ display: 'flex', gap: '10px' }}>
                                <IconButton 
                                    edge="end" 
                                    aria-label="edit" 
                                    onClick={() => handleEditTemplate(template.id)}
                                >
                                    <Edit htmlColor={ColorPalette.black}/>
                                </IconButton>
                                <IconButton  
                                    aria-label="delete" 
                                    onClick={() => handleDeleteTemplate(template.id)}
                                >
                                    <Delete htmlColor={ColorPalette.redLight1} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    );
};

export default AnamnesisTemplates;