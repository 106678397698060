import React from 'react';
import { Typography } from '@mui/material';
import styles from './Import.module.css';
import AreaTitle from '../../../components/areaTitle/AreaTitle';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

const Import = () => {
    return (
        <div className='page-content'>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <AreaTitle 
                        Title="Importar Dados" 
                        Description="Importe dados para o sistema."
                        Icon={<UploadFileOutlinedIcon/>}
                    />
                </div>
                
                <div className={styles.container}>
                    <Typography variant="body1">
                        Funcionalidade em desenvolvimento.
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default Import;