import React, { useEffect, useState } from "react";
import PatientConfig from "../../../consts/PatientConfig";
import styles from "./Header.module.css";
import CustomButtom from "../../../components/buttons/CustomButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMessage, faCalendarCheck  } from '@fortawesome/free-solid-svg-icons';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import Picture from '../../../assets/images/patient-picture.png';
import CalendarConfig from "../../../consts/CalendarConfig";
import { SendWhatsAppMessage } from  "../../../api/WhatsappAPI";
import MessagesTemplate from "../../../consts/MessagesTemplate";
import { DataProviderInstance } from "../../../api/DataProvider";

function Header(props) {
    const { Patient } = props;
    const { CompanyConfig: companyConfig } = DataProviderInstance();

    const age = Patient.birthday ? CalendarConfig.GetAge(Patient.birthday) : "Idade desconhecida";
    const gender = Patient.gender != null && Patient.gender != "" ? PatientConfig.GetGenderById(Patient.gender).Label : "";

    function OnPhoneClickHandler() {
        window.location.href = `tel:${Patient.phone}`;
    }

    function OnMessageClickHandler() {
        if (!companyConfig) return;

        const templates = MessagesTemplate.getTemplates(companyConfig);
        const currentHour = new Date().getHours();
        let greeting = "Olá";
        
        if (currentHour >= 5 && currentHour < 12) {
            greeting = "Bom dia";
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = "Boa tarde";
        } else {
            greeting = "Boa noite";
        }

        let template = templates.PatientGreetingMessage || "{greetings} {name}";
        let message = template;
        message = message.replaceAll("{name}", Patient.name.split(' ')[0]);
        message = message.replaceAll("{greetings}", greeting);
        message = message.replaceAll("{doctor_name}", companyConfig.doctor_name || "");
        message = message.replaceAll("{company_name}", companyConfig.company_name || "");

        SendWhatsAppMessage(Patient.phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    function OnScheduleClickHandler() {
        // Future implementation
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.containerContent}>
                <img src={Picture} width={60} height={60} alt="Patient" />
                <div style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <h2 className={styles.patientName}>{Patient.name} </h2>
                    <p className={styles.patientGender}> {gender + " (" + age + ")"} </p>
                </div>
            </div>
            <div className={styles.actions}>
                <CustomButtom style={"primary"} variant="outlined" icon={<FontAwesomeIcon icon={faPhone} style={{ color: "#206afa" }} />} onClick={OnPhoneClickHandler} />
                <CustomButtom style={"primary"} variant="outlined" icon={<IconWhatsapp style={{ color: "#206afa" }} />} onClick={OnMessageClickHandler} />
                {/*<CustomButtom id={3} style={"primary"} variant="outlined" icon={<FontAwesomeIcon icon={faCalendarCheck} style={{ color: "#206afa" }} />} onClick={OnScheduleClickHandler} />*/}
            </div>
        </div>
    )
}

export default Header;