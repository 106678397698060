import React, { useState, useEffect, useCallback } from 'react';
import styles from './VisualLogger.module.css';

const VisualLogger = () => {
    const [messages, setMessages] = useState([]);

    const addMessage = useCallback((text, type = 'info') => {
        const newMessage = {
            id: Date.now(),
            text,
            type,
            timestamp: new Date().toLocaleTimeString()
        };

        setMessages(prev => [...prev, newMessage]);

        // Auto-remove message after 5 seconds
        setTimeout(() => {
            setMessages(prev => prev.filter(msg => msg.id !== newMessage.id));
        }, 5000);
    }, []);

    // Expose the addMessage function globally for use in API calls
    useEffect(() => {
        window.visualLogger = {
            log: (text) => addMessage(text, 'info'),
            error: (text) => addMessage(text, 'error'),
            warning: (text) => addMessage(text, 'warning')
        };

        return () => {
            window.visualLogger = undefined;
        };
    }, [addMessage]);

    return (
        <div className={styles.container}>
            {messages.map(message => (
                <div
                    key={message.id}
                    className={`${styles.message} ${styles[message.type]}`}
                >
                    <span>{message.text}</span>
                    <span className={styles.timestamp}>{message.timestamp}</span>
                </div>
            ))}
        </div>
    );
};

export default VisualLogger;