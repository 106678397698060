import React from 'react';
import styles from './AppointmentsHistory.module.css';
import AppointmentHistoryItem from './components/appointment-history-item/AppointmentHistoryItem';
import Stats from './components/stats/Stats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faX, faCancel, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

const AppointmentsHistory = ({ data }) => {


    const lastAppointment = data.history.find(appointment => appointment.status == "completed") || null;
    const lastAppointmentDate = lastAppointment  ? new Date(lastAppointment.date) : null;
    const lastAppointmentDateFormatted = lastAppointmentDate ? `${lastAppointmentDate.getDate().toString().padStart(2, '0')}/${(lastAppointmentDate.getMonth() + 1).toString().padStart(2, '0')}/${lastAppointmentDate.getFullYear()}` : null;
    const currentDate = new Date();
    const daysSinceLastAppointment = lastAppointmentDate ? Math.floor((currentDate - lastAppointmentDate) / (1000 * 60 * 60 * 24)) : null;
    let timeSinceLastAppointment;

    if (lastAppointment === null) {
        timeSinceLastAppointment = "Nunca consultou";
    } else {
        const years = Math.floor(daysSinceLastAppointment / 365);
        const remainingDaysAfterYears = daysSinceLastAppointment % 365;
        const months = Math.floor(remainingDaysAfterYears / 30);
        const days = remainingDaysAfterYears % 30;

        const parts = [];
        
        if (years > 0) {
            parts.push(`${years} ${years === 1 ? 'ano' : 'anos'}`);
        }
        if (months > 0) {
            parts.push(`${months} ${months === 1 ? 'mês' : 'meses'}`);
        }
        if (days > 0) {
            parts.push(`${days} ${days === 1 ? 'dia' : 'dias'}`);
        }

        timeSinceLastAppointment = parts.length > 0
            ? parts.join(', ').replace(/,([^,]*)$/, ' e$1')
            : 'Hoje';
    }


    return (
        <div className={styles.container}>
            <div className={styles.containerStats}>
                <Stats icon={<FontAwesomeIcon icon={faCheckCircle} />} label="Realizados" value={data.stats.completed ? data.stats.completed : "0"} />
                <Stats icon={<FontAwesomeIcon icon={faX} />} label="Faltas" value={data.stats.noshow ? data.stats.noshow : "0"} />
                <Stats icon={<FontAwesomeIcon icon={faCancel} />} label="Cancelados pelo paciente" value={data.stats.canceled_by_patient ? data.stats.canceled_by_patient : "0"} />
                <Stats icon={<FontAwesomeIcon icon={faCalendarCheck} />} label="Tempo desde a última consulta" value={timeSinceLastAppointment+ " (" + lastAppointmentDateFormatted + ")"} />
            </div>
            <div className={styles.containerAppointmentsList}>
                <h3>Próximos agendamentos</h3>
                {data.upcoming.length === 0 ? <p>Nenhum agendamento existente.</p > :
                    data.upcoming.map((appointment, index) => (
                        <AppointmentHistoryItem key={index} data={appointment} />
                    ))
                }
            </div>
            <div className={styles.containerAppointmentsList}>
                <h3>Agendamentos passados</h3>
                {data.history.length === 0 ? <p>Nenhum agendamento realizado.</p > :
                    data.history.map((appointment, index) => (
                        <AppointmentHistoryItem key={index} data={appointment} />
                    ))
                }
            </div>
        </div>
    );
};

export default AppointmentsHistory;