import React, { useState, useRef, useEffect } from "react";
import { TextField, Select, MenuItem, FormControl, InputLabel, Autocomplete, createFilterOptions, CircularProgress, ListItemText } from "@mui/material";

import { useNavigate } from 'react-router-dom';
import './AppointmentPopupContent.css';
import PopupBase from "../base/PopupBase";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import ReschedulePopupContent from "../ReschedulePopupContent/ReschedulePopupContent";
import Header from "./header/Header";
import InputField from "../../components/inputfield/InputField";
import AppointmentsState from "../../consts/AppointmentsState";
import CalendarConfig from '../../consts/CalendarConfig';
import AppointmentStatusSelector from "./StatusSelector/AppointmentStatusSelector";
import CreatePatientPopup from '../CreatePatientPopup/CreatePatientPopup';
import { DataProviderInstance } from '../../api/DataProvider';
import MessagesTemplate from "../../consts/MessagesTemplate";
import CustomButton from "../../components/buttons/CustomButton";
import IconService from '@mui/icons-material/LocalHospitalOutlined';
import IconCreatePatient from '@mui/icons-material/PersonAddAlt1Outlined';
import IconMoney from '@mui/icons-material/MonetizationOnOutlined';
import IconReceipt from '@mui/icons-material/ReceiptLongOutlined';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import IconPatientData from '@mui/icons-material/ContactPageOutlined';
import IconRescheduled from '@mui/icons-material/RecyclingOutlined';
import { Check, CalendarToday, Schedule, DeleteOutline } from '@mui/icons-material';

import { SendWhatsAppMessage } from "../../api/WhatsappAPI";
import { APPOINTMENT_GET_RECEIPT } from '../../api/API';
import { Switch } from "@mui/material";

function AppointmentPopupContent(props) {

    const {
        CompanyConfig,
        patients, GetPatientById, GetPatients,
        SetAppointment, UpdateAppointment, DeleteAppointment,
        GetProcedureById, GetResourceById
    } = DataProviderInstance();
    
    const templates = MessagesTemplate.getTemplates(CompanyConfig);

    const { Data, OnCreateInvoice, OnViewInvoice, OnChange, OnHide  } = props;

    const keySendWhatssappReminder = "sendAppointmentCreationWhatssappReminder";

    const [createPatientPopupVisibility, ShowCreatePatientPopup] = useState(false);
    const [deleteAppointmentPopupVisibility, ShowDeleteAppointmentPopup] = useState(false);
    const [reschedulePopupVisibility, ShowReschedulePopup] = useState(false);
    const [sendWhatssappReminder, setSendWhatssappReminder] = useState(localStorage.getItem(keySendWhatssappReminder) === "true");

    const [patientData, setPatientData] = useState(null);
    const [patientsList, setPatientsList] = useState([]);
    const [sortedPatients, setSortedPatients] = useState([]);

    const [resource, setResource] = useState(null);
    const [procedure, setProcedure] = useState(null);

    const statusSelectorRef = useRef(null);
    const inputPriceRef = useRef(null);
    const inputDurationRef = useRef(null);

    const [patientNameInputValue, setPatientNameInputValue] = useState('');
    const [error, setError] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(Data?.status || AppointmentsState.Available);

    const navigate = useNavigate();

    const filter = createFilterOptions();

    // Update current status when Data changes
    useEffect(() => {
        if (Data?.status) {
            setCurrentStatus(Data.status);
        }
    }, [Data?.status]);

    // Handle status change
    const handleStatusChange = async (newStatus) => {
        // Update local state first
        setCurrentStatus(newStatus);
        
        // Create appointment data with new status
        const date = CalendarConfig.ISOToDate(Data.date);
        const parsedDate = CalendarConfig.GetAAAAMMDD(date);
        const time = CalendarConfig.GetTime(Data.date);
        
        const data = {
            ...Data,
            status: newStatus,
            date: parsedDate + " " + time,
            patient: Data.patient,
            resource: Data.resource,
            procedure: Data.procedure,
            duration: Data.duration,
            price: Data.price,
            invoiceId: Data.invoiceId
        };

        // Update appointment
        UpdateAppointment(data, (response) => {
            if (OnChange) {
                OnChange(response);
            }
            if (response?.status) {
                setCurrentStatus(response.status);
            }
        });
    };


    useEffect(() => {
        localStorage.setItem(keySendWhatssappReminder, sendWhatssappReminder);
    }, [sendWhatssappReminder]);

    useEffect(() => {
        ShowDeleteAppointmentPopup(false);
        ShowCreatePatientPopup(false);
        ShowReschedulePopup(false);

        // Carregar paciente se o ID do paciente estiver definido, independente se é novo agendamento ou não
        if (Data.patient) {
            const patient = GetPatientById(Data.patient);
            setPatientData(patient || null);
        } else {
            setPatientData(null);
        }

        // Carregar recurso se estiver definido, caso contrário usar o primeiro
        if (Data.resource) {
            setResource(GetResourceById(Data.resource));
        } else {
            setResource(CompanyConfig.Resources[0]);
        }

        // Carregar procedimento se estiver definido, caso contrário usar o primeiro
        if (Data.procedure) {
            setProcedure(GetProcedureById(Data.procedure));
        } else {
            setProcedure(CompanyConfig.Procedures[0]);
        }
    }, [Data]);

    function OnCreatePatientPopupHideHandler(data) {
        ShowCreatePatientPopup(false);
    }

    function OnOpenCreatePatientPopupHandler(suggestion) {

        ShowCreatePatientPopup(true);
    }

    function ValidateForm() {

        if (patientData == null || patientData.id == null) {
            setError(true);
            return false;
        }
        else {
            setError(false);
        }

        return true;
    }

    function OnCloseClickHandler() {
        // Verifica se statusSelectorRef.current existe antes de tentar acessar GetValue()
        if (statusSelectorRef.current && currentStatus !== statusSelectorRef.current.GetValue()) {
            ValidateAndSave(true); // Close popup when closing with changes
        } else {
            Hide();
        }
    }

    function OnDeleteClickHandler() {
        ShowDeleteAppointmentPopup(true);
    }

    function OnRescheduleClickHandler() {
        // Apenas mostra o popup de seleção de data
        ShowReschedulePopup(true);
    }

    function OnReschedulePopupHideHandler() {
        ShowReschedulePopup(false);
    }

    function OnConfirmRescheduleHandler(newAppointmentData) {
        // Primeiro atualiza o status do agendamento atual para "Rescheduled"
        const currentAppointment = {
            ...Data,
            status: AppointmentsState.Rescheduled
        };

        // Cria o novo agendamento com os dados do atual mas nova data
        const newAppointment = {
            ...Data,
            id: null,
            date: newAppointmentData.date,
            status: AppointmentsState.Scheduled,
            rescheduled_from: Data.id // Referência ao agendamento original
        };

        // Atualiza o agendamento atual
        UpdateAppointment(currentAppointment, (response) => {
            if (OnChange) {
                OnChange();
            }

            // Cria o novo agendamento
            SetAppointment(newAppointment, (response) => {
                if (OnChange) {
                    OnChange();
                }

                // Fecha os popups primeiro
                ShowReschedulePopup(false);
                Hide();

                // Depois de fechar os popups, envia a mensagem se necessário
                if (newAppointmentData.sendWhatsappReminder) {
                    setTimeout(() => {
                        const patient = GetPatientById(Data.patient);
                        let message = templates.AppointmentRescheduledConfirmedMessage;
                        message = message.replace("{name}", patient.name.split(' ')[0]);
                        message = message.replace("{day}", CalendarConfig.GetAAAAMMDDArray(newAppointment.date)[2]);
                        message = message.replace("{month}", CalendarConfig.Months[CalendarConfig.GetAAAAMMDDArray(newAppointment.date)[1] - 1]);
                        message = message.replace("{when}", CalendarConfig.GetDayOfWeek(newAppointment.date));
                        message = message.replace("{time}", CalendarConfig.GetTime(newAppointment.date));
                        message = message.replace("{doctor_name}", resource?.Label || "");
                        message = message.replace("{company_name}", CompanyConfig?.Name || "");
                        
                        SendWhatsAppMessage(patient.phone, MessagesTemplate.ConvertToWhatsAppText(message));
                    }, 500); // Pequeno delay para garantir que os popups fecharam
                }
            });
        });
    }

    function OnDeleteAppointmentConfirmClickHandler() {

        DeleteAppointment(Data.id, response => {
            if(OnChange) OnChange(response);
            Hide();
        });
    }

    function OnDeleteAppointmentCancelClickHandler() {
        ShowDeleteAppointmentPopup(false);
    }

    function OnConfirmClickHandler() {
        ValidateAndSave(true); // Close popup when confirming
    }

    function ValidateAndSave(shouldClose = false) {
        if (ValidateForm()) {
            SaveData();
            if (shouldClose) {
                Hide();
            }
        }
    }

    function SaveData() {
        var date = CalendarConfig.ISOToDate(Data.date);
        var parsedDate = CalendarConfig.GetAAAAMMDD(date);
        var time = CalendarConfig.GetTime(Data.date);

        let patientId = patientData.id;

        let newAppointment = Data.id ? false : true;

        let data = null;

        if (newAppointment) {
            data = CreateAppointmentData(
                null,
                AppointmentsState.Scheduled,
                parsedDate,
                time,
                patientId,
                resource,
                procedure
            );

        }
        else {
            data = CreateAppointmentData(
                Data.id,
                currentStatus, // Use currentStatus instead of getting from ref
                parsedDate,
                time,
                patientId,
                resource,
                procedure
            );
        }

        return data;
    }

    function getAppointmentCreationMessage(patient, appointmentData, previousAppointments) {
        let message = (previousAppointments == "0") ? templates.AppointmentCreatedFullMessage : templates.AppointmentCreatedShortMessage;

        let date = appointmentData.date;
        let yyyymmdd = CalendarConfig.GetAAAAMMDDArray(date);

        let monthDay = yyyymmdd[2];
        let monthName = CalendarConfig.Months[yyyymmdd[1] - 1];
        let dayOfWeek = CalendarConfig.GetDayOfWeek(date);

        message = message.replaceAll("{name}", patient.name.split(' ')[0]);
        message = message.replaceAll("{dayOfWeek}", dayOfWeek);
        message = message.replaceAll("{day}", monthDay);
        message = message.replaceAll("{month}", monthName);
        message = message.replaceAll("{time}", CalendarConfig.GetTime(date));
        message = message.replaceAll("{when}", dayOfWeek);
        message = message.replaceAll("{doctor_name}", resource?.Label || "");
        message = message.replaceAll("{company_name}", CompanyConfig?.Name || "");
        message = message.replaceAll("{address}", `${CompanyConfig?.Address || ""}, ${CompanyConfig?.Number || ""}`);
        message = message.replaceAll("{price}", appointmentData.price);

        return message;
    }

    function SendAppointmentDataToClient(patient, message) {

        SendWhatsAppMessage(patient.phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    function CreateAppointmentData(id, status, date, time, patient, resource, procedure) {
        var data = {
            id: id,
            status: status,
            date: date + " " + time,
            patient: patient,
            resource: (resource) ? resource.Id : null,
            procedure: (procedure) ? procedure.Id : null,
            duration: (procedure) ? procedure.Duration : null,
            price: (procedure) ? procedure.Price : null,
        }

        // Create or update a appointment;
        if (id == null) {
            SetAppointment(data, (response) => {
                
                if(OnChange)
                {
                    OnChange();
                }

                const patient = GetPatientById(data.patient);

                const message = getAppointmentCreationMessage(patient, data, response.previousAppointments);

                navigator.clipboard.writeText(MessagesTemplate.ConvertToClipboardText(message));

                if (sendWhatssappReminder) {
                    SendAppointmentDataToClient(patient, message);
                }
            });
        }
        else {
            UpdateAppointment(data, (response) => {
                if (OnChange) {
                    OnChange(response);
                }
                // Update local state to match server state
                if (response?.status) {
                    setCurrentStatus(response.status);
                }
            });
        }

        return data;
    }

    function Hide() {
        OnHide();
    }

    function createInvoiceHandler() {
        OnCreateInvoice(Data);
    }

    function viewInvoiceHandler() {
        OnViewInvoice(Data);
    }

    function showInvoiceHandler() {
        window.open(APPOINTMENT_GET_RECEIPT + "?id=" + Data.id, "_blank");
    }

    function OnStartServiceHandler() {
        const appointmentId = Data.id;
        navigate(`/appointment/${appointmentId}`);
    }

    function OnPatientInfoClickHandler() {
        navigate(`/patients/${patientData.id}`);
    }

    function OnSendMessageClickHandler() {
        let phone = patientData.phone;
        let message = ""; // Empty message to allow customization
        SendWhatsAppMessage(phone, MessagesTemplate.ConvertToWhatsAppText(message));
    }

    function GetEditAppointmentForm() {
        return (
            <div className="create-appointment-popup-content">
                <div className="status-wrapper">
                    <div className="status-section">
                        <AppointmentStatusSelector
                            Status={currentStatus}
                            ref={statusSelectorRef}
                            onChange={handleStatusChange}
                        />
                    </div>
                    <div className="status-actions">
                        {/* Appointment Management Group */}
                        <div className="action-group">
                            <CustomButton
                                variant="outlined"
                                style="primary"
                                label={"Reagendar"}
                                icon={<IconRescheduled />}
                                onClick={OnRescheduleClickHandler}
                                disabled={currentStatus === AppointmentsState.Done || currentStatus === AppointmentsState.Cancelled || currentStatus === AppointmentsState.Rescheduled}
                                title={currentStatus === AppointmentsState.Done ? "Não é possível reagendar um compromisso finalizado" :
                                      currentStatus === AppointmentsState.Cancelled ? "Não é possível reagendar um compromisso cancelado" :
                                      currentStatus === AppointmentsState.Rescheduled ? "Este compromisso já foi reagendado" : ""}
                            />
                        </div>
                        
                        {/* Financial Group */}
                        <div className="action-group">
                            {Data.invoiceId === "0" ? (
                                <CustomButton
                                    variant="outlined"
                                    style="primary"
                                    label={"Faturar"}
                                    icon={<IconMoney />}
                                    onClick={createInvoiceHandler}
                                />
                            ) : (
                                <CustomButton
                                    variant="outlined"
                                    style="primary"
                                    label={"Pagamento"}
                                    icon={<IconMoney />}
                                    onClick={viewInvoiceHandler}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="appointment-section">
                    <div className="appointment-section-title">Informações do Paciente</div>
                    <div className="appointment-main-info">
                        <InputField
                            Label={"Paciente"}
                            Value={patientData?.name || 'Paciente não encontrado'}
                            Disabled={true}
                        />
                        {patientData && (
                            <>
                                <CustomButton
                                    variant="outlined"
                                    size="small"
                                    style="primary"
                                    icon={<IconPatientData />}
                                    onClick={OnPatientInfoClickHandler}
                                />
                                <CustomButton
                                    variant="outlined"
                                    size="small"
                                    style="primary"
                                    icon={<IconWhatsapp />}
                                    onClick={OnSendMessageClickHandler}
                                />
                            </>
                        )}
                    </div>
                </div>

                <div className="appointment-section">
                    <div className="appointment-section-title">Detalhes da Consulta</div>
                    <div className="appointment-details">
                        <FormControl>
                            <InputLabel>Profissional</InputLabel>
                            <Select
                                defaultValue={resource.Id}
                                label="Profissional"
                                disabled={true}
                                onChange={handleResourceChange}>
                                {CompanyConfig.Resources.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel>Procedimento</InputLabel>
                            <Select
                                disabled={true}
                                defaultValue={procedure.Id}
                                label="Procedimento"
                                onChange={handleProcedureChange}>
                                {CompanyConfig.Procedures.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="appointment-price-duration">
                        <InputField Mandatory={false} Label={"Valor"} Value={procedure.Price} Disabled={true} />
                        <InputField Mandatory={false} Label={"Duração"} Value={procedure.Duration} Disabled={true} />
                    </div>
                </div>
            </div>
        );
    }

    function GetOptionsForSelect() {

        const sortedPatients = patientsList
            .filter(patient => patient.status == "1")
            .sort((a, b) => a.name.localeCompare(b.name));

        return sortedPatients.map((item) => ({
            id: item.id,
            label: item.name,
            status: item.status,
        }));
    }

    function OnAutocompleteSelectHandler(value) {
        if (value) {
            setPatientData(value);
        }
        else {
            setPatientData(null);
        }

    }

    function handleResourceChange(event) {
        let resource = GetResourceById(event.target.value);
        setResource(resource);
    }

    function handleProcedureChange(event) {
        let procedure = GetProcedureById(event.target.value);
        inputPriceRef.current.SetValue(procedure.Price);
        inputDurationRef.current.SetValue(procedure.Duration);
        setProcedure(procedure);
    }

    const [open, setOpen] = React.useState(false);
    const [loadingAPI, setLoadingAPI] = React.useState(false);

    useEffect(() => {
        LoadPatients();
    }, [open]);

    const LoadPatients = (callback) => {
        setLoadingAPI(true);
        GetPatients(false, (result) => {
            setPatientsList(result);
            setLoadingAPI(true);
            if(callback) callback(result);
        });
    }

    useEffect(() => {
        if (patientsList.length > 0) {
            setSortedPatients(GetOptionsForSelect());
        }
        else {
            setSortedPatients([]);
        }
    }, [patientsList]);


    function getPatientsCombobox() {
        return (
            <Autocomplete
                autoComplete
                options={sortedPatients}
                sx={{ flex: "1" }}
                open={open}
                value={patientData} // Define o valor do Autocomplete como `patientData`
                getOptionLabel={(option) => option.label || option.name} // Define como o texto é exibido
                isOptionEqualToValue={(option, value) => option.id === value.id} // Compara opções por ID
                onInputChange={(event, newInputValue) => {
                    if(newInputValue.length > 0)
                    {
                        setPatientNameInputValue(newInputValue); // Salva o valor digitado
                    }
                }}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event, newValue) => {
                    OnAutocompleteSelectHandler(newValue);
                }}
               
                loading={loadingAPI}
                renderOption={(props, item) => (
                    <li {...props} key={item.key}>
                        <ListItemText>{item.label}</ListItemText>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Paciente"
                        helperText={error ? 'Selecione um paciente' : ''}
                        error={error}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loadingAPI ? <CircularProgress color="inherit" size={10} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            },
                        }}
                    />
                )}
            />
        )
    }

    function GetNewAppointmentForm() {
        return (
            <div className="create-appointment-popup-content">
                <div className="appointment-section">
                    <div className="appointment-section-title">Paciente</div>
                    <div className="appointment-main-info">
                        {getPatientsCombobox()}
                        <CustomButton variant="outlined" style="secondary" label={"Novo"} icon={<IconCreatePatient />} onClick={OnCreateNewPatientClickHander} />
                    </div>
                </div>

                <div className="appointment-section">
                    <div className="appointment-section-title">Detalhes da Consulta</div>
                    <div className="appointment-details">
                        <FormControl>
                            <InputLabel>Profissional</InputLabel>
                            <Select
                                defaultValue={resource.Id}
                                label="Profissional"
                                onChange={handleResourceChange}>
                                {CompanyConfig.Resources.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel>Procedimento</InputLabel>
                            <Select
                                defaultValue={procedure.Id}
                                label="Procedimento"
                                onChange={handleProcedureChange}>
                                {CompanyConfig.Procedures.map((option) => (
                                    <MenuItem key={option.Id} value={option.Id}>{option.Label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="appointment-price-duration">
                        <InputField ref={inputPriceRef} Width={"100%"} Mandatory={false} Label={"Valor"} Value={procedure.Price} Disabled={true} />
                        <InputField ref={inputDurationRef} Width={"100%"} Mandatory={false} Label={"Duração"} Value={procedure.Duration} Disabled={true} />
                    </div>
                </div>

                <div className="whatsapp-reminder">
                    <div className="whatsapp-reminder-icon">
                        <IconWhatsapp fontSize="small" />
                    </div>
                    <div className="whatsapp-reminder-content">
                        <div className="whatsapp-reminder-label">Lembrete por WhatsApp</div>
                        <div className="whatsapp-reminder-description">Enviar mensagem automática de confirmação</div>
                    </div>
                    <Switch
                        defaultChecked={sendWhatssappReminder}
                        checked={sendWhatssappReminder}
                        onChange={(event) => setSendWhatssappReminder(event.target.checked)}
                        className="whatsapp-reminder-switch"
                    />
                </div>
            </div>
        );
    }

    function OnCreateNewPatientClickHander() {

        let patientData =
        {
            name: patientNameInputValue,
        }

        OnOpenCreatePatientPopupHandler(patientData);
    }

    function onPatientCreatedHandler(response) {

        let patientId = response.data.patientId;

        LoadPatients(
            (response) => {
                
                let patientData = response.find((item) => item.id == patientId);
                setPatientData(patientData);
            }
        )
    }

    function GetTitle() {
        let title;

        if (Data.status == AppointmentsState.Available) {
            title = "Novo compromisso";
        }
        else {
            title = "Compromisso (#" + Data.id + ")";
        }

        return title;
    }

    function GetContent() {
        let content;

        if (resource == null || procedure == null) {
            return <></>;
        }
        content = currentStatus === AppointmentsState.Available ? GetNewAppointmentForm() : GetEditAppointmentForm();

        return (
            <>
                <div className="create-appointment-popup-content">
                    <div className="appointment-header">
                        <div className="appointment-info">
                            <div className="appointment-datetime-box">
                                <div className="appointment-datetime-icon">
                                    <CalendarToday fontSize="small" />
                                </div>
                                <div className="appointment-datetime-info">
                                    <div className="appointment-datetime-label">Data</div>
                                    <div className="appointment-datetime-value">
                                        {(() => {
                                            if (!Data.date) return '';
                                            const dateArray = CalendarConfig.GetAAAAMMDDArray(Data.date);
                                            const year = parseInt(dateArray[0]);
                                            const month = parseInt(dateArray[1]);
                                            const day = parseInt(dateArray[2]);
                                            
                                            // Criar data com ano, mês (0-11) e dia
                                            const date = new Date(year, month - 1, day);
                                            const dayOfWeek = CalendarConfig.DaysOfWeek[date.getDay()];
                                            
                                            return `${dayOfWeek}, ${dateArray[2]} de ${CalendarConfig.Months[month - 1]}`;
                                        })()}
                                    </div>
                                </div>
                            </div>
                            <div className="appointment-datetime-box">
                                <div className="appointment-datetime-icon">
                                    <Schedule fontSize="small" />
                                </div>
                                <div className="appointment-datetime-info">
                                    <div className="appointment-datetime-label">Horário</div>
                                    <div className="appointment-datetime-value">
                                        {CalendarConfig.GetTime(Data.date)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Data && Data.id && Data.invoiceId === "0" && (
                            <div className="appointment-action-box error" onClick={OnDeleteClickHandler}>
                                <DeleteOutline fontSize="small" />
                            </div>
                        )}
                    </div>
                    {content}
                </div>
                <ConfirmPopup Visibility={deleteAppointmentPopupVisibility} Title={"Excluir compromisso"} Description={"Deseja realmente excluir este compromisso?"} OnConfirm={OnDeleteAppointmentConfirmClickHandler} OnHide={OnDeleteAppointmentCancelClickHandler} />
                <CreatePatientPopup Visibility={createPatientPopupVisibility} SuggestedName={patientNameInputValue} OnChange={onPatientCreatedHandler} OnHide={OnCreatePatientPopupHideHandler} />
                {reschedulePopupVisibility &&
                    <ReschedulePopupContent
                        OnConfirm={OnConfirmRescheduleHandler}
                        OnHide={OnReschedulePopupHideHandler}
                    />
                }
            </>
        )
    }

    function GetFooter() {
        return (
            <div style={{ display: "flex", flex: '1', flexDirection: "row", justifyContent: "flex-end" }}>
                {Data && Data.id ? (
                    /* Primary Action */
                    <CustomButton
                        variant="contained"
                        style="primary"
                        size="large"
                        label={currentStatus === AppointmentsState.Doing ? "Ir para atendimento" :
                               currentStatus === AppointmentsState.Done ? "Ver atendimento" : "Atender"}
                        onClick={OnStartServiceHandler}
                        disabled={currentStatus === AppointmentsState.Cancelled ||
                                currentStatus === AppointmentsState.Rescheduled}
                        title={currentStatus === AppointmentsState.Cancelled ? "Não é possível atender um compromisso cancelado" :
                              currentStatus === AppointmentsState.Rescheduled ? "Este compromisso foi reagendado" : ""}
                    />
                ) : (
                    <CustomButton
                        style="primary"
                        variant="contained"
                        size="large"
                        label={"Adicionar"}
                        onClick={OnConfirmClickHandler}
                        disabled={!patientData}
                        title={!patientData ? "Selecione um paciente para adicionar o compromisso" : ""}
                    />
                )}
            </div>
        );
    }

    function GetRender() {

        return <PopupBase Title={GetTitle()} Content={GetContent()} Footer={GetFooter()} OnClose={OnCloseClickHandler} />
    }

    return (GetRender());
}

export default AppointmentPopupContent;