import React, { useState, useEffect } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import styles from './EditMessageTemplate.module.css';
import PopupBase from '../base/PopupBase';
import CustomButton from '../../components/buttons/CustomButton';

const PREVIEW_VARIABLES = {
    name: "João Silva",
    day: "15",
    month: "março",
    when: "quinta-feira",
    time: "14:30",
    greetings: "Bom dia",
    doctor_name: "Dra. Maria",
    company_name: "Clínica Exemplo",
    address: "Rua das Flores, 123",
    price: "200,00",
    company_policies: "Política de cancelamento: 24h de antecedência"
};

const EditMessageTemplate = ({ template, onUpdate, onHide }) => {
    const { UpdateMessageTemplate } = DataProviderInstance();
    const [content, setContent] = useState(template.TemplateContent);
    const [preview, setPreview] = useState('');
    const [error, setError] = useState('');
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        updatePreview(content);
    }, [content]);

    const updatePreview = (text) => {
        let previewText = text;
        Object.entries(PREVIEW_VARIABLES).forEach(([key, value]) => {
            previewText = previewText.replace(new RegExp(`{${key}}`, 'g'), value);
        });
        setPreview(previewText);
    };

    const validateTemplate = (text) => {
        const requiredVariables = [
           // 'name', 'day', 'month', 'when', 'time', 'doctor_name',
            //'company_name', 'address', 'price_pix', 'price_card'
        ];

        const missingVariables = requiredVariables.filter(variable => {
            const isRequired = template.TemplateKey.includes('Appointment') || 
                             variable === 'name' || 
                             variable === 'company_name';
            return isRequired && !text.includes(`{${variable}}`);
        });

        if (missingVariables.length > 0) {
            setError(`Template deve incluir as seguintes variáveis: ${missingVariables.join(', ')}`);
            return false;
        }

        return true;
    };

    const handleSave = async () => {
        if (!validateTemplate(content)) {
            return;
        }

        setSaving(true);
        setError('');

        try {
            await UpdateMessageTemplate(
                template.Id,
                content,
                (response) => {
                    if (response.success) {
                        onUpdate();
                    } else {
                        setError('Falha ao salvar template');
                        setSaving(false);
                    }
                }
            );
        } catch (err) {
            setError('Erro ao salvar template: ' + err.message);
            setSaving(false);
        }
    };

    const getContent = () => (
        <div className={styles.editorContainer}>
            <div className={styles.editorGrid}>
                <div className={styles.editorSection}>
                    <h3>Editor</h3>
                    <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className={styles.editor}
                    />
                </div>
                <div className={styles.previewSection}>
                    <h3>Preview</h3>
                    <div className={styles.preview}>
                        {preview.split('<br />').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            {error && (
                <div className={styles.error}>
                    {error}
                </div>
            )}
            <div className={styles.variablesHelp}>
                <h3>Variáveis Disponíveis</h3>
                <div className={styles.variablesGrid}>
                    {Object.entries(PREVIEW_VARIABLES).map(([key, value]) => (
                        <div key={key} className={styles.variableItem}>
                            <code>{`{${key}}`}</code>
                            <span>Exemplo: {value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    const getPositiveButton = () => (
        <CustomButton
            variant="contained"
            style="primary"
            label={saving ? "Salvando..." : "Salvar Alterações"}
            onClick={handleSave}
            disabled={saving}
        />
    );

    return (
        <PopupBase
            Title={`Editar Template: ${template.TemplateKey}`}
            Content={getContent()}
            Footer={getPositiveButton()}
            OnClose={onHide}
        />
    );
};

export default EditMessageTemplate;