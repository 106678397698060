import React, { useState, useEffect } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import {
    Box,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Person2 as Person2Icon } from '@mui/icons-material';
import ColorPalette from '../../colorpalette/ColorPalette';
import PopupBase from '../../popup/base/PopupBase';
import CustomButton from '../../components/buttons/CustomButton';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import AreaTitle from '../../components/areaTitle/AreaTitle';

const ResourceList = () => {
    const { GetCompanyResources, SetCompanyResource, UpdateCompanyResource, DeleteCompanyResource } = DataProviderInstance();
    const [resources, setResources] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [selectedResource, setSelectedResource] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [resourceToDelete, setResourceToDelete] = useState(null);
    const [formData, setFormData] = useState({
        label: '',
        regulator: '',
        signature: ''
    });

    useEffect(() => {
        loadResources();
    }, []);

    const loadResources = () => {
        GetCompanyResources((result) => {
            setResources(result);
        });
    };

    const handleOpenModal = (mode, resource = null) => {
        setModalMode(mode);
        setSelectedResource(resource);
        setFormData(resource ? {
            label: resource.Label,
            regulator: resource.Regulator,
            signature: resource.Signature
        } : {
            label: '',
            regulator: '',
            signature: ''
        });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedResource(null);
        setFormData({
            label: '',
            regulator: '',
            signature: ''
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        if (modalMode === 'create') {
            SetCompanyResource(formData, () => {
                loadResources();
                handleCloseModal();
            });
        } else {
            UpdateCompanyResource({
                ...formData,
                id: selectedResource.Id
            }, () => {
                loadResources();
                handleCloseModal();
            });
        }
    };

    const handleOpenDeleteModal = (resource) => {
        setResourceToDelete(resource);
        setShowConfirmDelete(true);
    };

    const handleCloseDeleteModal = () => {
        setShowConfirmDelete(false);
        setResourceToDelete(null);
    };

    const handleDelete = () => {
        if (resourceToDelete) {
            DeleteCompanyResource(resourceToDelete.Id, () => {
                loadResources();
                handleCloseDeleteModal();
            });
        }
    };

    const modalContent = (
        <Stack spacing={2}>
            <TextField
                label="Nome do Recurso"
                name="label"
                value={formData.label}
                onChange={handleInputChange}
                fullWidth
                required
            />
            <TextField
                label="Regulador"
                name="regulator"
                value={formData.regulator}
                onChange={handleInputChange}
                fullWidth
            />
            <TextField
                label="Assinatura"
                name="signature"
                value={formData.signature}
                onChange={handleInputChange}
                fullWidth
            />
        </Stack>
    );

    return (
        <div className="page-content">
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 3,
                px: 2
            }}>
                <AreaTitle
                    Title="Recursos"
                    Description="Gerencie os recursos disponíveis em sua clínica"
                    Icon={<Person2Icon />}
                />
                <CustomButton
                    style="primary"
                    variant="contained"
                    icon={<AddIcon />}
                    label="Adicionar Recurso"
                    onClick={() => handleOpenModal('create')}
                />
            </Box>

            <Box >
                <div className="dashboard-item-container" style={{ width: '100%' }}>
                    <Stack spacing={2}>
                        {resources.map(resource => (
                            <Box
                                key={resource.Id}
                                sx={{
                                    p: 2,
                                    borderRadius: 1,
                                    bgcolor: '#F8F9FA',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '1px solid #E9ECEF'
                                }}
                            >
                                <Box>
                                    <Typography 
                                        variant="body1" 
                                        sx={{ 
                                            mb: 0.5,
                                            fontWeight: 500,
                                            color: '#212529'
                                        }}
                                    >
                                        {resource.Label}
                                    </Typography>
                                    {(resource.Regulator || resource.Signature) && (
                                        <Typography 
                                            variant="body2" 
                                            sx={{ 
                                                color: '#6C757D',
                                                fontSize: '0.875rem'
                                            }}
                                        >
                                            {resource.Regulator && `Regulador: ${resource.Regulator}`}
                                            {resource.Regulator && resource.Signature && ' | '}
                                            {resource.Signature && `Assinatura: ${resource.Signature}`}
                                        </Typography>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenModal('edit', resource)}
                                        sx={{ 
                                            color: '#495057',
                                            '&:hover': {
                                                bgcolor: '#E9ECEF'
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenDeleteModal(resource)}
                                        sx={{ 
                                            color: '#DC3545',
                                            '&:hover': {
                                                bgcolor: '#FFE9E9'
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                </div>
            </Box>

            {isModalOpen && (
                <PopupBase
                    Title={modalMode === 'create' ? 'Novo Recurso' : 'Editar Recurso'}
                    Content={modalContent}
                    PositiveButton={
                        <CustomButton
                            style="primary"
                            variant="contained"
                            label={modalMode === 'create' ? 'Adicionar' : 'Salvar'}
                            onClick={handleSubmit}
                        />
                    }
                    NegativeButton={
                        <CustomButton
                            style="secondary"
                            variant="text"
                            label="Cancelar"
                            onClick={handleCloseModal}
                        />
                    }
                    OnClose={handleCloseModal}
                />
            )}

            <ConfirmPopup
                Visibility={showConfirmDelete}
                Title="Desativar Recurso"
                Description={`Tem certeza que deseja desativar o recurso "${resourceToDelete?.Label}"?`}
                ConfirmLabel="Desativar"
                OnConfirm={handleDelete}
                OnHide={handleCloseDeleteModal}
            />
        </div>
    );
};

export default ResourceList;