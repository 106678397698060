import React, { useState } from 'react';
import './Sidebar.css';
import SidebarSection from './section/SidebarSection';
import AppConfig from '../consts/AppConfig';
import Logo from '../assets/images/logo-full.png';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  // Função para obter os itens de uma seção
  const getSectionItems = (routes) => {
    return routes.map(routeId => AppConfig.GetRouteById(routeId)).filter(Boolean);
  };

  return (
    <div 
      id="sidebar" 
      className={isOpen ? "sidebar-opened sidebar-expanded" : "sidebar-opened"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={Logo} className="logo" alt="Logo" width={30} height={30} />
      <div className="sidebar-groups">
        {AppConfig.MenuRoutes.map((section, index) => (
          <SidebarSection
            key={section.label || index}
            title={section.label}
            icon={section.icon}
            items={getSectionItems(section.routes)}
            isOpen={isOpen}
          />
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
