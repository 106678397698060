import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import './css/Form.css';
import './colorpalette/ColorPalette.css';
import './globals.css';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import AppConfig from './consts/AppConfig';
import { DataProvider } from './api/DataProvider';
import Login from './pages/login/Login';
import Menu from './menu/Menu';
import Loading from './components/loading/Loading';
import NPSForm from './pages/nps/NPSForm';
import VisualLogger from './components/visual-logger/VisualLogger';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < AppConfig.MinScreenSizeX);
  const [mobileMenuVisibility, setMobileMenuVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);

  const handleResize = () => {
    let isMobile = window.innerWidth < AppConfig.MinScreenSizeX;
    setIsMobile(isMobile);
    if (!isMobile && mobileMenuVisibility) {
      setMobileMenuVisibility(false);
    }
  };

  useEffect(() => {
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (userData !== null) {
      setCurrentCompanyId(userData.companyId);
    }
  }, [userData]);

  function checkAuthentication() {
    return userData !== null;
  }

  function openMenuHandler() {
    setMobileMenuVisibility(true);
  }

  function menuHideHandler() {
    setMobileMenuVisibility(false);
  }

  function dataProviderCompleteHandler() {
    setIsLoading(false);
  }

  function loginCompleteHandler(loginData) {
    localStorage.setItem('token', loginData.token);
    setUserData(loginData.user);
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <VisualLogger />
        <Routes>
          {/* Rotas públicas (sem autenticação) */}
          <Route path="/avaliacao/:token" element={<NPSForm />} />
          <Route path="/avaliacao" element={<NPSForm />} />

          {/* Rotas que requerem autenticação */}
          <Route
            path="*"
            element={
              !checkAuthentication() ? (
                <Login onSuccess={loginCompleteHandler} />
              ) : (
                <DataProvider userData={userData} companyId={currentCompanyId} OnComplete={dataProviderCompleteHandler}>
                  <div className='app-principal'>
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <>
                        {!isMobile && <Sidebar />}
                        <div className="app-content">
                          <Header isMobile={isMobile} OpenMenuCallback={openMenuHandler} />
                          <Routes>
                            <Route path="/" element={<Navigate to={"/dashboard"} />} />
                            {AppConfig.Routes.filter(route => route.id !== 'nps-form').map((route) => (
                              <Route
                                key={route.path}
                                path={route.path}
                                element={route.element}
                              />
                            ))}
                          </Routes>
                        </div>
                        {mobileMenuVisibility && <Menu OnHide={menuHideHandler} />}
                      </>
                    )}
                  </div>
                </DataProvider>
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
