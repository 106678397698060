import React from 'react';
import AppointmentToConfirmItem from './AppointmentToConfirmItem';
import { DataProviderInstance } from '../../../../api/DataProvider';
import styles from './AppointmentsByDay.module.css';
import CalendarConfig from '../../../../consts/CalendarConfig';
import { EventBusy } from '@mui/icons-material';

const AppointmentsByDay = ({ label, appointments }) => {
    const {
        GetProcedureById,
        GetResourceById,
        GetPatientById
    } = DataProviderInstance();

    function renderAppointment(item, index) {
        const patientData = GetPatientById(item.patient);
        const time = CalendarConfig.GetTime(item.date);
        const resource = GetResourceById(item.resource);
        const procedure = GetProcedureById(item.procedure);

        return (
            <AppointmentToConfirmItem
                key={index}
                Id={item.id}
                patientId={item.patient}
                State={item.status}
                Name={patientData.name}
                Date={item.date}
                Time={time}
                Phone={patientData.phone}
                Procedure={procedure.Label}
                Resource={resource.Label}
            />
        );
    }

    return (
        <div className={styles.appointmentsByDayContainer}>
            <div className={styles.dayHeader}>
                <h4 className={styles.dayTitle}>{label}</h4>
            </div>
            <div className={styles.appointmentsList}>
                {appointments && appointments.length > 0 ? (
                    appointments.map((item, index) => renderAppointment(item, index))
                ) : (
                    <div className={styles.emptyState}>
                        <EventBusy style={{ marginRight: '8px' }} />
                        Nenhum agendamento para este dia
                    </div>
                )}
            </div>
        </div>
    );
};

export default AppointmentsByDay;
