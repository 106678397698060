import { createTheme } from '@mui/material/styles';
import ColorPalette from '../colorpalette/ColorPalette';

const theme = createTheme({
  palette: {
    primary: {
      main: ColorPalette.main,
      light: ColorPalette.mainLight1,
      dark: ColorPalette.mainDark,
    },
    secondary: {
      main: ColorPalette.secondary,
      light: ColorPalette.secondaryLight,
    },
    error: {
      main: ColorPalette.red,
      light: ColorPalette.redLight1,
    },
    text: {
      primary: ColorPalette.textDark,
      secondary: ColorPalette.textLight,
    },
    warning: {
      main: ColorPalette.orange,
    },
    tertiary: {
      main: '#4F4F4F',
    },
  },
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: ColorPalette.main,
            color: ColorPalette.white,
            '&:hover': {
              backgroundColor: ColorPalette.mainDark,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          height: '38px',
          fontFamily: 'Roboto',
          whiteSpace: 'nowrap',
        },
        text: {
          color: ColorPalette.textDark,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: '#e1e1e1',
          },
        },
        outlined: {
          color: ColorPalette.textDark,
          borderColor: '#e1e1e1',
          backgroundColor: 'transparent',
          '&:hover': {
            borderColor: ColorPalette.main,
            backgroundColor: 'transparent',
          },
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: '#e0e0e0',
            color: '#9e9e9e'
          },
        },
      },
    },
  },
});

export default theme;