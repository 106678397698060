import React, { useState, useEffect } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomButton from '../buttons/CustomButton';
import styles from './ExportHistory.module.css';
import { DataProviderInstance } from '../../api/DataProvider';

const POLL_INTERVAL = 2000; // 2 seconds

const ExportHistory = ({ companyId, onHistoryUpdate }) => {
    const { GetExportHistory, GetExportStatus, CancelExport } = DataProviderInstance();
    const [history, setHistory] = useState([]);
    const [activePolls, setActivePolls] = useState(new Set());

    useEffect(() => {
        loadHistory();
        const interval = setInterval(loadHistory, 5000); // Refresh list every 5 seconds
        return () => {
            clearInterval(interval);
            // Clear all polling intervals
            activePolls.forEach(clearInterval);
        };
    }, [companyId]);

    // Update parent component when history changes
    useEffect(() => {
        onHistoryUpdate?.(history);
    }, [history, onHistoryUpdate]);

    const loadHistory = () => {
        GetExportHistory((data) => {
            if (data.error) {
                console.error('Error loading export history:', data.error);
                return;
            }
            setHistory(data.history || []);
            
            // Start polling for any pending/processing exports
            data.history?.forEach(item => {
                if (['pending', 'processing'].includes(item.status)) {
                    startPolling(item.id);
                }
            });
        });
    };

    const startPolling = (exportId) => {
        if (activePolls.has(exportId)) return;

        const interval = setInterval(() => {
            GetExportStatus(exportId, (data) => {
                if (data.error) {
                    clearInterval(interval);
                    setActivePolls(prev => {
                        const next = new Set(prev);
                        next.delete(exportId);
                        return next;
                    });
                    return;
                }

                setHistory(prev => prev.map(item => 
                    item.id === exportId ? { ...item, ...data } : item
                ));

                if (['complete', 'error'].includes(data.status)) {
                    clearInterval(interval);
                    setActivePolls(prev => {
                        const next = new Set(prev);
                        next.delete(exportId);
                        return next;
                    });
                    loadHistory(); // Refresh to get final state
                }
            });
        }, POLL_INTERVAL);

        setActivePolls(prev => new Set(prev).add(exportId));
    };

    const getStatusText = (status) => {
        switch (status) {
            case 'pending':
                return 'Aguardando';
            case 'processing':
                return 'Processando';
            case 'complete':
                return 'Concluído';
            case 'error':
                return 'Erro';
            case 'expired':
                return 'Expirado';
            default:
                return status;
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };
const handleDownload = (downloadUrl) => {
    window.location.href = downloadUrl;
};

const handleCancel = (exportId) => {
    CancelExport(exportId, (result) => {
        if (!result.error) {
            // Update history after cancellation
            loadHistory();
        }
    });
};


    const renderProgress = (item) => {
        if (['pending', 'processing'].includes(item.status)) {
            return (
                <div className={styles.progressCell}>
                    <CircularProgress
                        variant="determinate"
                        value={item.progress || 0}
                        size={24}
                    />
                    <Typography variant="body2" className={styles.progressText}>
                        {item.progress}%
                    </Typography>
                    {item.current_task && (
                        <Typography variant="caption" color="textSecondary" className={styles.taskText}>
                            {item.current_task}
                        </Typography>
                    )}
                </div>
            );
        }
        return `${item.progress}%`;
    };

    return (
        <div className={styles.container}>
            <Typography variant="h6" className={styles.title}>
                Histórico de Exportações
            </Typography>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Progresso</TableCell>
                            <TableCell>Concluído em</TableCell>
                            <TableCell>Expira em</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{formatDate(item.created_at)}</TableCell>
                                <TableCell>{getStatusText(item.status)}</TableCell>
                                <TableCell>{renderProgress(item)}</TableCell>
                                <TableCell>
                                    {item.completed_at ? formatDate(item.completed_at) : '-'}
                                </TableCell>
                                <TableCell>
                                    {item.expires_at ? formatDate(item.expires_at) : '-'}
                                </TableCell>
                                <TableCell>
                                    {item.status === 'complete' && item.download_url && (
                                        <>
                                            {new Date(item.expires_at) > new Date() ? (
                                                <CustomButton
                                                    variant="contained"
                                                    style="primary"
                                                    label="Download"
                                                    startIcon={<CloudDownloadIcon />}
                                                    onClick={() => handleDownload(item.download_url)}
                                                    size="small"
                                                />
                                            ) : (
                                                <Typography variant="caption" color="error">
                                                    Download expirado
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    {['pending', 'processing'].includes(item.status) && (
                                        <CustomButton
                                            variant="contained"
                                            style="secondary"
                                            label="Cancelar"
                                            startIcon={<CancelIcon />}
                                            onClick={() => handleCancel(item.id)}
                                            size="small"
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {history.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    Nenhuma exportação encontrada
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ExportHistory;