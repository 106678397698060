import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Grid, Box, Typography, Select, MenuItem, InputLabel, Switch, FormControl } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BlockIcon from '@mui/icons-material/Block';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import { DataProviderInstance } from '../../api/DataProvider';
import { GetCompanyAutomationSettingsAPI, UpdateCompanyAutomationSettingAPI } from '../../api/API';
import styles from './Preferences.module.css';
import CustomButton from '../../components/buttons/CustomButton';
import DaySelector from '../../components/day-selector/DaySelector';
import classNames from 'classnames';

const Preferences = () => {
    const { CompanyConfig, UpdateCompanyConfig } = DataProviderInstance();
    const [automationSettings, setAutomationSettings] = useState({});
    const [loading, setLoading] = useState(true);

    const initialState = {
        availableDaysOfWeek: CompanyConfig.AvailableDaysOfWeek,
        availableTime: CompanyConfig.AvailableTime,
        blockedTimes: CompanyConfig.BlockedTimes,
        targetOccupation: CompanyConfig.TargetOccupation,
        timeInterval: CompanyConfig.TimeInterval,
        goalAppointments: CompanyConfig.GoalAppointments,
        goalBilling: CompanyConfig.GoalBilling
    };

    // Load automation settings
    useEffect(() => {
        GetCompanyAutomationSettingsAPI(CompanyConfig.Id, (response) => {
            if (response.success && response.settings) {
                // Convert array to object with automation_type as key
                const settingsObj = response.settings.reduce((acc, setting) => ({
                    ...acc,
                    [setting.automation_type]: setting.is_enabled
                }), {});
                console.log('Loaded automation settings:', settingsObj);
                setAutomationSettings(settingsObj);
            }
            setLoading(false);
        });
    }, [CompanyConfig.Id]);

    // Handle automation toggle
    const handleAutomationToggle = (type) => {
        const newValue = !automationSettings[type];
        console.log('Toggling automation:', type, 'to:', newValue);
        
        // Optimistic update
        setAutomationSettings(prev => ({
            ...prev,
            [type]: newValue
        }));

        UpdateCompanyAutomationSettingAPI(CompanyConfig.Id, type, newValue, (response) => {
            if (!response.success) {
                // Revert on failure
                console.log('Failed to update automation setting, reverting');
                setAutomationSettings(prev => ({
                    ...prev,
                    [type]: !newValue
                }));
            }
        });
    };

    const [values, setValues] = useState(initialState);
    const [isEditable, setIsEditable] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);

    const handleChange = (field, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const generateTimeSlots = (startTime, endTime, interval) => {
        const slots = [];
        for (let time = startTime; time < endTime; time += interval) {
            if (interval < 1) {
                const hour = Math.floor(time);
                const minutes = (time % 1) * 60;
                slots.push({ hour: time, display: `${hour}:${minutes.toString().padStart(2, '0')}` });
            } else {
                slots.push({ hour: time, display: `${Math.floor(time)}:00` });
            }
        }
        return slots;
    };

    useEffect(() => {
        const [start, end] = values.availableTime;
        if (start < end) {
            setTimeSlots(generateTimeSlots(start, end, values.timeInterval));
        }
    }, [values.availableTime, values.timeInterval]);

    const handleBlockedTimesChange = (timeSlot) => {
        const hour = timeSlot.hour;
        if (values.blockedTimes.includes(hour)) {
            handleChange('blockedTimes', values.blockedTimes.filter(time => time !== hour));
        } else {
            handleChange('blockedTimes', [...values.blockedTimes, hour]);
        }
    };

    const handleEdit = () => {
        setIsEditable(true);
    };

    const handleSave = () => {
        if (values.availableTime[0] >= values.availableTime[1]) {
            alert('Horário de início não pode ser maior ou igual ao horário de término.');
            return;
        }

        UpdateCompanyConfig(values, (result) => {
            console.log('Resultado:', result);
        });

        setIsEditable(false);
    };

    const handleReset = () => {
        setValues(initialState);
        setIsEditable(false);
    };

    const SectionHeader = ({ icon: Icon, title }) => (
        <div className={styles.sectionHeader}>
            <Icon className={styles.sectionIcon} />
            <Typography className={styles.sectionTitle}>{title}</Typography>
        </div>
    );

    const ActionButtons = () => (
        <div className={styles.actionButtons}>
            {isEditable ? (
                <>
                    <CustomButton
                        variant="contained"
                        label="Salvar"
                        style="primary"
                        onClick={handleSave}
                        startIcon={<SaveIcon />}
                    />
                    <CustomButton
                        variant="outlined"
                        label="Cancelar"
                        style="primary"
                        onClick={handleReset}
                        startIcon={<CloseIcon />}
                    />
                </>
            ) : (
                <CustomButton
                    variant="contained"
                    label="Editar"
                    style="primary"
                    onClick={handleEdit}
                    startIcon={<EditIcon />}
                />
            )}
        </div>
    );

    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <div className='page-content'>
            <div className={styles.mainContainer}>
                <div className={styles.titleContainer}>
                    <AreaTitle Title="Configurações Gerais" />
                    <ActionButtons />
                </div>

                {/* Horário de Funcionamento Section */}
                <div className={styles.section}>
                    <SectionHeader icon={AccessTimeIcon} title="Horário de Funcionamento" />
                    <Box mb={2}>
                        <Typography variant="subtitle1" gutterBottom>
                            Dias de Funcionamento
                        </Typography>
                        <DaySelector
                            value={values.availableDaysOfWeek}
                            onChange={(newValue) => handleChange('availableDaysOfWeek', newValue)}
                            disabled={!isEditable}
                        />
                    </Box>
                    <div className={styles.timeSettingsRow}>
                        <TextField
                            className={styles.timeField}
                            label="Horário de Início"
                            type="number"
                            value={values.availableTime[0]}
                            onChange={(e) => handleChange('availableTime', [Number(e.target.value), values.availableTime[1]])}
                            disabled={!isEditable}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            className={styles.timeField}
                            label="Horário de Término"
                            type="number"
                            value={values.availableTime[1]}
                            onChange={(e) => handleChange('availableTime', [values.availableTime[0], Number(e.target.value)])}
                            disabled={!isEditable}
                            InputLabelProps={{ shrink: true }}
                        />
                        <FormControl className={styles.timeField}>
                            <InputLabel>Intervalo entre Consultas</InputLabel>
                            <Select
                                value={values.timeInterval}
                                onChange={(e) => handleChange('timeInterval', Number(e.target.value))}
                                disabled={!isEditable}
                            >
                                <MenuItem value={0.25}>15 minutos</MenuItem>
                                <MenuItem value={0.5}>30 minutos</MenuItem>
                                <MenuItem value={1}>1 hora</MenuItem>
                                <MenuItem value={2}>2 horas</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {/* Horários Bloqueados Section */}
                <div className={styles.section}>
                    <SectionHeader icon={BlockIcon} title="Horários Bloqueados" />
                    <div className={styles.blockedTimesGrid}>
                        {timeSlots.map((timeSlot) => (
                            <div
                                key={timeSlot.hour}
                                className={classNames(styles.timeChip, {
                                    [styles.blocked]: values.blockedTimes.includes(timeSlot.hour)
                                })}
                                onClick={() => isEditable && handleBlockedTimesChange(timeSlot)}
                                style={{ cursor: isEditable ? 'pointer' : 'default' }}
                            >
                                {timeSlot.display}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Metas e Limites Section */}
                <div className={styles.section}>
                    <SectionHeader icon={AssessmentIcon} title="Metas e Limites" />
                    <div className={styles.metricsGrid}>
                        <TextField
                            label="Máximo de Agendamentos por Dia"
                            type="number"
                            value={values.goalAppointments}
                            onChange={(e) => handleChange('goalAppointments', e.target.value)}
                            disabled={!isEditable}
                            fullWidth
                            className={styles.metricField}
                        />
                        <TextField
                            label="Meta de agendamentos por mês"
                            type="number"
                            value={values.targetOccupation}
                            onChange={(e) => handleChange('targetOccupation', e.target.value)}
                            disabled={!isEditable}
                            fullWidth
                            className={styles.metricField}
                        />
                        <TextField
                            label="Meta de Faturamento Mensal"
                            type="number"
                            value={values.goalBilling}
                            onChange={(e) => handleChange('goalBilling', e.target.value)}
                            disabled={!isEditable}
                            fullWidth
                            className={styles.metricField}
                        />
                    </div>
                </div>

                {/* Automação Section */}
                <div className={styles.section}>
                    <SectionHeader icon={SmartToyIcon} title="Automação" />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                Envio de mensagem por whatsapp:
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(automationSettings.appointment_reminder)}
                                                onChange={() => handleAutomationToggle('appointment_reminder')}
                                                disabled={!isEditable}
                                            />
                                        }
                                        label="Lembretes de Consulta"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(automationSettings.appointment_confirmation)}
                                                onChange={() => handleAutomationToggle('appointment_confirmation')}
                                                disabled={!isEditable}
                                            />
                                        }
                                        label="Confirmação de Consulta"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(automationSettings.birthday_message)}
                                                onChange={() => handleAutomationToggle('birthday_message')}
                                                disabled={!isEditable}
                                            />
                                        }
                                        label="Mensagem de Aniversário"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(automationSettings.first_appointment_followup)}
                                                onChange={() => handleAutomationToggle('first_appointment_followup')}
                                                disabled={!isEditable}
                                            />
                                        }
                                        label="Follow-up Primeira Consulta"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={Boolean(automationSettings.nps_survey)}
                                                onChange={() => handleAutomationToggle('nps_survey')}
                                                disabled={!isEditable}
                                            />
                                        }
                                        label="Pesquisa NPS"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default Preferences;