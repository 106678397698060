import React from 'react';
import styles from '../styles.module.css';
import AreaTitle from '../../../components/areaTitle/AreaTitle';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { PieChart, BarChart, BarPlot, ChartsXAxis, ChartsYAxis, ChartsTooltip, ChartsAxisHighlight, ChartsReferenceLine } from '@mui/x-charts';
import ColorPalette from '../../../colorpalette/ColorPalette';
import IconAppointments from "@mui/icons-material/Schedule";
import IconFirstTime from "@mui/icons-material/NumbersOutlined";
import IconProcedures from "@mui/icons-material/Assignment";
import IconResources from "@mui/icons-material/Work";
import IconAlert from "@mui/icons-material/Warning";
import CalendarConfig from '../../../consts/CalendarConfig';

function AppointmentsStatistics({ dashboardData, CompanyConfig }) {

  function GetAppointmentsHistoryGraph() {
      if (!dashboardData || !dashboardData.AppointmentsHistory || dashboardData.AppointmentsHistory.length === 0) {
        return (
          <div className={styles.boxRoundedContainer}>
            <AreaTitle
              Title={"Atendimentos"}
              Description={"Realizados durante o período selecionado."}
              Icon={<IconAppointments />}
            />
            <div>Dados de atendimentos indisponíveis.</div>
          </div>
        );
      }
  
      let source = dashboardData.AppointmentsHistory;
  
      let completedValues = [];
      let scheduledValues = [];
      let missedValues = [];
      let canceledValues = [];
      let confirmedValues = []; // Combined confirmed and confirmed_by_patient
      let labels = [];
  
      // Totals for summary display
      let totalMissed = 0;
      let totalCanceled = 0;
      let totalCompleted = 0;
      let totalScheduled = 0;
      let totalConfirmed = 0;
  
      source.forEach(item => {
        if (item?.months?.length > 0) {
          item.months.forEach(monthItem => {
            const completed = Number(monthItem.completed || 0);
            const missed = Number(monthItem.missed || 0);
            const scheduled = Number(monthItem.scheduled || 0);
            const canceled = Number(monthItem.canceled || 0) + Number(monthItem.canceled_by_patient || 0);
            const confirmed = Number(monthItem.confirmed || 0) + Number(monthItem.confirmed_by_patient || 0);
  
            completedValues.push(completed);
            missedValues.push(missed);
            scheduledValues.push(scheduled);
            canceledValues.push(canceled);
            confirmedValues.push(confirmed);
  
            // Add to totals
            totalCompleted += completed;
            totalMissed += missed;
            totalScheduled += scheduled;
            totalCanceled += canceled;
            totalConfirmed += confirmed;
  
            const monthLabel = CalendarConfig.Months[Number(monthItem.month) - 1].substring(0, 3);
            labels.push(`${monthLabel}-${item.year}`);
          });
        }
      });
  
      if (labels.length === 0) {
        completedValues = [0];
        scheduledValues = [0];
        missedValues = [0];
        canceledValues = [0];
        confirmedValues = [0];
        labels = ["-----"];
      }
  
      const series = [
        {
          data: completedValues,
          label: 'Realizados',
          type: 'bar',
          color: ColorPalette.greenLight,
          stack: 'total',
        },
        {
          data: confirmedValues,
          label: 'Confirmados',
          type: 'bar',
          color: ColorPalette.main,
          stack: 'total',
        },
        {
          data: scheduledValues,
          label: 'Agendados',
          type: 'bar',
          color: ColorPalette.mainLight1,
          stack: 'total',
        },
        {
          data: canceledValues,
          label: 'Cancelados',
          type: 'bar',
          color: ColorPalette.orange,
        },
        {
          data: missedValues,
          label: 'Faltas',
          type: 'bar',
          color: ColorPalette.red,
        },
      ];
  
      // Style for the summary boxes
      const summaryBoxStyle = {
        padding: '10px 15px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100px'
      };
  
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Atendimentos"}
            Description={"Realizados durante o período selecionado."}
            Icon={<IconAppointments />}
          />
          
          {/* Summary boxes for totals */}
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px', flexWrap: 'wrap', gap: '10px' }}>
            <div style={{ ...summaryBoxStyle, backgroundColor: ColorPalette.greenLight }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>{totalCompleted}</div>
              <div style={{ fontSize: '14px', color: '#333' }}>Realizados</div>
            </div>
            
            <div style={{ ...summaryBoxStyle, backgroundColor: ColorPalette.main }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>{totalConfirmed}</div>
              <div style={{ fontSize: '14px', color: 'white' }}>Confirmados</div>
            </div>
            
            <div style={{ ...summaryBoxStyle, backgroundColor: ColorPalette.mainLight1 }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>{totalScheduled}</div>
              <div style={{ fontSize: '14px', color: '#333' }}>Agendados</div>
            </div>
            
            <div style={{ ...summaryBoxStyle, backgroundColor: ColorPalette.orange }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>{totalCanceled}</div>
              <div style={{ fontSize: '14px', color: 'white' }}>Cancelados</div>
            </div>
            
            <div style={{ ...summaryBoxStyle, backgroundColor: ColorPalette.red }}>
              <div style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>{totalMissed}</div>
              <div style={{ fontSize: '14px', color: 'white' }}>Faltas</div>
            </div>
          </div>
          
          <BarChart
            xAxis={[{ scaleType: 'band', data: labels }]}
            series={series}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'top', horizontal: 'middle' },
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                markGap: 5,
                itemGap: 10,
                labelStyle: {
                  fontSize: 12,
                },
              },
            }}
          >
            <BarPlot />
            <ChartsReferenceLine y={Number(CompanyConfig.TargetOccupation)} label="Meta" lineStyle={{ stroke: ColorPalette.grayDark, strokeDasharray: "4 4" }} />
            <ChartsXAxis />
            <ChartsYAxis />
            <ChartsTooltip />
            <ChartsAxisHighlight x="line" y="line" />
          </BarChart>
        </div>
      );
    }

  function GetAppointmentsByPatientTypeChart() {
    if (!dashboardData || !dashboardData.AppointmentsByPatientType || dashboardData.AppointmentsByPatientType.length === 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Agendamentos: Novos x Retornantes"}
            Description={"Quantidade de agendamentos de pacientes novos e retornantes por período."}
            Icon={<IconFirstTime />}
          />
          <div>Dados de agendamentos indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AppointmentsByPatientType;
    const data = source.map(item => ({
      id: item.patientType,
      value: item.count,
      label: item.patientType,
    }));

    const palette = [ColorPalette.greenLight, ColorPalette.main];

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={<><IconAlert color="error" /> Agendamentos: Novos vs Retornante </>}
          Description={"Quantidade de agendamentos de pacientes novos e retornantes por período."}
          Icon={<IconAppointments />}
        />
        <PieChart
          colors={palette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetProceduresChart() {
    if (!dashboardData || !dashboardData.AppointmentsByProcedure || dashboardData.AppointmentsByProcedure.length === 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Agendamentos por Procedimento"}
            Description={"Distribuição dos agendamentos por tipo de procedimento."}
            Icon={<IconProcedures />}
          />
          <div>Dados de procedimentos indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AppointmentsByProcedure;
    let data = source.map(item => ({
      id: item.procedure,
      value: item.count,
      label: item.procedure,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Agendamentos por Procedimento"}
          Description={"Distribuição dos agendamentos por tipo de procedimento."}
          Icon={<IconProcedures />}
        />
        <PieChart
          colors={[ColorPalette.main, ColorPalette.secondary, ColorPalette.greenLight, ColorPalette.mainLight1, ColorPalette.secondaryLight, ColorPalette.mainDark, ColorPalette.mainLight1]}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetResourcesChart() {
    if (!dashboardData || !dashboardData.AppointmentsByResource || dashboardData.AppointmentsByResource.length === 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Agendamentos por Profissional"}
            Description={"Distribuição dos agendamentos por tipo de profissional."}
            Icon={<IconResources />}
          />
          <div>Dados de recursos indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.AppointmentsByResource;
    let data = source.map(item => ({
      id: item.resource,
      value: item.count,
      label: item.resource,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Agendamentos por Profissional"}
          Description={"Distribuição dos agendamentos por tipo de profissional."}
          Icon={<IconResources />}
        />
        <PieChart
          colors={[ColorPalette.main, ColorPalette.secondary, ColorPalette.greenLight, ColorPalette.mainLight1, ColorPalette.secondaryLight, ColorPalette.mainDark, ColorPalette.mainLight1]}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetTopScheduledTimesTable() {
    if (!dashboardData || !dashboardData.TopScheduledTimes || dashboardData.TopScheduledTimes.length === 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Horários Mais Agendados"}
            Description={"Os horários com maior número de agendamentos."}
            Icon={<IconAppointments />}
          />
          <div>Dados de horários indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.TopScheduledTimes;
    if (!Array.isArray(source)) source = [];
    source.sort((a, b) => (b.count || 0) - (a.count || 0));

    // Convert time strings to hour numbers for comparison
    const timeToHour = (time) => parseInt(time?.split(':')[0]) || 0;
    
    // Find first and last hours with appointments
    const hours = source.map(item => timeToHour(item.time));
    const firstHour = Math.min(...hours);
    const lastHour = Math.max(...hours);

    // Create array with hours between first and last appointment
    const workingHours = Array.from(
      { length: lastHour - firstHour + 1 },
      (_, i) => `${String(firstHour + i).padStart(2, '0')}:00`
    );

    // Create a map of existing data
    const timeCountMap = new Map(
      source.map(item => [
        item.time || '00:00',
        item.count !== undefined ? item.count : 0
      ])
    );

    // Map working hours to their counts, using 0 for missing hours
    const data = workingHours.map(hour => timeCountMap.get(hour) || 0);
    const labels = workingHours;

    // Get the top 3 values to highlight them in red (excluding zeros)
    const top3Values = [...new Set(data.filter(v => v > 0))]
      .sort((a, b) => b - a)
      .slice(0, 3);

    const maxValue = Math.max(...data);
    
    const series = [{
      data: data,
      valueFormatter: (value) => `${value} agendamentos`
    }];

    // Get indices of top 3 values
    const sortedIndices = data
      .map((value, index) => ({ value, index }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 3)
      .map(item => item.index);

    // Create array of colors for each bar
    const colors = data.map((_, index) =>
      sortedIndices.includes(index) ? ColorPalette.main : ColorPalette.red
    );

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Horários Mais Agendados"}
          Description={"Os horários com maior número de agendamentos."}
          Icon={<IconAppointments />}
        />
        <BarChart
          height={300}
          series={series}
          xAxis={[{
            scaleType: 'band',
            data: labels,
            tickLabelStyle: {
              angle: 45,
              textAnchor: 'start',
              fontSize: 12
            }
          }]}
          yAxis={[{
            hidden: true
          }]}
          colors={colors}
          slotProps={{
            legend: {
              hidden: true
            }
          }}
        >
          <BarPlot />
          <ChartsXAxis />
          <ChartsTooltip />
          <ChartsAxisHighlight x="line" y="line" />
        </BarChart>
      </div>
    );
  }

  return (
    <div className={styles.gridContainer}>
      {GetAppointmentsHistoryGraph()}
      {GetAppointmentsByPatientTypeChart()}
      {GetProceduresChart()}
      {GetResourcesChart()}
      {GetTopScheduledTimesTable()}
    </div>
  );
}

export default AppointmentsStatistics;