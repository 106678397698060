import React from 'react';
import styles from './CalendarGrid.module.css';
import TimeColumn from '../TimeColumn/TimeColumn';
import DayColumn from '../DayColumn/DayColumn';

const CalendarGrid = ({
  startHour = 8,
  endHour = 20,
  timeInterval = 15,
  days = [],
  appointments = [],
  onAppointmentSelect
}) => {
  // Debug appointments data
  console.log('Calendar Grid - Appointments:', appointments);
  console.log('Calendar Grid - Days:', days);

  // Filter out days that have no appointments to show
  const visibleDays = days.filter(day => {
    const dayStr = day.toISOString().split('T')[0];
    return appointments.some(apt => {
      const aptDate = new Date(apt.date);
      return aptDate.getDate() === day.getDate() &&
             aptDate.getMonth() === day.getMonth() &&
             aptDate.getFullYear() === day.getFullYear();
    }) || true; // Show empty days too
  });

  // Group appointments by day
  const getAppointmentsForDay = (day) => {
    return appointments.filter(apt => {
      const aptDate = new Date(apt.date);
      return aptDate.getDate() === day.getDate() &&
             aptDate.getMonth() === day.getMonth() &&
             aptDate.getFullYear() === day.getFullYear();
    });
  };

  return (
    <div className={styles.calendarGrid}>
      <div className={styles.scrollContainer}>
        <div className={styles.timeColumnWrapper}>
          <TimeColumn
            startHour={startHour}
            endHour={endHour}
            interval={timeInterval}
          />
        </div>
        <div className={styles.daysContainer}>
          {visibleDays.map((day) => {
            const dayAppointments = getAppointmentsForDay(day);
            console.log(`Appointments for ${day.toISOString()}:`, dayAppointments);
            
            return (
              <DayColumn
                key={day.toISOString()}
                date={day}
                appointments={dayAppointments}
                startHour={startHour}
                endHour={endHour}
                onAppointmentSelect={onAppointmentSelect}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarGrid;