import React, { useEffect } from "react";
import AppConfig from "../consts/AppConfig";
import styles from "./Menu.module.css";
import MenuSection from "./MenuSection";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Menu = ({ OnHide }) => {
    useEffect(() => {
        // Travar o scroll da página
        const body = document.querySelector('body');
        const html = document.documentElement;

        // Travar o scroll
        body.style.overflow = 'hidden';
        html.style.overflow = 'hidden';

        return () => {
            // Liberar o scroll da página
            body.style.overflow = 'auto';
            html.style.overflow = 'auto';
        };
    }, []);

    function OnCloseClickHandler() {
        OnHide();
    }

    // Função para obter os itens de uma seção
    const getSectionItems = (routes) => {
        return routes.map(routeId => AppConfig.GetRouteById(routeId)).filter(Boolean);
    };

    return (
        <div className={styles.container}>
            <div className={styles.close}>
                <FontAwesomeIcon icon={faClose} onClick={OnCloseClickHandler} />
            </div>
            <div className={styles.list}>
                {AppConfig.MenuRoutes.map((section, index) => (
                    <MenuSection
                        key={index}
                        title={section.label}
                        items={getSectionItems(section.routes)}
                        OnHide={OnHide}
                    />
                ))}
            </div>
        </div>
    );
}

export default Menu;