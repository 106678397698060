import React from 'react';
import { Box } from '@mui/material';
import { PaymentMethodList } from '../../components/payment-methods/PaymentMethodList';

const PaymentMethodsPage = () => {
    return (
        <Box sx={{ py: 3 }}>
            <PaymentMethodList />
        </Box>
    );
};

export default PaymentMethodsPage;