import styles from './Leads.module.css';
import { DataProviderInstance } from '../../api/DataProvider';
import { GetFriendlyPhone } from '../../utils/Utils';
import React, { useEffect, useState, useRef } from 'react';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import IconEdit from '@mui/icons-material/Edit';
import { CheckOutlined, CloseRounded, ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import ColorPalette from '../../colorpalette/ColorPalette';
import LeadsConfig from '../../consts/LeadsConfig';

const LeadCardItem = ({ leadData, onEdit, onUpdate, onWhatssappMessageClick }) => {

    const {
        CompanyConfig,
        GetLeads,
        UpdateLead
    } = DataProviderInstance()

    const getNextStatus = (currentStatus) => {
        const statusOrder = [
            LeadsConfig.StatusNew,
            LeadsConfig.StatusInProgress,
            LeadsConfig.StatusWaitingResponse,
            LeadsConfig.StatusFinished
        ];
        
        const currentIndex = statusOrder.indexOf(currentStatus);
        if (currentIndex < statusOrder.length - 1) {
            return statusOrder[currentIndex + 1];
        }
        return currentStatus;
    }

    const getPreviousStatus = (currentStatus) => {
        const statusOrder = [
            LeadsConfig.StatusNew,
            LeadsConfig.StatusInProgress,
            LeadsConfig.StatusWaitingResponse,
            LeadsConfig.StatusFinished
        ];
        
        const currentIndex = statusOrder.indexOf(currentStatus);
        if (currentIndex > 0) {
            return statusOrder[currentIndex - 1];
        }
        return currentStatus;
    }

    const handleStatusChange = (newStatus) => {
        const updatedLead = {
            ...leadData,
            status: newStatus
        };

        console.log('updatedLead', newStatus);
        
        UpdateLead(updatedLead, (response) => {
            console.log('onUpdate', newStatus);
            onUpdate();
        });
    }

    const {
        createdAt,
        valid,
        name,
        phone,
        channel,
        result,
        campaignId,
        observations,
        status,
      } = leadData;

    // Função para calcular a diferença em horas
    const calculateHoursSinceCreation = (createdAt) => {
        const createdDate = new Date(createdAt);
        const now = new Date();
        const diffTime = now - createdDate;
        return Math.floor(diffTime / (1000 * 60 * 60)); // Converter milissegundos para horas
    };

    // Função para determinar a classe de temperatura
    const getTemperatureClass = (hoursSinceCreation) => {
        if (hoursSinceCreation <= 24) return styles.hotLead; // Quente
        if (hoursSinceCreation <= 72) return styles.warmLead; // Morno
        return styles.coldLead; // Frio
    };

    const getResultClass = (result) => {
        if (result == LeadsConfig.ResultSuccess) return styles.successLead; // Agendado
        if (result == LeadsConfig.ResultFailure) return styles.failureLead; // Nao agendado
        if (result == LeadsConfig.ResultFailureInactive) return styles.failureInactiveLead; // Nao agendado, por inatividade

        return styles.lead; // Frio
    };
    

    const hoursSinceCreation = calculateHoursSinceCreation(createdAt);

    let date = new Date(createdAt);
    date = date.toLocaleDateString() + " " + date.toLocaleTimeString();

    const campaign = CompanyConfig.LeadsCampaigns.find(
        (x) => x.id == campaignId + ""
    );
    const sourceLabel = CompanyConfig.LeadsSources.find(
        (x) => x.id == campaign.sourceId + ""
    )?.name;

    const resultIcon =
        result === LeadsConfig.ResultSuccess ? (
            <span className={styles.successIcon}>
                <CheckOutlined htmlColor={ColorPalette.green} />
            </span>
        ) : result === LeadsConfig.ResultFailure || result === LeadsConfig.ResultFailureInactive ? (
            <span className={styles.failureIcon}>
                <CloseRounded htmlColor={ColorPalette.red} />
            </span>
        ) : null;

    // Determinar a classe de temperatura
    const temperatureClass = getTemperatureClass(hoursSinceCreation);
    const resultClass = getResultClass(result);

    const showCreateEditLeadPopup = (leadData) => {
        onEdit(leadData);
    }

    const onWhatssappMessageClickHandler = (leadData) => {
        onWhatssappMessageClick(leadData);
    }

    const handleButtonClick = (e) => {
        // Previne que o clique nos botões propague para o card
        e.stopPropagation();
    }

    return (
        <div
            key={leadData.id}
            className={`${styles.leadCard} ${status === LeadsConfig.StatusWaitingResponse ? temperatureClass : status === LeadsConfig.StatusFinished ? resultClass : ""}`}
            onClick={() => showCreateEditLeadPopup(leadData)}
            style={{ cursor: 'pointer' }}
        >
            <div className={styles.leadMainInfo}>
                <div className={styles.leadInfoColumn}>
                    <div className={styles.phoneNumber}>{GetFriendlyPhone(phone)}</div>
                    <div className={styles.leadName}>{name}</div>
                    <div className={styles.leadDate}>{date}</div>
                </div>
                <IconWhatsapp
                    className={styles.whatsappButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        onWhatssappMessageClickHandler(leadData);
                    }}
                />
            </div>
            <div className={styles.leadFooter}>
                <div className={styles.leadTags}>
                    <span className={styles.leadTag}>{channel}</span>
                    <span className={styles.leadTag}>{sourceLabel}</span>
                    <span className={styles.leadTag}>{campaign.name}</span>
                </div>
                <div className={styles.statusButtons}>
                    {status !== LeadsConfig.StatusNew && (
                        <div
                            className={styles.statusButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleStatusChange(getPreviousStatus(status));
                            }}
                        >
                            <ArrowBackIos style={{ fontSize: 16 }} />
                        </div>
                    )}
                    {(status !== LeadsConfig.StatusWaitingResponse && status !== LeadsConfig.StatusFinished) && (
                        <div
                            className={styles.statusButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleStatusChange(getNextStatus(status));
                            }}
                        >
                            <ArrowForwardIos style={{ fontSize: 16 }} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};


export default LeadCardItem;