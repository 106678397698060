import React, { useState, useEffect } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import styles from './Templates.module.css';
import Loading from '../../components/loading/Loading.js';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material/';
import ColorPalette from '../../colorpalette/ColorPalette.js';
import EditMessageTemplate from '../../popup/EditMessageTemplate/EditMessageTemplate';

const MessagesTemplates = () => {
    const { GetMessageTemplates } = DataProviderInstance();
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);

    useEffect(() => {
        loadMessageTemplates(() => setLoading(false));
    }, []);

    const loadMessageTemplates = (callback) => {
        GetMessageTemplates((response) => {
            setTemplates(response);
            if(callback) callback();
        });
    };

    if (loading) {
        return <Loading />;
    }

    const handleEditTemplate = (template) => {
        setSelectedTemplate(template);
        setIsEditPopupVisible(true);
    };

    const handleClose = () => {
        setIsEditPopupVisible(false);
        setSelectedTemplate(null);
    };

    const handleUpdate = () => {
        setIsEditPopupVisible(false);
        loadMessageTemplates();
    };

    const getTemplateDescription = (key) => {
        switch(key) {
            case 'AppointmentCreatedFullMessage':
                return 'Mensagem completa enviada quando uma consulta é agendada';
            case 'AppointmentCreatedShortMessage':
                return 'Versão resumida da mensagem de agendamento';
            case 'AppointmentAskForConfirmation1':
                return 'Primeira mensagem de confirmação de consulta';
            case 'AppointmentAskForConfirmation2':
                return 'Segunda mensagem de confirmação de consulta';
            case 'AppointmentAskForConfirmation3':
                return 'Terceira mensagem de confirmação de consulta';
            case 'Appointment7DaysReminder':
                return 'Lembrete enviado 7 dias antes da consulta';
            case 'AppointmentCancellationDueLackOfReturn':
                return 'Mensagem de cancelamento por falta de confirmação';
            case 'SuggestDate':
                return 'Sugestão de data para agendamento';
            case 'HappyBirthday':
                return 'Mensagem de feliz aniversário';
            case 'SuggestDateFromWaitingList':
                return 'Sugestão de data para pacientes na lista de espera';
            case 'AppointmentRescheduledConfirmedMessage':
                return 'Confirmação de reagendamento de consulta';
            default:
                return 'Template de mensagem';
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            {isEditPopupVisible && (
                <EditMessageTemplate 
                    template={selectedTemplate}
                    onUpdate={handleUpdate}
                    onHide={handleClose}
                />
            )}
            <div>
                <AreaTitle Title={`Templates de Mensagens (${templates.length})`} />
                <List>
                    {templates.map((template) => (
                        <ListItem 
                            key={template.Id}
                            style={{ 
                                backgroundColor: 'white',
                                borderRadius: '8px',
                                marginBottom: '10px'
                            }}
                        >
                            <ListItemText
                                primary={template.TemplateKey}
                                secondary={
                                    <>
                                        <span style={{ display: 'block', marginBottom: '8px', color: '#666' }}>
                                            {getTemplateDescription(template.TemplateKey)}
                                        </span>
                                        <span style={{ display: 'block', color: '#888' }}>
                                            {template.TemplateContent.substring(0, 100)}...
                                        </span>
                                    </>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton 
                                    edge="end" 
                                    aria-label="edit"
                                    onClick={() => handleEditTemplate(template)}
                                >
                                    <Edit htmlColor={ColorPalette.black}/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    );
};

export default MessagesTemplates;