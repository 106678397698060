import React, { useState } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import styles from './Company.module.css';
import RegistrationData from './areas/registrationData/RegistrationData';

function Company() {
  const [activeArea, setActiveArea] = useState('registration');

  const renderArea = () => {
    switch (activeArea) {
      case 'registration':
        return <RegistrationData />;
      default:
        return <RegistrationData />;
    }
  };

  return (
    <div className='page-content'>
      <div className={styles.sidebar}>
        <button
          className={`${styles.areaButton} ${activeArea === 'registration' ? styles.active : ''}`}
          onClick={() => setActiveArea('registration')}
        >
          Dados de Registro
        </button>
      </div>
      <div className={styles.mainContainer}>
        {renderArea()}
      </div>
    </div>
  );
}

export default Company;
