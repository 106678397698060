import React from 'react';
import styles from '../styles.module.css';
import { DataProviderInstance } from '../../../api/DataProvider';
import AreaTitle from '../../../components/areaTitle/AreaTitle';
import { FormControlLabel, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { PieChart, BarChart, BarPlot, ChartsXAxis, ChartsYAxis, ChartsTooltip } from '@mui/x-charts';
import ColorPalette from '../../../colorpalette/ColorPalette';
import IconPatients from "@mui/icons-material/PersonAddAlt1Outlined";
import IconPatientsGender from "@mui/icons-material/PeopleAltOutlined";
import IconAge from "@mui/icons-material/Cake";
import IconReason from "@mui/icons-material/HelpOutline";
import IconAlert from "@mui/icons-material/Warning";
import CalendarConfig from '../../../consts/CalendarConfig';
import PatientConfig from '../../../consts/PatientConfig';

function PatientsStatistics({ dashboardData, showUnknownOrigin, setShowUnknownOrigin }) {
    const { CompanyConfig, GetAppointments } = DataProviderInstance();
  
  const colorPalette = [ColorPalette.main, ColorPalette.secondary, ColorPalette.greenLight, ColorPalette.mainLight1, ColorPalette.secondaryLight, ColorPalette.mainDark, ColorPalette.mainLight1];

  function GetPatientsCreated() {
    if (
      !dashboardData ||
      !dashboardData.PatientsHistory ||
      !dashboardData.PatientsHistory.created ||
      dashboardData.PatientsHistory.created.length === 0
    ) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Novos pacientes"}
            Description={"Categorizados por origem de cadastro."}
            Icon={<IconPatients />}
          />
          <div>Dados de criação de pacientes indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.PatientsHistory.created;
    let labelsSet = new Set();
    let series = [];
    let totalPatients = 0;

    // Definir uma paleta de cores
    let customColorPalette = ['#D3D3D3', ...colorPalette,]; // Adiciona uma cor cinza para origens desconhecidas

    // Mapeia as origens conhecidas para séries, garantindo ids únicos
    if (source.length > 0 && CompanyConfig.PatientOrigins.length > 0) {

      totalPatients = source.reduce((acc, item) => {
        return acc + item.origins.reduce((acc, origin) => {
          return acc + parseInt(origin.count, 10);
        }
          , 0);
      }
        , 0);

      series = CompanyConfig.PatientOrigins
        .filter(origin => origin.Id !== undefined && origin.Id !== null)
        .map((origin, index) => {
          const originLabel = origin.Label || 'Desconhecido';
          return {
            data: [],
            id: origin.Id.toString(),
            label: originLabel,
          };
        });
    }

    // Variável para acumular pacientes com origem desconhecida
    let unknownCount = 0;

    source.forEach(item => {
      if (!item.year || !item.month || !item.origins) return; // Pula itens inválidos
      let labelDate = `${CalendarConfig.Months[item.month - 1].substring(0, 3)}-${item.year}`;
      labelsSet.add(labelDate);

      item.origins.forEach((origin) => {
        if (!origin || typeof origin.id === 'undefined' || typeof origin.count === 'undefined') return; // Pula origens inválidas

        if (!showUnknownOrigin) {
          if (origin.id == "-1" || origin.id == "0") return;
        }

        let originId = origin.id === "-1" ? "unknown" : origin.id.toString();

        let serie = series.find(s => s.id == originId);
        if (serie) {
          serie.data.push(parseInt(origin.count, 10));
        } else {
          // Se a origem não for encontrada, acumula na variável unknownCount
          unknownCount += parseInt(origin.count, 10);
        }
      });

      // Garantir que cada série tenha um valor para o mês atual
      series.forEach(serie => {
        if (serie.data.length < labelsSet.size) {
          serie.data.push(0);
        }
      });
    });

    // Adicionar a série 'unknown-patientsCreated' se houver pacientes desconhecidos
    if (unknownCount > 0) {
      let unknownSeries = series.find(s => s.id === 'unknown');
      if (unknownSeries) {
        unknownSeries.data.push(unknownCount);
      } else {
        series.push({
          data: [unknownCount],
          id: 'unknown',
          label: 'Desconhecido',
        });
      }
      labelsSet.add('Unknown'); // Adiciona um label para a série desconhecida
    }

    let labels = Array.from(labelsSet);

    // Preencher dados faltantes com zeros
    series.forEach(serie => {
      while (serie.data.length < labels.length) {
        serie.data.push(0);
      }
    });

    // Definir as cores para cada série
    const seriesColors = series.map((serie, index) => {
      if (serie.label == 'unknown' || serie.label == 'Desconhecido') {
        return '#D3D3D3'; // Cor cinza clara para origens desconhecidas
      }
      return customColorPalette[index % customColorPalette.length];
    });

    // Garante que cada série tenha dados válidos
    series = series.filter(serie =>
      serie &&
      Array.isArray(serie.data) &&
      serie.data.length > 0 &&
      serie.data.every(value => typeof value === 'number')
    );

    // Prepara os dados no formato que o BarChart espera
    const chartData = series.map(serie => ({
      data: serie.data,
      label: serie.label,
      type: 'bar',
      color: serie.label === 'Desconhecido' ? '#D3D3D3' : colorPalette[series.indexOf(serie) % colorPalette.length],
    }));

    // Verifica se temos dados válidos para o gráfico
    if (chartData.length === 0 || labels.length === 0 || chartData.some(serie => !Array.isArray(serie.data))) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Novos pacientes"}
            Description={"Categorizados por origem de cadastro."}
            Icon={<IconPatients />}
          />
          <div>Não há dados válidos para exibir.</div>
        </div>
      );
    }

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Novos pacientes"}
          Description={"Categorizados por origem de cadastro."}
          Icon={<IconPatients />}
        />
        <BarChart
          xAxis={[{ scaleType: 'band', data: labels }]}
          series={chartData}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        >
          <BarPlot />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
        </BarChart>
        <FormControlLabel
          control={
            <Switch
              checked={showUnknownOrigin}
              onChange={() => setShowUnknownOrigin(!showUnknownOrigin)}
            />
          }
          label="Exibir origens desconhecidas"
        />
      </div>
    );
  }

  function GetPatientsRetationRate() {
    if (!dashboardData || typeof dashboardData.PatientsRetentionRate === 'undefined') {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Taxa de Retenção de Pacientes"}
            Description={"Percentual de pacientes que retornaram ao consultório."}
            Icon={<IconPatients />}
          />
          <div>Dados de taxa de retenção de pacientes indisponíveis.</div>
        </div>
      );
    }

    let retentionRate = dashboardData.PatientsRetentionRate;
    const data = [
      { id: 'Retidos', value: retentionRate, label: 'Retidos' },
      { id: 'Não Retidos', value: 100 - retentionRate, label: 'Não Retidos' },
    ];
    const palette = [ColorPalette.main, ColorPalette.secondary];

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={<><IconAlert color="error" /> Taxa de Retenção de Pacientes</>}
          Description={"Percentual de pacientes que retornaram ao consultório."}
          Icon={<IconPatients />}
        />
        <PieChart
          colors={palette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetPatientsGenderGraph() {
    if (!dashboardData || !dashboardData.PatientsHistory || !dashboardData.PatientsHistory.genders || dashboardData.PatientsHistory.genders.length === 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Pacientes"}
            Description={"Categorizados por gênero"}
            Icon={<IconPatientsGender />}
          />
          <div>Dados de gênero dos pacientes indisponíveis.</div>
        </div>
      );
    }

    const palette = [ColorPalette.main, ColorPalette.secondary];
    let source = dashboardData.PatientsHistory.genders;
    let data = [];

    source.forEach((item) => {
      if (!item.gender || typeof item.count === 'undefined') return;
      let genderLabel = PatientConfig.GetGenderById(item.gender)?.Label || 'Desconhecido';
      data.push({
        id: item.gender.toString(),
        value: item.count,
        label: genderLabel,
      });
    });

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Pacientes"}
          Description={"Categorizados por gênero"}
          Icon={<IconPatientsGender />}
        />
        <PieChart
          colors={palette}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetAgeDistributionChart() {
    if (!dashboardData || !dashboardData.AgeDistribution || !Array.isArray(dashboardData.AgeDistribution)) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Faixa de Idade dos Pacientes"}
            Description={"Distribuição dos pacientes por faixa de idade."}
            Icon={<IconAge />}
          />
          <div>Dados de faixa de idade indisponíveis.</div>
        </div>
      );
    }

    const validData = (dashboardData.AgeDistribution || [])
      .filter(item =>
        item &&
        typeof item.ageGroup === 'string' &&
        (typeof item.count === 'number' || typeof item.count === 'string')
      )
      .map(item => ({
        ageGroup: item.ageGroup,
        count: Number(item.count || 0)
      }));

    if (validData.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Faixa de Idade dos Pacientes"}
            Description={"Distribuição dos pacientes por faixa de idade."}
            Icon={<IconAge />}
          />
          <div>Não há dados válidos de faixa de idade disponíveis.</div>
        </div>
      );
    }

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Faixa de Idade dos Pacientes"}
          Description={"Distribuição dos pacientes por faixa de idade."}
          Icon={<IconAge />}
        />
        <BarChart
          xAxis={[{ scaleType: 'band', data: validData.map(item => item.ageGroup) }]}
          series={[
            {
              data: validData.map(item => item.count),
              label: 'Quantidade',
              type: 'bar',
              color: ColorPalette.main,
            },
          ]}
          slotProps={{
            legend: {
              display: false,
            },
          }}
        >
          <BarPlot />
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
        </BarChart>
      </div>
    );
  }

  function GetPatientComplaintsTable() {
    if (!dashboardData || !dashboardData.PatientComplaints || dashboardData.PatientComplaints.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Motivos de Atendimentos"}
            Description={"Principais queixas dos pacientes."}
            Icon={<IconReason />}
          />
          <div>Dados de queixas de pacientes indisponíveis.</div>
        </div>
      );
    }

    const source = [...dashboardData.PatientComplaints].sort((a, b) => (b.count || 0) - (a.count || 0));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Motivos de Atendimentos"}
          Description={"Principais queixas dos pacientes."}
          Icon={<IconReason />}
        />
        <TableContainer component={Paper}>
          <Table aria-label="patient complaints">
            <TableHead>
              <TableRow>
                <TableCell><strong>Queixa</strong></TableCell>
                <TableCell align="right"><strong>Quantidade</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {source.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.complaint || '-----'}
                  </TableCell>
                  <TableCell align="right">{row.count !== undefined ? row.count : 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  function GetPatientLocationsTable() {
    if (!dashboardData || !dashboardData.PatientLocations || dashboardData.PatientLocations.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Pacientes por Origem Geográfica"}
            Description={"Distribuição de onde os pacientes estão vindo, auxiliando em campanhas de marketing localizadas."}
            Icon={<IconPatients />}
          />
          <div>Dados de localização indisponíveis.</div>
        </div>
      );
    }

    const sortedSource = [...dashboardData.PatientLocations].sort((a, b) => b.count - a.count);

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Pacientes por Origem Geográfica"}
          Description={"Distribuição de onde os pacientes estão vindo, auxiliando em campanhas de marketing localizadas."}
          Icon={<IconPatients />}
        />
        <TableContainer component={Paper}>
          <Table aria-label="patient locations">
            <TableHead>
              <TableRow>
                <TableCell><strong>Bairro</strong></TableCell>
                <TableCell align="right"><strong>Quantidade de Pacientes</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedSource.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.district || '-----'}
                  </TableCell>
                  <TableCell align="right">{row.count !== undefined ? row.count : 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <div className={styles.gridContainer}>
      {GetPatientsCreated()}
      {GetPatientsRetationRate()}
      {GetPatientsGenderGraph()}
      {GetAgeDistributionChart()}
      {GetPatientComplaintsTable()}
      {GetPatientLocationsTable()}
    </div>
  );
}

export default PatientsStatistics;