import React from 'react';
import styles from './PatientInfo.module.css';
import ViewMoreIcon from "@mui/icons-material/ContactPageOutlined";
import PatientConfig from '../../../../consts/PatientConfig';
import CustomButton from '../../../../components/buttons/CustomButton';
import AvatarBabyMale from '../../../../assets/images/avatars/baby_m.png';
import AvatarBabyFemale from '../../../../assets/images/avatars/baby_f.png';
import AvatarAdultMale from '../../../../assets/images/avatars/adult_m.png';
import AvatarAdultFemale from '../../../../assets/images/avatars/adult_f.png';
import CalendarConfig from '../../../../consts/CalendarConfig';
import {GetCurrentAgeWithLabel} from '../../../../utils/Utils';
import CpfWarning from '../../../../components/cpf-warning/CpfWarning';

const PatientInfo = ({ data }) => {
  const genderLabel = PatientConfig.GetGenderById(data.gender).Label;
  const birthday = new Date(data.birthday);
  const today = new Date();

  const getAge = () => {
    return CalendarConfig.GetAge(data.birthday);
  }

  const getAvatar = () => {
    const years = today.getFullYear() - birthday.getFullYear();

    if (years < 1)
      return data.gender == "0" ? AvatarBabyMale : AvatarBabyFemale;
    else
      return data.gender == "0" ? AvatarAdultMale : AvatarAdultFemale;
  }

  function OnPatientInfoClickHandler() {
    window.open(`/patients/${data.id}`);
  }

  return (
    <div className={styles.container}>
      <h3>Informações do paciente</h3>
      <div className={styles.containerAllInfo}>
        <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
          <div className={styles.imageContainer}>
            <img src={getAvatar()} width={"100%"} height={"100%"} />
          </div>
          <div className={styles.containerInfo}>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <h4 className={styles.name}>{data.name}</h4>
              {(!data.cpf || data.cpf.trim() === '') && <CpfWarning />}
            </div>
            <p className={styles.birthday}>{data.birthday ? CalendarConfig.GetFullDateName(data.birthday) + " (" + getAge() + ")" : "Idade desconhecida"}</p>
            <p className={styles.gender}>{genderLabel}</p>
          </div>
        </div>
        <CustomButton label={""} style="primary" variant="outlined" onClick={OnPatientInfoClickHandler} icon={<ViewMoreIcon />} />
      </div>
    </div>
  );
};

export default PatientInfo;
