import React, { useState, useEffect } from 'react';
import { DataProviderInstance } from '../../api/DataProvider';
import {
    Box,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, DesignServices as DesignServicesIcon } from '@mui/icons-material';
import ColorPalette from '../../colorpalette/ColorPalette';
import PopupBase from '../../popup/base/PopupBase';
import CustomButton from '../../components/buttons/CustomButton';
import InputMask from 'react-input-mask';
import ConfirmPopup from '../../popup/ConfirmPopup/ConfirmPopup';
import AreaTitle from '../../components/areaTitle/AreaTitle';

const ProcedureList = () => {
    const { GetCompanyProcedures, SetCompanyProcedure, UpdateCompanyProcedure, DeleteCompanyProcedure } = DataProviderInstance();
    const [procedures, setProcedures] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [selectedProcedure, setSelectedProcedure] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [procedureToDelete, setProcedureToDelete] = useState(null);
    const [formData, setFormData] = useState({
        label: '',
        price: '',
        duration: '00:00:00'
    });

    useEffect(() => {
        loadProcedures();
    }, []);

    const loadProcedures = () => {
        GetCompanyProcedures((result) => {
            setProcedures(result);
        });
    };

    const handleOpenModal = (mode, procedure = null) => {
        setModalMode(mode);
        setSelectedProcedure(procedure);
        setFormData(procedure ? {
            label: procedure.Label,
            price: procedure.Price.toString(),
            duration: procedure.Duration
        } : {
            label: '',
            price: '',
            duration: '00:00:00'
        });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedProcedure(null);
        setFormData({
            label: '',
            price: '',
            duration: '00:00:00'
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'duration') {
            // Remove qualquer caractere que não seja número ou :
            let cleanValue = value.replace(/[^\d:]/g, '');
            
            // Garante que tenha os : nas posições corretas
            if (cleanValue.length >= 2 && !cleanValue.includes(':')) {
                cleanValue = cleanValue.substring(0, 2) + ':' + cleanValue.substring(2);
            }
            if (cleanValue.length >= 5 && cleanValue.split(':').length < 3) {
                cleanValue = cleanValue.substring(0, 5) + ':00';
            }
            
            // Limita os valores de horas e minutos
            const parts = cleanValue.split(':');
            if (parts[0] && parseInt(parts[0]) > 23) parts[0] = '23';
            if (parts[1] && parseInt(parts[1]) > 59) parts[1] = '59';
            
            cleanValue = parts.join(':');
            
            setFormData(prev => ({
                ...prev,
                [name]: cleanValue
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = () => {
        // Garante que a duração esteja no formato correto (HH:mm:00)
        let formattedDuration = formData.duration;
        if (formattedDuration.match(/^\d{1}:/)) {
            formattedDuration = '0' + formattedDuration;
        }
        if (formattedDuration.match(/^\d{2}:\d{1}:/)) {
            formattedDuration = formattedDuration.replace(/^(\d{2}):(\d{1}):/, '$1:0$2:');
        }

        const data = {
            label: formData.label,
            price: parseFloat(formData.price),
            duration: formattedDuration
        };

        if (modalMode === 'create') {
            SetCompanyProcedure(data, () => {
                loadProcedures();
                handleCloseModal();
            });
        } else {
            UpdateCompanyProcedure({
                ...data,
                id: selectedProcedure.Id
            }, () => {
                loadProcedures();
                handleCloseModal();
            });
        }
    };

    const handleOpenDeleteModal = (procedure) => {
        setProcedureToDelete(procedure);
        setShowConfirmDelete(true);
    };

    const handleCloseDeleteModal = () => {
        setShowConfirmDelete(false);
        setProcedureToDelete(null);
    };

    const handleDelete = () => {
        if (procedureToDelete) {
            DeleteCompanyProcedure(procedureToDelete.Id, () => {
                loadProcedures();
                handleCloseDeleteModal();
            });
        }
    };

    const formatPrice = (price) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(price);
    };

    const formatDuration = (duration) => {
        const [hours, minutes] = duration.split(':');
        const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
        if (totalMinutes < 60) {
            return `${totalMinutes} min`;
        }
        const h = Math.floor(totalMinutes / 60);
        const m = totalMinutes % 60;
        return m > 0 
            ? `${h}h ${m}min`
            : `${h}h`;
    };

    const modalContent = (
        <Stack spacing={2}>
            <TextField
                label="Nome do Procedimento"
                name="label"
                value={formData.label}
                onChange={handleInputChange}
                fullWidth
                required
            />
            <TextField
                label="Preço"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                fullWidth
                required
                type="number"
                inputProps={{ min: 0, step: "0.01" }}
            />
            <InputMask
                mask="99:99:00"
                value={formData.duration}
                onChange={handleInputChange}
                maskChar="0"
            >
                {() => (
                    <TextField
                        label="Duração"
                        name="duration"
                        fullWidth
                        required
                        helperText="Formato: hh:mm:00"
                    />
                )}
            </InputMask>
        </Stack>
    );

    return (
        <div className="page-content">
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 3,
                px: 2
            }}>
                <AreaTitle
                    Title="Procedimentos"
                    Description="Gerencie os procedimentos disponíveis em sua clínica"
                    Icon={<DesignServicesIcon />}
                />
                <CustomButton
                    style="primary"
                    variant="contained"
                    icon={<AddIcon />}
                    label="Adicionar Procedimento"
                    onClick={() => handleOpenModal('create')}
                />
            </Box>

            <Box sx={{ px: 2, width: '100%' }}>
                <div className="dashboard-item-container" style={{ width: '100%' }}>
                    <Stack spacing={2}>
                        {procedures.map(procedure => (
                            <Box
                                key={procedure.Id}
                                sx={{
                                    p: 2,
                                    borderRadius: 1,
                                    bgcolor: '#F8F9FA',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '1px solid #E9ECEF'
                                }}
                            >
                                <Box>
                                    <Typography 
                                        variant="body1" 
                                        sx={{ 
                                            mb: 0.5,
                                            fontWeight: 500,
                                            color: '#212529'
                                        }}
                                    >
                                        {procedure.Label}
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        sx={{ 
                                            color: '#6C757D',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        {formatPrice(procedure.Price)} | {formatDuration(procedure.Duration)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenModal('edit', procedure)}
                                        sx={{ 
                                            color: '#495057',
                                            '&:hover': {
                                                bgcolor: '#E9ECEF'
                                            }
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenDeleteModal(procedure)}
                                        sx={{ 
                                            color: '#DC3545',
                                            '&:hover': {
                                                bgcolor: '#FFE9E9'
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                </div>
            </Box>

            {isModalOpen && (
                <PopupBase
                    Title={modalMode === 'create' ? 'Novo Procedimento' : 'Editar Procedimento'}
                    Content={modalContent}
                    PositiveButton={
                        <CustomButton
                            style="primary"
                            variant="contained"
                            label={modalMode === 'create' ? 'Adicionar' : 'Salvar'}
                            onClick={handleSubmit}
                        />
                    }
                    NegativeButton={
                        <CustomButton
                            style="secondary"
                            variant="text"
                            label="Cancelar"
                            onClick={handleCloseModal}
                        />
                    }
                    OnClose={handleCloseModal}
                />
            )}

            <ConfirmPopup
                Visibility={showConfirmDelete}
                Title="Desativar Procedimento"
                Description={`Tem certeza que deseja desativar o procedimento "${procedureToDelete?.Label}"?`}
                ConfirmLabel="Desativar"
                OnConfirm={handleDelete}
                OnHide={handleCloseDeleteModal}
            />
        </div>
    );
};

export default ProcedureList;