import React from 'react';
import { Box } from '@mui/material';
import ResourceList from './ResourceList';

const ResourcesPage = () => {
    return (
        <Box sx={{ py: 3 }}>
            <ResourceList />
        </Box>
    );
};

export default ResourcesPage;