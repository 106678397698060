import React, { useState, useEffect } from 'react';
import PopupBase from "../base/PopupBase";
import CustomButton from "../../components/buttons/CustomButton";
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Checkbox, 
  FormGroup, 
  FormControlLabel, 
  CircularProgress, 
  ListItemText,
  Grid,
  Chip,
  Typography,
  Divider,
  FormHelperText,
  Alert,
  Tooltip,
  IconButton
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import Autocomplete from "@mui/material/Autocomplete";
import { DataProviderInstance } from "../../api/DataProvider";
import styles from './AddWaitingListPopup.module.css';

function AddWaitingListPopup(props) {
  const { OnHide, OnSaved, initialData, availabilityParams } = props;
  const { GetPatientById, GetPatients, CompanyConfig, UserData, SetWaitingList, UpdateWaitingList } = DataProviderInstance();

  // Form state
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [priority, setPriority] = useState('MEDIUM');
  const [status, setStatus] = useState('PENDING');
  const [preferredDays, setPreferredDays] = useState([]);
  const [preferredTimes, setPreferredTimes] = useState([]);
  const [resource, setResource] = useState('');
  const [procedure, setProcedure] = useState('');
  const [observations, setObservations] = useState('');
  const [startDate, setStartDate] = useState(getCurrentDateString());
  const [endDate, setEndDate] = useState(getDefaultEndDateString());
  
  // UI state
  const [validationError, setValidationError] = useState('');
  const [patientsList, setPatientsList] = useState([]);
  const [sortedPatients, setSortedPatients] = useState([]);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  // Company configuration
  const availableDaysOfWeek = CompanyConfig.AvailableDaysOfWeek || [];
  const availableTimeRange = CompanyConfig.AvailableTime || [8, 18]; // Default 8am to 6pm
  const blockedTimes = CompanyConfig.BlockedTimes || [];

  // Days and times mapping
  const daysOfWeek = [
    { value: 1, label: 'Segunda-feira' },
    { value: 2, label: 'Terça-feira' },
    { value: 3, label: 'Quarta-feira' },
    { value: 4, label: 'Quinta-feira' },
    { value: 5, label: 'Sexta-feira' },
    { value: 6, label: 'Sábado' },
    { value: 0, label: 'Domingo' },
  ];

  // Initialize resources and procedures
  useEffect(() => {
    if (CompanyConfig.Resources && CompanyConfig.Resources.length > 0 && !initialData) {
      setResource(CompanyConfig.Resources[0].Id);
    }
    if (CompanyConfig.Procedures && CompanyConfig.Procedures.length > 0 && !initialData) {
      setProcedure(CompanyConfig.Procedures[0].Id);
    }
  }, [CompanyConfig, initialData]);

  // Load patients list
  useEffect(() => {
    setLoading(true);
    GetPatients(false, (result) => {
      setPatientsList(result);
      setLoading(false);
    });
  }, []);

  // Prepare autocomplete options
  useEffect(() => {
    if (patientsList.length > 0) {
      setSortedPatients(
        patientsList
          .filter(patient => patient.status === "1")
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(item => ({
            id: item.id,
            label: item.name,
            status: item.status,
          }))
      );
    }
  }, [patientsList]);

  // Load initial data if in edit mode
  useEffect(() => {
    if (initialData) {
      const patient = GetPatientById(initialData.patientId);
      if (patient) {
        const patientOption = { id: patient.id, label: patient.name, status: patient.status };
        setSelectedPatient(patientOption);
      }

      setPriority(initialData.priority);
      setStatus(initialData.status);
      setResource(initialData.resourceId);
      setProcedure(initialData.procedureId);
      setObservations(initialData.observations || '');
      setStartDate(initialData.startDate);
      setEndDate(initialData.endDate);
      
      // Parse JSON for preferred days and times
      if (initialData.preferredDays) {
        try {
          const days = typeof initialData.preferredDays === 'string' 
            ? JSON.parse(initialData.preferredDays) 
            : initialData.preferredDays;
          setPreferredDays(days);
        } catch (error) {
          console.error("Error parsing preferredDays:", error);
          setPreferredDays([]);
        }
      }
      
      if (initialData.preferredTimes) {
        try {
          const times = typeof initialData.preferredTimes === 'string' 
            ? JSON.parse(initialData.preferredTimes) 
            : initialData.preferredTimes;
          setPreferredTimes(times);
        } catch (error) {
          console.error("Error parsing preferredTimes:", error);
          setPreferredTimes([]);
        }
      }
    }
  }, [initialData]);

  // Helper functions
  function getCurrentDateString() {
    const today = new Date();
    return today.toISOString().split('T')[0];
  }

  function getDefaultEndDateString() {
    const date = new Date();
    // Use availabilityParams.daysLimit if available, otherwise fallback to 30 days
    const daysToAdd = availabilityParams?.daysLimit || 30;
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0];
  }

  // Form validation
  function validate() {
    if (!selectedPatient) {
      setValidationError("Selecione um paciente");
      return false;
    }

    if (preferredDays.length === 0) {
      setValidationError("Selecione ao menos um dia preferido");
      return false;
    }

    if (preferredTimes.length === 0) {
      setValidationError("Selecione ao menos um horário preferido");
      return false;
    }

    if (!startDate) {
      setValidationError("Selecione uma data de início");
      return false;
    }

    if (!endDate) {
      setValidationError("Selecione uma data de término");
      return false;
    }

    const startDateObj = new Date(startDate + "T00:00:00");
    const endDateObj = new Date(endDate + "T00:00:00");
    
    // Get today's date without time component
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    // Get yesterday to allow for timezone differences
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    // Compare dates - allow today or future dates, but not before yesterday
    if (startDateObj < yesterday) {
      setValidationError("A data de início não pode ser anterior a hoje");
      return false;
    }

    if (endDateObj < startDateObj) {
      setValidationError("A data de término deve ser posterior à data de início");
      return false;
    }

    return true;
  }

  // Add new waiting list entry
  function handleAddClick() {
    if (!validate()) return;

    setLoading(true);
    setApiError('');

    // Sort preferredTimes from smallest to largest
    const sortedTimes = [...preferredTimes].sort();

    const newEntry = {
      companyId: CompanyConfig.Id,
      patientId: selectedPatient.id,
      procedureId: procedure,
      resourceId: resource,
      startDate: startDate,
      endDate: endDate,
      preferredDays: preferredDays,
      preferredTimes: sortedTimes, // Use sorted times
      priority: priority,
      observations: observations,
      createdBy: UserData.id
    };

    SetWaitingList(newEntry, (response) => {
      setLoading(false);
      
      if (response.success) {
        OnSaved(response);
        OnHide(true);
      } else {
        setApiError(response.error || "Erro ao adicionar à lista de espera");
      }
    });
  }

  // Update existing waiting list entry
  function handleUpdateClick() {
    if (!validate()) return;

    setLoading(true);
    setApiError('');

    // Sort preferredTimes from smallest to largest
    const sortedTimes = [...preferredTimes].sort();

    const updatedEntry = {
      id: initialData.id,
      companyId: initialData.companyId,
      patientId: selectedPatient.id,
      procedureId: procedure,
      resourceId: resource,
      startDate: startDate,
      endDate: endDate,
      preferredDays: preferredDays,
      preferredTimes: sortedTimes, // Use sorted times
      priority: priority,
      status: status,
      observations: observations,
      updatedBy: UserData.id
    };

    UpdateWaitingList(updatedEntry, (response) => {
      setLoading(false);
      
      if (response.success) {
        OnSaved(response);
        OnHide(true);
      } else {
        setApiError(response.error || "Erro ao atualizar item da lista de espera");
      }
    });
  }

  // Toggle preferred day
  function toggleDay(day) {
    setPreferredDays(prevDays => 
      prevDays.includes(day) 
        ? prevDays.filter(d => d !== day) 
        : [...prevDays, day]
    );
    setValidationError('');
  }

  // Toggle preferred time
  function toggleTime(time) {
    setPreferredTimes(prevTimes => 
      prevTimes.includes(time) 
        ? prevTimes.filter(t => t !== time) 
        : [...prevTimes, time]
    );
    setValidationError('');
  }

  // Generate time options
  function generateTimeOptions() {
    const startHour = parseInt(availableTimeRange[0]);
    const endHour = parseInt(availableTimeRange[1]);
    
    const times = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      const hourStr = hour.toString().padStart(2, '0');
      
      // Only full hours (removed half-hour intervals)
      const fullHour = `${hourStr}:00`;
      if (!blockedTimes.includes(fullHour)) {
        times.push(fullHour);
      }
    }
    
    return times;
  }

  // Select all days
  function selectAllDays() {
    setPreferredDays(availableDaysOfWeek);
    setValidationError('');
  }

  // Select all times
  function selectAllTimes() {
    setPreferredTimes(generateTimeOptions());
    setValidationError('');
  }

  // Render the patient autocomplete
  function renderPatientAutocomplete() {
    return (
      <Autocomplete
        id="patient-autocomplete"
        options={sortedPatients}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedPatient}
        onChange={(event, newValue) => {
          setSelectedPatient(newValue);
          setValidationError('');
        }}
        disabled={!!initialData} // Disable in edit mode
        open={autocompleteOpen}
        onOpen={() => setAutocompleteOpen(true)}
        onClose={() => setAutocompleteOpen(false)}
        loading={loading}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <ListItemText primary={option.label} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Paciente"
            variant="outlined"
            required
            error={validationError.includes('paciente')}
            helperText={validationError.includes('paciente') ? validationError : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  }

  // Popup content
  function renderContent() {
    return (
      <div className={styles.formContainer}>
        {apiError && <Alert severity="error" sx={{ mb: 2 }}>{apiError}</Alert>}
        
        <Grid container spacing={2}>
          {/* Patient, Status, and Priority */}
          <Grid item xs={12} md={6}>
            {renderPatientAutocomplete()}
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                disabled={!initialData} // Only enable in edit mode
              >
                <MenuItem value="PENDING">Pendente</MenuItem>
                <MenuItem value="CONTACTED">Contatado</MenuItem>
                <MenuItem value="CONFIRMED">Confirmado</MenuItem>
                <MenuItem value="SCHEDULED">Agendado</MenuItem>
                <MenuItem value="DECLINED">Recusado</MenuItem>
                <MenuItem value="CANCELLED">Cancelado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="priority-label">Prioridade</InputLabel>
              <Select
                labelId="priority-label"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                label="Prioridade"
              >
                <MenuItem value="HIGH">Alta</MenuItem>
                <MenuItem value="MEDIUM">Média</MenuItem>
                <MenuItem value="LOW">Baixa</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          {/* Resource and Procedure */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="resource-label">Profissional</InputLabel>
              <Select
                labelId="resource-label"
                value={resource}
                onChange={(e) => setResource(e.target.value)}
                label="Profissional"
              >
                <MenuItem value="">Qualquer profissional</MenuItem>
                {CompanyConfig.Resources && CompanyConfig.Resources.map((res) => (
                  <MenuItem key={res.Id} value={res.Id}>{res.Label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>Deixe em branco para considerar qualquer profissional</FormHelperText>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="procedure-label">Procedimento</InputLabel>
              <Select
                labelId="procedure-label"
                value={procedure}
                onChange={(e) => setProcedure(e.target.value)}
                label="Procedimento"
                required
              >
                {CompanyConfig.Procedures && CompanyConfig.Procedures.map((proc) => (
                  <MenuItem key={proc.Id} value={proc.Id}>{proc.Label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          {/* Date Range */}
          <Grid item xs={12}>
            <div className={styles.sectionHeader}>
              <Typography variant="subtitle1" fontWeight="medium">Período para Sugestões</Typography>
              <Tooltip title="Define o período em que o sistema procurará disponibilidades para sugerir ao paciente">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <Divider sx={{ mb: 2 }} />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              label="Data Inicial"
              type="date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                setValidationError('');
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              required
              // Remove min attribute to allow any date input
              // The validation function will handle date restrictions
              error={validationError.includes('início')}
              helperText={validationError.includes('início')
                ? validationError
                : 'Data a partir da qual começar a sugerir horários'}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              label="Data Final"
              type="date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                setValidationError('');
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              required
              // Remove min attribute to allow any date input
              // The validation function will handle date restrictions
              error={validationError.includes('término')}
              helperText={validationError.includes('término')
                ? validationError
                : 'Data limite para sugestão de horários'}
            />
          </Grid>
          
          {/* Preferred Days */}
          <Grid item xs={12}>
            <div className={styles.sectionHeader}>
              <Typography variant="subtitle1" fontWeight="medium">Dias Preferidos</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomButton
                  variant="outlined"
                  style="secondary"
                  label="Selecionar Todos"
                  onClick={selectAllDays}
                  size="small"
                />
                <Tooltip title="Selecione os dias da semana que o paciente prefere ser atendido">
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <Divider sx={{ mb: 2 }} />
            
            <div className={styles.daysContainer}>
              {daysOfWeek
                .filter(day => availableDaysOfWeek.includes(day.value))
                .map(day => (
                  <Chip
                    key={day.value}
                    label={day.label}
                    onClick={() => toggleDay(day.value)}
                    color={preferredDays.includes(day.value) ? "primary" : "default"}
                    variant={preferredDays.includes(day.value) ? "filled" : "outlined"}
                    className={styles.chip}
                    icon={preferredDays.includes(day.value) ? <CheckIcon fontSize="small" /> : null}
                  />
                ))
              }
            </div>
            
            {validationError.includes('dia') ? (
              <FormHelperText error>{validationError}</FormHelperText>
            ) : (
              <FormHelperText>
                {preferredDays.length > 0 
                  ? `Selecionado(s): ${preferredDays.length} dia(s)` 
                  : "Selecione os dias da semana que o paciente prefere"}
              </FormHelperText>
            )}
          </Grid>
          
          {/* Preferred Times */}
          <Grid item xs={12}>
            <div className={styles.sectionHeader}>
              <Typography variant="subtitle1" fontWeight="medium">Horários Preferidos</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomButton
                  variant="outlined"
                  style="secondary"
                  label="Selecionar Todos"
                  onClick={selectAllTimes}
                  size="small"
                />
                <Tooltip title="Selecione os horários que o paciente prefere ser atendido">
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <Divider sx={{ mb: 2 }} />
            
            <div className={styles.timesContainer}>
              {generateTimeOptions().map(time => (
                <Chip
                  key={time}
                  label={time}
                  onClick={() => toggleTime(time)}
                  color={preferredTimes.includes(time) ? "primary" : "default"}
                  variant={preferredTimes.includes(time) ? "filled" : "outlined"}
                  className={styles.chip}
                  icon={preferredTimes.includes(time) ? <CheckIcon fontSize="small" /> : null}
                />
              ))}
            </div>
            
            {validationError.includes('horário') ? (
              <FormHelperText error>{validationError}</FormHelperText>
            ) : (
              <FormHelperText>
                {preferredTimes.length > 0 
                  ? `Selecionado(s): ${preferredTimes.length} horário(s)` 
                  : "Selecione os horários que o paciente prefere"}
              </FormHelperText>
            )}
          </Grid>
          
          {/* Observations */}
          <Grid item xs={12}>
            <TextField
              label="Observações"
              multiline
              rows={3}
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
              fullWidth
              variant="outlined"
              placeholder="Informações adicionais sobre o paciente ou preferências específicas..."
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  // Popup footer with buttons
  function renderFooter() {
    return (
      <div className={styles.footerButtons}>
        {validationError && <Alert severity="error">{validationError}</Alert>}
        
        <div className={styles.buttonGroup}>
          <CustomButton 
            variant="outlined" 
            style="secondary" 
            label="Cancelar" 
            onClick={() => OnHide(false)} 
            disabled={loading}
          />
          
          {initialData ? (
            <CustomButton 
              variant="contained" 
              style="primary" 
              label="Salvar alterações" 
              onClick={handleUpdateClick} 
              disabled={loading}
            />
          ) : (
            <CustomButton 
              variant="contained" 
              style="primary" 
              label="Adicionar" 
              onClick={handleAddClick} 
              disabled={loading}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <PopupBase 
      Title={initialData ? "Editar item da lista de espera" : "Adicionar à lista de espera"} 
      Content={renderContent()} 
      Footer={renderFooter()} 
      OnClose={() => OnHide(false)} 
      maxWidth="md"
    />
  );
}

export default AddWaitingListPopup;