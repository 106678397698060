import React, { useState, useEffect } from 'react';
import styles from './DayColumn.module.css';
import AppointmentSlot from '../AppointmentSlot/AppointmentSlot';
import CalendarConfig from '../../../../consts/CalendarConfig';
import AppointmentsState from '../../../../consts/AppointmentsState';
import { AppointmentModel } from '../../../../consts/Models';

const DayColumn = ({
  date,
  appointments = [],
  startHour = 8,
  endHour = 20,
  onAppointmentSelect
}) => {
  const totalHours = endHour - startHour;
  const dayOfWeek = CalendarConfig.DaysOfWeek[date.getDay()];
  const isToday = date.toDateString() === new Date().toDateString();

  // Create hour markers
  const hourMarkers = [];
  for (let hour = startHour; hour <= endHour; hour++) {
    const position = ((hour - startHour) / totalHours) * 100;
    hourMarkers.push(
      <div
        key={`hour-${hour}`}
        className={styles.hourMarker}
        style={{ top: `${position}%` }}
      />
    );
  }

  // Format date to MySQL format without timezone conversion
  const formatDateToMySql = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = '00';
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // Calculate minimum height percentage
  const getMinHeightPercentage = () => {
    const totalCalendarHeight = window.innerHeight - 80 - 52; // viewport height - parent header - calendar header
    return (60 / totalCalendarHeight) * 100;
  };

  // Generate empty time slots for every 15 minutes
  const generateEmptySlots = () => {
    const slots = [];
    const totalMinutes = (endHour - startHour) * 60;
    const interval = 15; // 15-minute intervals
    const minHeightPercentage = getMinHeightPercentage();

    for (let minutes = 0; minutes < totalMinutes; minutes += interval) {
      const hour = Math.floor(minutes / 60) + startHour;
      const minute = minutes % 60;
      const position = (minutes / totalMinutes) * 100;
      // Cada slot deve ocupar o espaço completo de uma hora (4/4)
      const height = 100 / totalHours;

      // Check if this slot overlaps with any appointment
      const slotStart = new Date(date);
      slotStart.setHours(hour, minute, 0, 0);
      const slotEnd = new Date(slotStart);
      slotEnd.setMinutes(slotStart.getMinutes() + interval);

      const hasOverlap = appointments.some(apt => {
        const aptStart = new Date(apt.date);
        const [aptHours, aptMinutes] = (apt.duration || '1:00').split(':').map(Number);
        const aptEnd = new Date(aptStart.getTime() + (aptHours * 60 + aptMinutes) * 60000);
        return slotStart < aptEnd && slotEnd > aptStart;
      });

      if (!hasOverlap) {
        slots.push(
          <div
            key={`empty-${hour}-${minute}`}
            className={styles.emptySlot}
            style={{
              top: `${position}%`,
              height: `${height}%`
            }}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setHours(hour, minute, 0, 0);
              const dateStr = formatDateToMySql(newDate);
              const emptyAppointment = new AppointmentModel(
                null,
                AppointmentsState.Available,
                dateStr,
                null,
                null,
                null,
                "1:00"
              );
              onAppointmentSelect(emptyAppointment);
            }}
          >
            <span className={`${styles.timeIndicator} ${minute === 0 ? styles.fullHour : ''}`}>
              {`${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`}
            </span>
          </div>
        );
      }
    }

    return slots;
  };

  // Sort appointments by time and status
  const sortedAppointments = [...appointments].sort((a, b) => {
    const timeA = new Date(a.date).getTime();
    const timeB = new Date(b.date).getTime();
    if (timeA !== timeB) return timeA - timeB;

    const statusPriority = {
      [AppointmentsState.Canceled]: 10,
      [AppointmentsState.CanceledByPatient]: 10,
      [AppointmentsState.Rescheduled]: 10,
      [AppointmentsState.Missed]: 2,
      [AppointmentsState.Available]: 0,
      default: 1,
    };

    const priorityA = statusPriority[a.status] || statusPriority.default;
    const priorityB = statusPriority[b.status] || statusPriority.default;
    return priorityA - priorityB;
  });

  // Group overlapping appointments
  const groupAppointments = () => {
    const groups = [];
    let currentGroup = [];

    for (const apt of sortedAppointments) {
      const aptStart = new Date(apt.date);
      const [hours, minutes] = (apt.duration || '1:00').split(':').map(Number);
      const aptEnd = new Date(aptStart.getTime() + (hours * 60 + minutes) * 60000);

      const overlapsWithGroup = currentGroup.some(groupApt => {
        const groupStart = new Date(groupApt.date);
        const [groupHours, groupMinutes] = (groupApt.duration || '1:00').split(':').map(Number);
        const groupEnd = new Date(groupStart.getTime() + (groupHours * 60 + groupMinutes) * 60000);
        return aptStart < groupEnd && aptEnd > groupStart;
      });

      if (overlapsWithGroup) {
        currentGroup.push(apt);
      } else {
        if (currentGroup.length > 0) {
          groups.push([...currentGroup]);
        }
        currentGroup = [apt];
      }
    }

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups;
  };

  const appointmentGroups = groupAppointments();

  // State for current time
  const [currentTime, setCurrentTime] = useState(new Date());

  // Update current time every second
  useEffect(() => {
    if (!isToday) return;

    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [isToday]);

  // Add current time indicator if it's today and within visible hours
  const currentTimeIndicator = (() => {
    if (!isToday) return null;
    
    const currentHour = currentTime.getHours() + currentTime.getMinutes() / 60 + currentTime.getSeconds() / 3600;
    
    // Only show if current time is within visible range
    if (currentHour < startHour || currentHour > endHour) return null;
    
    const formatTimeDisplay = (date) => {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    };

    return (
      <div
        className={styles.currentTimeIndicator}
        style={{
          top: `${((currentHour - startHour) / totalHours) * 100}%`
        }}
      >
        <div className={styles.currentTimeDisplay}>
          {formatTimeDisplay(currentTime)}
        </div>
      </div>
    );
  })();

  return (
    <div className={`${styles.dayColumn} ${isToday ? styles.today : ''}`}>
      <div className={styles.dayHeader}>
        <span className={styles.dayName}>{dayOfWeek}</span>
        <span className={styles.dayNumber}>{date.getDate()}</span>
      </div>
      <div className={styles.appointmentsContainer}>
        {hourMarkers}
        {generateEmptySlots()}
        {currentTimeIndicator}
        {appointmentGroups.map((group, groupIndex) => (
          <div key={`group-${groupIndex}`}>
            {group.map((appointment, index) => (
              <AppointmentSlot
                key={appointment.id || `${appointment.date}-${index}`}
                appointment={appointment}
                startHour={startHour}
                endHour={endHour}
                totalMinutes={(endHour - startHour) * 60}
                groupSize={group.length}
                groupIndex={index}
                onClick={() => onAppointmentSelect(appointment)}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayColumn;