import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { DataProviderInstance } from '../../api/DataProvider';
import PatientBirthdayItem from './patientBirthdayItem/PatientBirthdayItem';
import AreaTitle from '../../components/areaTitle/AreaTitle';
import Loading from '../../components/loading/Loading';
import IconAppointments from "@mui/icons-material/EventAvailableOutlined";
import IconBirthday from "@mui/icons-material/CakeOutlined";
import AppointmentsToConfirm from './appointmentsToConfirm/AppointmentsToConfirm';
import AppointmentsReminder from './appointmentsReminder/AppointmentsReminder';

function Dashboard() {
  const {
    UserData,
    GetDashboard
  } = DataProviderInstance();

  const [dashboardData, setDashboardData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    GetData();
  }, []);

  function GetData() {
    GetDashboard((result) => {
      setDashboardData(result);
      setIsLoaded(true);
    });
  }

  function GetGreetings() {
    const currentHour = new Date().getHours();
    const name = UserData?.name?.split(' ')[0] || 'Usuário';

    if (currentHour >= 0 && currentHour < 12) {
      return `Bom dia ${name}!`;
    } else if (currentHour >= 12 && currentHour < 18) {
      return `Boa tarde ${name}!`;
    } else {
      return `Boa noite ${name}!`;
    }
  }

  function GetHeader() {
    return (
      <header className='dashboard-welcome'>
        <h2>{GetGreetings()}</h2>
        <p>Aqui estão alguns dados relevantes sobre seu dia de hoje.</p>
      </header>
    );
  }

  function GetPatientsBirthdays() {
    const hasBirthdays = dashboardData?.PatientsBirthdays?.length > 0;

    return (
      <div className='dashboard-item-container'>
        <AreaTitle 
          Title="Aniversariantes" 
          Description="Não deixe de enviar os parabéns!" 
          Icon={<IconBirthday />} 
        />
        <div className='dashboard-birthdays-list'>
          {hasBirthdays ? (
            dashboardData.PatientsBirthdays.map((item, index) => (
              <PatientBirthdayItem 
                key={`birthday-${item.id || index}`}
                Name={item.name} 
                Age={item.age} 
                Phone={item.phone} 
              />
            ))
          ) : (
            <div className='dashboard-birthdays-empty'>
              <IconBirthday className='empty-icon' />
              <span className='empty-text'>
                Nenhum aniversariante hoje
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  function GetAppointmentsToConfirm() {
    return (
      <div className='dashboard-item-container'>
        <AreaTitle 
          Title="Agendamentos a confirmar" 
          Description="Confirme os próximos agendamentos ainda não confirmados." 
          Icon={<IconAppointments />} 
        />
        <div className='dashboard-section'>
          <AppointmentsToConfirm list={dashboardData?.AppointmentsToConfirm || []} />
        </div>
      </div>
    );
  }

  function GetAppointmentsToReminder() {
    return (
      <div className='dashboard-item-container'>
        <AreaTitle 
          Title="Enviar lembretes" 
          Description="Agendamentos que serão realizados daqui 7 dias" 
          Icon={<IconAppointments />} 
        />
        <div className='dashboard-section'>
          <AppointmentsReminder list={dashboardData?.ReminderAppointments || []} />
        </div>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div className='page-content'>
        <Loading />
      </div>
    );
  }

  return (
    <div className='page-content'>
      {GetHeader()}
      <div className='dashboard-content'>
        <div className='dashboard-top-section'>
          {GetPatientsBirthdays()}
        </div>
        <div className='dashboard-bottom-section'>
          {GetAppointmentsToConfirm()}
          {GetAppointmentsToReminder()}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;