import React from 'react';
import styles from '../styles.module.css';
import AreaTitle from '../../../components/areaTitle/AreaTitle';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  Fade,
} from "@mui/material";
import ColorPalette from '../../../colorpalette/ColorPalette';
import IconNPS from "@mui/icons-material/Analytics";
import IconGoogle from "@mui/icons-material/Google";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

function NPSThermometer({ score, promoters, neutrals, detractors }) {
  const position = ((score + 100) / 200) * 100;
  
  return (
    <Box sx={{ width: '100%', position: 'relative', mt: 4, mb: 2 }}>
      <Typography 
        variant="h2" 
        sx={{ 
          textAlign: 'center', 
          mb: 3, 
          fontWeight: 'bold',
          color: '#333'
        }}
      >
        {score}
      </Typography>

      <Box
        sx={{
          height: '40px',
          borderRadius: '20px',
          background: 'linear-gradient(90deg, #ff4444 0%, #ffaa00 50%, #44b700 100%)',
          position: 'relative',
          mb: 2
        }}
      >
        <Box
          sx={{
            width: 0,
            height: 0,
            position: 'absolute',
            left: `${position}%`,
            top: '-20px',
            transform: 'translateX(-50%)',
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            borderTop: '16px solid #333',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '-18px',
              left: '-10px',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '14px solid #333',
              zIndex: 2
            }
          }}
        />
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h5" 
              sx={{ 
                color: ColorPalette.red,
                fontWeight: 'bold'
              }}
            >
              {detractors}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Detratores
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h5" 
              sx={{ 
                color: ColorPalette.orange,
                fontWeight: 'bold'
              }}
            >
              {neutrals}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Neutros
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h5" 
              sx={{ 
                color: ColorPalette.greenLight,
                fontWeight: 'bold'
              }}
            >
              {promoters}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Promotores
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={4}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            -100
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            0
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            100
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function CircularProgressWithLabel({ value, color, size = 80 }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={4}
        sx={{
          color: color,
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round',
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#ffffff' }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function NPSStatistics({ npsData, error, selectedCategory, onCategoryChange }) {

  function GetNPSOverview() {
    if (!npsData?.statistics) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title="Visão Geral do NPS"
            Description="Métricas principais do Net Promoter Score"
            Icon={<IconNPS />}
          />
          <div>Dados do NPS indisponíveis.</div>
        </div>
      );
    }

    const stats = npsData.statistics;

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title="Visão Geral do NPS"
          Description="Métricas principais do Net Promoter Score"
          Icon={<IconNPS />}
        />
        <NPSThermometer 
          score={stats.nps_score}
          promoters={stats.promoters}
          neutrals={stats.neutrals}
          detractors={stats.detractors}
        />
      </div>
    );
  }

  function GetGoogleReviewsStatus() {
    if (!npsData?.statistics?.google_reviews) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title="Status das Avaliações Google"
            Description="Acompanhamento das solicitações de avaliação no Google"
            Icon={<IconGoogle />}
          />
          <div>Dados de avaliações Google indisponíveis.</div>
        </div>
      );
    }

    const { google_reviews } = npsData.statistics;

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title="Status das Avaliações Google"
          Description="Acompanhamento das solicitações de avaliação no Google"
          Icon={<IconGoogle />}
        />
        <Grid container spacing={3} sx={{ mt: 2 }}>
          <Grid item xs={12} md={4}>
            <Fade in={true} timeout={800}>
              <Paper
                elevation={1}
                sx={{
                  p: 3,
                  bgcolor: ColorPalette.main,
                  color: 'white',
                  borderRadius: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                <RequestQuoteIcon sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="h4" fontWeight="bold">
                  {google_reviews.total_requests}
                </Typography>
                <Typography variant="subtitle1" textAlign="center">
                  Total de Solicitações
                </Typography>
              </Paper>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4}>
            <Fade in={true} timeout={1000}>
              <Paper
                elevation={1}
                sx={{
                  p: 3,
                  bgcolor: ColorPalette.greenLight,
                  borderRadius: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                <CheckCircleIcon sx={{ fontSize: 40, mb: 1, color: '#00b300' }} />
                <Typography variant="h4" fontWeight="bold" sx={{ color: '#00b300' }}>
                  {google_reviews.completed_reviews}
                </Typography>
                <Typography variant="subtitle1" textAlign="center" sx={{ color: '#00b300' }}>
                  Avaliações Realizadas
                </Typography>
              </Paper>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4}>
            <Fade in={true} timeout={1200}>
              <Paper
                elevation={1}
                sx={{
                  p: 3,
                  bgcolor: ColorPalette.mainLight1,
                  color: 'white',
                  borderRadius: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                <TrendingUpIcon sx={{ fontSize: 40, mb: 1 }} />
                <CircularProgressWithLabel 
                  value={google_reviews.conversion_rate} 
                  color="white"
                />
                <Typography variant="subtitle1" textAlign="center">
                  Taxa de Conversão
                </Typography>
              </Paper>
            </Fade>
          </Grid>
        </Grid>
      </div>
    );
  }

  function GetResponsesList() {
    return (
      <div className={styles.boxRoundedContainer} style={{ width: '100%', maxWidth: '100%', maxHeight: 'none', height: 'auto' }}>
        <AreaTitle
          Title="Respostas NPS"
          Description="Lista de todas as respostas recebidas"
          Icon={<IconNPS />}
        />
        
        <FormControl fullWidth sx={{ mb: 3, mt: 5 }}>
          <InputLabel>Filtrar por categoria</InputLabel>
          <Select
            value={selectedCategory}
            label="Filtrar por categoria"
            onChange={onCategoryChange}
          >
            <MenuItem value="all">Todas</MenuItem>
            <MenuItem value="promoter">Promotores</MenuItem>
            <MenuItem value="neutral">Neutros</MenuItem>
            <MenuItem value="detractor">Detratores</MenuItem>
          </Select>
        </FormControl>

        {error && (
          <div style={{color: 'red', marginBottom: '20px'}}>{error}</div>
        )}

        {!Array.isArray(npsData?.responses) || npsData.responses.length === 0 ? (
          <div>Nenhuma resposta encontrada para o período selecionado.</div>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Paciente</strong></TableCell>
                  <TableCell><strong>Data da Consulta</strong></TableCell>
                  <TableCell><strong>Data da Avaliação</strong></TableCell>
                  <TableCell><strong>Nota</strong></TableCell>
                  <TableCell><strong>Categoria</strong></TableCell>
                  <TableCell><strong>Comentários</strong></TableCell>
                  <TableCell><strong>Status Google</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {npsData.responses.map((response, index) => (
                  <TableRow key={index}>
                    <TableCell>{response.patient_name}</TableCell>
                    <TableCell>{response.appointment_date}</TableCell>
                    <TableCell>{response.created_at}</TableCell>
                    <TableCell>{response.score}</TableCell>
                    <TableCell>{response.category}</TableCell>
                    <TableCell>{response.comments || '-'}</TableCell>
                    <TableCell>{response.google_review_status || 'N/A'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div className={styles.gridContainer}>
        {GetNPSOverview()}
        {GetGoogleReviewsStatus()}
      </div>
      <div style={{ width: '100%' }}>
        {GetResponsesList()}
      </div>
    </div>
  );
}

export default NPSStatistics;