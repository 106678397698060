import React from "react";
import Button from '@mui/material/Button';

function CustomButton(props) {
    const { id, style, variant, label, icon, border, onClick, enabled = true, ...otherProps } = props;

    // Map our style prop to MUI color prop
    const getColor = (style) => {
        switch (style) {
            case "primary": return "primary";
            case "secondary": return "secondary";
            case "tertiary": return "tertiary";
            case "warning": return "warning";
            case "error": return "error";
            default: return "primary";
        }
    };

    const buttonStyle = border ? { borderWidth: border } : {};

    function OnClickHandler() {
        onClick(id);
    }

    const buttonProps = {
        variant,
        color: getColor(style),
        onClick: OnClickHandler,
        disableElevation: true,
        disabled: !enabled,
        style: buttonStyle,
        ...otherProps
    };

    if (!label || label === "") {
        return (
            <Button {...buttonProps}>
                {icon}
            </Button>
        );
    }

    return (
        <Button {...buttonProps} startIcon={icon}>
            {label}
        </Button>
    );
}

export default CustomButton;
