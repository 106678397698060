import React from 'react';
import styles from './AppointmentsToConfirm.module.css';
import AppointmentsByDay from './item/AppointmentsByDay';
import CalendarConfig from '../../../consts/CalendarConfig';
import { EventBusy } from '@mui/icons-material';

const AppointmentsToConfirm = ({ list }) => {
  function getFormattedLabel(dateStr) {
    const today = new Date();
    const tomorrow = new Date(today);
    const currentDateStr = CalendarConfig.GetDDMMAAAA(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDateStr = CalendarConfig.GetDDMMAAAA(tomorrow);

    if (dateStr === currentDateStr) {
      return "Hoje, " + CalendarConfig.GetFullDateName(dateStr);
    } else if (dateStr === tomorrowDateStr) {
      return "Amanhã, " + CalendarConfig.GetFullDateName(dateStr);
    } else {
      return CalendarConfig.GetFullDateName(dateStr);
    }
  }

  function renderAppointmentGroup(item, index) {
    const dateStr = CalendarConfig.GetDDMMAAAA(item.date);
    const label = getFormattedLabel(dateStr);

    return (
      <AppointmentsByDay
        key={index}
        label={label}
        appointments={item.list}
      />
    );
  }

  if (!list || list.length === 0) {
    return (
      <div className={styles.emptyContainer}>
        <EventBusy className={styles.emptyIcon} />
        <span className={styles.emptyText}>
          Não há agendamentos para confirmar no momento
        </span>
      </div>
    );
  }

  return (
    <div className={styles.listContainer}>
      {list.map((item, index) => renderAppointmentGroup(item, index))}
    </div>
  );
}

export default AppointmentsToConfirm;
