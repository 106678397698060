import React from 'react';
import styles from './Financial.module.css';
import HistoryItem from './components/history-item/HistoryItem';
import Stats from './components/stats/Stats';
import WalletSection from './components/wallet-section/WalletSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill, faMoneyCheck, faWallet } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../../../components/buttons/CustomButton';
import IconWhatsapp from '@mui/icons-material/WhatsApp';

const Financial = ({ patientData, data }) => {

    const getFriendlyDate = (date) => {
        const [datePart, timePart] = date.split(' ');
        const [year, month, day] = datePart.split('-');
        const [hour, minute] = timePart.split(':');
        const formattedDate = `${day}/${month}/${year} às ${hour}:${minute}`;
        return formattedDate;
    }

    const handleRequestDebits = () => {

        const filteredDebits = data.torecive.filter(item => item.status === "completed" || item.status === "noshow");
        const listOfDebitsString = filteredDebits.map(item => `- ${getFriendlyDate(item.date)} : R$ ${item.price}`).join('\n');
        const totalValue = filteredDebits.reduce((acc, item) => acc + parseFloat(item.price), 0);

        const messageTemplate = `Olá, ${patientData.name}! Gostaria de lembrar que você possui ${filteredDebits.length > 1 ? 'débitos pendentes' : 'um débito pendente'} totalizando ${totalValue} reais, referente ${filteredDebits.length > 1 ? 'as consultas realizadas' : 'a consulta realizada'}  com a Dra. Juliana Nakashima, listados abaixo:\n\n${listOfDebitsString}\n\nPor favor, entre em contato para regularizarmos a situação.\nObrigada!`;

        navigator.clipboard.writeText(messageTemplate);
    }

    return (
        <div className={styles.container}>
            <div className={styles.containerStats}>
                <Stats icon={<FontAwesomeIcon icon={faMoneyBill} />} label="A receber" value={data.stats.torecive ? "R$ " + data.stats.torecive : "R$ 0"} />
                <Stats icon={<FontAwesomeIcon icon={faMoneyCheck} />} label="Pago" value={data.stats.paid ? "R$ " + data.stats.paid : "R$ 0"} />
            </div>
            <WalletSection patientId={patientData.id} />
            <div className={styles.containerAppointmentsList}>
                <div className={styles.titleContainer}>
                    <h3>A receber</h3>
                    <CustomButton style="primary" variant="contained" label="Cobrar débitos" icon={<IconWhatsapp />} onClick={handleRequestDebits} />
                </div>
                {data.torecive.length === 0 ? <p>Nenhum débito existente.</p > :
                    data.torecive.map((item, index) => (
                        <HistoryItem key={index} data={item} />
                    ))
                }
            </div>
            <div className={styles.containerAppointmentsList}>
                    <h3>Pagos</h3>
                {data.paid.length === 0 ? <p>Nenhum pagamento realizado.</p > :
                    data.paid.map((item, index) => (
                        <HistoryItem key={index} data={item} />
                    ))
                }
            </div>
        </div>
    );
};

export default Financial;