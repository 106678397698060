import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import './DaySelector.css';

const DaySelector = ({ value, onChange, disabled }) => {
  const days = [
    { label: 'Domingo', shortLabel: 'Dom', value: 0 },
    { label: 'Segunda', shortLabel: 'Seg', value: 1 },
    { label: 'Terça', shortLabel: 'Ter', value: 2 },
    { label: 'Quarta', shortLabel: 'Qua', value: 3 },
    { label: 'Quinta', shortLabel: 'Qui', value: 4 },
    { label: 'Sexta', shortLabel: 'Sex', value: 5 },
    { label: 'Sábado', shortLabel: 'Sáb', value: 6 },
  ];

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <ToggleButtonGroup
      value={value}
      onChange={handleChange}
      aria-label="dias da semana"
      disabled={disabled}
      className="day-selector-group"
    >
      {days.map((day) => (
        <ToggleButton
          key={day.value}
          value={day.value}
          aria-label={day.label}
          title={day.label}
          className="day-selector-button"
        >
          {day.shortLabel}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default DaySelector;