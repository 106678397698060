import React from 'react';
import styles from './PatientBirthdayItem.module.css';
import CustomButtom from '../../../components/buttons/CustomButton';
import MessagesTemplate from '../../../consts/MessagesTemplate';
import { SendWhatsAppMessage } from '../../../api/WhatsappAPI';
import IconWhatsapp from '@mui/icons-material/WhatsApp'
import { useEffect, useState } from 'react';
import { Check } from '@mui/icons-material';
import { DataProviderInstance } from '../../../api/DataProvider';

function PatientBirthdayItem(props) {
    const { Name, Age, Phone } = props;
    const localStorageId = "birthDayMessageSent-" + Name + Age + Phone;
    const { CompanyConfig } = DataProviderInstance();
    const templates = MessagesTemplate.getTemplates(CompanyConfig);
    const [messageSent, setMessagesSent] = useState(false);
    const hasValidPhone = Phone && Phone.length >= 8;

    useEffect(() => {
        setMessagesSent(GetMessagesSent());
    }, []);

    useEffect(() => {
        if (messageSent) {
            localStorage.setItem(localStorageId, true);
        }
    }, [messageSent, localStorageId]);

    function GetMessagesSent() {
        const value = localStorage.getItem(localStorageId);
        return value ? true : false;
    }

    function MessageSent() {
        setMessagesSent(true);
    }

    function OnContactClickHandler(e) {
        let message = templates.HappyBirthday;
        message = message.replaceAll("{name}", Name.split(' ')[0]);
        message = message.replaceAll("{company_name}", CompanyConfig?.Name || "");
        SendWhatsAppMessage(Phone, MessagesTemplate.ConvertToWhatsAppText(message));
        MessageSent();
    }

    return (
        <div className={styles.patientBirthDayContainer}>
            <div className={styles.infoContainer}>
                <span className={styles.age}>{Age}</span>
                <span className={styles.name}>{Name}</span>
            </div>
            <div className={styles.containerActions}>
                {messageSent && (
                    <div className={styles.messageSentIcon}>
                        <Check fontSize="small" />
                    </div>
                )}
                {hasValidPhone && (
                    <CustomButtom
                        style="secondary"
                        variant="text"
                        icon={<IconWhatsapp />}
                        onClick={OnContactClickHandler}
                        className={styles.whatsappButton}
                    />
                )}
            </div>
        </div>
    );
}

export default PatientBirthdayItem;