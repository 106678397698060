import React, { useState, useEffect } from 'react';
import styles from './Appointments.module.css';
import { DataProviderInstance } from '../../api/DataProvider';
import Header from './header/Header';
import CalendarGrid from './components/CalendarGrid/CalendarGrid';
import AppointmensState from '../../consts/AppointmentsState';
import CalendarConfig from '../../consts/CalendarConfig';
import AppointmentPopupContent from '../../popup/AppointmentPopupContent/AppointmentPopupContent';
import AvailableDatePopupContent from '../../popup/AvailableDatePopupContent/AvailableDatePopupContent';
import BlockAppointmentPopupContent from '../../popup/BlockAppointmentPopupContent/BlockAppointmentPopupContent';
import CreateInvoicePopup from '../../popup/CreateInvoicePopup/CreateInvoicePopup';
import AppConfig from '../../consts/AppConfig';
import FilterList from './components/filters/FilterList';
import CalendarDatePicker from './components/filters/calendarDatePicker/CalendarDatePicker';
import ColorPalette from '../../colorpalette/ColorPalette';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FilterListIcon from '@mui/icons-material/FilterList';

function Appointments() {
  const { CompanyConfig, GetAppointments } = DataProviderInstance();
  const [isMobile, setIsMobile] = useState(window.innerWidth < AppConfig.MinScreenSizeX);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [viewDaysCount, setViewDaysCount] = useState(7);
  const [page, setPage] = useState(0);
  const _today = GetCurrentDate();
  const [popupId, setPopupId] = useState(-1);

  const usingStatesSource = AppointmensState.Filters;
  const [allAppointments, setAllAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filtersByDayOfWeek, setFiltersByDayOfWeek] = useState(CalendarConfig.DaysOfWeek.map((_, index) => index));
  const [filtersByState, setFiltersByState] = useState(usingStatesSource.map((_, index) => index));
  const [filtersByProcedures, setFiltersByProcedures] = useState([]);
  const [filtersByResources, setFiltersByResources] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (CompanyConfig) {
      if (CompanyConfig.Procedures) {
        setFiltersByProcedures(CompanyConfig.Procedures.map((_, index) => index));
      }
      if (CompanyConfig.Resources) {
        setFiltersByResources(CompanyConfig.Resources.map((_, index) => index));
      }
    }
  }, [CompanyConfig]);

  function LoadData() {
    setIsUpdating(true);
    const startDate = getCurrentViewDate(_today, page, viewDaysCount).toISOString().slice(0, 10);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + viewDaysCount - 1);

    const startDateStr = startDate;
    const endDateStr = endDate.toISOString().slice(0, 10);

    console.log('Loading appointments:', { startDateStr, endDateStr });

    GetAppointments(startDateStr, endDateStr, (result) => {
      console.log('Loaded appointments:', result);
      setAllAppointments(result);
    });
  }

  useEffect(() => {
    setFilteredAppointments(allAppointments);
  }, [allAppointments]);

  useEffect(() => {
    const delayLoading = setTimeout(() => setIsUpdating(false), 100);
    return () => clearTimeout(delayLoading);
  }, [filteredAppointments]);

  useEffect(() => {
    LoadData();
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    LoadData();
  }, [viewDaysCount, page]);

  function handleResize() {
    const isSmallScreen = window.innerWidth < AppConfig.MinScreenSizeX;
    setIsMobile(isSmallScreen);

    if (window.innerWidth <= 1140) {
      setIsFiltersExpanded(true);
    }
  }

  useEffect(() => {
    let result = [...allAppointments];

    // Filter By Status
    result = result.filter((item) => filtersByState.includes(usingStatesSource.indexOf(item.status)));

    // Filter by Procedure
    let proceduresIds = filtersByProcedures
      .filter(index => CompanyConfig?.Procedures?.[index])
      .map(index => CompanyConfig.Procedures[index].Id);
    proceduresIds.push(null, "", "0");

    // Filter by Resource
    let resourcesIds = filtersByResources
      .filter(index => CompanyConfig?.Resources?.[index])
      .map(index => CompanyConfig.Resources[index].Id);
    resourcesIds.push(null, "", "0");

    result = result.filter((item) => {
      // Handle blocked time slots
      const isBlockedTime = item.procedure === "0" && item.resource === "0";
      if (isBlockedTime) return true;

      // Check if both procedure and resource are selected
      const isProcedureSelected = proceduresIds.includes(item.procedure);
      const isResourceSelected = resourcesIds.includes(item.resource);
      
      return isProcedureSelected && isResourceSelected;
    });

    console.log('Filtered appointments:', result);
    setFilteredAppointments(result);
  }, [filtersByState, filtersByProcedures, filtersByResources, allAppointments]);

  function GetCurrentDate() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  function getCurrentViewDate(today, page, viewDaysCount) {
    const currentDayOfWeek = today.getDay();
    const startDateOffset = page * viewDaysCount;

    if (viewDaysCount > 1) {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - currentDayOfWeek + (page * 7));
      startOfWeek.setHours(0, 0, 0, 0);
      return startOfWeek;
    } else {
      const newDate = new Date(today);
      newDate.setDate(today.getDate() + startDateOffset);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    }
  }

  function getDaysForCurrentView() {
    const days = [];
    const startDate = getCurrentViewDate(_today, page, viewDaysCount);
    
    for (let i = 0; i < viewDaysCount; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      
      if (filtersByDayOfWeek.includes(currentDate.getDay())) {
        days.push(new Date(currentDate));
      }
    }
    
    return days;
  }

  function OnPrevClickHandler() {
    setPage(page - 1);
  }

  function OnNextClickHandler() {
    setPage(page + 1);
  }

  function OnTodayClickHandler() {
    setPage(0);
  }

  function OnSelectViewModeHandler(value) {
    setViewDaysCount(value === 0 ? 1 : 7);
    setPage(0);
  }

  function onDateChangeByCalendarDatePickerHandler(selectedDate) {
    const selected = new Date(selectedDate);
    const todayCopy = new Date(_today);
    const dayDiff = Math.floor((selected - todayCopy) / (1000 * 60 * 60 * 24));
    const newPage = Math.floor(dayDiff / viewDaysCount);
    setPage(newPage);
  }

  function OnItemSelectHandler(appointmentData) {
    setCurrentAppointment(appointmentData);

    if (appointmentData.status === AppointmensState.Available) {
      setPopupId(0);
    } else if (appointmentData.status === AppointmensState.Blocked) {
      setPopupId(2);
    } else {
      setPopupId(1);
    }
  }

  function OnHidePopupHandler() {
    setCurrentAppointment(null);
    setPopupId(-1);
  }

  function GetMonthView() {
    const currentViewDay = getCurrentViewDate(_today, page, viewDaysCount);
    return currentViewDay.getMonth();
  }

  function CreateAppointmentHandler(data) {
    setCurrentAppointment(data);
    setPopupId(1);
  }

  function CreateBlockHandler(data) {
    setCurrentAppointment(data);
    setPopupId(2);
  }

  function CreateInvoiceFromAppointmentHandler(data) {
    setCurrentAppointment(data);
    setPopupId(3);
  }

  function ViewInvoiceFromAppointmentHandler(data) {
    setCurrentAppointment(data);
    setPopupId(3);
  }

  function GetPopup() {
    switch (popupId) {
      case 0:
        return <AvailableDatePopupContent 
          Data={currentAppointment} 
          CreateBlockCallback={CreateBlockHandler} 
          CreateAppointmentCallback={CreateAppointmentHandler} 
          OnHide={OnHidePopupHandler} 
        />;
      case 1:
        return <AppointmentPopupContent 
          Data={currentAppointment} 
          OnCreateInvoice={CreateInvoiceFromAppointmentHandler} 
          OnViewInvoice={ViewInvoiceFromAppointmentHandler} 
          OnChange={LoadData} 
          OnHide={OnHidePopupHandler} 
        />;
      case 2:
        return <BlockAppointmentPopupContent 
          Data={currentAppointment} 
          OnChange={LoadData} 
          OnHide={OnHidePopupHandler} 
        />;
      case 3:
        return <CreateInvoicePopup 
          Data={currentAppointment} 
          OnCreated={LoadData} 
          OnHide={OnHidePopupHandler} 
        />;
      default:
        return null;
    }
  }

  const dateLabel = `${CalendarConfig.Months[GetMonthView()]} de ${getCurrentViewDate(_today, page, viewDaysCount).getFullYear()}`;

  return (
    <div className='page-xcontent'>
      <div className={styles.mainContainer}>
        <div className={`${styles.filtersContainer} ${!isFiltersExpanded ? styles.filtersContainerCollapsed : ''}`}>
          <button
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            className={styles.toggleFiltersButton}
          >
            {isFiltersExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </button>
          {!isFiltersExpanded && (
            <div className={styles.collapsedIcons}>
              <button onClick={() => setIsFiltersExpanded(true)} className={styles.iconButton}>
                <CalendarMonthIcon className={styles.collapsedIcon} style={{ color: ColorPalette.main }} />
              </button>
              <button onClick={() => setIsFiltersExpanded(true)} className={styles.iconButton}>
                <FilterListIcon className={styles.collapsedIcon} style={{ color: ColorPalette.main }} />
              </button>
            </div>
          )}
          <div className={styles.filtersInnerContainer}>
            <CalendarDatePicker
              currentDate={getCurrentViewDate(_today, page, viewDaysCount)}
              viewDaysCount={viewDaysCount}
              onChange={onDateChangeByCalendarDatePickerHandler}
            />
            <div className={styles.filtersTitle}>Filtros</div>
            <FilterList
              id="status"
              label="Situação"
              filters={usingStatesSource.map((item) => AppointmensState.GetLabel(item))}
              onChange={setFiltersByState}
            />
            <FilterList
              id="procedures"
              label="Procedimentos"
              filters={CompanyConfig?.Procedures?.map((item) => item.Label) || []}
              onChange={setFiltersByProcedures}
            />
            <FilterList
              id="resources"
              label="Profissionais"
              filters={CompanyConfig?.Resources?.map((item) => item.Label) || []}
              onChange={setFiltersByResources}
            />
            <FilterList
              id="dayOfWeek"
              label="Dia da Semana"
              filters={CalendarConfig.DaysOfWeek}
              onChange={setFiltersByDayOfWeek}
            />
          </div>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.headerContainer}>
            <Header 
              Date={dateLabel} 
              isMobile={isMobile} 
              OnSelectViewMode={OnSelectViewModeHandler} 
              OnPrev={OnPrevClickHandler} 
              OnNext={OnNextClickHandler} 
              OnToday={OnTodayClickHandler} 
              isUpdating={isUpdating} 
            />
          </div>
          <div className={`${styles.calendarContainer} ${!isFiltersExpanded ? styles.calendarExpanded : ''}`}>
            <CalendarGrid
              days={getDaysForCurrentView()}
              appointments={filteredAppointments}
              startHour={CompanyConfig?.AvailableTime?.StartHour || 8}
              endHour={CompanyConfig?.AvailableTime?.EndHour || 20}
              timeInterval={CompanyConfig?.TimeInterval || 15}
              onAppointmentSelect={OnItemSelectHandler}
            />
          </div>
        </div>
      </div>
      {GetPopup()}
    </div>
  );
}

export default Appointments;
