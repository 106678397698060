import React, { useState } from 'react';
import styles from './Templates.module.css';
import TabBar from '../../components/tab-bar/TabBar';
import DocumentsTemplates from './DocumentsTemplates';
import MessagesTemplates from './MessagesTemplates';
import AnamnesisTemplates from './AnamnesisTemplates';

const Templates = () => {
    const areas = [
        { id: 0, label: "Documentos" },
        { id: 1, label: "Mensagens" },
        { id: 2, label: "Anamneses" }
    ];

    const [currentArea, setCurrentArea] = useState(areas[0].id);

    const handleAreaChange = (newValue) => {
        setCurrentArea(newValue);
    };

    const getArea = () => {
        switch (currentArea) {
            case 0:
                return <DocumentsTemplates />;
            case 1:
                return <MessagesTemplates />;
            case 2:
                return <AnamnesisTemplates />;
            default:
                return <div></div>;
        }
    };

    return (
        <div className='page-content'>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <TabBar 
                    labels={areas.map(item => item.label)} 
                    value={currentArea} 
                    onChange={handleAreaChange}
                />
                <div className={styles.containerArea}>
                    {getArea()}
                </div>
            </div>
        </div>
    );
};

export default Templates;