import React, { useState, useEffect } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Box
} from '@mui/material';
import PopupBase from '../base/PopupBase';
import CustomButton from '../../components/buttons/CustomButton';

const defaultQuestionData = {
    type: 'singleChoice',
    label: '',
    options: [''],
    mandatory: false,
    observation: false,
    tip: '',
    placeholder: '',
    helpText: ''
};

const questionTypes = {
    singleChoice: "Escolha Única",
    multipleChoice: "Múltipla Escolha",
    text: "Texto Curto",
    textArea: "Texto Longo",
    number: "Número",
    date: "Data"
};

const QuestionEditor = ({ open, onClose, onSave, initialData }) => {
    const [questionData, setQuestionData] = useState({...defaultQuestionData});

    useEffect(() => {
        // Only update state when popup is actually opened
        if (open && initialData) {
            setQuestionData(initialData);
        } else if (!open) {
            // Reset state when popup is closed
            setQuestionData({...defaultQuestionData});
        }
    }, [open, initialData]);

    const handleClose = () => {
        if (open) {
            setQuestionData({...defaultQuestionData});
            onClose();
        }
    };

    const handleTypeChange = (event) => {
        const newType = event.target.value;
        setQuestionData(prev => ({
            ...prev,
            type: newType,
            options: newType.includes('Choice') ? [''] : undefined
        }));
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...questionData.options];
        newOptions[index] = value;
        setQuestionData(prev => ({
            ...prev,
            options: newOptions
        }));
    };

    const addOption = () => {
        setQuestionData(prev => ({
            ...prev,
            options: [...prev.options, '']
        }));
    };

    const removeOption = (index) => {
        const newOptions = questionData.options.filter((_, i) => i !== index);
        setQuestionData(prev => ({
            ...prev,
            options: newOptions
        }));
    };

    const handleSave = () => {
        if (!questionData.label.trim()) {
            alert('Por favor, preencha a pergunta');
            return;
        }

        if (questionData.type.includes('Choice') && (!questionData.options || questionData.options.length === 0)) {
            alert('Por favor, adicione pelo menos uma opção de resposta');
            return;
        }

        if (open) {
            onSave(questionData);
            onClose();
            setQuestionData({...defaultQuestionData});
        }
    };

    const getContent = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
                label="Pergunta"
                fullWidth
                value={questionData.label}
                onChange={(e) => setQuestionData(prev => ({ ...prev, label: e.target.value }))}
            />

            <FormControl fullWidth>
                <InputLabel>Tipo de Pergunta</InputLabel>
                <Select
                    value={questionData.type}
                    onChange={handleTypeChange}
                    label="Tipo de Pergunta"
                >
                    {Object.entries(questionTypes).map(([value, label]) => (
                        <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {questionData.type.includes('Choice') && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {questionData.options.map((option, index) => (
                        <Box key={index} sx={{ display: 'flex', gap: 1 }}>
                            <TextField
                                fullWidth
                                label={`Opção ${index + 1}`}
                                value={option}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                            />
                            {questionData.options.length > 1 && (
                                <CustomButton
                                    variant="outlined"
                                    style="error"
                                    label="Remover"
                                    onClick={() => removeOption(index)}
                                />
                            )}
                        </Box>
                    ))}
                    <CustomButton
                        variant="outlined"
                        style="primary"
                        label="Adicionar Opção"
                        onClick={addOption}
                    />
                </Box>
            )}

            <TextField
                label="Dica para o profissional"
                fullWidth
                multiline
                rows={2}
                value={questionData.tip || ''}
                onChange={(e) => setQuestionData(prev => ({ ...prev, tip: e.target.value }))}
            />

            {!questionData.type.includes('Choice') && (
                <TextField
                    label="Placeholder"
                    fullWidth
                    value={questionData.placeholder || ''}
                    onChange={(e) => setQuestionData(prev => ({ ...prev, placeholder: e.target.value }))}
                    helperText="Texto de exemplo que aparece no campo antes do preenchimento"
                />
            )}

            <TextField
                label="Texto de ajuda"
                fullWidth
                value={questionData.helpText || ''}
                onChange={(e) => setQuestionData(prev => ({ ...prev, helpText: e.target.value }))}
                helperText="Texto explicativo que aparece abaixo da pergunta"
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={questionData.mandatory}
                        onChange={(e) => setQuestionData(prev => ({ ...prev, mandatory: e.target.checked }))}
                    />
                }
                label="Resposta Obrigatória"
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={questionData.observation}
                        onChange={(e) => setQuestionData(prev => ({ ...prev, observation: e.target.checked }))}
                    />
                }
                label="Permitir Observações"
            />
        </Box>
    );

    const getFooter = () => (
        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
            <CustomButton
                variant="outlined"
                style="secondary"
                label="Cancelar"
                onClick={handleClose}
            />
            <CustomButton
                variant="contained"
                style="primary"
                label="Salvar"
                onClick={handleSave}
            />
        </Box>
    );

    if (!open) return null;

    return (
        <PopupBase
            Title={initialData ? 'Editar Pergunta' : 'Nova Pergunta'}
            Content={getContent()}
            Footer={getFooter()}
            OnClose={handleClose}
        />
    );
};

export default QuestionEditor;