import React, { useEffect, useState, useRef } from 'react';
import { DataProviderInstance } from '../../../../api/DataProvider';
import styles from './RegistrationData.module.css';
import Loading from '../../../../components/loading/Loading.js';
import InputField from "../../../../components/inputfield/InputField";
import CustomButton from "../../../../components/buttons/CustomButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import ColorPallete from '../../../../colorpalette/ColorPalette';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import Button from '@mui/material/Button';
import RemotePaths from '../../../../consts/RemotePaths';
import { Badge, Tooltip, Typography } from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const RegistrationData = () => {
    const { CompanyConfig, GetCEPInfo, UpdateCompany } = DataProviderInstance();

    const getInitialLogoPath = () => {
        if (!CompanyConfig.Logo) {
            return RemotePaths.PATH_USER_UPLOADS_COMPANY_LOGO(CompanyConfig.Id) + "default.png";
        }
        return RemotePaths.PATH_USER_UPLOADS_COMPANY_LOGO(CompanyConfig.Id) + CompanyConfig.Logo;
    };

    const [editing, setEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [logoPath, setLogoPath] = useState(getInitialLogoPath());
    const [hasChanges, setHasChanges] = useState(false);
    const [changedFields, setChangedFields] = useState({});
    const [loadingAddress, setLoadingAddress] = useState(false);

    // Form refs
    const inputFieldNameRef = useRef(null);
    const inputFieldCNPJRef = useRef(null);
    const inputFieldCEPRef = useRef(null);
    const inputFieldAddressRef = useRef(null);
    const inputFieldNumberRef = useRef(null);
    const inputFieldComplementRef = useRef(null);
    const inputFieldDistrictRef = useRef(null);
    const inputFieldCityRef = useRef(null);
    const inputFieldStateRef = useRef(null);
    const inputFieldCountryRef = useRef(null);
    const inputFieldPhoneRef = useRef(null);
    const inputFieldEmailRef = useRef(null);
    const inputFieldFileRef = useRef(null);

    // Form state
    const [name, setName] = useState(CompanyConfig.Name || '');
    const [cnpj, setCnpj] = useState(CompanyConfig.CNPJ || '');
    const [cep, setCep] = useState(CompanyConfig.Cep || '');
    const [address, setAddress] = useState(CompanyConfig.Address || '');
    const [number, setNumber] = useState(CompanyConfig.Number || '');
    const [complement, setComplement] = useState(CompanyConfig.Complement || '');
    const [district, setDistrict] = useState(CompanyConfig.District || '');
    const [city, setCity] = useState(CompanyConfig.City || '');
    const [state, setState] = useState(CompanyConfig.State || '');
    const [country, setCountry] = useState(CompanyConfig.Country || '');
    const [phone, setPhone] = useState(CompanyConfig.Phone || '');
    const [email, setEmail] = useState(CompanyConfig.Email || '');

    useEffect(() => {
        const path = selectedFile ? URL.createObjectURL(selectedFile) : getInitialLogoPath();
        setLogoPath(path);
    }, [selectedFile]);

    const SectionHeader = ({ icon: Icon, title }) => (
        <div className={styles.sectionHeader}>
            <Icon className={styles.sectionIcon} />
            <Typography className={styles.sectionTitle}>{title}</Typography>
        </div>
    );

    const ActionButtons = () => (
        <div className={styles.actionButtons}>
            {editing ? (
                <>
                    <CustomButton
                        variant="contained"
                        label="Salvar"
                        style="primary"
                        onClick={saveClickHandler}
                        startIcon={<SaveIcon />}
                    />
                    <CustomButton
                        variant="outlined"
                        label="Cancelar"
                        style="primary"
                        onClick={cancelClickHandler}
                        startIcon={<CloseIcon />}
                    />
                </>
            ) : (
                <CustomButton
                    variant="contained"
                    label="Editar"
                    style="primary"
                    onClick={() => setEditing(true)}
                    startIcon={<EditIcon />}
                />
            )}
        </div>
    );

    function OnSearchCEPClickHandler() {
        let cep = inputFieldCEPRef.current.GetValue();
        if (cep.trim() !== "") {
            GetFullAdressByCEP(cep);
        }
    }

    function GetFullAdressByCEP(value) {
        setLoadingAddress(true);
        GetCEPInfo(value, (result) => {
            inputFieldAddressRef.current.SetValue(result.logradouro);
            inputFieldDistrictRef.current.SetValue(result.bairro);
            inputFieldCityRef.current.SetValue(result.localidade);
            inputFieldStateRef.current.SetValue(result.uf);
            inputFieldCountryRef.current.SetValue("Brasil");

            setAddress(result.logradouro);
            setDistrict(result.bairro);
            setCity(result.localidade);
            setState(result.uf);
            setLoadingAddress(false);
        });
    }

    function onUpdateValue(setter, field, originalValue) {
        return (value) => {
            setter(value);
            const hasChanged = value !== originalValue;
            setHasChanges(hasChanged);
            setChangedFields(prev => {
                const newFields = { ...prev };
                if (hasChanged) {
                    newFields[field] = true;
                } else {
                    delete newFields[field];
                }
                return newFields;
            });
        };
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setHasChanges(true);
    };

    const clearFile = () => {
        if (inputFieldFileRef.current) {
            inputFieldFileRef.current.value = '';
        }
        setSelectedFile(null);
    };

    const cancelClickHandler = () => {
        setName(CompanyConfig.Name);
        setCnpj(CompanyConfig.CNPJ);
        setCep(CompanyConfig.Cep);
        setAddress(CompanyConfig.Address);
        setNumber(CompanyConfig.Number);
        setComplement(CompanyConfig.Complement);
        setDistrict(CompanyConfig.District);
        setCity(CompanyConfig.City);
        setState(CompanyConfig.State);
        setCountry(CompanyConfig.Country);
        setPhone(CompanyConfig.Phone);
        setEmail(CompanyConfig.Email);
        setChangedFields({});
        setHasChanges(false);
        setEditing(false);
        clearFile();

        // Reset all input fields
        [
            { ref: inputFieldNameRef, value: CompanyConfig.Name },
            { ref: inputFieldCNPJRef, value: CompanyConfig.CNPJ },
            { ref: inputFieldCEPRef, value: CompanyConfig.Cep },
            { ref: inputFieldAddressRef, value: CompanyConfig.Address },
            { ref: inputFieldNumberRef, value: CompanyConfig.Number },
            { ref: inputFieldComplementRef, value: CompanyConfig.Complement },
            { ref: inputFieldDistrictRef, value: CompanyConfig.District },
            { ref: inputFieldCityRef, value: CompanyConfig.City },
            { ref: inputFieldStateRef, value: CompanyConfig.State },
            { ref: inputFieldCountryRef, value: CompanyConfig.Country },
            { ref: inputFieldPhoneRef, value: CompanyConfig.Phone },
            { ref: inputFieldEmailRef, value: CompanyConfig.Email }
        ].forEach(({ ref, value }) => ref.current.SetValue(value));
    };

    const saveClickHandler = () => {
        setEditing(false);
        const formData = {
            logo: selectedFile,
            name,
            cnpj,
            cep,
            address,
            number,
            complement,
            district,
            city,
            state,
            country,
            phone,
            email
        };

        UpdateCompany(formData, (result) => {
            if (result) {
                setChangedFields({});
                setHasChanges(false);
                clearFile();
            }
        });
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.titleContainer}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <AreaTitle Title="Dados cadastrais" />
                    {Object.keys(changedFields).length > 0 && (
                        <Tooltip title="Dados alterados" arrow>
                            <Badge badgeContent={Object.keys(changedFields).length} color="error" />
                        </Tooltip>
                    )}
                </div>
                <ActionButtons />
            </div>

            {/* Company Information Section */}
            <div className={styles.section}>
                <SectionHeader icon={BusinessIcon} title="Informações da Empresa" />
                <div className={styles.logoContainer}>
                    <img
                        src={logoPath}
                        alt="Logo"
                        className={styles.logo}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        disabled={!editing}
                    >
                        Carregar arquivo
                        <input
                            ref={inputFieldFileRef}
                            hidden
                            type="file"
                            accept=".png,.jpg,.jpeg,.svg"
                            onChange={handleFileChange}
                        />
                    </Button>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.formRow}>
                        <InputField
                            Mandatory={false}
                            Value={name}
                            Label="Nome"
                            ref={inputFieldNameRef}
                            OnUpdateValue={onUpdateValue(setName, 'name', CompanyConfig.Name)}
                            className={changedFields.name ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                        <InputField
                            Mandatory={false}
                            Value={cnpj}
                            Label="CNPJ"
                            ref={inputFieldCNPJRef}
                            Mask="99.999.999/9999-99"
                            OnUpdateValue={onUpdateValue(setCnpj, 'cnpj', CompanyConfig.CNPJ)}
                            className={changedFields.cnpj ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                    </div>
                </div>
            </div>

            {/* Address Section */}
            <div className={styles.section}>
                <SectionHeader icon={LocationOnIcon} title="Endereço" />
                <div className={styles.formGroup}>
                    <div className={styles.formRow}>
                        <div style={{ display: "flex", gap: "8px", alignItems: "flex-start" }}>
                            <InputField
                                Mandatory={false}
                                Value={cep}
                                Label="CEP"
                                ref={inputFieldCEPRef}
                                Mask="99999-999"
                                OnUpdateValue={onUpdateValue(setCep, 'cep', CompanyConfig.Cep)}
                                className={changedFields.cep ? styles.changedField : ''}
                                Disabled={!editing}
                            />
                            <CustomButton
                                style="secondary"
                                variant="outlined"
                                icon={<FontAwesomeIcon icon={faMagnifyingGlass} color={ColorPallete.secondary} style={{ fontSize: '16px' }} />}
                                onClick={OnSearchCEPClickHandler}
                                enabled={editing}
                            />
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <InputField
                            Mandatory={false}
                            Value={address}
                            Label="Rua"
                            ref={inputFieldAddressRef}
                            OnUpdateValue={onUpdateValue(setAddress, 'address', CompanyConfig.Address)}
                            className={changedFields.address ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                        <InputField
                            Mandatory={false}
                            Value={number}
                            Label="Número"
                            ref={inputFieldNumberRef}
                            OnUpdateValue={onUpdateValue(setNumber, 'number', CompanyConfig.Number)}
                            className={changedFields.number ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                        <InputField
                            Mandatory={false}
                            Value={complement}
                            Label="Complemento"
                            ref={inputFieldComplementRef}
                            OnUpdateValue={onUpdateValue(setComplement, 'complement', CompanyConfig.Complement)}
                            className={changedFields.complement ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                    </div>
                    <div className={styles.formRow}>
                        <InputField
                            Mandatory={false}
                            Value={district}
                            Label="Bairro"
                            ref={inputFieldDistrictRef}
                            OnUpdateValue={onUpdateValue(setDistrict, 'district', CompanyConfig.District)}
                            className={changedFields.district ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                        <InputField
                            Mandatory={false}
                            Value={city}
                            Label="Cidade"
                            ref={inputFieldCityRef}
                            OnUpdateValue={onUpdateValue(setCity, 'city', CompanyConfig.City)}
                            className={changedFields.city ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                        <InputField
                            Mandatory={false}
                            Value={state}
                            Label="Estado"
                            ref={inputFieldStateRef}
                            OnUpdateValue={onUpdateValue(setState, 'state', CompanyConfig.State)}
                            className={changedFields.state ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                        <InputField
                            Mandatory={false}
                            Value={country}
                            Label="País"
                            ref={inputFieldCountryRef}
                            OnUpdateValue={onUpdateValue(setCountry, 'country', CompanyConfig.Country)}
                            className={changedFields.country ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                    </div>
                </div>
            </div>

            {/* Contact Section */}
            <div className={styles.section}>
                <SectionHeader icon={ContactPhoneIcon} title="Contatos" />
                <div className={styles.formGroup}>
                    <div className={styles.formRow}>
                        <InputField
                            Mandatory={false}
                            Value={phone}
                            Label="Telefone"
                            ref={inputFieldPhoneRef}
                            Mask="(99) 99999-9999"
                            OnUpdateValue={onUpdateValue(setPhone, 'phone', CompanyConfig.Phone)}
                            className={changedFields.phone ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                        <InputField
                            Mandatory={false}
                            Value={email}
                            Label="Email"
                            ref={inputFieldEmailRef}
                            OnUpdateValue={onUpdateValue(setEmail, 'email', CompanyConfig.Email)}
                            className={changedFields.email ? styles.changedField : ''}
                            Disabled={!editing}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationData;