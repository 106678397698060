import html2pdf from 'html2pdf.js';
import * as XLSX from 'xlsx';

const CreatePDF = (content, fileName) => {
    html2pdf().set({
        margin: 0,
        image: { type: 'jpeg', quality: 1 }, // Use JPEG and reduce the quality slightly
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: 'avoid-all' }, // Make sure to avoid awkward page breaks
        html2canvas: { 
            scale: 1.5, // A good balance between quality and file size
            useCORS: true,
            logging: false, // Disable logging for performance
            letterRendering: true // Improves text rendering
        } 
    }).from(content).save(fileName);
}


const ExportToExcel = (data, title, fileName) => {
  // Cria uma planilha a partir dos dados
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Cria um livro de trabalho e adiciona a planilha
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, title);

  // Converte o livro de trabalho para arquivo Excel e baixa
  XLSX.writeFile(workbook, fileName+".xlsx");
}


const GetCurrentAge = (birthday) => {

    const today = new Date();
    const years = today.getFullYear() - birthday.getFullYear();
    const months = today.getMonth() - birthday.getMonth();

    if (months < 0 || (months === 0 && today.getDate() < birthday.getDate())) {
        return years - 1;
    }

    return years;
}

const GetFriendlyCPF = (value) => {
    let cpf = value.toString().replace(/[.,]/g, "");
    if(cpf.length === 0)
    {
        return "";
    }
    if (cpf.length < 11) {
        cpf = "0".repeat(11 - cpf.length) + cpf;
    }
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

const ValidateCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }
    if (remainder !== parseInt(cpf.charAt(9))) {
        return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }

    remainder = 11 - (sum % 11);
    if (remainder === 10 || remainder === 11) {
        remainder = 0;
    }
    if (remainder !== parseInt(cpf.charAt(10))) {
        return false;
    }

    return true;
}

const GetFriendlyPhone = (value) => {
    // Remove todos os caracteres não numéricos
    const phone = value.toString().replace(/\D/g, '');
    
    if (phone.length === 0) {
        return "";
    }

    // Extrai o DDI (primeiros 2 dígitos) e o resto do número
    const ddi = phone.slice(0, 2);
    const rest = phone.slice(2);

    // Extrai o DDD (próximos 2 dígitos) e o número local
    const ddd = rest.slice(0, 2);
    const number = rest.slice(2);

    // Formata o número local (4 dígitos - 4 dígitos)
    const formattedNumber = number.replace(/(\d{4})(\d{4})/, "$1-$2");

    // Retorna o número completo formatado
    return `+${ddi} (${ddd}) ${formattedNumber}`;
}

export {
    CreatePDF,
    ExportToExcel,
    GetFriendlyCPF,
    ValidateCPF,
    GetCurrentAge,
    GetFriendlyPhone,
};
