import React, { useEffect, useState, useRef } from 'react';
import styles from './Login.module.css'
import InputField from '../../components/inputfield/InputField';
import { LoginAPI } from '../../api/API';
import CustomButton from '../../components/buttons/CustomButton';
import LogoImage from '../../assets/images/logo-full.png';
import Loading from '../../components/loading/Loading';
import VisibilityOnIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';

function Login({ onSuccess }) {

  const inputFieldEmailRef = useRef(null);
  const inputFieldPasswordRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  function ValidateForm() {
    return inputFieldEmailRef.current.Validate() && inputFieldPasswordRef.current.Validate()
  }

  const doLogin = (email, password, isAutoLogin = false) => {
    if (isLoggingIn) return; // Prevent multiple login attempts
    
    setIsLoggingIn(true);
    setLoading(true);
    
    if (!isAutoLogin) {
      window.visualLogger?.log('Iniciando login...');
    }

    LoginAPI(email, password, (result) => {
      setIsLoggingIn(false);
      
      if (result.error) {
        window.visualLogger?.error(result.error);
        setLoading(false);
        return;
      }
      
      if (!isAutoLogin) {
        window.visualLogger?.log('Login realizado com sucesso');
      }
      saveCredentials(email, password);
      onSuccess(result);
    });
  }

  const handleLoginClickButton = () => {
    if (ValidateForm()) {
      let email = inputFieldEmailRef.current.GetValue();
      let password = inputFieldPasswordRef.current.GetValue();
      doLogin(email, password, false);
    }
  };

  const saveCredentials = (email, password) => {
    localStorage.setItem('email', email);
    localStorage.setItem('password', password);
  }

  const getCredentials = () => {
    let email = localStorage.getItem('email');
    let password = localStorage.getItem('password');
    return { email, password };
  }

  const checkCredentials = () => {
    let credentials = getCredentials();
    if (credentials.email && credentials.password) {
      doLogin(credentials.email, credentials.password, true);
    }
  }

  useEffect(() => {
    checkCredentials();
    return () => {
      setIsLoggingIn(false); // Cleanup on unmount
    };
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='page-content'>
      {loading ? <Loading /> :
        <div className={styles.container}>
          <img className={styles.logo} src={LogoImage} alt="Logo" />
          <div className={styles.containerLogin}>
            <h2>Login</h2>
            <div className={styles.containerInputs}>
              <InputField Width={"100%"} Label={"Email"} type="email" placeholder="Email" ref={inputFieldEmailRef} />
              <div className={styles.containerPassword}>
                <InputField Width={"100%"} Label={"Senha"} type={showPassword ? "text" : "password"} placeholder="Password" ref={inputFieldPasswordRef} />
                {!showPassword ? <VisibilityOnIcon className={styles.buttonVisibility} onClick={handleTogglePasswordVisibility} />
                  : <VisibilityOffIcon className={styles.buttonVisibility} onClick={handleTogglePasswordVisibility} />
                }
              </div>
            </div>
            <CustomButton variant="contained" style="primary" label={"Entrar"} onClick={handleLoginClickButton} />
          </div>
        </div>
      }
    </div>
  );
}

export default Login;
