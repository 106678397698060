import React, { useEffect, useState, useRef } from 'react';
import { DataProviderInstance } from '../../../../api/DataProvider';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import CustomButton from '../../../../components/buttons/CustomButton';
import styles from './Leads.module.css';
import { ExportToExcel } from '../../../../utils/Utils';
import LeadsConfig from '../../../../consts/LeadsConfig';

import DateRangeHeader from '../../../../components/dateRangeHeader/DateRangeHeader';
import FilterList from '../../filters/FilterList';
import Loading from '../../../../components/loading/Loading';

function Leads() {

  const
    {
      CompanyConfig,
      GetLeads,
    } = DataProviderInstance()


  const maxItemsPerPage = 25000000;
  const [loaded, setLoaded] = useState(false);
  const [leads, setLeads] = useState([]);
  const [filtered, setFiltered] = React.useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentLead, setCurrentLead] = useState(null);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [needsReload, setNeedsReload] = React.useState(false);
  const filterRef = useRef(null);

  const usingStatesSource = ['sim', 'não'];
  const [filtersByState, setFiltersByState] = useState(usingStatesSource.map((item, index) => index));
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setNeedsReload(true);
  }, []);

  useEffect(() => {
    if (needsReload) {
      setLoaded(false);
      LoadData();
    }
  }, [needsReload]);

  const LoadData = () => {
    GetLeads((result) => {
      setLeads(result.data);
      setLoaded(true);
      setNeedsReload(false);
    });
  }

  useEffect(() => {
    const parseDate = (dateString) => {
      if (!dateString) return null;

      // Divida a string no formato "YYYY-MM-DD"
      const [year, month, day] = dateString.split("-").map(Number);

      // Crie a data localmente
      return new Date(year, month - 1, day); // `month - 1` porque o mês começa em 0
    };

    const startDateParsed = parseDate(startDate);
    if (startDateParsed) {
      startDateParsed.setHours(0, 0, 0, 0);
    }

    const endDateParsed = parseDate(endDate);
    if (endDateParsed) {
      endDateParsed.setHours(23, 59, 59, 999);
    }

    const filtered = leads.filter((lead) => {
      const leadDate = new Date(lead.createdAt);

      return (
        (!startDateParsed || leadDate >= startDateParsed) &&
        (!endDateParsed || leadDate <= endDateParsed)
      );
    });

    setFiltered(filtered);
  }, [startDate, endDate, leads]);

  const filtersStates = usingStatesSource;

  const getList = () => {
    const list = [];
    let start = currentPage * maxItemsPerPage;
    let end = Math.min(start + maxItemsPerPage, filtered.length);

    for (let i = start; i < end; i++) {
      let leadData = filtered[i];
      let { createdAt, name, phone, channel, result, campaignId, observations } = leadData;

      let date = new Date(createdAt);
      date = date.toLocaleDateString() + " " + date.toLocaleTimeString();

      let campaign = CompanyConfig.LeadsCampaigns.find(x => x.id == campaignId + "");
      let sourceLabel = CompanyConfig.LeadsSources.find(x => x.id == campaign.sourceId + "")?.name;

      if (leadData.valid == "0") continue;

      list.push(
        <tr key={i}>
          <td>{date}</td>
          <td>{result}</td>
          <td>{name}</td>
          <td>{phone}</td>
          <td>{channel}</td>
          <td>{sourceLabel}</td>
          <td>{campaign.name}</td>
          <td>{observations}</td>
        </tr>
      );
    }

    return (
      <div className={styles.containerTable}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Data</th>
              <th>Status</th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Canal</th>
              <th>Origem</th>
              <th>Campanha</th>
              <th>Obs</th>
            </tr>
          </thead>
          <tbody>
            {list}
          </tbody>
        </table>
      </div>
    );
  }

  function onExportExcelClickHandler() {
    let data = [...filtered];

    data = data.map(({ id, companyId, phone, name, sourceId, campaignId, updatedAt, updatedBy
      , ...rest }) => {
      let campaign = CompanyConfig.LeadsCampaigns.find(x => x.id == campaignId + "");
      let campaignName = campaign.name;
      let source = CompanyConfig.LeadsSources.find(x => x.id == campaign.sourceId + "")?.name;
      return { ...rest, source, campaignName };
    });

    ExportToExcel(data, "Leads", "leads-" + startDate + "-" + endDate);
  }

  const handleDateChange = (initialDate, endDate) => {
    setStartDate(initialDate);
    setEndDate(endDate);
  }

  function formatDate(date) {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  function onFilterByAppointmentsStatusChangeHandler() {

  }

  return (
    <div className={styles.mainContainer}>
      <AreaTitle Title="Leads" />
      <DateRangeHeader onChange={handleDateChange} />
     {/* <FilterList ref={filterRef} id="reportsAppointments" label="Filtros" filters={filtersStates} onChange={onFilterByAppointmentsStatusChangeHandler} initOpened={true} />*/}
      {isLoading && <Loading />}
      <div className={styles.containerResume}>
        <AreaTitle Title="Resultado" Description={`<b>${filtered.length} lead(s)</b> encontrado(s) no período de <b>${formatDate(startDate)} à ${formatDate(endDate)}</b>.`} />
        <CustomButton
            variant="contained"
            style="primary"
            label={"Exportar para Excel"}
            onClick={onExportExcelClickHandler}
          />
      </div>

      {getList()}

    </div>
  );
}

export default Leads;