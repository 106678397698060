import React, { useState, useEffect } from 'react';
import { Alert, Divider } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CustomButton from '../buttons/CustomButton';
import styles from './CompanyDataExport.module.css';
import { DataProviderInstance } from '../../api/DataProvider';
import ExportHistory from './ExportHistory';

const CompanyDataExport = ({ companyId }) => {
    const { CreateExportJob, GetExportHistory } = DataProviderInstance();
    const [error, setError] = useState(null);
    const [history, setHistory] = useState([]);

    // Load initial history
    useEffect(() => {
        GetExportHistory((data) => {
            if (!data.error) {
                setHistory(data.history || []);
            }
        });
    }, [GetExportHistory, companyId]);

    const startExport = async () => {
        try {
            setError(null);
            // Generate temporary ID
            const tempId = 'temp-' + new Date().getTime();
            
            // Add pending export to history immediately
            setHistory(prev => [{
                id: tempId,
                status: 'pending',
                progress: 0,
                created_at: new Date().toISOString()
            }, ...prev]);

            // Make API call
            CreateExportJob((data) => {
                if (data.error) {
                    // Remove temporary entry on error
                    setHistory(prev => prev.filter(item => item.id !== tempId));
                    throw new Error(data.error);
                }
                // Update history with real exportId
                setHistory(prev => prev.map(item =>
                    item.id === tempId ? {
                        ...item,
                        id: data.exportId
                    } : item
                ));
            });
        } catch (err) {
            setError(err.message);
        }
    };

    const hasActiveExport = history.some(item => 
        ['pending', 'processing'].includes(item.status)
    );

    const exportButton = error ? (
        <Alert severity="error" className={styles.alert}>
            {error}
            <CustomButton
                variant="contained"
                style="secondary"
                label="Tentar Novamente"
                onClick={startExport}
                size="small"
                sx={{ marginTop: 1 }}
                enabled={!hasActiveExport}
            />
        </Alert>
    ) : (
        <CustomButton
            variant="contained"
            style="primary"
            label="Exportar Dados"
            startIcon={<CloudDownloadIcon />}
            onClick={startExport}
            enabled={!hasActiveExport}
            sx={{
                '&.Mui-disabled': {
                    backgroundColor: '#e0e0e0',
                    color: '#9e9e9e'
                }
            }}
        />
    );

    const content = (
        <div className={styles.container}>
            <ExportHistory 
                companyId={companyId}
                onHistoryUpdate={setHistory}
            />
        </div>
    );

    return {
        button: exportButton,
        content: content
    };
};

export default CompanyDataExport;