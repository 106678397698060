import React, { useState, useEffect } from 'react';
import styles from './WalletSection.module.css';
import { Typography, Button } from '@mui/material';
import { DataProviderInstance } from '../../../../../../api/DataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faCreditCard, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import PackagePurchaseFlow from '../../../../../../components/package-purchase/PackagePurchaseFlow';

const WalletSection = ({ patientId }) => {
  const { CompanyConfig, GetPatientWallet, GetWalletTransactions } = DataProviderInstance();

  const [wallet, setWallet] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPackages, setOpenPackages] = useState(false);

  useEffect(() => {
    fetchWalletData();
  }, [patientId]);

  const fetchWalletData = () => {
    GetPatientWallet(patientId, (response) => {
      if (response.success) {
        setWallet(response.data);
        
        const startDate = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0];
        const endDate = new Date().toISOString().split('T')[0];
        
        GetWalletTransactions(response.data.wallet_id, startDate, endDate, (transResponse) => {
          if (transResponse.success) {
            setTransactions(transResponse.data.transactions || []);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  };

  const handlePurchaseComplete = () => {
    fetchWalletData();
  };

  const getTransactionTypeColor = (type) => {
    switch (type) {
      case 'ADD':
        return 'success';
      case 'DEDUCT':
        return 'error';
      case 'EXPIRE':
        return 'warning';
      case 'TRANSFER':
        return 'info';
      default:
        return 'default';
    }
  };

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <div className={styles.containerAppointmentsList}>
      <div className={styles.titleContainer}>
        <h3>Carteira</h3>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenPackages(true)}
        >
          Comprar Pacote
        </Button>
      </div>

      <PackagePurchaseFlow
        companyConfig={CompanyConfig}
        walletId={wallet?.wallet_id}
        onPurchaseComplete={handlePurchaseComplete}
        open={openPackages}
        onClose={() => setOpenPackages(false)}
      />

      <div className={styles.walletContent}>
        {/* Saldo e Informações */}
        <div className={styles.statsContainer}>
          <div className={styles.container}>
            <div className={styles.label}>Saldo em Carteira</div>
            <div className={styles.containerValues}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faWallet} />
              </div>
              <div className={styles.value}>
                R$ {(wallet?.monetary_balance || 0).toFixed(2)}
              </div>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.label}>Total de Créditos</div>
            <div className={styles.containerValues}>
              <div className={styles.icon}>
                <FontAwesomeIcon icon={faCreditCard} />
              </div>
              <div className={styles.value}>
                {wallet?.service_credits?.reduce((total, credit) => total + credit.credits_remaining, 0) || 0}
              </div>
            </div>
          </div>

          {wallet?.service_credits?.length > 0 && wallet.service_credits.some(credit => credit.expiration_date && credit.expiration_date !== "0000-00-00") && (
            <div className={styles.container}>
              <div className={styles.label}>Próximo Vencimento</div>
              <div className={styles.containerValues}>
                <div className={styles.icon}>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
                <div className={styles.value}>
                  {new Date(Math.min(...wallet.service_credits
                    .filter(credit => credit.expiration_date && credit.expiration_date !== "0000-00-00")
                    .map(credit => new Date(credit.expiration_date)))).toLocaleDateString()}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Lista de Créditos */}
        <div className={styles.creditsList}>
          <h4>Créditos de Serviços</h4>
          <div className={styles.creditsContainer}>
            {(() => {
              // Agrupa os créditos por procedimento e data de expiração
              const groupedCredits = (wallet?.service_credits || []).reduce((acc, credit) => {
                const key = `${credit.procedure_id}_${credit.expiration_date || 'no_expiration'}`;
                if (!acc[key]) {
                  acc[key] = {
                    procedure_id: credit.procedure_id,
                    procedure_name: credit.procedure_name,
                    credits_remaining: 0,
                    expiration_date: credit.expiration_date
                  };
                }
                acc[key].credits_remaining += credit.credits_remaining;
                return acc;
              }, {});

              // Converte o objeto agrupado em array e ordena
              return Object.values(groupedCredits)
                .sort((a, b) => {
                  // Primeiro ordena por procedimento
                  if (a.procedure_name < b.procedure_name) return -1;
                  if (a.procedure_name > b.procedure_name) return 1;
                  
                  // Depois por data de expiração
                  if (!a.expiration_date || a.expiration_date === "0000-00-00") return 1;
                  if (!b.expiration_date || b.expiration_date === "0000-00-00") return -1;
                  return new Date(a.expiration_date) - new Date(b.expiration_date);
                })
                .map((credit) => {
                  const hasExpiration = credit.expiration_date && credit.expiration_date !== "0000-00-00";
                  const expirationDate = hasExpiration ? new Date(credit.expiration_date) : null;
                  const daysUntilExpiration = expirationDate ?
                    Math.ceil((expirationDate - new Date()) / (1000 * 60 * 60 * 24)) : null;
                  const isNearExpiration = hasExpiration && daysUntilExpiration <= 5 && daysUntilExpiration > 0;
                  
                  return (
                    <div
                      key={`${credit.procedure_id}_${credit.expiration_date || 'no_expiration'}`}
                      className={`${styles.creditItem} ${isNearExpiration ? styles.expiringCredit : ''}`}
                    >
                      <span className={styles.creditBadge}>
                        {credit.credits_remaining}x
                      </span>
                      <span className={styles.creditName}>
                        {credit.procedure_name}
                      </span>
                      <span className={styles.creditDate}>
                        {!hasExpiration ? 'Não expira' :
                          isNearExpiration ?
                            `Expira em ${daysUntilExpiration} dias` :
                            `Válido até ${expirationDate.toLocaleDateString()}`
                        }
                      </span>
                    </div>
                  );
                });
            })()}
          </div>
        </div>

        {/* Histórico de Transações */}
        <div className={styles.transactionsList}>
          <h4>Histórico de Transações</h4>
          <div className={styles.transactionsContainer}>
            {(transactions || []).map((transaction) => (
              <div key={transaction.transaction_id} className={styles.transactionItem}>
                <span className={`${styles.transactionBadge} ${transaction.type === 'ADD' ? styles.positive : styles.negative}`}>
                  {transaction.type === 'ADD' ? '+' : '-'}1x
                </span>
                <span className={styles.transactionDescription}>
                  {transaction.description}
                </span>
                <span className={styles.transactionDate}>
                  {new Date(transaction.created_at).toLocaleDateString()}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletSection;