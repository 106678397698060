import React, { useState, useEffect } from 'react';
import styles from './PackagePurchaseFlow.module.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material';
import { DataProviderInstance } from '../../api/DataProvider';

const PackagePurchaseFlow = ({ 
  onPurchaseComplete,
  companyConfig,
  walletId = null, // opcional, se não fornecido, será apenas visualização
  open = false,
  onClose,
}) => {
  const { GetServicePackages, PurchaseServicePackage } = DataProviderInstance();

  const [packages, setPackages] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(companyConfig.PaymentOptions[0]?.Id);
  const [purchaseConfirmationOpen, setPurchaseConfirmationOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    if (open) {
      GetServicePackages((response) => {
        if (response.success) {
          setPackages(response.data || []);
        }
      });
    }
  }, [open]);

  const handlePurchaseClick = (packageData) => {
    setSelectedPackage(packageData);
    setPurchaseConfirmationOpen(true);
  };

  const handleConfirmPurchase = () => {
    if (!selectedPackage || !walletId) return;

    PurchaseServicePackage(
      walletId,
      selectedPackage.package_id,
      selectedPaymentMethod,
      'PENDING',
      (response) => {
        if (response.success) {
          setPurchaseConfirmationOpen(false);
          if (onPurchaseComplete) {
            onPurchaseComplete(response);
          }
          if (onClose) {
            onClose();
          }
        }
      }
    );
  };

  const renderPackagesList = () => (
    <Grid container spacing={2} style={{ marginTop: 8 }}>
      {(packages || [])
        .filter(pkg => pkg.active)
        .sort((a, b) => a.name.localeCompare(b.name, 'pt-BR'))
        .map((pkg) => (
        <Grid item xs={12} sm={6} md={4} key={pkg.package_id}>
          <div className={styles.packageCard}>
            <div className={styles.packageHeader}>
              <div>
                <h4>{pkg.name}</h4>
                <span className={`${styles.packageStatus} ${pkg.active ? styles.active : ''}`}>
                  {pkg.active ? "Ativo" : "Inativo"}
                </span>
              </div>
              <span className={styles.packagePrice}>R$ {pkg.price.toFixed(2)}</span>
            </div>

            <p className={styles.packageDescription}>{pkg.description}</p>

            <div className={styles.packageDetails}>
              <div>
                <span className={styles.detailLabel}>Procedimentos</span>
                <div className={styles.proceduresList}>
                  {(pkg.procedures || []).map((proc, index) => (
                    <div key={index} className={styles.procedureItem}>
                      <span>{companyConfig.Procedures.find(p => p.Id === proc.procedure_id)?.Label || 'Procedimento não encontrado'}</span>
                      <span>{proc.quantity}x</span>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <span className={styles.detailLabel}>Validade</span>
                <span className={styles.detailValue}>{pkg.validity_days} dias</span>
              </div>
            </div>

            {pkg.active && walletId && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={styles.packageButton}
                onClick={() => handlePurchaseClick(pkg)}
              >
                Comprar
              </Button>
            )}
          </div>
        </Grid>
      ))}
    </Grid>
  );

  const renderPurchaseConfirmation = () => (
    selectedPackage && (
      <>
        <Typography variant="h6" gutterBottom>
          {selectedPackage.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Valor: R$ {selectedPackage.price.toFixed(2)}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Procedimentos incluídos:
        </Typography>
        {(selectedPackage.procedures || []).map((proc, index) => (
          <Typography key={index} variant="body2" color="textSecondary" style={{ marginLeft: 16 }}>
            • {companyConfig.Procedures.find(p => p.Id === proc.procedure_id)?.Label || 'Procedimento não encontrado'} ({proc.quantity}x)
          </Typography>
        ))}
        <Typography variant="body2" color="textSecondary" gutterBottom style={{ marginTop: 8 }}>
          Validade: {selectedPackage.validity_days} dias
        </Typography>
        
        <FormControl component="fieldset" style={{ marginTop: 16 }}>
          <FormLabel component="legend">Forma de Pagamento</FormLabel>
          <RadioGroup
            value={selectedPaymentMethod}
            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
          >
            {companyConfig.PaymentOptions.map((option) => (
              <FormControlLabel
                key={option.Id}
                value={option.Id}
                control={<Radio />}
                label={option.Label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    )
  );

  return (
    <>
      {/* Dialog de Pacotes */}
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Pacotes Disponíveis</DialogTitle>
        <DialogContent>
          {renderPackagesList()}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de Confirmação de Compra */}
      <Dialog
        open={purchaseConfirmationOpen}
        onClose={() => setPurchaseConfirmationOpen(false)}
      >
        <DialogTitle>Confirmar Compra</DialogTitle>
        <DialogContent>
          {renderPurchaseConfirmation()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPurchaseConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleConfirmPurchase} variant="contained" color="primary">
            Confirmar Compra
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PackagePurchaseFlow;