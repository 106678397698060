import React from 'react';
import { Box } from '@mui/material';
import ProcedureList from './ProcedureList';

const ProceduresPage = () => {
    return (
        <Box sx={{ py: 3 }}>
            <ProcedureList />
        </Box>
    );
};

export default ProceduresPage;