import React, { useState } from "react";
import PopupBase from "../../../../../popup/base/PopupBase";
import CustomButton from "../../../../../components/buttons/CustomButton";
import { TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch } from "@mui/material";

export function CreateEditCategoryPopup(props) {
  const { category, onClose, onSave } = props;

  const initialData = {
    id: category?.id || null,
    name: category?.name || "",
    type: category?.type || "income",
    isActive: category?.isActive ?? true
  };

  const [categoryData, setCategoryData] = useState(initialData);

  const handleChange = (field, value) => {
    setCategoryData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = () => {
    if (!categoryData.name.trim()) {
      return;
    }

    onSave(categoryData);
  };

  const getPositiveButton = () => (
    <CustomButton
      variant="contained"
      style="primary"
      label={category ? "Salvar Alterações" : "Adicionar"}
      onClick={handleSave}
    />
  );

  const getContent = () => (
    <div style={{ marginTop: "10px" }}>
      <div className="form">
        <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          {/* Nome */}
          <div className="form-group">
            <TextField
              fullWidth
              label="Nome"
              value={categoryData.name}
              onChange={(e) => handleChange("name", e.target.value)}
              variant="outlined"
            />
          </div>

          {/* Tipo */}
          <div className="form-group">
            <FormControl fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Select
                value={categoryData.type}
                onChange={(e) => handleChange("type", e.target.value)}
                disabled={!!category} // Não permite alterar o tipo ao editar
              >
                <MenuItem value="income">Receita</MenuItem>
                <MenuItem value="expense">Despesa</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Status */}
          {category && (
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={categoryData.isActive}
                    onChange={(e) => handleChange("isActive", e.target.checked)}
                  />
                }
                label={categoryData.isActive ? "Ativo" : "Inativo"}
              />
            </FormControl>
          )}
        </div>
      </div>
    </div>
  );

  const getTitle = () => (category ? "Editar Categoria" : "Nova Categoria");

  return (
    <PopupBase
      Title={getTitle()}
      Content={getContent()}
      Footer={getPositiveButton()}
      OnClose={onClose}
    />
  );
}