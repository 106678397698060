import React, { useState, useEffect } from 'react';
import styles from './Categories.module.css';
import { DataProviderInstance } from '../../../../api/DataProvider';
import AreaTitle from '../../../../components/areaTitle/AreaTitle';
import CustomButton from '../../../../components/buttons/CustomButton';
import Loading from '../../../../components/loading/Loading';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Switch } from "@mui/material";
import IconFinance from "@mui/icons-material/MonetizationOnOutlined";
import IconEdit from "@mui/icons-material/Edit";
import IconAdd from "@mui/icons-material/Add";
import { CreateEditCategoryPopup } from './popups/CreateEditCategoryPopup';

function Categories() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [showCreateEditPopup, setShowCreateEditPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showInactive, setShowInactive] = useState(false);

  const {
    GetFinancialCategories,
    SetFinancialCategory,
    UpdateFinancialCategory
  } = DataProviderInstance();

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = () => {
    setIsLoading(true);
    GetFinancialCategories((response) => {
      setIsLoading(false);
      if (response.status === "success") {
        setCategories(response.data || []);
      } else {
        // TODO: Adicionar tratamento de erro visual quando tivermos o componente
        console.error("Erro ao carregar categorias:", response.message);
      }
    });
  };

  const handleCreateCategory = () => {
    setSelectedCategory(null);
    setShowCreateEditPopup(true);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowCreateEditPopup(true);
  };

  const handleSaveCategory = (categoryData) => {
    if (selectedCategory) {
      // Editar categoria existente
      UpdateFinancialCategory(categoryData, (response) => {
        if (response.status === "success") {
          loadCategories();
          setShowCreateEditPopup(false);
        } else {
          // TODO: Adicionar tratamento de erro visual quando tivermos o componente
          console.error("Erro ao atualizar categoria:", response.message);
        }
      });
    } else {
      // Criar nova categoria
      SetFinancialCategory(categoryData, (response) => {
        if (response.status === "success") {
          loadCategories();
          setShowCreateEditPopup(false);
        } else {
          // TODO: Adicionar tratamento de erro visual quando tivermos o componente
          console.error("Erro ao criar categoria:", response.message);
        }
      });
    }
  };

  const renderCategoriesTable = (type) => {
    const filteredCategories = categories.filter(cat => 
      cat.type === type && (showInactive || cat.isActive)
    );

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Nome</strong></TableCell>
              <TableCell align="center"><strong>Status</strong></TableCell>
              <TableCell align="right"><strong>Ações</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.map((category) => (
              <TableRow 
                key={category.id}
                className={!category.isActive ? styles.inactiveRow : ''}
              >
                <TableCell>{category.name}</TableCell>
                <TableCell align="center">
                  {category.isActive ? 'Ativo' : 'Inativo'}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEditCategory(category)}>
                    <IconEdit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <AreaTitle
          Title="Categorias Financeiras"
          Description="Gerencie as categorias de receitas e despesas"
          Icon={<IconFinance />}
        />
        <div className={styles.actions}>
          <div className={styles.showInactive}>
            <Switch
              checked={showInactive}
              onChange={(e) => setShowInactive(e.target.checked)}
            />
            <span>Exibir inativos</span>
          </div>
          <CustomButton
            variant="contained"
            style="primary"
            label="Nova Categoria"
            onClick={handleCreateCategory}
            startIcon={<IconAdd />}
          />
        </div>
      </div>

      <div className={styles.categoriesContainer}>
        <div className={styles.categorySection}>
          <h3>Receitas</h3>
          {renderCategoriesTable('income')}
        </div>
        <div className={styles.categorySection}>
          <h3>Despesas</h3>
          {renderCategoriesTable('expense')}
        </div>
      </div>

      {showCreateEditPopup && (
        <CreateEditCategoryPopup
          category={selectedCategory}
          onClose={() => setShowCreateEditPopup(false)}
          onSave={handleSaveCategory}
        />
      )}
    </div>
  );
}

export default Categories;