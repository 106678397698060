import React from 'react';
import styles from './Export.module.css';
import CompanyDataExport from '../../../components/company-data-export/CompanyDataExport';
import AreaTitle from '../../../components/areaTitle/AreaTitle';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

const Export = () => {
    const exportComponent = CompanyDataExport({});

    return (
        <div className='page-content'>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <AreaTitle 
                            Title="Exportar Dados" 
                            Description="Exporte os dados do sistema para backup ou análise."
                            Icon={<CloudDownloadOutlinedIcon/>}
                        />
                    </div>
                    {exportComponent.button}
                </div>
                
                <div className={styles.container}>
                    {exportComponent.content}
                </div>
            </div>
        </div>
    );
};

export default Export;