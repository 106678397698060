import React from 'react';
import styles from './AppointmentSlot.module.css';
import AppointmentsState from '../../../../consts/AppointmentsState';
import { DataProviderInstance } from '../../../../api/DataProvider';
import IconPaid from '@mui/icons-material/AttachMoneyOutlined';

const AppointmentSlot = ({
  appointment,
  startHour,
  endHour,
  totalMinutes,
  groupSize = 1,
  groupIndex = 0,
  onClick
}) => {
  const { GetPatientById, GetProcedureById, GetResourceById } = DataProviderInstance();
  
  // Calculate position and height based on minutes for more precise positioning
  const calculatePosition = () => {
    const date = new Date(appointment.date);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    
    // Calculate position based on minutes since start
    const totalMinutesInView = (endHour - startHour) * 60;
    const minutesSinceStart = ((hours - startHour) * 60) + minutes;
    const position = (minutesSinceStart / totalMinutesInView) * 100;
    
    return position;
  };

  const calculateHeight = () => {
    // Use 15-minute intervals as minimum height like empty slots
    const interval = 15;
    const minHeightPercentage = (interval / totalMinutes) * 100;

    if (!appointment.duration) return minHeightPercentage;
    
    const [hours, minutes] = appointment.duration.split(':').map(Number);
    const durationInMinutes = (hours * 60) + minutes;
    const heightPercentage = (durationInMinutes / totalMinutes) * 100;
    
    return Math.max(heightPercentage, minHeightPercentage);
  };

  // Get appointment data
  const patient = appointment.patient ? GetPatientById(appointment.patient) : null;
  const procedure = appointment.procedure ? GetProcedureById(appointment.procedure) : null;
  const resource = appointment.resource ? GetResourceById(appointment.resource) : null;
  const styleData = AppointmentsState.GetDataFromId(appointment.status);
  
  // Calculate width based on group size
  const width = `${100 / groupSize}%`;
  const left = `${(100 / groupSize) * groupIndex}%`;

  // Determine if appointment is cancelled/missed
  const isInactive = [
    AppointmentsState.Canceled,
    AppointmentsState.CanceledByPatient,
    AppointmentsState.Missed,
    AppointmentsState.Rescheduled
  ].includes(appointment.status);

  const getTime = () => {
    const date = new Date(appointment.date);
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  // Get status-specific class
  const getStatusClass = () => {
    switch (appointment.status) {
      case AppointmentsState.Confirmed:
      case AppointmentsState.ConfirmedByPatient:
        return styles.confirmed;
      case AppointmentsState.Scheduled:
        return styles.scheduled;
      case AppointmentsState.Done:
        return styles.done;
      case AppointmentsState.Blocked:
        return styles.blocked;
      case AppointmentsState.Doing:
        return styles.doing;
      case AppointmentsState.Canceled:
      case AppointmentsState.CanceledByPatient:
        return styles.canceled;
      case AppointmentsState.Missed:
        return styles.missed;
      default:
        return '';
    }
  };

  // Check if appointment has a valid invoice
  const hasInvoice = appointment.invoiceId && appointment.invoiceId !== "0" && appointment.invoiceId !== "";

  const formatPatientName = (fullName) => {
    if (!fullName) return '';
    
    const names = fullName.split(' ').filter(name => name.length > 0);
    if (names.length <= 2) return fullName;
    
    const firstTwoNames = names.slice(0, 2).join(' ');
    const remainingInitials = names.slice(2)
      .map(name => name.charAt(0))
      .join('.');
    
    return `${firstTwoNames} ${remainingInitials}.`;
  };

  const formatResourceName = (fullName) => {
    if (!fullName) return '';
    
    const names = fullName.split(' ').filter(name => name.length > 0);
    if (names.length <= 1) return fullName;
    
    const firstName = names[0];
    const remainingInitials = names.slice(1)
      .map(name => name.charAt(0))
      .join('.');
    
    return `${firstName} ${remainingInitials}.`;
  };

  return (
    <div
      className={`${styles.appointmentContainer}`}
      style={{
        top: `${calculatePosition()}%`,
        height: `${calculateHeight()}%`,
        width,
        left,
      }}
      onClick={onClick}
    >
      <div
        className={`${styles.appointmentSlot} ${isInactive ? styles.inactive : ''} ${getStatusClass()}`}
        style={{
          backgroundColor: styleData.backgroundColor || '#fff',
          borderLeftColor: styleData.borderColor || '#e0e0e0',
        }}
      >
        <div className={styles.appointmentContent}>
          {appointment.status !== AppointmentsState.Available && (
            <>
              <div className={styles.timeInfo}>
                <div className={styles.time}>{getTime()}</div>
                {(procedure || resource) && (
                  <div className={styles.procedure}>
                    {procedure?.Label}
                    {procedure && resource && " • "}
                    {resource ? formatResourceName(resource.Label) : ''}
                  </div>
                )}
              </div>
              {appointment.status === AppointmentsState.Blocked ? (
                <div className={styles.patientName}>
                  {appointment.patient || "Bloqueado"}
                </div>
              ) : patient && (
                <div className={styles.patientName}>
                  {hasInvoice && (
                    <IconPaid className={styles.paidIcon} fontSize="medium" />
                  )}
                  {formatPatientName(patient.name)}
                </div>
              )}
            </>
          )}
          {appointment.status === AppointmentsState.Available && (
            <div className={styles.availableSlot}>
              <div className={styles.time}>{getTime()}</div>
              <div className={styles.availableText}>Disponível</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentSlot;