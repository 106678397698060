import React, { useState } from 'react';
import styles from './MenuSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import MenuItem from './MenuItem';

const MenuSection = ({ title, items, OnHide }) => {
    const [isExpanded, setIsExpanded] = useState(title === "Principal");

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={styles.section}>
            <div className={styles.sectionHeader} onClick={toggleExpand}>
                <FontAwesomeIcon 
                    icon={isExpanded ? faChevronDown : faChevronRight} 
                    className={styles.icon}
                />
                <span className={styles.title}>{title}</span>
            </div>
            {isExpanded && (
                <div className={styles.sectionContent}>
                    {items.map((item) => (
                        <MenuItem 
                            key={item.path} 
                            path={item.path} 
                            icon={item.icon} 
                            label={item.label} 
                            OnHide={OnHide}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MenuSection;