import React from 'react';
import styles from '../styles.module.css';
import AreaTitle from '../../../components/areaTitle/AreaTitle';
import { PieChart, BarChart, LineChart, BarPlot, ChartsXAxis, ChartsYAxis, ChartsTooltip, ChartsAxisHighlight } from '@mui/x-charts';
import ColorPalette from '../../../colorpalette/ColorPalette';
import IconFinance from "@mui/icons-material/MonetizationOnOutlined";
import IconLTV from "@mui/icons-material/AttachMoney";
import IconPayments from "@mui/icons-material/Payment";
import CalendarConfig from '../../../consts/CalendarConfig';

function FinancialStatistics({ financialStats, dashboardData }) {

  function GetRevenueExpenseDistributionChart() {
    if (!financialStats || !financialStats.timeline || financialStats.timeline.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Receitas vs Despesas"}
            Description={"Proporção entre receitas realizadas e despesas"}
            Icon={<IconFinance />}
          />
          <div>Dados de receitas e despesas indisponíveis.</div>
        </div>
      );
    }

    // Calcular o total de receitas realizadas e despesas
    const totals = financialStats.timeline.reduce((acc, item) => {
      acc.revenue += Number(item.actual_revenue || 0);
      acc.expense += Number(item.expense || 0);
      return acc;
    }, { revenue: 0, expense: 0 });

    if (totals.revenue === 0 && totals.expense === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Receitas vs Despesas"}
            Description={"Proporção entre receitas realizadas e despesas"}
            Icon={<IconFinance />}
          />
          <div>Não há dados válidos de receitas e despesas.</div>
        </div>
      );
    }

    const data = [
      { id: 'Receitas', value: totals.revenue, label: `Receitas: R$ ${totals.revenue.toFixed(2)}` },
      { id: 'Despesas', value: totals.expense, label: `Despesas: R$ ${totals.expense.toFixed(2)}` }
    ];

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Receitas vs Despesas"}
          Description={"Proporção entre receitas realizadas e despesas"}
          Icon={<IconFinance />}
        />
        <PieChart
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          colors={[ColorPalette.main, ColorPalette.red]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetFinancialTimelineChart() {
    if (!financialStats || !financialStats.timeline || financialStats.timeline.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Evolução Financeira"}
            Description={"Visão consolidada de receitas realizadas, previstas e despesas"}
            Icon={<IconFinance />}
          />
          <div>Dados de evolução financeira indisponíveis.</div>
        </div>
      );
    }

    const timelineData = financialStats.timeline || [];

    if (timelineData.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Evolução Financeira"}
            Description={"Visão consolidada de receitas realizadas, previstas e despesas"}
            Icon={<IconFinance />}
          />
          <div>Dados de evolução financeira indisponíveis.</div>
        </div>
      );
    }

    const validData = timelineData.filter(item =>
      item &&
      typeof item.month === 'string' &&
      (typeof item.actual_revenue === 'number' || typeof item.actual_revenue === 'string') &&
      (typeof item.predicted_revenue === 'number' || typeof item.predicted_revenue === 'string') &&
      (typeof item.expense === 'number' || typeof item.expense === 'string')
    );

    if (validData.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Evolução Financeira"}
            Description={"Visão consolidada de receitas realizadas, previstas e despesas"}
            Icon={<IconFinance />}
          />
          <div>Não há dados válidos de evolução financeira.</div>
        </div>
      );
    }

    const labels = validData.map(item => {
      const [year, month] = item.month.split('-');
      return CalendarConfig.Months[parseInt(month) - 1].substring(0, 3) + "-" + year;
    });

    const actualRevenueData = validData.map(item => Number(item.actual_revenue || 0));
    const predictedRevenueData = validData.map(item => Number(item.predicted_revenue || 0));
    const expenseData = validData.map(item => Number(item.expense || 0));

    // Se tiver apenas um mês de dados, usar gráfico de barras
    if (validData.length === 1) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Evolução Financeira"}
            Description={"Visão consolidada de receitas realizadas, previstas e despesas"}
            Icon={<IconFinance />}
          />
          <BarChart
            height={300}
            series={[
              {
                data: [actualRevenueData[0]],
                label: 'Receita Realizada',
                color: ColorPalette.main,
              },
              {
                data: [predictedRevenueData[0]],
                label: 'Receita Prevista',
                color: ColorPalette.mainLight1,
              },
              {
                data: [expenseData[0]],
                label: 'Despesas',
                color: ColorPalette.red,
              },
            ]}
            xAxis={[{
              data: [labels[0]],
              scaleType: 'band',
            }]}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'top', horizontal: 'middle' },
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                markGap: 5,
                itemGap: 10,
                labelStyle: {
                  fontSize: 12,
                },
              },
            }}
          >
            <BarPlot />
            <ChartsXAxis />
            <ChartsYAxis />
            <ChartsTooltip />
            <ChartsAxisHighlight x="line" y="line" />
          </BarChart>
        </div>
      );
    }

    // Se tiver múltiplos meses, manter o gráfico de linha
    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Evolução Financeira"}
          Description={"Visão consolidada de receitas realizadas, previstas e despesas"}
          Icon={<IconFinance />}
        />
        <LineChart
          xAxis={[{ scaleType: 'point', data: labels }]}
          series={[
            {
              data: actualRevenueData,
              label: 'Receita Realizada',
              color: ColorPalette.main,
              area: true,
              showMark: true,
            },
            {
              data: predictedRevenueData,
              label: 'Receita Prevista',
              color: ColorPalette.mainLight1,
              area: true,
              showMark: true,
            },
            {
              data: expenseData,
              label: 'Despesas',
              color: ColorPalette.red,
              area: true,
              showMark: true,
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              markGap: 5,
              itemGap: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        >
          <ChartsXAxis />
          <ChartsYAxis />
          <ChartsTooltip />
          <ChartsAxisHighlight x="line" y="line" />
        </LineChart>
      </div>
    );
  }

  function GetCategoryComparisonChart() {
    if (!financialStats || !financialStats.categoryComparison) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Comparação entre Categorias"}
            Description={"Análise comparativa entre diferentes categorias"}
            Icon={<IconFinance />}
          />
          <div>Dados de comparação entre categorias indisponíveis.</div>
        </div>
      );
    }

    const expenseData = (financialStats.categoryComparison?.expense || [])
      .filter(item => item && typeof item.total === 'number')
      .sort((a, b) => Math.abs(b.total) - Math.abs(a.total));

    const incomeData = (financialStats.categoryComparison?.income || [])
      .filter(item => item && typeof item.total === 'number')
      .sort((a, b) => Math.abs(b.total) - Math.abs(a.total));

    const topExpenses = expenseData.slice(0, 5);
    const topIncomes = incomeData.slice(0, 5);

    if (topExpenses.length === 0 && topIncomes.length === 0) {
      return (
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Top 5 Categorias"}
            Description={"Maiores receitas e despesas por categoria"}
            Icon={<IconFinance />}
          />
          <div>Não há dados de categorias disponíveis.</div>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
        {/* Gráfico de Despesas */}
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Top 5 Despesas"}
            Description={"Maiores gastos por categoria"}
            Icon={<IconFinance />}
          />
          <BarChart
            xAxis={[{ scaleType: 'band', data: topExpenses.map(item => item.category) }]}
            series={[
              {
                data: topExpenses.map(item => Math.abs(item.total || 0)),
                color: ColorPalette.red,
                valueFormatter: (value) => `R$ ${value.toFixed(2)}`,
              },
            ]}
            height={300}
          >
            <BarPlot />
            <ChartsXAxis />
            <ChartsYAxis />
            <ChartsTooltip />
            <ChartsAxisHighlight x="line" y="line" />
          </BarChart>
        </div>

        {/* Gráfico de Receitas */}
        <div className={styles.boxRoundedContainer}>
          <AreaTitle
            Title={"Top 5 Receitas"}
            Description={"Maiores receitas por categoria"}
            Icon={<IconFinance />}
          />
          <BarChart
            xAxis={[{ scaleType: 'band', data: topIncomes.map(item => item.category) }]}
            series={[
              {
                data: topIncomes.map(item => Math.abs(item.total || 0)),
                color: ColorPalette.main,
                valueFormatter: (value) => `R$ ${value.toFixed(2)}`,
              },
            ]}
            height={300}
          >
            <BarPlot />
            <ChartsXAxis />
            <ChartsYAxis />
            <ChartsTooltip />
            <ChartsAxisHighlight x="line" y="line" />
          </BarChart>
        </div>
      </div>
    );
  }

  function GetPaymentMethodsChart() {
    if (!dashboardData || !dashboardData.PaymentMethods || dashboardData.PaymentMethods.length === 0) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"Valores por Método de Pagamento"}
            Description={"Distribuição dos valores recebidos por cada método de pagamento."}
            Icon={<IconPayments />}
          />
          <div>Dados de métodos de pagamento indisponíveis.</div>
        </div>
      );
    }

    let source = dashboardData.PaymentMethods;
    let data = source.map(item => ({
      id: item.paymentMethod,
      value: item.amount,
      label: item.paymentMethod,
    }));

    return (
      <div className={styles.boxRoundedContainer}>
        <AreaTitle
          Title={"Valores por Método de Pagamento"}
          Description={"Distribuição dos valores recebidos por cada método de pagamento."}
          Icon={<IconPayments />}
        />
        <PieChart
          colors={[ColorPalette.main, ColorPalette.secondary, ColorPalette.greenLight, ColorPalette.mainLight1, ColorPalette.secondaryLight, ColorPalette.mainDark, ColorPalette.mainLight1]}
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              labelStyle: {
                fontSize: 12,
              },
            },
          }}
        />
      </div>
    );
  }

  function GetAverageLTV() {
    if (!dashboardData || typeof dashboardData.AverageLTV === 'undefined' || typeof dashboardData.AverageLTV === null) {
      return (
        <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
          <AreaTitle
            Title={"LTV Médio dos Pacientes"}
            Description={"Valor médio de vida de um paciente."}
            Icon={<IconLTV />}
          />
          <div>Dados de LTV indisponíveis.</div>
        </div>
      );
    }

    const ltv = Number(dashboardData.AverageLTV);

    return (
      <div className={styles.boxRoundedContainer} style={{ minHeight: "auto" }}>
        <AreaTitle
          Title={`LTV Médio dos Pacientes:  R$ ${ltv.toFixed(2)}`}
          Description={"Valor médio de vida de um paciente."}
          Icon={<IconLTV />}
        />
      </div>
    );
  }

  return (
    <div className={styles.gridContainer}>
      {GetFinancialTimelineChart()}
      {GetRevenueExpenseDistributionChart()}
      {GetCategoryComparisonChart()}
      {GetPaymentMethodsChart()}
      {GetAverageLTV()}
    </div>
  );
}

export default FinancialStatistics;