import React from 'react';

import Import from '../pages/system/import/Import';
import Export from '../pages/system/export/Export';
import Login from '../pages/login/Login';
import Leads from '../pages/leads/Leads';
import WhatsappMessages from '../pages/whatsappMessages/WhatsappMessages';
import Dashboard from '../pages/dashboard/Dashboard';
import Appointments from '../pages/appointments/Appointments';
import Appointment from '../pages/appointment/Appointment';
import WaitingList from '../pages/waitingList/WaitingList';
import Invoice from '../pages/invoice/Invoice';
import Document from '../pages/document/Document';
import Patients from '../pages/patients/Patients';
import Patient from '../pages/patient/Patient';
import Reports from '../pages/reports/Reports';
import Statistics from '../pages/statistics/Statistics';
import Company from '../pages/company/Company'
import Logout from '../pages/logout/Logout';
import NPSForm from '../pages/nps/NPSForm';
import Financial from '../pages/financial/Financial';
import ResourcesPage from '../pages/resources';
import ProceduresPage from '../pages/procedures';
import PaymentMethodsPage from '../pages/payment-methods';
import Packages from '../pages/packages/Packages';
import Templates from '../pages/templates/Templates';
import Preferences from '../pages/preferences/Preferences';

import IconDashboard from '@mui/icons-material/SpaceDashboardOutlined';
import IconLeads from '@mui/icons-material/GroupsOutlined';
import IconCalendar from '@mui/icons-material/CalendarMonthOutlined';
import IconPatients from '@mui/icons-material/PeopleAltOutlined';
import IconStatistics from '@mui/icons-material/BarChartOutlined';
import IconReports from '@mui/icons-material/DocumentScannerOutlined';
import IconCompany from '@mui/icons-material/ApartmentOutlined';
import IconResources from '@mui/icons-material/Person2Outlined';
import IconServices from '@mui/icons-material/DesignServicesOutlined';
import IconSettings from '@mui/icons-material/TuneOutlined';
import IconLogout from '@mui/icons-material/LogoutOutlined';
import IconWhatsapp from '@mui/icons-material/WhatsApp';
import IconWaitingList from '@mui/icons-material/HourglassEmptyOutlined';
import IconFinancial from '@mui/icons-material/MonetizationOnOutlined';
import IconHome from '@mui/icons-material/HomeOutlined';
import IconMarketing from '@mui/icons-material/CampaignOutlined';
import IconAnalytics from '@mui/icons-material/InsightsOutlined';
import IconConfig from '@mui/icons-material/TuneOutlined';
import IconSystem from '@mui/icons-material/AdminPanelSettingsOutlined';
import IconPreferences from '@mui/icons-material/SettingsOutlined';
import IconTemplates from '@mui/icons-material/DescriptionOutlined';
import IconPackages from '@mui/icons-material/LocalOfferOutlined';
import IconSchedule from '@mui/icons-material/EventNoteOutlined';
import IconPayment from '@mui/icons-material/PaymentOutlined';
import IconUpload from '@mui/icons-material/UploadFileOutlined';
import IconDownload from '@mui/icons-material/CloudDownloadOutlined';

export const IdLogin = "login";
export const IdDashboard = "dashboard";
export const IdLeads = "leads";
export const IdWhatsappMessages = "whatsapp";
export const IdSchedule = "schedule";
export const IdSchedule_Appointments = "schedule/appointments";
export const IdAppointment = "schedule/appointments/appointment";
export const IdWaitingList = "schedule/waitingList";
export const IdInvoice = "invoice";
export const IdDocument = "document";
export const IdPatients = "patients";
export const IdPatients_Patient = "patients/patient";
export const IdStatistics = "statistics";
export const IdReports = "reports";
export const IdCompany = "company";
export const IdResources = "resources";
export const IdProcedures = "procedures";
export const IdPaymentMethods = "payment-methods";
export const IdSettings = "settings";
export const IdLogout = "logout";
export const IdNPSForm = "nps-form";
export const IdFinancial = "financial";
export const IdPreferences = "preferences";
export const IdTemplates = "templates";
export const IdPackages = "packages";
export const IdSystemImport = "system/import";
export const IdSystemExport = "system/export";

const AppConfig = {
  MinScreenSizeX: 1010,
  Routes:
    [
      {
        id: IdLogin,
        path: "/login",
        label: "Login",
        element: <Login />,
      },
      {
        id: IdDashboard,
        path: "/dashboard",
        label: "Dashboard",
        element: <Dashboard />,
        icon: <IconDashboard fontSize='small'/>,
      },
      {
        id: IdLeads,
        path: "/leads",
        label: "Leads",
        element: <Leads />,
        icon: <IconLeads fontSize='small'/>,
      },
      {
        id: IdWhatsappMessages,
        path: "/whatsapp",
        label: "Whatsapp",
        element: <WhatsappMessages />,
        icon: <IconWhatsapp fontSize='small'/>,
      },
      {
        id: IdSchedule_Appointments,
        path: "/schedule/appointments",
        label: "Agendamentos",
        element: <Appointments />,
        icon: <IconCalendar fontSize='small'/>,
      },
      {
        id: IdWaitingList,
        path: "/schedule/waitingList",
        label: "Lista de espera",
        element: <WaitingList />,
        icon: <IconWaitingList fontSize='small'/>,
      },
      {
        id: IdAppointment,
        path: "/appointment/:id",
        label: "Consulta",
        element: <Appointment fontSize='small'/>,
      },
      {
        id: IdInvoice,
        path: "/invoice/:id",
        label: "Recibo",
        element: <Invoice fontSize='small'/>,
      },
      {
        id: IdDocument,
        path: "/document/:id",
        label: "Documento",
        element: <Document fontSize='small'/>,
      },
      {
        id: IdPatients,
        path: "/patients",
        label: "Pacientes",
        element: <Patients />,
        icon: <IconPatients fontSize='small'/>,
      },
      {
        id: IdPatients_Patient,
        path: "/patients/:id",
        label: "Paciente",
        element: <Patient />,
      },
      {
        id: IdFinancial,
        path: "/financial",
        label: "Financeiro",
        element: <Financial />,
        icon: <IconFinancial fontSize='small'/>,
      },
      {
        id: IdStatistics,
        path: "/statistics",
        label: "Estatísticas",
        element: <Statistics />,
        icon: <IconStatistics fontSize='small'/>,
      },
      {
        id: IdReports,
        path: "/reports",
        label: "Relatórios",
        element: <Reports />,
        icon: <IconReports fontSize='small'/>,
      },
      {
        id: IdCompany,
        path: "/company",
        label: "Dados cadastrais",
        element: <Company />,
        icon: <IconCompany fontSize='small'/>,
      },
      {
        id: IdPreferences,
        path: "/preferences",
        label: "Preferências",
        element: <Preferences />,
        icon: <IconPreferences fontSize='small'/>,
      },
      {
        id: IdResources,
        path: "/resources",
        label: "Recursos",
        element: <ResourcesPage />,
        icon: <IconResources fontSize='small'/>,
      },
      {
        id: IdProcedures,
        path: "/procedures",
        label: "Procedimentos",
        element: <ProceduresPage />,
        icon: <IconServices fontSize='small'/>,
      },
      {
        id: IdPaymentMethods,
        path: "/payment-methods",
        label: "Métodos de Pagamento",
        element: <PaymentMethodsPage />,
        icon: <IconPayment fontSize='small'/>,
      },
      {
        id: IdPackages,
        path: "/packages",
        label: "Pacotes",
        element: <Packages />,
        icon: <IconPackages fontSize='small'/>,
      },
      {
        id: IdTemplates,
        path: "/templates",
        label: "Templates",
        element: <Templates />,
        icon: <IconTemplates fontSize='small'/>,
      },
      {
        id: IdLogout,
        path: "/logout",
        label: "Sair",
        icon: <IconLogout fontSize='small'/>,
        element: <Logout />,
      },
      {
        id: IdNPSForm,
        path: "/nps-form/:id",
        label: "Formulário NPS",
        element: <NPSForm />,
      },
      {
        id: IdSystemImport,
        path: "/system/import",
        label: "Importar Dados",
        element: <Import />,
        icon: <IconUpload fontSize='small'/>,
      },
      {
        id: IdSystemExport,
        path: "/system/export",
        label: "Exportar Dados",
        element: <Export />,
        icon: <IconDownload fontSize='small'/>,
      }
    ],
  MenuRoutes: [
    { 
      label: "Geral",
      icon: <IconHome fontSize='small'/>,
      routes: [
        IdDashboard,
        IdPatients,
        IdFinancial
      ]
    },
    {
      label: "Agenda",
      icon: <IconSchedule fontSize='small'/>,
      routes: [
        IdSchedule_Appointments,
        IdWaitingList
      ]
    },
    { 
      label: "Marketing",
      icon: <IconMarketing fontSize='small'/>,
      routes: [
        IdLeads,
        IdWhatsappMessages
      ]
    },
    { 
      label: "Análises",
      icon: <IconAnalytics fontSize='small'/>,
      routes: [
        IdStatistics,
        IdReports
      ]
    },
    { 
      label: "Empresa",
      icon: <IconCompany fontSize='small'/>,
      routes: [
        IdCompany,
        IdPreferences,
        IdResources,
        IdProcedures,
        IdPaymentMethods,
        IdPackages,
        IdTemplates
      ]
    },
    { 
      label: "Sistema",
      icon: <IconSystem fontSize='small'/>,
      routes: [
        IdSystemImport,
        IdSystemExport,
        IdLogout
      ]
    }
  ],
  GetRouteById: (id) => {
    return AppConfig.Routes.find((route) => {
      return route.id === id;
    })
  },
  GetRouteLabel: (path) => {
    return AppConfig.Routes.find((route) => {
      return route.path === path;
    })
  },
}

export default AppConfig;