import React, { useEffect, useState } from 'react';
import { TextField } from "@mui/material";
import CustomButton from '../buttons/CustomButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import styles from './DateRangeHeader.module.css';

const DateRangeHeader = ({ onChange, initialStartDate, initialEndDate }) => {
    // Define datas padrão: primeiro dia do mês até o último dia do mês atual
    const defaultStartDate = dayjs().startOf('month').format('YYYY-MM-DD');
    const defaultEndDate = dayjs().endOf('month').format('YYYY-MM-DD');

    const [currentStartDate, setCurrentStartDate] = useState(dayjs(initialStartDate || defaultStartDate));
    const [currentEndDate, setCurrentEndDate] = useState(dayjs(initialEndDate || defaultEndDate));
    const [activeButton, setActiveButton] = useState("Esse mês");

    // Dispara onChange com as datas iniciais e atualiza o botão ativo
    useEffect(() => {
        if (!initialStartDate || !initialEndDate) {
            onChange(defaultStartDate, defaultEndDate);
        }
        checkActiveButton();
    }, []);

    // Atualiza o botão ativo sempre que as datas mudarem
    useEffect(() => {
        checkActiveButton();
    }, [currentStartDate, currentEndDate]);

    const handleShow = () => {
        const formattedStart = currentStartDate.format('YYYY-MM-DD');
        const formattedEnd = currentEndDate.format('YYYY-MM-DD');
        
        // Só chama onChange se as datas forem diferentes das iniciais
        if (formattedStart !== initialStartDate || formattedEnd !== initialEndDate) {
            onChange(formattedStart, formattedEnd);
        }
    }

    const updateDates = (start, end, buttonLabel) => {
        const newStart = dayjs(start);
        const newEnd = dayjs(end);
        
        setCurrentStartDate(newStart);
        setCurrentEndDate(newEnd);
        setActiveButton(buttonLabel);
        
        // Chama handleShow apenas se as datas forem diferentes
        const formattedStart = newStart.format('YYYY-MM-DD');
        const formattedEnd = newEnd.format('YYYY-MM-DD');
        
        if (formattedStart !== currentStartDate.format('YYYY-MM-DD') ||
            formattedEnd !== currentEndDate.format('YYYY-MM-DD')) {
            onChange(formattedStart, formattedEnd);
        }
    }

    const handleToday = () => {
        const today = new Date();
        updateDates(today, today, "Hoje");
    }

    const handleThisWeek = () => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
        const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));
        updateDates(startOfWeek, endOfWeek, "Essa semana");
    }

    const handleThisMonth = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        updateDates(startOfMonth, endOfMonth, "Esse mês");
    }

    const handleThisYear = () => {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const endOfYear = new Date(today.getFullYear(), 11, 31);
        updateDates(startOfYear, endOfYear, "Esse ano");
    }

    const handleLast6Months = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth() - 6, 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        updateDates(startOfMonth, endOfMonth, "Últimos 6 meses");
    }

    const handleFromTheBeginning = () => {
        const today = new Date();
        const startOfMonth = new Date(1900, 0, 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        updateDates(startOfMonth, endOfMonth, "Desde o início");
    }

    const checkActiveButton = () => {
        const start = currentStartDate.format("YYYY-MM-DD");
        const end = currentEndDate.format("YYYY-MM-DD");
        const today = dayjs().format("YYYY-MM-DD");

        // Verifica se é o período do mês atual até hoje
        if (start === dayjs().startOf("month").format("YYYY-MM-DD") && end === today) {
            setActiveButton("Esse mês");
            return;
        }

        // Outros casos
        if (start === today && end === today) {
            setActiveButton("Hoje");
        } else if (
            start === dayjs().startOf("week").format("YYYY-MM-DD") &&
            end === dayjs().endOf("week").format("YYYY-MM-DD")
        ) {
            setActiveButton("Essa semana");
        } else if (
            start === dayjs().startOf("month").format("YYYY-MM-DD") &&
            end === dayjs().endOf("month").format("YYYY-MM-DD")
        ) {
            setActiveButton("Esse mês");
        } else if (
            start === dayjs().subtract(6, "month").startOf("month").format("YYYY-MM-DD") &&
            end === dayjs().endOf("month").format("YYYY-MM-DD")
        ) {
            setActiveButton("Últimos 6 meses");
        } else if (
            start === dayjs().startOf("year").format("YYYY-MM-DD") &&
            end === dayjs().endOf("year").format("YYYY-MM-DD")
        ) {
            setActiveButton("Esse ano");
        } else if (start === "1500-01-01") {
            setActiveButton("Desde o início");
        } else {
            setActiveButton(null); // Nenhum botão ativo
        }
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.containerDatePicker}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Data de Início"
                        defaultValue={dayjs(new Date(), 'YYYY-MM-DD')}
                        value={currentStartDate}
                        format='DD/MM/YYYY'
                        onChange={(date) => {
                            setCurrentStartDate(date);
                            onChange(date.format('YYYY-MM-DD'), currentEndDate.format('YYYY-MM-DD'));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />

                    <DatePicker
                        label="Data de Fim"
                        defaultValue={dayjs(new Date(), 'YYYY-MM-DD')}
                        format='DD/MM/YYYY'
                        value={currentEndDate}
                        onChange={(date) => {
                            setCurrentEndDate(date);
                            onChange(currentStartDate.format('YYYY-MM-DD'), date.format('YYYY-MM-DD'));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </div>
            <div className={styles.containerShortcuts}>
                <CustomButton
                    variant={activeButton === "Hoje" ?  "contained" : "outlined"}
                    style={activeButton === "Hoje" ?  "primary" : "secondary"}
                    label={"Hoje"}
                    onClick={handleToday}
                />
                <CustomButton
                    variant={activeButton === "Essa semana" ?  "contained" : "outlined"}
                    style={activeButton === "Essa semana" ?  "primary" : "secondary"}
                    label={"Essa semana"}
                    onClick={handleThisWeek}
                />
                <CustomButton
                    variant={activeButton === "Esse mês" ?  "contained" : "outlined"}
                    style={activeButton === "Esse mês" ?  "primary" : "secondary"}
                    label={"Esse mês"}
                    onClick={handleThisMonth}
                />
                <CustomButton
                    variant={activeButton === "Últimos 6 meses" ?  "contained" : "outlined"}
                    style={activeButton === "Últimos 6 meses" ?  "primary" : "secondary"}
                    label={"Últimos 6 meses"}
                    onClick={handleLast6Months}
                />
                <CustomButton
                    variant={activeButton === "Esse ano" ?  "contained" : "outlined"}
                    style={activeButton === "Esse ano" ? "primary" : "secondary"}
                    label={"Esse ano"}
                    onClick={handleThisYear}
                />
                <CustomButton
                    variant={activeButton === "Desde o início" ?  "contained" : "outlined"}
                    style={activeButton === "Desde o início" ?  "primary" : "secondary"}
                    label={"Desde o início"}
                    onClick={handleFromTheBeginning}
                />
            </div>
        </div>
    );
};

export default DateRangeHeader;
