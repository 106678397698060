const ColorPalette = {
   // Cores base
   transparent: "transparent",
   white: "#fff",
   black: "#000",

   // Cores principais
   main: "#206afa",
   mainLight1: "#aed4f5",
   mainLight2: "#7fb3d5",
   mainDark: "#385AA4",
   mainAlpha01: "rgba(32, 106, 250, 0.1)",
   mainAlpha02: "rgba(32, 106, 250, 0.2)",

   // Cores secundárias
   secondary: "#ed3680",
   secondaryLight:"#ffdfec",

   // Estados e feedback
   red: "#e74c3c",
   redLight1:"#f08070",
   redLight2:"#f5b7b1",
   orange: "#f39c12",
   green:"#B9C4B0",
   greenLight:"#E2FCD6",

   // Texto
   textDark:"#273644",
   textLight: "#535353",
   textLight2: "#a3a3a3",

   // Tons de cinza
   grayLight: "#f7f7f7",
   grayDark:"#242424",
   grayAlpha01: "rgba(0, 0, 0, 0.1)",
   grayAlpha02: "rgba(0, 0, 0, 0.2)",

   // Bordas e divisores
   border: "rgba(0, 0, 0, 0.1)",
   borderDark: "rgba(0, 0, 0, 0.2)",
   shadow: "rgba(0, 0, 0, 0.1)"
};

export default ColorPalette;