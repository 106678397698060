import React, { useEffect, useState, createContext, useContext } from 'react';
import { SetAppointmentInvoiceAPI, SetPatientDocumentAPI, GetPatientDocumentsAPI } from './API';
import { UpdateCompanyAPI, UpdateCompanyConfigAPI, GetCompanyConfigAPI } from './API';
import { GetDashboardAPI } from './API';
import { GetStatisticsAPI } from './API';
import { GetReportsAppointmentsAPI, GetReportsFinancialAPI } from './API';
import { GetPatientsAPI, SetPatientAPI, UpdatePatientAPI } from './API';
import { GetAppointmentsAPI, SetAppointmentAPI, UpdateAppointmentAPI, DeleteAppointmentAPI } from './API';
import { GetCEPInfoAPI } from './API';
import { SetPatientAnamneseAPI, UpdatePatientAnamneseAPI, DeletePatientAnamneseAPI } from './API';
import { SetPatientHistoryAPI } from './API';
import { SetPatientCranialAsymmetryAPI, UpdatePatientCranialAsymmetryAPI, DeletePatientCranialAsymmetryAPI } from './API';
import { GetInvoiceAPI, UpdateInvoiceAPI, UpdateInvoiceNfIssuedAPI } from './API';
import {
  GetWaitingListAPI, SetWaitingListAPI, UpdateWaitingListAPI, DeleteWaitingListAPI, CheckWaitingListAvailabilityAPI
} from './API';
import { GetLeadsAPI, SetLeadAPI, UpdateLeadAPI } from './API';
import { GetTemplatesDocumentsAPI, SetTemplatesDocumentsAPI, UpdateTemplatesDocumentsAPI, DeleteTemplatesDocumentsAPI } from './API';
import { SendWhatsappMessageAPI, GetWhatsappChatsAPI, GetWhatsappChatMessagesAPI } from './API';
import { GetAIAnswerAPI } from './API';
import { GetNPSStatisticsAPI, GetNPSResponsesAPI, SetNPSResponseAPI, UpdateNPSGoogleReviewAPI } from './API';
import { GetCompanyMessageTemplatesAPI, UpdateCompanyMessageTemplateAPI } from './API';
import { GetAnamnesisTemplatesAPI, SetAnamnesisTemplateAPI, UpdateAnamnesisTemplateAPI, DeleteAnamnesisTemplateAPI } from './API';
import { GetCompanyAutomationSettingsAPI, UpdateCompanyAutomationSettingAPI } from './API';
import {
  GetCompanyResourcesAPI,
  SetCompanyResourceAPI,
  UpdateCompanyResourceAPI,
  DeleteCompanyResourceAPI,
  GetCompanyProceduresAPI,
  SetCompanyProcedureAPI,
  UpdateCompanyProcedureAPI,
  DeleteCompanyProcedureAPI,
  GetCompanyPaymentMethodsAPI,
  SetCompanyPaymentMethodAPI,
  UpdateCompanyPaymentMethodAPI,
  DeleteCompanyPaymentMethodAPI
} from './API';
import {
  GetFinancialCategoriesAPI,
  SetFinancialCategoryAPI,
  UpdateFinancialCategoryAPI,
  UpdateFinancialTransactionAPI,
  GetFinancialTransactionsAPI,
  GetFinancialDashboardAPI,
  DeleteFinancialTransactionAPI,
  SetFinancialTransactionAPI,
  GetFinancialStatisticsAPI,
  GetActiveRecurringTransactionsAPI,
  CreateExportJobAPI,
  GetExportStatusAPI,
  GetExportHistoryAPI,
  CancelExportAPI
} from './API';
import {
  GetPatientWalletAPI,
  GetWalletTransactionsAPI,
  GetServicePackagesAPI,
  PurchaseServicePackageAPI,
  CreateServicePackageAPI,
  UpdateServicePackageAPI,
  DeleteServicePackageAPI,
  DeductWalletCreditsAPI
} from './API';

// DataProvider.js - Provides data access functions for the application
const DataContext = createContext();

export const DataProvider = ({ children, userData, companyId, OnComplete }) => {

  const [isUpdating, setIsUpdating] = useState(false);
  const [UserData, setUserData] = useState(userData);
  const [CompanyConfig, setCompanyConfig] = useState(null);
  const [patients, SetPatients] = useState([]);
  const [appointments, setAppointments] = useState([]);
  
  useEffect(() => {
    if (companyId === null) {
      return;
    }

    const fetchData = async () => {
      window.visualLogger?.log('Carregando dados iniciais...');
      
      try {
        // Load company data
        const companyData = await new Promise((resolve, reject) => {
          GetCompanyConfigAPI(companyId, (result) => {
            if (!result || result.error) {
              window.visualLogger?.error('Erro ao carregar dados da empresa');
              reject(new Error(result?.error || 'Erro ao carregar dados da empresa'));
              return;
            }

            let data = {};
            try {
              // Process company data
              data.Id = result.id;
              data.CNPJ = result.cnpj;
              data.Logo = result.logo;
              data.Name = result.name;
              data.Phone = result.phone;
              data.Email = result.email;
              data.Cep = result.cep;
              data.District = result.district;
              data.City = result.city;
              data.State = result.state;
              data.Country = result.country;
              data.Address = result.address;
              data.Number = result.number;
              data.Complement = result.complement;
              data.AvailableDaysOfWeek = JSON.parse(result.availableDaysOfWeek);
              data.AvailableTime = JSON.parse(result.availableTime);
              data.BlockedTimes = JSON.parse(result.blockedTimes);
              data.TargetOccupation = JSON.parse(result.targetOccupation);
              data.TimeInterval = JSON.parse(result.timeInterval);
              data.GoalAppointments = JSON.parse(result.goalAppointments);
              data.GoalBilling = JSON.parse(result.goalBilling);
              data.Resources = result.resources;
              data.Procedures = result.procedures;
              data.PaymentOptions = result.paymentOptions;
              data.PatientOrigins = result.patientOrigins;
              data.PatientComplaints = result.patientComplaints;
              data.DocumentTemplates = result.documentTemplates;
              data.AnamneseTemplates = result.anamneseTemplates;
              data.MessageTemplates = result.messageTemplates;
              data.LeadsSources = result.leadsSources;
              data.LeadsCampaigns = result.leadsCampaigns;
              
              setCompanyConfig(data);
              resolve(data);
            } catch (e) {
              window.visualLogger?.error('Erro ao processar dados da empresa');
              reject(e);
            }
          });
        });

        window.visualLogger?.log('Carregando templates de mensagens...');
        
        // Load message templates
        await new Promise((resolve, reject) => {
          GetCompanyMessageTemplatesAPI(companyId, (response) => {
            if (response && response.length > 0) {
              setCompanyConfig(prevConfig => ({
                ...prevConfig,
                MessageTemplates: response
              }));
            }
            resolve();
          });
        });

        window.visualLogger?.log('Carregando lista de pacientes...');
        
        // Load patients
        await new Promise((resolve, reject) => {
          GetPatientsAPI(companyId, (result) => {
            if (!result) {
              window.visualLogger?.error('Erro ao carregar lista de pacientes');
              reject(new Error('Erro ao carregar lista de pacientes'));
              return;
            }
            SetPatients(result);
            resolve();
          });
        });

        window.visualLogger?.log('Dados carregados com sucesso');
        OnComplete();
        setIsUpdating(false);
      } catch (error) {
        window.visualLogger?.error('Erro ao carregar dados: ' + error.message);
        setIsUpdating(false);
      }
    };

    fetchData();
  }, [companyId]);

  const SetAppointmentInvoice = async (appointmentId, patient, price, paymentMethod, procedure, issueDocument, callback) => {
    setIsUpdating(true);

    const createdBy = userData.id;

    SetAppointmentInvoiceAPI(appointmentId, companyId, createdBy, patient, price, paymentMethod, procedure, issueDocument, (response) => {
      GetAppointments();
      callback(response);
    });
  }

  const UpdateInvoice = async (invoiceId, price, paymentMethod, issueDocument, callback) => {
    const updatedBy = userData.id;

    UpdateInvoiceAPI(invoiceId, price, paymentMethod, issueDocument, updatedBy, (response) => {
      callback(response);
    });
  }

  const UpdateInvoiceNfIssued = async (invoiceId, issueDocument, callback) => {
    const updatedBy = userData.id;
    UpdateInvoiceNfIssuedAPI(invoiceId, issueDocument, updatedBy, (response) => {
      callback(response);
    });
  }

  const GetInvoice = async (id, callback) => {
    GetInvoiceAPI(id, (response) => {
      callback(response);
    });
  }

  const UpdateCompanyConfig = async (data, callback) => {
    setIsUpdating(true);
    UpdateCompanyConfigAPI(companyId, data, (result) => {
      GetCompany((result) => {
        setIsUpdating(false);
        callback(result);
      });
    });
  }

  const UpdateCompany = async (data, callback) => {
    
    setIsUpdating(true);

    UpdateCompanyAPI(companyId, data, (result) => {
      GetCompany((result) => {
        setIsUpdating(false);
        callback(result);
      });
    });
  }

  const GetCompany = async (callback) => {

    GetCompanyConfigAPI(companyId, (result) => {
      let data = {};

      data.Id = result.id;
      data.CNPJ = result.cnpj;
      data.Logo = result.logo;

      data.Name = result.name;

      data.Phone = result.phone;
      data.Email = result.email;

      data.Cep = result.cep;
      data.District = result.district;
      data.City = result.city;
      data.State = result.state;
      data.Country = result.country;
      data.Address = result.address;
      data.Number = result.number;
      data.Complement = result.complement;

      data.AvailableDaysOfWeek = JSON.parse(result.availableDaysOfWeek);
      data.AvailableTime = JSON.parse(result.availableTime);
      data.BlockedTimes = JSON.parse(result.blockedTimes);
      
      data.TargetOccupation = JSON.parse(result.targetOccupation);
      data.TimeInterval = JSON.parse(result.timeInterval);
      data.GoalAppointments = JSON.parse(result.goalAppointments);
      data.GoalBilling = JSON.parse(result.goalBilling);
      data.Resources = result.resources;
      data.Procedures = result.procedures;
      data.PaymentOptions = result.paymentOptions;
      data.PatientOrigins = result.patientOrigins;

      data.PatientComplaints = result.patientComplaints;
      data.DocumentTemplates = result.documentTemplates;
      data.AnamneseTemplates = result.anamneseTemplates;
      data.MessageTemplates = result.messageTemplates;

      data.LeadsSources = result.leadsSources;
      data.LeadsCampaigns = result.leadsCampaigns;
      

      setCompanyConfig(data);

      if(callback) callback(data);
    });
  };

  const GetCEPInfo = (cep, callback) => {
    GetCEPInfoAPI(cep, (result) => {
      callback(result);
    });
  }

  const GetPatients = async (dispatchUpdatingEvent = true, callback) => {
    if (dispatchUpdatingEvent) setIsUpdating(true);
    GetPatientsAPI(companyId, (result) => {
      SetPatients(result);
      if (callback) callback(result);
      if (dispatchUpdatingEvent) setIsUpdating(false);
    });
  };

  const GetPatientById = (id) => {
    return patients.find((element) => {
      return element.id == id;
    })
  }

  const SetPatient = async (data, callback) => {

    const createdBy = userData.id;

    SetPatientAPI(companyId, createdBy, data, (response) => {
      if(callback) callback(response);
      GetPatients(false);
    });
  }

  const UpdatePatient = async (data, callback) => {
    UpdatePatientAPI(data, () => {
      GetPatients();
      if(callback) callback();
    });
  }


  const GetAppointments = async (startDate, endDate, callback) => {

    GetAppointmentsAPI(companyId, startDate, endDate, (result) => {

      setAppointments(result);
      
      if(callback)
      {
        callback(result);
      }
    });
  };


  const SetAppointment = async (data, callback) => {

    const createdBy = userData.id;

    SetAppointmentAPI(companyId, createdBy, data, (response) => {

      if (callback !== undefined) {
        callback(response);
      }
    });
  }

  const UpdateAppointment = async (data, callback) => {
    UpdateAppointmentAPI(data, () => {
      if(callback) callback();
    });
  }

  const DeleteAppointment = async (id, callback) => {
    DeleteAppointmentAPI(id, () => {
      if(callback) callback();
    });
  }

  const GetLeads = async (callback) => {
    GetLeadsAPI(companyId, (response) => {
      if(callback) callback(response);
    });
  }

  const SetLead = async (data, callback) => {

    const createdBy = userData.id;
      
   SetLeadAPI(createdBy, data, (response) => {
        if(callback) callback(response);
      });
  }

  const UpdateLead = async (data, callback) => {

    const updatedBy = userData.id;

    UpdateLeadAPI(updatedBy, data, (response) => {
      if(callback) callback(response);
    });

  }

  const syncDocumentTemplatesState = async (templates) => {
    setCompanyConfig(prevConfig => ({
      ...prevConfig,
      DocumentTemplates: templates
    }));
  };

  const GetTemplatesDocuments = async (callback) => {
    GetTemplatesDocumentsAPI(companyId, (response) => {
      if(callback) callback(response);
    });
  }

  const SetTemplatesDocuments = async (data, callback) => {
    const createdBy = userData.id;

    SetTemplatesDocumentsAPI(companyId, createdBy, data, (response) => {
      GetTemplatesDocumentsAPI(companyId, (templates) => {
        syncDocumentTemplatesState(templates);
        if(callback) callback(response);
      });
    });
  }

  const UpdateTemplatesDocuments = async (data, callback) => {
    const updatedBy = userData.id;

    UpdateTemplatesDocumentsAPI(companyId, updatedBy, data, (response) => {
      GetTemplatesDocumentsAPI(companyId, (templates) => {
        syncDocumentTemplatesState(templates);
        if(callback) callback(response);
      });
    });
  }

  const DeleteTemplatesDocuments = async (id, callback) => {
    DeleteTemplatesDocumentsAPI(id, (response) => {
      GetTemplatesDocumentsAPI(companyId, (templates) => {
        syncDocumentTemplatesState(templates);
        if(callback) callback(response);
      });
    });
  }

  const GetMessageTemplates = async (callback) => {
    return new Promise((resolve, reject) => {
      try {
        GetCompanyMessageTemplatesAPI(companyId, (response) => {
          if (response) {
            syncCompanyMessageTemplatesState(response);
            if(callback) callback(response);
            resolve(response);
          } else {
            resolve([]); // Resolve with empty array instead of rejecting
          }
        });
      } catch (error) {
        resolve([]); // Resolve with empty array instead of rejecting
      }
    });
  }

  const UpdateMessageTemplate = async (id, template_content, callback) => {
    UpdateCompanyMessageTemplateAPI(id, template_content, (response) => {
      GetCompanyMessageTemplatesAPI(companyId, (templates) => {
        syncCompanyMessageTemplatesState(templates);
        if(callback) callback(response);
      });
    });
  }

  const GetDashboard = async (data) => {
    GetDashboardAPI(companyId, data, () => {
    });
  }

  const GetStatistics = async (startDate, endDate, callback) => {
    GetStatisticsAPI(companyId, startDate, endDate, callback);
  }

  const GetReportsAppointments = async (startDate, endDate, callback) => {
    GetReportsAppointmentsAPI(companyId, startDate, endDate,callback);
  }

  const GetReportsFinancial = async (startDate, endDate, callback) => {
    GetReportsFinancialAPI(companyId, startDate, endDate, callback);
  }

  function GetProcedureById(id) {
    return CompanyConfig.Procedures.find(p => p.Id == id);
  }

  function GetResourceById(id) {
    return CompanyConfig.Resources.find(p => p.Id == id);
  }

  const SetPatientAnamnese = async (currentDate, patientId, appointmentId, json, template, callback) => {
    const createdBy = userData.id;

    SetPatientAnamneseAPI(currentDate, createdBy, patientId, appointmentId, json, template, (response) => {
      callback(response);
    });
  };

  const UpdatePatientAnamnese = async (id, patientId, json, callback) => {
    const updatedBy = userData.id;

    UpdatePatientAnamneseAPI(id, patientId, json, updatedBy, (response) => {
      callback(response);
    });
  };

  const DeletePatientAnamnese = async (id, callback) => {
    const deletedBy = userData.id;

    DeletePatientAnamneseAPI(id, deletedBy, (response) => {
      callback(response);
    });
  };

  const SetPatientHistory = async (currentDate, patientId, appointmentId, assessment, evolution, callback) => {

    const createdBy = userData.id;

    SetPatientHistoryAPI(currentDate, createdBy, patientId, appointmentId, assessment, evolution, (response) => {
      callback(response);
    });
  };

  const SetPatientCranialAsymmetry = async (currentDate, patientId, appointmentId, ab, bp, dd, de, callback) => {
    const createdBy = userData.id;

    SetPatientCranialAsymmetryAPI(currentDate, createdBy, patientId, appointmentId, ab, bp, dd, de, (response) => {
      callback(response);
    });
  }

  const UpdatePatientCranialAsymmetry = async (id, currentDate, patientId, appointmentId, ap, bp, dd, de, callback) => {
    UpdatePatientCranialAsymmetryAPI(id, currentDate, patientId, appointmentId, ap, bp, dd, de, (response) => {
      callback(response);
    });
  }

  const DeletePatientCranialAsymmetry = async (id, callback) => {
    DeletePatientCranialAsymmetryAPI(id, (response) => {
      callback(response);
    });
  }

  const SetPatientDocument = async (patientId, date, appointmentId, title, description, callback) => {

    const createdBy = userData.id;

    SetPatientDocumentAPI(patientId, date, appointmentId, title, description, createdBy, (response) => {
      callback(response);
    });
  };

  const GetPatientDocuments = async (patientId, callback) => {
    GetPatientDocumentsAPI(patientId, (response) => {
      callback(response);
    });
  };

  const GetWaitingList = async (params, callback) => {
    // Ensure companyId is set
    params.companyId = params.companyId || companyId;
    GetWaitingListAPI(params, callback);
  }

  const SetWaitingList = async (data, callback) => {
    const createdBy = userData.id;
    SetWaitingListAPI(companyId, createdBy, data, callback);
  }

  const UpdateWaitingList = async (data, callback) => {
    const updatedBy = userData.id;
    UpdateWaitingListAPI(updatedBy, data, callback);
  }

  const DeleteWaitingList = async (id, callback) => {
    DeleteWaitingListAPI(id, callback);
  }

  const CheckWaitingListAvailability = async (params, callback) => {
    // Ensure companyId is set
    params.companyId = params.companyId || companyId;
    CheckWaitingListAvailabilityAPI(params, callback);
  }

  const SendWhatsappMessage = async (to, text, delay, callback) => {
    SendWhatsappMessageAPI(to, text, delay, (result) => {
      if(callback) callback(result);
    });
  }

  const GetWhatsappChats = async (callback) => {
    GetWhatsappChatsAPI(companyId, (result) => {
      if(callback) callback(result);
    });
  }
 
  const GetWhatsappChatMessages = async (chatId, callback) => {
    GetWhatsappChatMessagesAPI(companyId, chatId, (result) => {
      if(callback)  callback(result);
    });
  }

  const GetAIAnswer = async (questions, callback) => {
    
      GetAIAnswerAPI(companyId, questions, (result) => {
        if(callback) callback(result);
      });
  }

  const GetNPSStatistics = async (startDate, endDate, callback) => {
    return new Promise((resolve) => {
      GetNPSStatisticsAPI(companyId, startDate, endDate, (result) => {
        if(callback) callback(result);
        resolve(result);
      });
    });
  }

  const GetNPSResponses = async (startDate, endDate, category, callback) => {
    return new Promise((resolve) => {
      GetNPSResponsesAPI(companyId, startDate, endDate, category, (result) => {
        if(callback) callback(result);
        resolve(result);
      });
    });
  }

  const SetNPSResponse = async (patientId, appointmentId, score, comments, callback) => {
    SetNPSResponseAPI(companyId, patientId, appointmentId, score, comments, (result) => {
      if(callback) callback(result);
    });
  }

  const UpdateNPSGoogleReview = async (reviewId, status, callback) => {
    UpdateNPSGoogleReviewAPI(companyId, reviewId, status, (result) => {
      if(callback) callback(result);
    });
  }

  // Financial Categories
  const GetFinancialCategories = async (callback) => {
    GetFinancialCategoriesAPI(companyId, (result) => {
      if(callback) callback(result);
    });
  }

  const SetFinancialCategory = async (data, callback) => {
    SetFinancialCategoryAPI(companyId, data, (result) => {
      if(callback) callback(result);
    });
  }

  const UpdateFinancialCategory = async (data, callback) => {
    UpdateFinancialCategoryAPI({
      ...data,
      companyId: companyId
    }, (result) => {
      if(callback) callback(result);
    });
  }

  // Financial Transactions
  const GetActiveRecurringTransactions = async (callback) => {
    GetActiveRecurringTransactionsAPI(companyId, (result) => {
      if(callback) callback(result);
    });
  }

  const GetFinancialTransactions = async (startDate, endDate, type, categoryId, callback) => {
    GetFinancialTransactionsAPI(companyId, startDate, endDate, type, categoryId, (result) => {
      if(callback) callback(result);
    });
  }

  const SetFinancialTransaction = async (data, callback) => {
    data.createdBy = userData.id;
    SetFinancialTransactionAPI(companyId, data, (result) => {
      if(callback) callback(result);
    });
  }

  const UpdateFinancialTransaction = async (data, callback) => {
    UpdateFinancialTransactionAPI(data, (result) => {
      if(callback) callback(result);
    });
  }

  const DeleteFinancialTransaction = async (id, callback) => {
    DeleteFinancialTransactionAPI(companyId, id, (result) => {
      if(callback) callback(result);
    });
  }

  // Financial Dashboard
  const GetFinancialDashboard = async (month, year, callback) => {
    GetFinancialDashboardAPI(companyId, month, year, (result) => {
      if(callback) callback(result);
    });
  }

  const GetFinancialStatistics = async (startDate, endDate, callback) => {
    GetFinancialStatisticsAPI(companyId, startDate, endDate, (result) => {
      if(callback) callback(result);
    });
  }

  // Resources APIs
  const syncCompanyResourcesState = async (resources) => {
    setCompanyConfig(prevConfig => ({
      ...prevConfig,
      Resources: resources
    }));
  };

  const syncCompanyProceduresState = async (procedures) => {
    setCompanyConfig(prevConfig => ({
      ...prevConfig,
      Procedures: procedures
    }));
  };

  const syncCompanyPaymentMethodsState = async (paymentMethods) => {
    setCompanyConfig(prevConfig => ({
      ...prevConfig,
      PaymentOptions: paymentMethods
    }));
  };

  const syncAnamnesisTemplatesState = async (templates) => {
    setCompanyConfig(prevConfig => ({
      ...prevConfig,
      AnamneseTemplates: templates
    }));
  };

  const GetAnamnesisTemplates = async (callback) => {
    GetAnamnesisTemplatesAPI(companyId, (response) => {
      if(callback) callback(response);
    });
  };

  const SetAnamnesisTemplate = async (label, questions, callback) => {
    SetAnamnesisTemplateAPI(companyId, { label, questions }, (response) => {
      GetAnamnesisTemplatesAPI(companyId, (templates) => {
        syncAnamnesisTemplatesState(templates);
        if(callback) callback(response);
      });
    });
  };

  const UpdateAnamnesisTemplate = async (data, callback) => {
    UpdateAnamnesisTemplateAPI(data, (response) => {
      GetAnamnesisTemplatesAPI(companyId, (templates) => {
        syncAnamnesisTemplatesState(templates);
        if(callback) callback(response);
      });
    });
  };

  const DeleteAnamnesisTemplate = async (id, callback) => {
    DeleteAnamnesisTemplateAPI(id, (response) => {
      GetAnamnesisTemplatesAPI(companyId, (templates) => {
        syncAnamnesisTemplatesState(templates);
        if(callback) callback(response);
      });
    });
  };

  const syncCompanyMessageTemplatesState = async (messageTemplates) => {
    if (!messageTemplates || messageTemplates.length === 0) {
      return;
    }
    
    return new Promise(resolve => {
      setCompanyConfig(prevConfig => {
        if (!prevConfig) {
          resolve();
          return prevConfig;
        }
        
        const newConfig = {
          ...prevConfig,
          MessageTemplates: messageTemplates
        };
        
        // Use setTimeout to ensure the state update is processed
        setTimeout(() => {
          resolve();
        }, 0);
        
        return newConfig;
      });
    });
  };

  const GetCompanyResources = async (callback) => {
    GetCompanyResourcesAPI(companyId, (result) => {
      if(callback) callback(result);
    });
  }

  const SetCompanyResource = async (data, callback) => {
    SetCompanyResourceAPI(companyId, data, (result) => {
      GetCompanyResourcesAPI(companyId, (resources) => {
        syncCompanyResourcesState(resources);
        if(callback) callback(result);
      });
    });
  }

  const UpdateCompanyResource = async (data, callback) => {
    UpdateCompanyResourceAPI(data, (result) => {
      GetCompanyResourcesAPI(companyId, (resources) => {
        syncCompanyResourcesState(resources);
        if(callback) callback(result);
      });
    });
  }

  const DeleteCompanyResource = async (id, callback) => {
    DeleteCompanyResourceAPI(id, (result) => {
      GetCompanyResourcesAPI(companyId, (resources) => {
        syncCompanyResourcesState(resources);
        if(callback) callback(result);
      });
    });
  }

  // Procedures APIs
  const GetCompanyProcedures = async (callback) => {
    GetCompanyProceduresAPI(companyId, (result) => {
      if(callback) callback(result);
    });
  }

  const SetCompanyProcedure = async (data, callback) => {
    SetCompanyProcedureAPI(companyId, data, (result) => {
      GetCompanyProceduresAPI(companyId, (procedures) => {
        syncCompanyProceduresState(procedures);
        if(callback) callback(result);
      });
    });
  }

  const UpdateCompanyProcedure = async (data, callback) => {
    UpdateCompanyProcedureAPI(data, (result) => {
      GetCompanyProceduresAPI(companyId, (procedures) => {
        syncCompanyProceduresState(procedures);
        if(callback) callback(result);
      });
    });
  }

  const DeleteCompanyProcedure = async (id, callback) => {
    DeleteCompanyProcedureAPI(id, (result) => {
      GetCompanyProceduresAPI(companyId, (procedures) => {
        syncCompanyProceduresState(procedures);
        if(callback) callback(result);
      });
    });
  }

  // Payment Methods APIs
  const GetCompanyPaymentMethods = async (callback) => {
    GetCompanyPaymentMethodsAPI(companyId, (result) => {
      if(callback) callback(result);
    });
  }

  const SetCompanyPaymentMethod = async (data, callback) => {
    SetCompanyPaymentMethodAPI(companyId, data, (result) => {
      GetCompanyPaymentMethodsAPI(companyId, (paymentMethods) => {
        syncCompanyPaymentMethodsState(paymentMethods);
        if(callback) callback(result);
      });
    });
  }

  const UpdateCompanyPaymentMethod = async (data, callback) => {
    UpdateCompanyPaymentMethodAPI(data, (result) => {
      GetCompanyPaymentMethodsAPI(companyId, (paymentMethods) => {
        syncCompanyPaymentMethodsState(paymentMethods);
        if(callback) callback(result);
      });
    });
  }

  const DeleteCompanyPaymentMethod = async (id, callback) => {
    DeleteCompanyPaymentMethodAPI(id, (result) => {
      GetCompanyPaymentMethodsAPI(companyId, (paymentMethods) => {
        syncCompanyPaymentMethodsState(paymentMethods);
        if(callback) callback(result);
      });
    });
  }

  // Automation Settings functions
  const GetCompanyAutomationSettings = async (callback) => {
    return new Promise((resolve) => {
      GetCompanyAutomationSettingsAPI(companyId, (result) => {
        if(callback) callback(result);
        resolve(result);
      });
    });
  }

  const UpdateCompanyAutomationSetting = async (automationType, isEnabled, callback) => {
    return new Promise((resolve) => {
      UpdateCompanyAutomationSettingAPI(companyId, automationType, isEnabled, (result) => {
        if(callback) callback(result);
        resolve(result);
      });
    });
  }

  const allData =
  {
    isUpdating,
    UserData,
    CompanyConfig, UpdateCompany,UpdateCompanyConfig,
    GetLeads, SetLead , UpdateLead,
    GetProcedureById, GetResourceById,
    GetCEPInfo,
    GetDashboard,
    GetStatistics,
    GetReportsAppointments,GetReportsFinancial,
    patients, GetPatients, SetPatient, UpdatePatient, GetPatientById,
    SetPatientAnamnese, UpdatePatientAnamnese, DeletePatientAnamnese, SetPatientDocument, GetPatientDocuments,
    appointments,GetAppointments, SetAppointment, UpdateAppointment, DeleteAppointment,
    GetWaitingList, SetWaitingList, UpdateWaitingList, DeleteWaitingList, CheckWaitingListAvailability,
    GetInvoice, SetAppointmentInvoice,UpdateInvoice,UpdateInvoiceNfIssued,
    SetPatientHistory, SetPatientCranialAsymmetry, UpdatePatientCranialAsymmetry, DeletePatientCranialAsymmetry,
    GetTemplatesDocuments, SetTemplatesDocuments, UpdateTemplatesDocuments, DeleteTemplatesDocuments, syncDocumentTemplatesState,
    GetMessageTemplates, UpdateMessageTemplate, syncCompanyMessageTemplatesState,
    GetAnamnesisTemplates, SetAnamnesisTemplate, UpdateAnamnesisTemplate, DeleteAnamnesisTemplate,
    GetWhatsappChats,GetWhatsappChatMessages, SendWhatsappMessage,
    GetAIAnswer,
    // Export functions
    CreateExportJob: (callback) => {
      CreateExportJobAPI(companyId, (result) => {
        if(callback) callback(result);
      });
    },
    GetExportStatus: (exportId, callback) => {
      GetExportStatusAPI(exportId, companyId, (result) => {
        if(callback) callback(result);
      });
    },
    GetExportHistory: (callback) => {
      GetExportHistoryAPI(companyId, (result) => {
        if(callback) callback(result);
      });
    },
    CancelExport: (exportId, callback) => {
      CancelExportAPI(exportId, companyId, (result) => {
        if(callback) callback(result);
      });
    },
    // NPS functions
    GetNPSStatistics, GetNPSResponses, SetNPSResponse, UpdateNPSGoogleReview,
    // Financial functions
    GetFinancialCategories, SetFinancialCategory, UpdateFinancialCategory,
    GetFinancialTransactions, SetFinancialTransaction, UpdateFinancialTransaction, DeleteFinancialTransaction, GetActiveRecurringTransactions,
    GetFinancialDashboard, GetFinancialStatistics,
    // Resources and Procedures functions
    GetCompanyResources, SetCompanyResource, UpdateCompanyResource, DeleteCompanyResource,
    GetCompanyProcedures, SetCompanyProcedure, UpdateCompanyProcedure, DeleteCompanyProcedure,
    GetCompanyPaymentMethods, SetCompanyPaymentMethod, UpdateCompanyPaymentMethod, DeleteCompanyPaymentMethod,
    syncCompanyResourcesState, syncCompanyProceduresState, syncCompanyPaymentMethodsState,
    // Automation Settings
    GetCompanyAutomationSettings, UpdateCompanyAutomationSetting,
    // Package functions
    GetPackages: (callback) => {
      GetServicePackagesAPI(companyId, (result) => {
        if(callback) callback(result);
      });
    },
    CreatePackage: (data, callback) => {
      CreateServicePackageAPI(companyId, data, (result) => {
        if(callback) callback(result);
      });
    },
    UpdatePackage: (packageId, data, callback) => {
      UpdateServicePackageAPI(packageId, data, (result) => {
        if(callback) callback(result);
      });
    },
    DeletePackage: (packageId, callback) => {
      DeleteServicePackageAPI(packageId, (result) => {
        if(callback) callback(result);
      });
    },
    // Wallet functions
    GetPatientWallet: (patientId, callback) => {
      GetPatientWalletAPI(patientId, (result) => {
        if(callback) callback(result);
      });
    },
    GetWalletTransactions: (walletId, startDate, endDate, callback) => {
      GetWalletTransactionsAPI(walletId, startDate, endDate, (result) => {
        if(callback) callback(result);
      });
    },
    GetServicePackages: (callback) => {
      GetServicePackagesAPI(companyId, (result) => {
        if(callback) callback(result);
      });
    },
    PurchaseServicePackage: (walletId, packageId, paymentMethod, paymentStatus, callback) => {
      const createdBy = userData.id;
      PurchaseServicePackageAPI(walletId, packageId, paymentMethod, paymentStatus, createdBy, (result) => {
        if(callback) callback(result);
      });
    },
    DeductWalletCredits: (walletId, procedureId, description, callback) => {
      const createdBy = userData.id;
      DeductWalletCreditsAPI(walletId, procedureId, description, createdBy, (result) => {
        if(callback) callback(result);
      });
    }
  };

  return (
    <DataContext.Provider value={allData}>
      {children}
    </DataContext.Provider>
  );
};

export const DataProviderInstance = () => useContext(DataContext);